import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { UploadStore } from '../../stores/upload/UploadStore';
import { Loader } from '../../components/loader/Loader';
import { ReactComponent as UploadIcon } from '../../assets/icon/upload-document.svg';

import './FileUploadZone.scss';

interface IFileUploadDropZone {
  className?: string;
  label?: string;
  store: UploadStore;
}

const FileUploadZone = observer(
  ({ store, label, className }: IFileUploadDropZone): JSX.Element => {
    const { t } = useTranslation();

    const onDrop = useCallback(
      (acceptedFiles: Array<File>) => {
        store.setLoading(true);

        for (const file of acceptedFiles) {
          store.handleFileDrop(file.name, file, file.type, file.size);
        }

        store.validateFilenames();

        store.setLoading(false);
      },
      [store],
    );

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop });

    return (
      <section className={cn('FileUploadZone', className)}>
        {label && <label className="control__label">{label}</label>}

        <div className="FileUploadZone-square-container">
          <div {...getRootProps({ className: `FileUploadZone-dropzone ${isDragAccept ? 'FileUploadZone-dropzone-active' : undefined}` })}>
            {store.isLoading ? (
              <Loader />
            ) : (
              <div className="FileUploadZone-text-container">
                <UploadIcon />
                <input {...getInputProps()} />
                <div className="FileUploadZone-dropzone-content">
                  <div className="body-xs space-m-l-4">
                    {t('common.drag_drop')} {t('common.or')} <span className="FileUploadZone-browse-text">{t('common.browse')}</span>{' '}
                    {t('common.to_choose_file')}.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  },
);

export default FileUploadZone;
