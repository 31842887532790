import { ITransformationArguments, IMetadataDigitalContent } from '@cdam/shared';
import ClientHttpService, { IClientHttpServiceOptions, UrlType } from '../services/ClientHttpService';
import DialogStore from '../stores/dialog/DialogStore';

export default {
  transformationArgsToQuery(transformationArgs: ITransformationArguments | undefined): string {
    if (!transformationArgs || Object.keys(transformationArgs).length < 1) {
      return '';
    }

    return `?${Object.entries(transformationArgs)
      .map((entry) => `${encodeURIComponent(entry[0])}=${encodeURIComponent(entry[1])}`)
      .join('&')}`;
  },
  async handleItemDownload(
    e: React.MouseEvent<HTMLAnchorElement | HTMLSpanElement> | null,
    url: string,
    fileName: string,
    urlType = UrlType.MetadataAkamai,
  ): Promise<void> {
    if (e) {
      e.preventDefault();
    }

    await DialogStore.execFullScreen(this.download(url, fileName, urlType));
  },
  async download(url: string, fileName: string, urlType = UrlType.Metadata): Promise<void> {
    try {
      const options: IClientHttpServiceOptions = {
        urlType,
        url,
        method: 'GET',
      };

      const blob = await ClientHttpService.fetchBlob(options);

      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
      } else {
        const objectUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');

        anchor.href = objectUrl;
        anchor.download = fileName;
        anchor.click();
        URL.revokeObjectURL(objectUrl);
      }
    } catch (e) {
      DialogStore.error(e);
    }
  },
  getFileNameWithExtension(digitalContent?: IMetadataDigitalContent): string {
    if (!digitalContent) {
      return '';
    }

    const { fileName, storageURL } = digitalContent;

    return fileName.lastIndexOf('.') !== -1 ? fileName : `${fileName}.${storageURL.slice(storageURL.lastIndexOf('.'))}`;
  },
};
