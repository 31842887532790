import i18n from '../i18n';

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class Formatter {
  public static getDateTime(ISODateTime: string): string {
    const date = new Date(ISODateTime);

    if (this.isToday(date)) {
      return `${i18n.t('formatter.today')} ${i18n.t('formatter.at')} ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
    } else if (this.isYesterday(date)) {
      return `${i18n.t('formatter.yesterday')} ${i18n.t('formatter.at')} ${date.toLocaleTimeString(undefined, {
        hour: '2-digit',
        minute: '2-digit',
      })}`;
    }

    return `${this.prepend0ToNumber(date.getDate())} ${date.toLocaleString(undefined, {
      month: 'short',
    })} ${date.getFullYear()} ${i18n.t('formatter.at')} ${date.toLocaleTimeString(undefined, { hour: '2-digit', minute: '2-digit' })}`;
  }

  public static getNumber(number: number): string {
    return number.toLocaleString(undefined);
  }

  public static getFileSizeFromBytes(bytes: number, decimals: number): string {
    if (!bytes) {
      return i18n.t('formatter.unknown_size');
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${this.getNumber(parseFloat((bytes / Math.pow(k, i)).toFixed(dm)))} ${sizes[i]}`;
  }

  public static getSumFileSizes(sizes: Array<number>): string {
    if (!sizes) {
      return i18n.t('formatter.unknown_size');
    }

    let allFilesSize = 0;

    sizes.forEach((element) => {
      allFilesSize = allFilesSize + element;
    });

    return this.getFileSizeFromBytes(allFilesSize, 1);
  }

  private static isToday(date: Date): boolean {
    const now = new Date();
    const input = new Date(date);

    return now.getDate() === input.getDate() && now.getMonth() === input.getMonth() && now.getFullYear() === input.getFullYear();
  }

  private static isYesterday(date: Date): boolean {
    const now = new Date();
    const input = new Date(date);

    return now.getDate() === input.getDate() + 1 && now.getMonth() === input.getMonth() && now.getFullYear() === input.getFullYear();
  }

  private static prepend0ToNumber(number: number): string {
    if (number < 10) {
      return `0${number}`;
    }

    return number.toString();
  }

  // If toLocaleTimeString won't work as expected, use this manual way of displaying time
  // private static getAMPMTime(date: Date): string {
  //   const hours = date.getHours();
  //   const minutes = date.getMinutes();

  //   const ampm = hours >= 12 ? 'PM' : 'AM';

  //   const ampmFormatHours = hours % 12;

  //   const normalizedHours = ampmFormatHours ? ampmFormatHours : 12;
  //   const normalizedMinutes = this.append0ToNumber(minutes);

  //   return `${this.append0ToNumber(normalizedHours)}:${normalizedMinutes} ${ampm}`;
  // }
}
