import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import Icon from 'components/icons/Icon';

import './MatrixItemDeleteDialog.scss';

interface IConfirmDialogProps {
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  translationKey: string;
  deleteBtnTranslationKey: string;
}

const MatrixItemDeleteDialog = observer(({ deleteBtnTranslationKey, onCancel, onConfirm, translationKey }: IConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <div className="MatrixItemDeleteDialog modal">
      <Icon icon="icon-attention-big" height={40} width={40} />
      <div className="MatrixItemDeleteDialog-text">{t(translationKey)}</div>
      <Button className="MatrixItemDeleteDialog-button space-m-r-2" buttonType={ButtonType.Secondary} size={ButtonSize.Default} onClick={onCancel}>
        {t('common.cancel')}
      </Button>
      <Button
        className="MatrixItemDeleteDialog-delete-button space-m-l-2"
        buttonType={ButtonType.Primary}
        size={ButtonSize.Default}
        onClick={onConfirm}
      >
        {t(deleteBtnTranslationKey)}
      </Button>
    </div>
  );
});

export default MatrixItemDeleteDialog;
