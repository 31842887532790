/* eslint-disable complexity, @typescript-eslint/return-await, no-await-in-loop */
import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import {
  IUploadTemplate,
  IUploadTemplateInfo,
  IMetadataSearchAttribute,
  IUniqueNameResponse,
  appLocationEnum,
  Name,
  assetGroupIdsEnum,
  assetGroupsEnum,
  AppVariantEnum,
  IMatrixOwnersItem,
  AssetDomainsNamesEnums,
  AuthGroup,
} from '@cdam/shared';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import DialogStore from 'stores/dialog/DialogStore';
import NavigationService from 'services/NavigationService';
import FilterUtils from 'utils/FilterUtils';
import ConfigUtils from 'utils/ConfigUtils';
import ViewStore from '../view/ViewStore';
import TemplateConfig from '../../configs/TemplateConfig';
import { IMatrixOwnerSearchable } from '../../containers/search/dialogs/components/MatrixSettingsOwners';
import SecurityUtils from '../../utils/SecurityUtils';
import ProfileStore from '../profile/ProfileStore';
import { getEmptyTemplate } from './data/EmptyTemplate';

enum TemplateMode {
  Edit,
  Create,
}

export default class TemplateStore {
  @observable public isLoading = false;
  @observable public error: Error | null = null;
  @observable public nameIsUnique = true;
  @observable public templatesResults: Array<IUploadTemplateInfo> | null = null;
  @observable public selectedTemplate: IUploadTemplate | null = null;
  @observable public selectedTemplateId: string | undefined = undefined;
  @observable public metadataFilters: Array<AssetFilter> = [];
  @observable public taxonomyMetadataFilters: Array<AssetFilter> = [];
  @observable public mandatoryFilters: Array<AssetFilter> = [];
  @observable public allOwners: Array<IMatrixOwnersItem> = [];
  @observable public generalItemOwners: Array<IMatrixOwnersItem> = [];
  @observable public editingItemOwners: Array<IMatrixOwnersItem> = [];

  private templates: Array<IUploadTemplateInfo> | null = null;
  private mode: TemplateMode | undefined = undefined;
  private cachedTemplate = '';
  private lastCall: number | undefined = undefined;
  private lastCallTimer: any = undefined;
  private readonly debounceMilliseconds = 500;

  public constructor() {
    makeObservable(this);
  }

  @computed
  // eslint-disable-next-line complexity
  public get saveDisabled(): boolean {
    if ((this.selectedTemplate && this.selectedTemplate.name === '') || !this.nameIsUnique) {
      return true;
    }

    if (this.generalItemOwners.length === 0) {
      return true;
    }

    for (const filter of this.mandatoryFilters) {
      if (filter.attribute.name === 'productView' && this.selectedTemplate && this.selectedTemplate.extractProductViews) {
        if (filter.selectedReferenceItems.length !== 0) {
          return true;
        }
      } else if (filter.attribute.name === 'articleNumber' && this.selectedTemplate && this.selectedTemplate.extractArticleNumbers) {
        if (filter.selectedReferenceItems.length !== 0) {
          return true;
        }
      }

      // else if (filter.attribute.referenceType && filter.selectedReferenceItems.length === 0) {
      //   return true;
      // }
    }

    return false;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public searchTemplates(searchString: string): void {
    if (searchString.length === 0) {
      this.templatesResults = this.templates;
    }

    this.templatesResults = this.templates?.filter((template) => template.name.includes(searchString)) ?? [];
  }

  @action
  public async load(templateId?: string): Promise<void> {
    this.isLoading = true;
    this.error = null;

    const owners = await this.getAllOwners();

    runInAction(() => {
      this.allOwners = owners;
      this.selectedTemplateId = templateId;
      this.selectedTemplate = null;
    });

    try {
      if (!this.templates) {
        const templateList = await ClientHttpService.fetch<Array<IUploadTemplateInfo>>({
          urlType: UrlType.Backend,
          url: `/upload-templates/${ViewStore.selectedAssetDomain}`,
          method: 'GET',
        });

        runInAction(() => {
          this.templates = templateList;
          this.templatesResults = templateList;
          this.isLoading = false;
        });
      }

      let template: IUploadTemplate | null = null;

      if (this.selectedTemplateId === 'new') {
        runInAction(() => {
          this.generalItemOwners = this.allOwners.filter((owner) => owner.username.includes(ProfileStore.displayName));
          this.selectedTemplate = getEmptyTemplate();
          this.mode = TemplateMode.Create;
          this.loadMetadataFilters();
          this.isLoading = false;
        });
      } else if (this.selectedTemplateId) {
        template = await ClientHttpService.fetch<IUploadTemplate>({
          urlType: UrlType.Backend,
          url: `/upload-templates/template/${this.selectedTemplateId}`,
          method: 'GET',
        });

        runInAction(() => {
          this.generalItemOwners = template?.ownersRaw ?? [];
          this.selectedTemplate = template;
          this.nameIsUnique = true;
          this.mode = TemplateMode.Edit;
          this.loadMetadataFilters();
          this.isLoading = false;
        });
      }

      this.cachedTemplate = JSON.stringify(this.selectedTemplate);
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public addToEditingItemOwners(owner: IMatrixOwnersItem): void {
    this.editingItemOwners.push(owner);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public removeEditingItemOwners(index: number): void {
    this.editingItemOwners = this.editingItemOwners.filter((_, i) => index !== i);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public addToGeneralItemOwners(owner: IMatrixOwnersItem): void {
    this.generalItemOwners.push(owner);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public removeGeneralItemOwners(index: number): void {
    this.generalItemOwners = this.generalItemOwners.filter((_, i) => index !== i);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public bulkUpdateFilters(): void {}

  @action
  public loadMetadataFilters(): void {
    if (ViewStore.appVariant === AppVariantEnum.ArchiveUI) {
      const allFilters = TaxonomyStore.attributeGroupList;
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const filterConfig = TemplateConfig[ViewStore.selectedAssetDomain]!;

      this.taxonomyMetadataFilters = allFilters.filter((filter) => filterConfig.allowedFilters.includes(filter.attribute.name));

      this.mandatoryFilters = [];
      filterConfig.defaultFilters.forEach((filterName) => {
        const filter = this.taxonomyMetadataFilters.find((f) => f.attribute.name === filterName);

        if (filter) {
          filter.setMandatory(true);
          filter.setCanToggleMandatory(false);
          filter.setCanBeRemoved(false);

          this.mandatoryFilters.push(filter);
        }
      });
    } else if (ViewStore.appVariant === AppVariantEnum.GateUI) {
      const assetGroupFilter = ConfigUtils.createFilter(Name.General, 'assetGroup', [assetGroupIdsEnum.PRODUCT_IMAGES], true);

      if (!assetGroupFilter) {
        return;
      }

      this.taxonomyMetadataFilters = [
        assetGroupFilter,
        ...TaxonomyStore.getTaxonomyAttributeFiltersWithRules(undefined, undefined, appLocationEnum.UPLOAD_PI, [assetGroupsEnum.PRODUCT_IMAGES]),
      ];
      this.mandatoryFilters = [
        assetGroupFilter,
        ...ConfigUtils.getMandatoryFilter(appLocationEnum.UPLOAD_PI, this.taxonomyMetadataFilters, [assetGroupsEnum.PRODUCT_IMAGES]),
      ];
    }

    if (this.mode === TemplateMode.Create) {
      this.metadataFilters = this.mandatoryFilters;
    } else if (this.mode === TemplateMode.Edit) {
      if (this.selectedTemplate) {
        this.metadataFilters = [];

        for (const preselectedFilter of this.selectedTemplate.attributes) {
          const filter = this.taxonomyMetadataFilters.find(
            (assetFilter) => assetFilter.attribute.name === preselectedFilter.name && assetFilter.groupName === preselectedFilter.group,
          );

          if (filter && preselectedFilter.valueList) {
            for (const val of preselectedFilter.valueList) {
              filter.changeValue(val.value);
            }

            filter.setMandatory(preselectedFilter.mandatory ?? true);

            if (this.mandatoryFilters.find((f) => f.attribute.name === filter.attribute.name)) {
              filter.setCanToggleMandatory(false);
              filter.setCanBeRemoved(false);
            }

            this.metadataFilters.push(filter);
          }
        }
      }
    }

    // TODO: define asset group based on user rights with UX
    this.toggleProductViewsFilter(!!this.selectedTemplate?.extractProductViews);
  }

  @action
  public async saveTemplate(): Promise<void> {
    if (!this.selectedTemplate) {
      return;
    }

    if (this.generalItemOwners.length === 0) {
      DialogStore.alert('Template must have at least one owner.');

      return;
    }

    this.selectedTemplate.attributes = FilterUtils.filtersToMetadataAttributes(this.metadataFilters);
    this.selectedTemplate.domain = ViewStore.selectedAssetDomain;
    this.selectedTemplate.owners = this.generalItemOwners.map((owner) => owner.username);
    this.selectedTemplate.ownersRaw = this.generalItemOwners;

    try {
      await DialogStore.execFullScreen(
        ClientHttpService.fetch({ urlType: UrlType.Backend, url: '/upload-templates/template', method: 'POST', body: this.selectedTemplate }),
      );

      await DialogStore.execFullScreen(this.resetPage(500));
    } catch (e) {
      DialogStore.error(e);
    }
  }

  @action
  public async deleteTemplate(): Promise<void> {
    if (!this.selectedTemplate) {
      return;
    }

    try {
      await DialogStore.execFullScreen(
        ClientHttpService.fetch({ urlType: UrlType.Backend, url: `/upload-templates/template/${this.selectedTemplateId}`, method: 'DELETE' }),
      );

      await DialogStore.execFullScreen(this.resetPage(1000));
    } catch (e) {
      DialogStore.error(e);
    }
  }

  @action
  public updateSelectedTemplateName(name: string): void {
    if (this.selectedTemplate) {
      this.selectedTemplate.name = name;
    }
  }

  @action
  public validateTemplateName(name: string): void {
    if (name) {
      const previousCall = this.lastCall;

      this.lastCall = Date.now();

      if (previousCall && this.lastCall - previousCall <= this.debounceMilliseconds) {
        clearTimeout(this.lastCallTimer);
      }

      this.lastCallTimer = setTimeout(() => {
        (async () => {
          const isUnique = await this.checkIfTemplateNameIsUnique(name);

          runInAction(() => {
            this.nameIsUnique = isUnique;
          });
        })();
      }, this.debounceMilliseconds);
    } else {
      this.nameIsUnique = true;
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public clearAllMetadataFilters(): void {
    this.metadataFilters = [...this.mandatoryFilters];
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    item.setMandatory(false);

    return FilterUtils.toggleSelectedFilter(item, filters);
  }

  @action
  public toggleExtractArticleNumbersCheckbox(): void {
    if (!this.selectedTemplate) {
      return;
    }

    this.selectedTemplate.extractArticleNumbers = !this.selectedTemplate.extractArticleNumbers;
  }

  @action
  public toggleExtractProductViewsCheckbox(): void {
    if (!this.selectedTemplate) {
      return;
    }

    this.selectedTemplate.extractProductViews = !this.selectedTemplate.extractProductViews;

    this.toggleProductViewsFilter(this.selectedTemplate.extractProductViews);
  }

  public unsavedChanges(): boolean {
    const template = JSON.stringify(this.selectedTemplate);

    if (template !== this.cachedTemplate) {
      return true;
    }

    if (this.metadataFiltersChanged()) {
      return true;
    }

    return false;
  }

  private async getAllOwners(): Promise<Array<IMatrixOwnerSearchable>> {
    // TODO: move values to settings/config
    let groups: Array<string> = [];

    switch (ViewStore.selectedAssetDomain) {
      case AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        groups = [AuthGroup.splash_archive_admin];
        break;
      case AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
      default:
        groups = [AuthGroup.dabox_archive_access];
    }

    const users = await SecurityUtils.getUsers(groups);

    return users.reduce<Array<IMatrixOwnerSearchable>>((acc, item) => {
      acc.push(item);

      return acc;
    }, []);
  }

  @action
  private toggleProductViewsFilter(value: boolean): void {
    const filter = this.metadataFilters.find((f) => f.groupName === 'Asset Information' && f.attribute.name === 'productView');

    if (filter) {
      filter.isDisabled = value;

      if (value) {
        filter.clearAllSelectedValues();
      }
    }
  }

  @action
  private async resetPage(delayMiliseconds: number): Promise<void> {
    // Delay resetting a page to give backend time to index new/deleted content
    await new Promise<void>((resolve) => setTimeout(() => resolve(), delayMiliseconds));

    this.templates = null;

    NavigationService.navigateTo('/edit-templates');

    this.load();
  }

  private metadataFiltersChanged(): boolean {
    const cachedTemplate = JSON.parse(this.cachedTemplate) as IUploadTemplate;
    let cachedFilters: Array<IMetadataSearchAttribute> = [];

    if (cachedTemplate) {
      cachedFilters = cachedTemplate.attributes;
    }

    const metadataFilters = FilterUtils.filtersToMetadataAttributes(this.metadataFilters);

    for (const cachedFilter of cachedFilters) {
      const metadataFilter = metadataFilters.find((f) => f.group === cachedFilter.group && f.name === cachedFilter.name);

      if (metadataFilter?.valueList) {
        for (const value of metadataFilter.valueList) {
          if (!cachedFilter.valueList?.find((v) => v.value === value.value)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  private async checkIfTemplateNameIsUnique(name: string): Promise<boolean> {
    const result = await ClientHttpService.fetch<IUniqueNameResponse>({
      urlType: UrlType.Backend,
      url: `/upload-templates/check-name?name=${name}`,
      method: 'GET',
    });

    return result.isUnique;
  }
}
