import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './polyfills';

import dotenv from 'dotenv';
import * as ReactDOM from 'react-dom';
import React, { Suspense } from 'react';
import { Router } from 'react-router-dom';
import { configure } from 'mobx';
import { ReactComponent as SymbolDef } from 'yarn-design-system-icons/dist/assets/yarn-svg-icons/symbol-defs.svg';
import UploadDownloadManager from 'containers/uploadDownloadManager/UploadDownloadManager';
import './style/GlobalStyles.scss';
import 'yarn-design-system/dist/yarn.css';
import 'yarn-design-system-icons/dist/yarn-icons.css';
import 'yarn-design-system-fonts/dist/yarn-fonts.min.css';
import './i18n';
import Dialog from './containers/dialog/Dialog';
import NavigationService from './services/NavigationService';
import { Loader } from './components/loader/Loader';
import Root from './Root';

dotenv.config();

// don't allow state modifications outside mobx actions
configure({
  enforceActions: 'observed',
  useProxies: 'never',
});

const AppInit = (): JSX.Element => (
  <Suspense fallback={<Loader />}>
    <Router history={NavigationService.history}>
      <SymbolDef />
      <Root />
    </Router>
    <Dialog />
    <UploadDownloadManager />
  </Suspense>
);

ReactDOM.render(<AppInit />, document.getElementById('root'));
