/* eslint-disable complexity */
import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AssetContentType, FeatureFlagsEnum } from '@cdam/shared';
import Icon from '../../../components/icons/Icon';
import AssetsStore from '../../../stores/assets/AssetsStore';
import DialogStore from '../../../stores/dialog/DialogStore';
import { ReactComponent as DownloadIcon } from '../../../assets/icon/downloadZip.svg';
import NavigationService from '../../../services/NavigationService';
import AssetTypeSelector from '../../../components/coredam/AssetTypeSelector';
import { FeatureFlag } from '../../../components/feature-flag';
import EditColumnsDialog, { IEditColumnsDialogProps } from '../dialog/EditColumns';
import ViewStore from '../../../stores/view/ViewStore';

import './AssetsSearch.scss';

const openEditColumnsDialog = (props: IEditColumnsDialogProps): void => {
  DialogStore.addDialog(<EditColumnsDialog {...props} />);
};

interface IAssetsSearchProps {
  store: AssetsStore;
}

const AssetsSearch: React.FC<IAssetsSearchProps> = observer(({ store }) => {
  const { t } = useTranslation();
  const [allSelected, toggleAllSelected] = useState(false);

  useEffect(() => {
    toggleAllSelected(store.selectedAssets.length !== 0);
  }, [store.selectedAssets]);

  const [exportVisible, setExportVisible] = useState(false);

  const closeModal = (e: Event): void => {
    const clickedElement = e.target as HTMLElement;

    if (clickedElement?.closest) {
      const closestFilterDropDown = clickedElement.closest('.AssetsSearch-export-modal');

      if (closestFilterDropDown) {
        return;
      }
    }

    if (exportVisible) {
      setExportVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', closeModal);
    document.addEventListener('touchstart', closeModal);

    return () => {
      document.removeEventListener('mousedown', closeModal);
      document.removeEventListener('touchstart', closeModal);
    };
  });

  return (
    <div className="AssetsSearch">
      <div className="AssetsSearch-allSelect ie-font-fix">
        <input
          type="checkbox"
          className="AssetsSearch-allSelect-checkbox"
          checked={allSelected}
          onChange={(e) => {
            store.toggleAllAssets(e.target.checked);
            toggleAllSelected(!allSelected);
          }}
        />
        <div className="space-m-l-3 ie-font-fix">{t('common.all')}</div>
      </div>
      <div className="AssetsSearch-info">
        <div className="space-m-r-3 ie-font-fix">
          <strong className="value">{store.paginationData?.totalElements ?? 0}</strong> {t('common.results')}
        </div>
        <div className="ie-font-fix">
          <strong className="value">{store.selectedAssets.length}</strong> {t('common.selected')}
        </div>
      </div>
      <div className="navInput">
        <div className="searchIconContainer">
          <Icon icon="icon-search" height={22} width={22} />
        </div>
        <input
          className="input ie-font-fix"
          type="text"
          placeholder={t('common.filter_search')}
          value={store.searchInput}
          onChange={(e) => store.onChangeSearchInput(e.target.value.trim())}
        />

        <FeatureFlag feature={FeatureFlagsEnum.EditColumns}>
          <>
            <div className="AssetsSearch-divider" />
            <div className="downloadingIconContainer">
              <Icon
                icon="icon-column-view"
                color="#737373"
                height={23}
                width={23}
                // eslint-disable-next-line @typescript-eslint/unbound-method
                onClick={() => openEditColumnsDialog({ selectedColumns: store.listViewCols, onClose: store.editColumns })}
              />
            </div>
          </>
        </FeatureFlag>

        {ViewStore.canEdit && (
          <FeatureFlag feature={FeatureFlagsEnum.EditBulk}>
            <>
              <div className="AssetsSearch-divider" />
              <div className="downloadingIconContainer">
                <Icon
                  icon="icon-edit"
                  color="#737373"
                  className={store.selectedAssets.length === 0 ? 'AssetsSearch-disabled' : ''}
                  height={23}
                  width={23}
                  onClick={() => {
                    if (store.selectedAssets.length === 0) {
                      return;
                    }

                    store.bulkEditMetadata();
                  }}
                />
                {store.selectedAssets.length > 0 && <div className="downloadingIconCount">{store.selectedAssets.length}</div>}
              </div>
            </>
          </FeatureFlag>
        )}

        {ViewStore.canEdit && (
          <>
            <div className="AssetsSearch-divider" />
            <div className="downloadingIconContainer">
              <Icon
                icon="icon-delete"
                color="#737373"
                height={23}
                width={23}
                className={store.selectedAssets.length === 0 ? 'AssetsSearch-disabled' : ''}
                onClick={() => {
                  if (store.selectedAssets.length === 0) {
                    return;
                  }

                  store.deleteSelectedAssets();
                }}
              />
              {store.selectedAssets.length > 0 && <div className="downloadingIconCount">{store.selectedAssets.length}</div>}
            </div>
          </>
        )}

        <div className="AssetsSearch-divider" />
        <div className="downloadingIconContainer" onClick={() => setExportVisible(!exportVisible)}>
          <Icon icon="icon-download" height={22} width={22} />
          {store.selectedAssets.length > 0 && <div className="downloadingIconCount">{store.selectedAssets.length}</div>}
          {exportVisible && (
            <div className="AssetsSearch-export-modal box-shadow-2">
              <div className="AssetsSearch-export-modal-content space-p-h-2" onClick={() => store.downloadMultiple()}>
                <DownloadIcon className="AssetsSearch-export-modal-content-icon" />
                <div className="space-m-l-1"> {t('advancedDownload.download_zip')}</div>
              </div>
              <div className="AssetsSearch-export-modal-content space-p-h-2" onClick={() => store.openExportFilenameDialog()}>
                <Icon icon="icon-download-excel-file" height={20} width={20} />
                <div className="space-m-l-1">{t('advancedDownload.export_xlsc')}</div>
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="AssetsSearch-settings">
        <div className="AssetsSearch-view-settings">
          <p className="space-m-0 space-p-2">{t('common.view')}:</p>
          <NavLink
            className="AssetsSearch-view-settings-link listViewNavLink space-p-1"
            to={`/assets${NavigationService.search}`}
            isActive={(_, { pathname }) => pathname === '/assets'}
            title={t('common.list_view')}
          >
            <Icon icon="icon-list-view" height={22} width={22} />
          </NavLink>
          <NavLink
            className="AssetsSearch-view-settings-link space-p-1"
            to={`/assets/cards${NavigationService.search}`}
            isActive={(_, { pathname }) => pathname === '/assets/cards'}
            title={t('common.card_view')}
          >
            <Icon icon="icon-tile-view" height={22} width={22} />
          </NavLink>
        </div>
        {store.itemsType === AssetContentType.PI && (
          <div className="filterSettings ie-font-fix">
            <AssetTypeSelector
              type="article"
              border={true}
              disabled={store.selectedType && !store.selectedType.showArticles}
              onClick={() => store.onTypeSelected('article')}
            />
            <AssetTypeSelector
              type="asset"
              border={true}
              disabled={store.selectedType && !store.selectedType.showAssets}
              onClick={() => store.onTypeSelected('asset')}
            />
          </div>
        )}
      </div>
    </div>
  );
});

export default AssetsSearch;
