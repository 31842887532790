/* eslint-disable complexity */
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppVariantEnum, FeatureFlagsEnum } from '@cdam/shared';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import NavigationService from 'services/NavigationService';
import ViewStore from '../../stores/view/ViewStore';
import { ReactComponent as AdidasIcon } from '../../assets/icon/adidas-nav-logo.svg';
import GateLogo from '../logo/GateLogo';
import GateTitleText from '../logo/GateTitleText';
import ArchiveTitleText from '../logo/ArchiveTitleText';
import { FeatureFlag } from '../feature-flag';
import ProfileStore from '../../stores/profile/ProfileStore';
import Settings from './Settings';

import './Navbar.scss';

const Navbar: React.FC = observer(() => {
  const { t } = useTranslation();
  const initials = useMemo(() => ProfileStore.getInitials(), []);

  const [showSettings, setShowSettings] = useState(false);

  const onSettingsClick = useCallback(() => {
    setShowSettings(!showSettings);
  }, [showSettings]);

  function closeOpenedSettings(e: Event): void {
    const clickedElement = e.target as HTMLElement;

    if (clickedElement?.closest) {
      const closest = clickedElement.closest('.Navbar-profile-avatar');

      if (closest) {
        return;
      }
    }

    setShowSettings(false);
  }

  useEffect(() => {
    document.addEventListener('click', closeOpenedSettings);
    document.addEventListener('touch', closeOpenedSettings);

    return () => {
      document.removeEventListener('click', closeOpenedSettings);
      document.removeEventListener('touch', closeOpenedSettings);
    };
  });

  return (
    <div className="Navbar header" data-testid="navbar">
      <nav className="Navbar-container">
        <div className="header__left space-m-l-6" data-testid="navbar-logo">
          <NavLink to="/" className="header__brand">
            {ViewStore.appVariant === AppVariantEnum.ArchiveUI ? (
              <>
                <GateLogo height="2rem" width="2rem" className="space-m-r-2" />
                <ArchiveTitleText height="2rem" width="7.75rem" />
                {ViewStore.displayDomain && <div className="space-m-l-2">{ViewStore.navbarSubHeading}</div>}
              </>
            ) : (
              <>
                <GateLogo height="2rem" width="2rem" className="space-m-r-2" />
                <GateTitleText height="1.25rem" width="3.875rem" />
              </>
            )}
          </NavLink>
        </div>
        <div className="header__right" data-testid="navbar-right">
          <FeatureFlag feature={FeatureFlagsEnum.TemplateUpload}>
            <>
              {ViewStore.showUploadButton && (
                <Button
                  buttonType={ButtonType.Secondary}
                  size={ButtonSize.Small}
                  icon="icon-upload"
                  className="Navbar-upload-btn"
                  data-testid="navbar-upload-button"
                  onClick={() => NavigationService.navigateTo(ViewStore.appVariant === AppVariantEnum.GateUI ? '/upload-product-images' : '/upload')}
                >
                  {t('common.upload')}
                </Button>
              )}
            </>
          </FeatureFlag>
          <div>
            {initials ? (
              <div className="Navbar-profile-avatar Navbar-avatar-text" data-testid="navbar-avatar">
                <span
                  onClick={() => {
                    onSettingsClick();
                  }}
                >
                  {initials}
                </span>
              </div>
            ) : (
              <img className="Navbar-profile-avatar" src="https://via.placeholder.com/50" alt="user" onClick={() => onSettingsClick()} />
            )}
            {showSettings && <Settings />}
          </div>
          <div className="Navbar-adidas-icon ie-font-fix" data-testid="navbar-adidas-icon">
            <AdidasIcon />
          </div>
        </div>
      </nav>
    </div>
  );
});

export default Navbar;
