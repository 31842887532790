import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import MinimalLayout from 'components/layout/MinimalLayout';
import { AssetData } from 'stores/assets/data/AssetData';
import cn from 'classnames';
import AdvancedDownloadStore from 'stores/assets/AdvancedDownloadStore';
import ViewStore from 'stores/view/ViewStore';
import { AppVariantEnum } from '@cdam/shared';
import DialogStore from '../../../stores/dialog/DialogStore';
import DropdownCheckboxItem from '../../../components/dropdown/DropdownCheckboxItem';
import AdvancedDownloadBar from './components/AdvancedDownloadBar';
import AdvancedDownloadCardView from './components/AdvancedDownloadCardView';
import GlobalDownloadTypeSelectDropdown from './components/GlobalDownloadTypeSelectDropdown';
import GlobalDownloadFormatSelectDropdown from './components/GlobalDownloadFormatSelectDropdown';
import AdvancedDownloadMultiple from './components/AdvancedDownloadMultiple';

import './AdvancedDownload.scss';

interface IAdvancedDownloadProps {
  onClose(): void;
  selectedAssets: Array<AssetData>;
  className?: string;
  confidential: boolean;
}

// eslint-disable-next-line complexity
const AdvancedDownload: React.FC<IAdvancedDownloadProps> = observer(({ className, onClose, selectedAssets, confidential }) => {
  const { t } = useTranslation();

  const [advancedDownloadStore] = useState(() => new AdvancedDownloadStore());

  useEffect(() => {
    advancedDownloadStore.init(selectedAssets);
  }, [advancedDownloadStore, selectedAssets]);

  const handleDownloadClick = async (packageName: string): Promise<void> => {
    const sanitizedPackageName = packageName.replace(/ /g, '_');
    const fileExists = await advancedDownloadStore.validateZipFilename(sanitizedPackageName);

    if (!fileExists) {
      advancedDownloadStore.validateDownloadDialog(sanitizedPackageName);
    }
  };

  const handleWatermarkChange = useCallback(() => advancedDownloadStore.toggleWatermark(), [advancedDownloadStore]);

  if (advancedDownloadStore.isDownloading) {
    DialogStore.removeLastDialog();

    return null;
  }

  if (confidential) {
    advancedDownloadStore.setDownloadWatermark('Confidential');
  }

  const dropdownValue = !advancedDownloadStore.formatIsMixed
    ? advancedDownloadStore.globalAssetFormats?.length
      ? advancedDownloadStore.globalAssetFormats.map((f) => f.name).join(', ')
      : (advancedDownloadStore.assets.length &&
          advancedDownloadStore.assets[0].selectedFileFormats.length &&
          advancedDownloadStore.assets[0].selectedFileFormats.map((e) => e.name).join(', ')) ||
        t('advancedDownload.global_dropdown_default').toString()
    : t('advancedDownload.global_dropdown_mixed').toString();

  return (
    <MinimalLayout
      className={cn('AdvancedDownload', className)}
      headerClassName="space-m-h-6 space-m-t-6 space-m-b-4"
      header={t('matrix.download_assets_heading')}
      onCloseButtonClick={onClose}
    >
      <section>
        <div className="AdvancedDownload-container">
          <div className="row">
            {ViewStore.appVariant === AppVariantEnum.GateUI && (
              <>
                <div className="col-md-3">
                  <GlobalDownloadFormatSelectDropdown advancedDownloadStore={advancedDownloadStore} dropdownValue={dropdownValue} />
                </div>
                {ViewStore.appVariant === AppVariantEnum.GateUI && (
                  <React.Fragment>
                    <div className="col-md-3 AdvancedDownload-topps">
                      <GlobalDownloadTypeSelectDropdown
                        advancedDownloadStore={advancedDownloadStore}
                        label={t('advancedDownload.what_to_download')}
                      />
                    </div>
                    {!confidential && (
                      <div className="col-md-4 space-p-t-4">
                        <DropdownCheckboxItem
                          value={t('advancedDownload.remove_watermark')}
                          checked={advancedDownloadStore.watermarkChecked}
                          labelClassName="space-m-t-1"
                          onClick={handleWatermarkChange}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )}
              </>
            )}
          </div>
        </div>
        <div className="AdvancedDownload-spacer space-m-t-4" />
      </section>
      <section className="AdvancedDownload-assets-section">
        {selectedAssets.length > 50 ? (
          <AdvancedDownloadMultiple items={selectedAssets} format={dropdownValue} />
        ) : (
          <AdvancedDownloadCardView selectedAssets={selectedAssets} advancedDownloadStore={advancedDownloadStore} />
        )}
      </section>
      <section>
        <AdvancedDownloadBar className="space-p-v-2 space-p-r-4" onDownloadClick={handleDownloadClick} />
      </section>
    </MinimalLayout>
  );
});

export default AdvancedDownload;
