import React from 'react';

import './TextBoxControlled.scss';

interface TextBoxControlledProps extends React.ComponentProps<'input'> {
  className?: string;
  label?: string;
  name?: string;
  error?: string;
  height?: number;
  noMargin?: boolean;
  errorOutline?: boolean;
  type?: string;
}

const TextBoxControlled = ({ className, label, name, height, error, noMargin, errorOutline, type, ...rest }: TextBoxControlledProps): JSX.Element => (
  <div className={`${className ?? ''} form-group ${errorOutline ? 'has-error' : ''} ${noMargin ? 'TextBoxControlled-remove-margin' : ''}`}>
    {label && (
      <label htmlFor={name} className="control__label">
        {label}
      </label>
    )}
    <div className="form-control__container">
      <input type={type ?? 'text'} className="form-control" style={{ height: height ? `${height}rem` : undefined }} {...rest} />
    </div>
    {error && error !== '' && (
      <div className="helptext" style={{ color: 'red' }}>
        {error}
      </div>
    )}
  </div>
);

export default TextBoxControlled;
