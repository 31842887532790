import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { UploadStore } from 'stores/upload/UploadStore';
import Icon from 'components/icons/Icon';
import Button, { ButtonSize, ButtonType } from 'components/input/Button';
import { AssetType } from '@cdam/shared';
import DetailsStore from 'stores/assets/DetailsStore';
import { useTranslation } from 'react-i18next';
import { UploadItem } from '../../stores/uploadDownloadManager/data/UploadItem';
import { Loader } from '../loader/Loader';

import './VersioningUploadZone.scss';

interface IVersioningUploadZone {
  className?: string;
  label?: string;
  store: UploadStore;
  metadataId: string;
  metadataFileName?: string;
  detailsStore: DetailsStore;
}

const VersioningUploadZone = observer(
  // eslint-disable-next-line complexity
  ({ label, className, store, metadataId, metadataFileName, detailsStore }: IVersioningUploadZone): JSX.Element => {
    const { t } = useTranslation();
    const [shownItem, setShownItem] = useState(false);
    const [loadFailed, setLoadFailed] = useState(false);

    const onDrop = useCallback(
      (acceptedFiles: Array<File>) => {
        store.setLoading(true);

        for (const file of acceptedFiles) {
          const fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1);

          const metadataName = metadataFileName?.slice(0, metadataFileName.lastIndexOf('.'));

          const fileName = metadataFileName ? `${metadataName}.${fileExtension}` : file.name;

          store.handleFileDrop(fileName, file, file.type, file.size, metadataId);

          // TODO: ensure only one file is uploaded
          detailsStore.setUploadVersioningFile(new UploadItem(store.uploadAssets[0].name, store.uploadAssets));

          setShownItem(true);
        }
      },
      [store, metadataFileName, metadataId, detailsStore],
    );

    const upload = async (): Promise<void> => {
      setLoadFailed(false);

      if (detailsStore.versioningFile) {
        const result = await detailsStore.versioningFile.startProcess();

        if (result && !detailsStore.versioningFile.error) {
          if (detailsStore.assetType === AssetType.Article) {
            await detailsStore.loadArticle(metadataId);
          } else {
            await detailsStore.load();
          }

          setShownItem(false);
          detailsStore.removeUploadVersioningFile();
          store.removeFile(0);
        } else {
          setLoadFailed(true);
        }
      }
    };

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop });

    return (
      <section className={cn('space-p-2-1 VersioningUploadZone form-group', className)}>
        {label && <label className="control__label">{label}</label>}

        <div className="VersioningUploadZone-square-container">
          {shownItem ? (
            <div className="VersioningUploadZone-items space-p-2">
              <div
                className="VersioningUploadZone-item"
                onClick={() => {
                  store.removeFile(0);
                  setShownItem(false);
                }}
              >
                {detailsStore.versioningFile && !detailsStore.versioningFile.isLoading ? (
                  <Icon icon={'icon-close'} color="#000" height={25} width={25} />
                ) : (
                  <Loader small={true} className="VersioningUploadZone-loader" />
                )}
                <div className="VersioningUploadZone-item-text">{store.uploadAssets[0].name}</div>
              </div>
              {loadFailed && <div>{t('error.failed_load')}</div>}
              <div className="VersioningUploadZone-button-container">
                {detailsStore.versioningFile && !detailsStore.versioningFile.isLoading && (
                  <Button
                    buttonType={ButtonType.Primary}
                    size={ButtonSize.Default}
                    disabled={!store.allMandatoryFiltersSelected || !store.allAssetsChecksumCalculated}
                    icon="icon-upload"
                    iconColor="#fff"
                    className="FilesTable-upload-btn"
                    onClick={async () => {
                      upload();
                    }}
                  >
                    {!loadFailed ? t('common.upload').toUpperCase() : t('common.retry').toUpperCase()}
                  </Button>
                )}
                {!store.allAssetsChecksumCalculated && (
                  <div className="VersioningUploadZone-checksum-loader">
                    <div className="loader loader--sm" />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div
              {...getRootProps({ className: `VersioningUploadZone-dropzone ${isDragAccept ? 'VersioningUploadZone-dropzone-active' : undefined}` })}
            >
              {store.isLoading ? (
                <Loader />
              ) : (
                <>
                  <svg className="icon icon-upload" height="30px" width="30px">
                    <use xlinkHref="#icon-upload" />
                  </svg>
                  <input {...getInputProps()} />
                  <div className="VersioningUploadZone-dropzone-content">
                    <div className="body-xs space-m-l-4">{t('details.history.file_drop')}</div>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </section>
    );
  },
);

export default VersioningUploadZone;
