import { AuthGroup, IGroupData, IMatrixOwnersItem } from '@cdam/shared';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import AuthStore from '../stores/auth/AuthStore';

export default {
  async checkIfUserIsInAnyOfGroups(username: string, groups: Array<AuthGroup>): Promise<boolean> {
    const userGroups = await ClientHttpService.fetch<IGroupData>({
      method: 'GET',
      urlType: UrlType.Backend,
      url: `/users/groups/${username}`,
    });

    for (const group of groups) {
      if (userGroups.groupNames.includes(group)) {
        return true;
      }
    }

    return false;
  },

  async getUsers(groups?: Array<string>): Promise<Array<IMatrixOwnersItem>> {
    if (!AuthStore.token) {
      return [];
    }

    const query = groups ? `?${groups.map((group) => `groups=${encodeURIComponent(group)}`).join('&')}` : '';

    const values = await ClientHttpService.fetch<Array<IMatrixOwnersItem>>({
      urlType: UrlType.Backend,
      url: `/users${query}`,
      method: 'GET',
    });

    return values;
  },
};
