import React from 'react';
import { useTranslation } from 'react-i18next';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import NavigationService from 'services/NavigationService';
import DialogStore from '../../../stores/dialog/DialogStore';
import MatrixStore from '../../../stores/assets/MatrixStore';
import Button, { ButtonType, ButtonSize } from '../../../components/input/Button';
import PaginatedEventCards from './PaginatedEventCards';

import './MatrixAdminArea.scss';

interface IMatrixAdminArea {
  store: MatrixStore;
}

const MatrixAdminArea = ({ store }: IMatrixAdminArea): JSX.Element => {
  const { t } = useTranslation();

  const createCopy = async (): Promise<void> => {
    // TODO: handle error message
    const { id } = await ClientHttpService.fetch<{ id: string }>({
      method: 'POST',
      url: `/matrix/create-copy/${store.selectedEvent?.id}`,
      urlType: UrlType.Backend,
    });

    NavigationService.navigateTo(`/matrix-settings/${store.brand}/${id}`);
  };

  const execCreateCopy = (): void => {
    DialogStore.execFullScreen(createCopy());
  };

  return (
    <div className="jumbotron matrix-admin-area space-p-4">
      <p className="matrix-admin-area-heading">{t('matrix.admin_area_label')}</p>
      <div className="matrix-admin-area-container">
        <div>
          {store.selectedEvent && (
            <>
              <Button
                className="space-m-r-3 matrix-admin-area-edit-button"
                buttonType={ButtonType.Secondary}
                size={ButtonSize.Default}
                data-testid="search-view-edit-matrix"
                onClick={() => NavigationService.navigateTo(`/matrix-settings/${store.brand}/${store.selectedEvent?.id}`)}
              >
                {t('matrix.edit_matrix_button')}
              </Button>
              <Button
                className="space-m-r-3 matrix-admin-area-edit-button"
                buttonType={ButtonType.Secondary}
                size={ButtonSize.Default}
                data-testid="search-view-copy-matrix"
                onClick={execCreateCopy}
              >
                {t('matrix.create_matrix_copy_button')}
              </Button>
            </>
          )}
          <Button
            buttonType={ButtonType.Primary}
            size={ButtonSize.Default}
            data-testid="search-view-add-matrix"
            onClick={() => NavigationService.navigateTo(`/matrix-settings/${store.brand}`)}
          >
            {t('matrix.add_matrix_title')}
          </Button>
        </div>
        <div className="space-m-t-5">
          <h6 className="matrix-admin-area-section-heading">{t('matrix.drafts')}</h6>
          {store.events?.drafts?.length ? (
            <PaginatedEventCards events={store.events.drafts} brand={store.brand} numberPerPages={3} data-testid="search-view-drafts" />
          ) : (
            <span className="matrix-admin-area-empty-events-message">{t('matrix.drafts_empty')}</span>
          )}
        </div>
        <div className="space-m-t-5">
          <h6 className="matrix-admin-area-section-heading">{t('matrix.expired_events')}</h6>
          {store.events?.expired?.length ? (
            <PaginatedEventCards events={store.events.expired} brand={store.brand} numberPerPages={3} data-testid="search-view-expired" />
          ) : (
            <span className="matrix-admin-area-empty-events-message">{t('matrix.expired_empty')}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default MatrixAdminArea;
