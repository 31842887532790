import { UIAssetDomainsNamesEnums, IDisplayCols, AssetContentType } from '@cdam/shared';
import ViewStore from '../stores/view/ViewStore';

function getAssetColumns(): Array<string> {
  switch (ViewStore?.selectedUiAssetDomain) {
    case UIAssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
      return [
        'assetId',
        'assetName',
        'status_rd',
        'batchName',
        'agency',
        'subBrand_pm',
        'brand_pm',
        'category_pm',
        'year_pm',
        'season_pm',
        'jobn_pm',
      ];
    case UIAssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
      return ['assetId', 'assetName', 'status', 'subBrand', 'category', 'DABOX_campaign', 'year', 'season', 'jobno', 'generic'];
    default:
      return ['assetName', 'assetGroup', 'marketingSeason', 'lastModifiedOn', 'owners', 'createdOn'];
  }
}

export default {
  sortableAttributes: ['assetCategory', 'assetName', 'businessUnit', 'functions', 'lastModifiedBy', 'lastModifiedOn', 'marketingSeason'],
  defaultColumns(): Array<IDisplayCols> {
    return [
      {
        assetGroup: UIAssetDomainsNamesEnums.MARVIN_MCT,
        assetColumns: ['assetName', 'assetCategory', 'businessUnit', 'marketingSeason', 'lastModifiedOn', 'checkInUser'],
        articleColumns: [],
      },
      {
        assetGroup: UIAssetDomainsNamesEnums.MARVIN_PI,
        assetColumns: [
          'assetName',
          'articleNumber',
          'assetCategory',
          'productView',
          'status',
          'usage',
          'vendor',
          'imageStyle',
          'confidential',
          'imageLaunchDate',
        ],
        articleColumns: ['articleNumber', 'businessUnit', 'productManager'],
      },
      {
        assetGroup: AssetContentType.Unknown,
        articleColumns: [],
        assetColumns: getAssetColumns(),
      },
    ] as Array<IDisplayCols>;
  },
  addMCTColumnList: [
    'assetCategory',
    'assetName',
    'brand',
    'businessUnit',
    'channel',
    'channelRooms',
    'checkInUser',
    'creationCenter',
    'lastModifiedOn',
    'keyCategory',
    'market',
    'marketingSeason',
    'milestones',
    'rescaleFactor',
    'sportsCategory',
    'subBrand',
  ],
  addMCTCustomColumnList: ['originalFileSize', 'fileType', 'fileFormat'],

  // formattingRules: {
  //   dateTimeFormat: ['lastModifiedOn', 'createdOn', 'imageLaunchDate'],
  // },
};
