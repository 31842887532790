import { IMetadata, IMetadataAttributeGroup, IMetadataDigitalContent, Name } from '@cdam/shared';
import { action, computed, observable, runInAction, toJS, makeObservable } from 'mobx';
import TaxonomyStore from '../../taxonomy/TaxonomyStore';
import { AssetFilter } from './AssetFilter';

export class AssetData {
  @observable public expanded = false;

  @observable private sourceAsset: IMetadata = {} as IMetadata;
  private previousAssetAttributeList: Array<IMetadataAttributeGroup> | undefined;

  @computed
  public get asset(): IMetadata {
    return this.sourceAsset;
  }

  @computed
  public get activeDigitalContent(): IMetadataDigitalContent | undefined {
    return this.sourceAsset?.digitalContentList?.find((content) => content.version === this.sourceAsset.activeDigitalContent);
  }

  public constructor(asset: IMetadata) {
    makeObservable(this);
    this.init(asset);
  }

  @action
  public init(asset: IMetadata): void {
    this.sourceAsset = asset;
  }

  @action
  public update(asset: IMetadata): void {
    this.sourceAsset = asset;
  }

  @action
  public toggleExpand(): void {
    this.expanded = !this.expanded;
  }

  /**
   * getUpdatedAttributeList creates a deep clone of the asset's attributeGroupList and applies asset filter to it.
   *
   * @param filters {AssetFilter[]} - array of asset filter that you want to apply
   * @returns { IMetadataAttributeGroup[]}
   */
  @action
  public getUpdatedAttributeList(filters: Array<AssetFilter>): Array<IMetadataAttributeGroup> {
    let attributeGroupList = toJS(this.sourceAsset.attributeGroupList);

    for (const filter of filters) {
      const metadataAttribute = filter.metadataAttribute;

      if (!metadataAttribute) {
        // eslint-disable-next-line no-continue
        continue;
      }

      let group = attributeGroupList.find((group) => group.name === filter.groupName);

      if (!group) {
        attributeGroupList = [...attributeGroupList, { name: filter.groupName as Name, attributeList: [] }];
        group = attributeGroupList[attributeGroupList.length - 1];
      }

      group.attributeList = group.attributeList.filter((attribute) => attribute.name !== filter.attribute.name);

      group.attributeList = [...group.attributeList, metadataAttribute];
    }

    return attributeGroupList;
  }

  @action
  public revertAssetAttributeList(): void {
    if (this.previousAssetAttributeList) {
      runInAction(() => {
        this.sourceAsset.attributeGroupList = this.previousAssetAttributeList ?? [];
        this.previousAssetAttributeList = undefined;
      });
    }
  }

  public hasAttribute(groupName: string, attributeName: string, value?: string): boolean {
    const group = this.sourceAsset.attributeGroupList.find((group) => group.name === groupName);

    if (group) {
      const attribute = group.attributeList.find((attribute) => attribute.name === attributeName);

      if (attribute) {
        if (!value) {
          return true;
        }

        return attribute.values ? attribute.values.includes(value) : attribute.value === value;
      }
    }

    return false;
  }

  public getAttributeValues(groupName: string, attribute: string): Array<string> | null {
    let data: Array<string> | null = null;

    const groupData = this.getAttributeGroupsData(groupName);

    if (groupData) {
      const attributeData = groupData[attribute];

      if (attributeData) {
        const parseAttributeData = toJS(attributeData);

        data = Array.isArray(parseAttributeData) ? parseAttributeData : [parseAttributeData];
      }
    }

    return data;
  }

  public getAttributeDisplayValue(groupName: string, attribute: string): string | null {
    let displayValue: string | null = null;

    const attributeValues = this.getAttributeValues(groupName, attribute);

    if (attributeValues && attributeValues.length !== 0) {
      if (TaxonomyStore.getAttributeType(groupName, attribute) === 'referenceData') {
        displayValue = TaxonomyStore.getTaxonomyReferenceValue(groupName, attribute, attributeValues);
      } else {
        displayValue = attributeValues[0];
      }
    }

    return displayValue;
  }

  private getAttributeGroupsData(groupName: string): Record<string, string | Array<string>> | null {
    return this.asset.attributeGroups[groupName] || null;
  }
}
