/* eslint-disable complexity */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Brand } from '@cdam/shared';
import Icon from 'components/icons/Icon';
import { MatrixSearch } from '../../../components/icons/MatrixSearch';
import MatrixStore from '../../../stores/assets/MatrixStore';
import { AssetCell } from '../../../stores/assets/data/AssetCell';
import ProfileStore from '../../../stores/profile/ProfileStore';

import './TableCell.scss';

interface ICellProps {
  cell: AssetCell;
}

const CellCounterItem = ({ cell }: ICellProps): JSX.Element => {
  const { t } = useTranslation();

  return cell.isGroupItem ? (
    <div
      className="TableCell-cell-counter-item group"
      onClick={() => {
        cell.onClick();
      }}
    >
      {Number(cell.count) > 0 ? (
        <>
          <Icon icon="icon-download" color="#00a2a2" height={22} width={22} />
          <div>{t('common.download')}</div>
        </>
      ) : (
        <span>{cell.count}</span>
      )}
    </div>
  ) : (
    <div
      className="TableCell-cell-counter-item"
      onClick={() => {
        cell.onClick();
      }}
    >
      <MatrixSearch />
      <span>{cell.count}</span>
    </div>
  );
};

const SearchMatrixModal = ({ cell }: ICellProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="SearchMatrixModal">
      <div
        className="SearchMatrixModal-browse"
        onClick={() => {
          cell.onClick();
        }}
      >
        <MatrixSearch />
        {t('common.browse')}
      </div>
      <div
        className="SearchMatrixModal-upload"
        onClick={() => {
          cell.openUploadDialog();
        }}
      >
        <Icon icon="icon-upload" height={20} width={20} />
        {t('common.upload')}
      </div>
    </div>
  );
};

const HighlightedCell = ({ cell, store, isEven, hidden }: { cell: AssetCell; store: MatrixStore; isEven: boolean; hidden: boolean }): JSX.Element => {
  let uploadGroup = false;

  if (cell.cellData?.upload) {
    uploadGroup = cell.cellData.upload.some((u) => ProfileStore.groups.includes(u));
  }

  return (
    <div
      className={`TableCell-item highlighted-cell ${store.isGroupItemSelected ? 'group-selected' : ''} ${
        isEven ? ' highlight-cell-even' : ' highlight-cell-odd'
      } ${hidden ? ' TableCell-hidden' : ''}`}
    >
      {uploadGroup && !cell.isGroupItem ? <SearchMatrixModal cell={cell} /> : <CellCounterItem cell={cell} />}
    </div>
  );
};

interface ITableCellProps {
  columnIndex: number;
  rowIndex: number;
  store: MatrixStore;
  cell: AssetCell;
  borderTop: boolean;
  evenRow: boolean;
  brand: Brand;
  onMouseEnter?(): void;
}

const TableCell = observer(
  ({ borderTop, brand, columnIndex, rowIndex, store, cell, evenRow, onMouseEnter }: ITableCellProps): JSX.Element => {
    if (!cell.yAxis || !cell.xAxis) {
      throw new Error();
    }

    const hasFilter = (cell.yAxis.filters && cell.yAxis.filters.length > 0) || (cell.xAxis.filters && cell.xAxis.filters.length > 0);

    const isGroupItem = cell.isGroupItem;

    const handleMouseEnter = (): void =>
      store.selectCell(isGroupItem || hasFilter ? rowIndex : -1, isGroupItem || hasFilter ? columnIndex : -1, isGroupItem);

    const cellIsSelected = cell.highlightRow && cell.highlightCell;

    return (
      <td
        id={`table-cell-${rowIndex}-${columnIndex}`}
        className={`TableCell ${isGroupItem ? (evenRow ? 'groupItemEven' : 'groupItemOdd') : ''}`}
        onMouseEnter={onMouseEnter}
      >
        <div className={`TableCell-relative-container ${brand === Brand.Reebok ? 'reebok-table-cell' : ''}`}>
          <div
            className={`TableCell-item ${cell.highlightRow && !cell.isGroupItem ? 'highlight-row' : ''} ${
              cell.highlightCell ? 'highlight-column' : ''
            } ${cell.highlightCell && store.isGroupItemSelected ? 'highlight-group-column' : ''} ${
              rowIndex === store.groupHighlightEndIndex && cell.highlightCell && store.isGroupItemSelected ? 'highlight-group-column-last' : ''
            } ${borderTop ? 'borderTop' : ''}`}
            onMouseEnter={handleMouseEnter}
          >
            {(isGroupItem || hasFilter) && <span>{cell.count}</span>}
          </div>
          <HighlightedCell cell={cell} store={store} isEven={evenRow} hidden={!cellIsSelected} />
        </div>
      </td>
    );
  },
);

export default TableCell;
