import React, { ReactElement, useCallback, useState, useEffect } from 'react';
import cn from 'classnames';
import Icon from 'components/icons/Icon';

import './Dropdown.scss';

interface IDropdownProps {
  label?: string;
  value?: string;
  onClick?(): void;
  children?: ReactElement | Array<ReactElement>;
  noBorder?: boolean;
  disabled?: boolean;
  className?: string;
  closeWhenItemSelect?: boolean;
  maxFilterWidth?: string;
  minFilterWidth?: string;
  disableLabel?: boolean;
  iconColor?: string;
  error?: boolean;
}

// eslint-disable-next-line complexity
const Dropdown = ({
  children,
  closeWhenItemSelect,
  label,
  noBorder,
  iconColor,
  value,
  className,
  onClick,
  disabled,
  maxFilterWidth,
  minFilterWidth,
  disableLabel,
  error,
}: IDropdownProps): JSX.Element => {
  const [openUncontrolled, setOpenUncontrolled] = useState(false);

  const onClickUncontrolled = useCallback(() => {
    setOpenUncontrolled(!openUncontrolled);
  }, [openUncontrolled]);

  const closeOpenedFilter = useCallback(
    (e: Event): void => {
      const clickedElement = e.target as HTMLElement;

      if (clickedElement?.closest) {
        const closestFilterDropDown = clickedElement.closest('.Dropdown-list');

        if (closestFilterDropDown) {
          return;
        }
      }

      if (openUncontrolled) {
        setOpenUncontrolled(false);
      }
    },
    [openUncontrolled],
  );

  useEffect(() => {
    document.addEventListener('click', closeOpenedFilter);
    document.addEventListener('touch', closeOpenedFilter);

    return () => {
      document.removeEventListener('click', closeOpenedFilter);
      document.removeEventListener('touch', closeOpenedFilter);
    };
  });

  const handleClick = (): void => {
    if (typeof onClick !== 'undefined') {
      onClick();
    }

    onClickUncontrolled();
    setOpenUncontrolled(!openUncontrolled);
  };

  return (
    <div className={cn('Dropdown', className)} style={{ maxWidth: maxFilterWidth, minWidth: minFilterWidth /* stylelint-disable */ }}>
      <div className="dropdown">
        {!disableLabel && <div className="dropdown__label">{label}</div>}
        <div className={cn(`dropdown__inner-container ${className}-container`)} onClick={!disabled ? handleClick : undefined}>
          <div
            className={`dropdown__selected dropdown__control ${className}-control ${noBorder ? 'Dropdown-no-border' : ''} ${
              error ? 'Dropdown-error' : ''
            }`}
          >
            <div className="Dropdown-text">{value}</div>
          </div>
          {!disabled && (
            <div className="Dropdown-icon">
              <Icon
                icon={`${openUncontrolled ? 'icon-arrow-dropdown-up' : 'icon-arrow-dropdown-down'}`}
                color={iconColor ? iconColor : '#737373'}
                height={22}
                width={22}
              />
            </div>
          )}
        </div>
      </div>

      {children && openUncontrolled && (
        <div className={`Dropdown-list ${className}-list`} onClick={closeWhenItemSelect ? handleClick : undefined}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
