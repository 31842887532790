/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from '../../../../components/input/Button';
import AssetsFilter from '../../../assets/components/AssetsFilter';
import { AssetData } from '../../../../stores/assets/data/AssetData';
import BulkEditStore from '../../../../stores/assets/BulkEditStore';
import { AssetFilter } from '../../../../stores/assets/data/AssetFilter';
import DialogStore from '../../../../stores/dialog/DialogStore';
import Table from '../../../../components/table/Table';

import './EditMetadata.scss';

interface IEditMetadata {
  assets: Array<AssetData>;
  headers: Array<string>;
  filters: Array<AssetFilter>;
}

// eslint-disable-next-line complexity
const EditBulkMetadata: React.FC<IEditMetadata> = observer(({ assets, filters, headers }) => {
  const { t } = useTranslation();
  const [store] = useState(() => new BulkEditStore(filters, assets, headers));

  return (
    <div className="EditMetadata space-p-4">
      <section className="EditMetadata-navigation space-m-b-4">
        <div className="EditMetadata-heading">
          <h4 className="space-m-r-3">{t('details.bulk_edit')}</h4>
        </div>
        <div>
          <Button
            className="space-m-r-3 EditMetadata-cancel"
            buttonType={ButtonType.Secondary}
            size={ButtonSize.Default}
            onClick={() => DialogStore.removeLastDialog()}
          >
            {t('common.close')}
          </Button>
          <Button
            buttonType={ButtonType.Primary}
            size={ButtonSize.Default}
            disabled={!store.canSave}
            onClick={() => {
              store.save();
            }}
          >
            {t('common.save')}
          </Button>
        </div>
      </section>
      <section className="EditMetadata-content space-p-2">
        <div className="space-m-b-3">
          <AssetsFilter
            filterClassName="space-m-r-3 space-m-t-4"
            filters={filters}
            singleChoice={true}
            selectedFilters={filters}
            clearAllFilters={() => store.clearAllFilters()}
            toggleSelectedFilter={(item, filters) => store.toggleSelectedFilter(item, filters)}
            minFilterWidth="15rem"
            brand={undefined}
            choicesMandatory={true}
            disableDeleting={true}
            disableAdding={true}
          />
        </div>
      </section>
      <section className="EditMetadata-content space-p-2">
        <h5>{t('details.current_values')}</h5>
        <Table headers={store.headers} items={store.tableItems} />
      </section>
    </div>
  );
});

export default EditBulkMetadata;
