import React from 'react';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import {
  AppVariantEnum,
  AssetContentType,
  AssetSearch,
  AssetType,
  ContextAction,
  EventCategoryEnum,
  EventTypeEnum,
  HandledError,
  HttpMethodEnum,
  IContextMenu,
  IMasterdataResponse,
  IMetadataOrderBy,
  IMetadataSearch,
  IMetadataSearchAttribute,
  IMetadataSearchFacet,
  IMetadataSearchPage,
  ISelectedType,
  ISubscribeRequest,
  LogLevelEnum,
  Name,
  SortDirection,
  appLocationEnum,
  assetGroupsEnum,
  handleErrorToLogData,
  AssetDomainsNamesEnums,
  UIAssetDomainsNamesEnums,
} from '@cdam/shared';
import NavigationService from 'services/NavigationService';
import AdvancedDownload from 'containers/assets/dialog/AdvancedDownload';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import DialogStore from 'stores/dialog/DialogStore';
import FilterUtils from 'utils/FilterUtils';
import LoggingService from 'services/LoggingService';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import { fillUserColumnSelection, IDisplayArticles, IDisplayItems, showConfidential } from 'utils/AssetDisplayUtils';
import UtilityStore from 'stores/utility/UtilityStore';
import _ from 'lodash';
import TableListViewConfig from 'configs/TableListViewConfig';
import { assetViewSelectedFilters } from 'configs/SelectedFilters';
import ExportUtils from 'utils/ExportUtils';
import { ExportDialog } from 'containers/dialog/dialogs/ExportDialog';
import FilterDisplayTypeConfig from 'configs/FilterDisplayTypeConfig';
import DetailsConfig from 'configs/DetailsConfig';
import { addDays } from 'date-fns';
import FilterConfigUtils from '../../utils/ConfigUtils';
import EditBulkMetadata from '../../containers/assets/details/components/EditBulkMetadata';
import ViewStore from '../view/ViewStore';
import MatrixItemDeleteDialog from '../../containers/search/dialogs/MatrixItemDeleteDialog';
import MetadataUtils from '../../utils/MetadataUtils';
import { DATE_RANGE_PREFIX, DATE_RANGE_SEPARATOR } from '../../components/date/DateTimePicker';
import i18n from '../../i18n';
import { AssetData } from './data/AssetData';
import { AssetFilter } from './data/AssetFilter';

export const PAGE_SIZE = 50;

export interface IDisplayColumns {
  assetGroup: string;
  assetColumns: Array<AssetFilter>;
  articleColumns: Array<AssetFilter>;
}

export default class AssetsStore {
  @observable public isLoading = false;
  @observable public error: Error | null = null;
  @observable public displayItems: IDisplayItems | null = null;
  @observable public paginationData: IMetadataSearchPage | null = null;
  @observable public selectedAssets: Array<AssetData> = [];
  @observable public selectedFilters: Array<AssetFilter> = [];
  @observable public selectedType: ISelectedType | null = null;
  @observable public searchInput = '';
  @observable public subscribedItems: Array<ISubscribeRequest> = [];
  @observable public itemsType: AssetContentType | null = null;
  @observable public contextAction: IContextMenu | null = null;
  @observable public orderBy: Array<IMetadataOrderBy> | null = null;
  @observable public listViewCols: Array<IDisplayColumns> = [];

  public filters: Array<AssetFilter> = [];
  public assetGroupsEnums: Array<assetGroupsEnum> | undefined;
  public appLocation: appLocationEnum = appLocationEnum.SEARCH_RESULTS;

  private readonly mandatoryFilters: Array<AssetFilter> = [];

  @computed
  public get filteredItems(): IDisplayItems | null {
    return this.displayItems;
  }

  @computed
  public get UIAssetDomainFromAssetContent(): UIAssetDomainsNamesEnums | AssetContentType {
    switch (this.itemsType) {
      case AssetContentType.MCT:
        return UIAssetDomainsNamesEnums.MARVIN_MCT;
      case AssetContentType.PI:
        return UIAssetDomainsNamesEnums.MARVIN_PI;
      default:
        return AssetContentType.Unknown;
    }
  }

  // @observable public taxonomyLoading = false;
  // @observable public taxonomyError: Error | null = null;
  // @observable public filters: AssetFilter[] | null = null;

  private prevSearch = '';
  private loadingIndex = 0;
  private assetSearch: AssetSearch | null = null;
  private readonly assetGroup: assetGroupsEnum | undefined = undefined;

  private abortController: AbortController | null = null;

  public constructor() {
    makeObservable(this);
    this.loadUserColumns();

    if (ViewStore.appVariant === AppVariantEnum.ArchiveUI) {
      const preselectedFilters = assetViewSelectedFilters[ViewStore.selectedAssetDomain] ?? [];

      runInAction(() => {
        this.filters = TaxonomyStore.attributeGroupList;
        this.filters.forEach((filter) => {
          if (preselectedFilters.includes(filter.attribute.name)) {
            this.selectedFilters.push(filter);
          }
        });
      });
    } else if (ViewStore.appVariant === AppVariantEnum.GateUI) {
      const assetGroupsIds = this.getAssetGroupRefIds(NavigationService.history.location.search);

      this.assetGroupsEnums = assetGroupsIds?.map(
        (id) => TaxonomyStore.getTaxonomyReferenceDescription(Name.General, 'assetGroup', id) as assetGroupsEnum,
      );

      this.appLocation = appLocationEnum.SEARCH_RESULTS;

      const assetGroupFilter = FilterConfigUtils.createFilter(Name.General, 'assetGroup', assetGroupsIds, false, true);

      if (!assetGroupFilter) {
        // eslint-disable-next-line
        console.error('Filter error');

        return;
      }

      this.mandatoryFilters.push(assetGroupFilter);

      this.filters = [
        ...this.mandatoryFilters,
        ...TaxonomyStore.getTaxonomyAttributeFiltersWithRules(
          undefined,
          undefined,
          this.appLocation,
          this.assetGroupsEnums && this.assetGroupsEnums.length > 0 ? this.assetGroupsEnums : undefined,
        ),
      ];

      const metadataSearch = this.urlSearchToMetadataSearch(NavigationService.history.location.search);

      runInAction(() => {
        if (metadataSearch) {
          const preselectedFilters = FilterConfigUtils.getPreselectedFilter(this.appLocation, this.filters, [assetGroupsEnum.MARKETING_CONTENT]);
          const searchFilters = this.metadataSearchToFilters(metadataSearch, this.filters);
          const searchFiltersNotInPreselected = searchFilters.filter(
            (f) => !preselectedFilters.find((p) => f.attribute.name === p.attribute.name && f.groupName === p.groupName),
          );

          this.selectedFilters = preselectedFilters.reduce<Array<AssetFilter>>(
            (result, current) => {
              const searchFilter = searchFilters.find((f) => f.attribute.name === current.attribute.name && f.groupName === current.groupName);

              if (searchFilter) {
                result.push(searchFilter);
              } else {
                result.push(current);
              }

              return result;
            },
            [...searchFiltersNotInPreselected],
          );

          this.searchInput = metadataSearch.searchText ? metadataSearch.searchText.replace(/\*/g, '') : '';
        } else {
          this.selectedFilters = [
            ...this.mandatoryFilters,
            ...FilterConfigUtils.getPreselectedFilter(this.appLocation, this.filters, [assetGroupsEnum.MARKETING_CONTENT]),
          ];
        }
      });
    }

    runInAction(() => {
      this.itemsType = AssetContentType.Unknown;

      this.selectedType = {
        showAssets: true,
        showArticles: false,
        showModels: false,
      };

      this.contextAction = {
        contextMenu: false,
        index: 0,
        positionX: 0,
        positionY: 0,
        actionMenu: false,
        actionType: ContextAction.Add,
        editType: AssetType.Asset,
      };
    });
  }

  @action
  public validateFilters(): void {
    FilterConfigUtils.taxonomyWithAppliedRules(this.selectedFilters, this.filters, appLocationEnum.SEARCH_RESULTS, this.assetGroupsEnums);
  }

  @action
  public fillDisplayItems(items: Array<AssetData>, showArticles?: boolean): void {
    this.displayItems = { items, articles: this.fillArticles(items) };

    if (ViewStore.appVariant === AppVariantEnum.GateUI) {
      this.setAssetGroup();
    }

    if (showArticles && this.selectedType) {
      this.selectedType.showArticles = true;
    }
  }

  @action
  public removeDisplayItems(itemIds: Array<string>): void {
    if (!this.displayItems?.items) {
      return;
    }

    this.displayItems = { ...this.displayItems, items: this.displayItems?.items.filter((item) => !itemIds.includes(item.asset.id)) };
  }

  @action
  public onChangeSearchInput(value: string): void {
    const search: IMetadataSearch = {
      searchText: value,
      exactSearch: false,
      textSearchTargetList: [
        {
          attributeGroup: 'Asset Information',
          attributeName: 'assetName',
        },
        {
          attributeGroup: 'Asset Information',
          attributeName: 'articleNumber',
        },
      ],
    };

    const metadataSearch = this.urlSearchToMetadataSearch(NavigationService.history.location.search);

    if (metadataSearch?.searchFacet) {
      search.searchFacet = metadataSearch.searchFacet;
    }

    if (metadataSearch?.orderBy) {
      search.orderBy = metadataSearch.orderBy;
    }

    this.load(search, true);
    this.searchInput = value;
  }

  @action
  public onTypeSelected(value: string): void {
    if (!this.selectedType) {
      return;
    }

    if (value === 'model') {
      this.selectedType.showModels = !this.selectedType.showModels;
    } else if (value === 'asset') {
      this.selectedType.showAssets = !this.selectedType.showAssets;
    } else if (value === 'article') {
      this.selectedType.showArticles = !this.selectedType.showArticles;
    }
  }

  // TODO: refactor to use view store for search.orderBy default and to validate search, bonus make use of autorun
  @action
  // eslint-disable-next-line complexity
  public async load(s: IMetadataSearch | undefined, forceLoad: boolean): Promise<void> {
    let search = s;
    let preselectArticle = false;

    if (search?.searchFacet?.searchAttributeList) {
      search = JSON.parse(JSON.stringify(search)) as IMetadataSearch;
      const searchFacet = search.searchFacet as IMetadataSearchFacet;

      searchFacet.searchAttributeList = searchFacet.searchAttributeList.filter((att) => att.valueList?.length);

      preselectArticle = searchFacet.searchAttributeList.map((e) => e.name).includes('articleNumber');

      if (!searchFacet.searchAttributeList.length) {
        delete search.searchFacet;
      }
      if (!search.searchFacet && !search.searchText) {
        search = undefined;
      }
    }

    if (search && !search.orderBy && ViewStore.appVariant === AppVariantEnum.GateUI) {
      search.orderBy = [
        {
          attributeGroup: 'General',
          attributeName: 'lastModifiedOn',
          sorting: 'desc',
        },
      ];
    }

    if (!search) {
      search = {
        searchText: '*',
      };
    }

    // Exclude items without digital content
    search = { ...(search ?? {}), activeDigitalContentAvailable: true };

    if (search.searchFacet) {
      this.checkForDateFilters(search.searchFacet.searchAttributeList);
    }

    const stringifiedSearch = JSON.stringify(search);

    if (!this.error && !forceLoad && stringifiedSearch === this.prevSearch) {
      return;
    }

    const currentLoadingIndex = ++this.loadingIndex;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.prevSearch = stringifiedSearch;

    this.isLoading = true;
    this.displayItems = null;
    this.error = null;
    this.paginationData = null;
    this.selectedAssets = [];

    try {
      this.abortController = new AbortController();
      const signal = this.abortController.signal;

      this.assetSearch = new AssetSearch();

      const response = await this.assetSearch.search(
        search,
        0,
        PAGE_SIZE,
        // eslint-disable-next-line @typescript-eslint/return-await
        async (s, url) =>
          await ClientHttpService.fetch({
            urlType: UrlType.Metadata,
            url,
            method: 'POST',
            body: s,
            signal,
          }),
        // eslint-disable-next-line @typescript-eslint/return-await
        async (url) =>
          await ClientHttpService.fetch<IMasterdataResponse>({
            urlType: UrlType.Metadata,
            url,
            method: 'GET',
            signal,
          }),
      );

      LoggingService.log({
        message: `Search for ${s?.searchText ?? 'all assets'}`,
        event: EventTypeEnum.METADATA_SEARCH,
        level: LogLevelEnum.INFO,
        category: EventCategoryEnum.WEB,
        http: {
          request: {
            method: HttpMethodEnum.POST,
            body: {
              content: JSON.stringify(search.searchFacet),
            },
          },
        },
      });

      runInAction(() => {
        this.fillDisplayItems(
          response.assets.map((asset) => new AssetData(asset)),
          preselectArticle,
        );
        this.paginationData = response.pagination;
        this.isLoading = false;
      });
    } catch (e) {
      const error = e as HandledError;

      LoggingService.log({
        message: `Search for ${s?.searchText ?? 'all assets'}`,
        event: EventTypeEnum.METADATA_SEARCH,
        level: LogLevelEnum.WARN,
        category: EventCategoryEnum.WEB,
        error: handleErrorToLogData(error),
      });

      if (currentLoadingIndex !== this.loadingIndex) {
        return;
      }

      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public async loadMore(page: IMetadataSearchPage): Promise<void> {
    const currentLoadingIndex = ++this.loadingIndex;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.isLoading = true;
    this.error = null;

    try {
      this.abortController = new AbortController();
      const signal = this.abortController.signal;

      if (!this.assetSearch) {
        const error = new HandledError('No asset store', undefined, undefined, undefined, undefined, 3);

        LoggingService.log({ message: 'Asset Store was not initialized', event: EventTypeEnum.APP_ERROR, level: LogLevelEnum.ERROR }, error);

        throw error;
      }

      const response = await this.assetSearch.search(
        JSON.parse(this.prevSearch),
        page.number + 1,
        PAGE_SIZE,
        // eslint-disable-next-line @typescript-eslint/return-await
        async (s, url) =>
          await ClientHttpService.fetch({
            urlType: UrlType.Metadata,
            url,
            method: 'POST',
            body: s,
            signal,
          }),
        // eslint-disable-next-line @typescript-eslint/return-await
        async (url) =>
          await ClientHttpService.fetch<IMasterdataResponse>({
            urlType: UrlType.Metadata,
            url,
            method: 'GET',
            signal,
          }),
      );

      runInAction(() => {
        const items = response.assets.map((asset) => new AssetData(asset));

        this.displayItems && this.fillDisplayItems((this.displayItems.items || []).concat(items));
        this.paginationData = response.pagination;
        this.isLoading = false;
      });
    } catch (e) {
      if (currentLoadingIndex !== this.loadingIndex) {
        return;
      }

      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public resetOrderBy(assetFilters: Array<AssetFilter>, ignoreIndex: number): void {
    assetFilters.forEach((assetFilter, index) => {
      if (index !== ignoreIndex) {
        assetFilter.sort = undefined;
      }
    });

    this.orderBy = null;
  }

  @action
  public setOrderBy(assetFilter: AssetFilter, direction: SortDirection): void {
    assetFilter.sort = direction;

    this.orderBy = [
      {
        attributeGroup: assetFilter.groupName,
        attributeName: assetFilter.attribute.name,
        sorting: direction,
      },
    ];
  }

  @action
  public setAssetGroup(): void {
    if (!this.displayItems) {
      return;
    }

    if (this.displayItems.items.every((e) => e.asset.attributeGroups.General.assetGroup === '7')) {
      this.itemsType = AssetContentType.MCT;

      ViewStore.changeSelectedGateUIAssetDomainSync(UIAssetDomainsNamesEnums.MARVIN_MCT);

      this.selectedType = {
        showAssets: true,
        showArticles: false,
        showModels: false,
      };

      return;
    } else if (this.displayItems.items.every((e) => e.asset.attributeGroups.General.assetGroup === '9')) {
      this.itemsType = AssetContentType.PI;

      ViewStore.changeSelectedGateUIAssetDomainSync(UIAssetDomainsNamesEnums.MARVIN_PI);

      return;
    }

    this.itemsType = AssetContentType.Unknown;
  }

  @action
  public toggleAsset(item: AssetData): void {
    const existingIndex = this.selectedAssets.indexOf(item);

    if (existingIndex === -1) {
      this.selectedAssets.push(item);
    } else {
      this.selectedAssets.splice(existingIndex, 1);
    }
  }

  @action
  public toggleAllSubItems(item: Array<AssetData>, checked: boolean): void {
    if (checked) {
      for (const i of item) {
        if (this.selectedAssets.includes(i)) {
          this.selectedAssets.splice(this.selectedAssets.indexOf(i), 1);
        }
      }
    } else {
      for (const i of item) {
        if (!this.selectedAssets.includes(i)) {
          this.selectedAssets.push(i);
        }
      }
    }
  }

  @action
  public toggleAllAssets(select: boolean): void {
    if (!this.displayItems) {
      return;
    }

    this.selectedAssets = select ? this.displayItems.items.slice() : [];
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    const toggle = FilterUtils.toggleSelectedFilter(item, filters);

    if (filters) {
      const assetGroups = this.assetGroup ? [this.assetGroup] : undefined;

      FilterConfigUtils.revalidateSelectedFilters(filters, appLocationEnum.SEARCH_RESULTS, assetGroups);
    }

    return toggle;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public clearAllFilters(): void {
    this.selectedFilters = this.mandatoryFilters;
  }

  public handleEditAction(action: ContextAction, item?: AssetFilter): void {
    if (!this.contextAction || !this.itemsType) {
      return;
    }

    if (action === ContextAction.Remove) {
      this.handleColumnRemove(this.contextAction.index, this.itemsType);
    } else if (item && action === ContextAction.Replace) {
      this.handleColumnReplace(item, this.contextAction.index, this.itemsType);
    } else if (item && action === ContextAction.Add) {
      this.handleColumnInsert(item, this.contextAction.index, this.itemsType);
    }
  }

  @action
  public handleContextMenu(contextMenu: boolean, positionX?: number, positionY?: number, index?: number | undefined): void {
    if (!this.contextAction) {
      return;
    }

    this.contextAction.contextMenu = contextMenu;

    if (contextMenu) {
      this.handleActionMenu(false);
    }
    if (index !== undefined) {
      this.contextAction.index = index;
    }
    if (positionX && positionY) {
      this.contextAction.positionX = positionX;
      this.contextAction.positionY = positionY;
    }
  }

  @action
  public handleActionMenu(actionMenu: boolean, actionType?: ContextAction): void {
    if (!this.contextAction) {
      return;
    }

    this.contextAction.actionMenu = actionMenu;

    if (actionType) {
      this.contextAction.actionType = actionType;
    }
  }

  @action
  public async getUserColumnSelection(): Promise<Array<IDisplayColumns>> {
    return fillUserColumnSelection();
  }

  @computed
  public get filtersByType(): Array<AssetFilter> {
    if (!this.listViewCols.length) {
      return [];
    }

    const configColumns = TableListViewConfig.addMCTColumnList;
    const availableFilter: Array<AssetFilter> = [];

    this.filters.forEach((element) => {
      if (configColumns.includes(element.attribute.name)) {
        availableFilter.push(element);
      }
    });

    const customColumns = TableListViewConfig.addMCTCustomColumnList;

    customColumns.forEach((element) => {
      availableFilter.push(
        new AssetFilter(element, { name: element, list: 'false', type: 'string', indexed: 'false', uiVisible: 'false', description: 'false' }),
      );
    });

    return availableFilter;
  }

  @action
  public handleColumnInsert(item: AssetFilter, index: number, group: AssetContentType): void {
    if (!this.listViewCols) {
      return;
    }

    const groupIndex = this.listViewCols.findIndex((e) => e.assetGroup === group);

    if (groupIndex === -1) {
      return;
    }

    if (!this.listViewCols[groupIndex].assetColumns.map((e) => e.attribute.name).includes(item.attribute.name)) {
      this.listViewCols[groupIndex].assetColumns = [
        ...this.listViewCols[groupIndex].assetColumns.slice(0, index + 1),
        item,
        ...this.listViewCols[groupIndex].assetColumns.slice(index + 1),
      ];

      return;
    }

    const removeIndex = this.listViewCols[groupIndex].assetColumns.findIndex((e) => e.attribute.name === item.attribute.name);

    this.listViewCols[groupIndex].assetColumns.splice(removeIndex, 1);
  }

  @action
  public handleColumnMove(items: Array<AssetFilter>, group: AssetContentType | null): void {
    if (!this.listViewCols || !group) {
      return;
    }

    const groupIndex = this.listViewCols.findIndex((e) => e.assetGroup === group);

    if (groupIndex === -1) {
      return;
    }

    if (!_.isEqual(this.listViewCols[groupIndex].assetColumns, items)) {
      this.listViewCols[groupIndex].assetColumns = items;
    }
  }

  @action
  public handleColumnReplace(item: AssetFilter, index: number, group: AssetContentType): void {
    if (!this.listViewCols) {
      return;
    }

    const groupIndex = this.listViewCols.findIndex((e) => e.assetGroup === group);

    if (groupIndex === -1) {
      return;
    }

    this.listViewCols[groupIndex].assetColumns[index] = item;
  }

  @action
  public clearUserColumns(): void {
    this.listViewCols = fillUserColumnSelection(TableListViewConfig.defaultColumns());
  }

  @action
  public handleColumnRemove(index: number, group: AssetContentType): void {
    if (!this.listViewCols) {
      return;
    }

    const groupIndex = this.listViewCols.findIndex((e) => e.assetGroup === group);

    if (groupIndex === -1) {
      return;
    }

    this.listViewCols[groupIndex].assetColumns.splice(index, 1);
  }

  public getMetadataSearchBody(): IMetadataSearch {
    const search: IMetadataSearch = {};

    if (this.selectedFilters.length) {
      search.searchFacet = {
        allMustMatch: true,
        searchAttributeList: this.selectedFilters.map((filter) => ({
          group: filter.groupName,
          name: filter.attribute.name,
          valueList: filter.getMetadataSearchValueList(),
        })),
      };
    }
    if (this.orderBy) {
      search.orderBy = this.orderBy;
    }

    if (this.searchInput) {
      search.searchText = this.searchInput;
    }

    return search;
  }

  public urlSearchToMetadataSearch(search: string): IMetadataSearch | null {
    if (!search) {
      return null;
    }

    const urlParams = new URLSearchParams(search);
    const q = urlParams.get('q');

    if (!q) {
      return null;
    }

    return JSON.parse(q) as IMetadataSearch;
  }

  @action
  public openAssetDetailsDialog(item: AssetData, assetType: AssetType): void {
    this.storeNavigationIds(item, assetType);

    if (assetType === AssetType.Article) {
      const articleNumber = (item.asset.attributeGroups['Asset Information']?.articleNumber as string) ?? '';

      NavigationService.navigateTo(`/details/${articleNumber}/article`);

      return;
    }

    NavigationService.navigateTo(`/details/${item.asset.id}`);
  }

  @action.bound
  public editColumns(columns: Array<IDisplayColumns>): void {
    this.listViewCols = columns;
    UtilityStore.setColumnList(columns);
  }

  public storeNavigationIds(item: AssetData, assetType: AssetType): void {
    if (!this.displayItems) {
      return;
    }

    if (assetType === AssetType.Article) {
      const articles = this.displayItems?.articles?.map((item) => item.articleName);

      if (!articles) {
        return;
      }

      const values: Array<string> = [];

      for (const item of articles) {
        if (item) {
          values.push(item);
        }
      }

      UtilityStore.storeAssetDetailsNavigation(values);

      return;
    } else if (this.selectedType?.showArticles && assetType === AssetType.Asset) {
      const articleNumber = (item.asset.attributeGroups['Asset Information']?.articleNumber as string) ?? '';

      const values = this.displayItems?.articles?.find((e) => e.articleName === articleNumber);

      values && UtilityStore.storeAssetDetailsNavigation(values.items.map((item) => item.asset.id));

      return;
    }

    const items = this.displayItems.items.map((item) => item.asset.id);

    UtilityStore.storeAssetDetailsNavigation(items);
  }

  public downloadMultiple(): void {
    if (!this.selectedAssets.length) {
      DialogStore.alert('Please check some assets first.');
    } else {
      this.openAdvancedDownloadDialog();
    }
  }

  public openAdvancedDownloadDialog(items?: Array<AssetData>): void {
    const confidential =
      (items?.length && showConfidential(undefined, items)) ||
      (this.selectedAssets && this.selectedFilters.length && showConfidential(this.selectedAssets[0])) ||
      false;

    DialogStore.addDialog(
      <AdvancedDownload
        confidential={confidential}
        selectedAssets={items ? items : this.selectedAssets}
        onClose={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }

  public openExportFilenameDialog(): void {
    DialogStore.addDialog(
      <ExportDialog
        onConfirm={(fileName: string) => {
          DialogStore.removeLastDialog();

          this.downloadAsXlsxFile(fileName);
        }}
        onCancel={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }

  public downloadAsXlsxFile(fileName: string): void {
    if (!this.itemsType || !this.displayItems || !this.listViewCols) {
      return;
    }

    const groupIndex = UtilityStore.listViewCols.findIndex((e) => e.assetGroup === (this.UIAssetDomainFromAssetContent as string));
    const headers = this.listViewCols[groupIndex].assetColumns.map((e) => e);

    if (this.selectedAssets.length !== 0) {
      ExportUtils.exportDataToFile(headers, this.selectedAssets, fileName);
    } else {
      ExportUtils.exportDataToFile(headers, this.displayItems.items, fileName);
    }
  }

  public bulkEditMetadata(): void {
    if (this.selectedAssets.length === 0) {
      DialogStore.alert('Please select check some assets first.');

      return;
    }

    const filters = TaxonomyStore.attributeGroupList;
    let editableFilterNames: Array<string> = [];

    switch (ViewStore.selectedAssetDomain) {
      case AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        editableFilterNames = DetailsConfig.splashEditFilter;
        break;
      case AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
      default:
        editableFilterNames = DetailsConfig.daboxEditFilter;
    }

    const displayFilters = editableFilterNames.reduce<Array<AssetFilter>>((acc, name) => {
      const filter = filters.find((f) => f.attribute.name === name);

      if (filter) {
        acc.push(filter);
      }

      return acc;
    }, []);

    DialogStore.addDialog(<EditBulkMetadata assets={this.selectedAssets} headers={['assetName', ...editableFilterNames]} filters={displayFilters} />);
  }

  public deleteSelectedAssets(): void {
    DialogStore.addDialog(
      <MatrixItemDeleteDialog
        translationKey={'common.delete_asset'}
        deleteBtnTranslationKey={'common.delete'}
        onCancel={() => DialogStore.removeLastDialog()}
        onConfirm={async () => {
          await this.handleDeleteAssets();
        }}
      />,
    );
  }

  private async handleDeleteAssets(): Promise<void> {
    if (!this.selectedAssets) {
      return;
    }

    const failedDeletions = await DialogStore.execFullScreen(MetadataUtils.bulkDeleteAssets(this.selectedAssets));

    const successfulyDeletedAssets = this.selectedAssets.reduce<Array<string>>((assetIds, asset) => {
      if (!failedDeletions.find((x) => x.asset.asset.id === asset.asset.id)) {
        assetIds.push(asset.asset.id);
      }

      return assetIds;
    }, []);

    DialogStore.removeLastDialog();

    this.removeDisplayItems(successfulyDeletedAssets);

    this.toggleAllAssets(false);

    if (failedDeletions.length !== 0) {
      DialogStore.alert(`Failed to delete items:\n${failedDeletions.map((x) => x.message).join('\n')}`);
    } else {
      DialogStore.alert(i18n.t('common.asset_delete_success'));
    }
  }

  private getAssetGroupRefIds(url: string): Array<string> | undefined {
    let assetGroups: Array<string> | undefined;
    const search = this.urlSearchToMetadataSearch(url);

    if (search) {
      const assetGroupSearch = search.searchFacet?.searchAttributeList?.find((item) => item.group === Name.General && item.name === 'assetGroup');

      if (assetGroupSearch?.valueList) {
        assetGroups = assetGroupSearch.valueList.map((value) => value.value);
      }
    }

    return assetGroups;
  }

  private fillArticles(items: Array<AssetData>): Array<IDisplayArticles> {
    const articleList: Array<IDisplayArticles> = [];

    if (ViewStore.appVariant === AppVariantEnum.GateUI) {
      for (const item of items) {
        const articleName = (item.asset.attributeGroups['Asset Information']?.articleNumber as string) ?? '';

        if (articleName) {
          if (articleList.map((e) => e.articleName).includes(articleName)) {
            const index = articleList.map((e) => e.articleName).indexOf(articleName);

            articleList[index].items.push(item);
          } else {
            articleList.push({ articleName, items: [item] });
          }
        } else {
          const existsWithoutArticle = articleList.map((e) => e.articleName).includes(undefined);

          if (existsWithoutArticle) {
            const index = articleList.map((e) => e.articleName).indexOf(undefined);

            articleList[index].items.push(item);
          } else {
            articleList.push({ articleName: undefined, items: [item] });
          }
        }
      }
    } else if (ViewStore.appVariant === AppVariantEnum.ArchiveUI) {
      articleList.push({ articleName: undefined, items });
    }

    return articleList;
  }

  private metadataSearchToFilters(search: IMetadataSearch, filters: Array<AssetFilter>): Array<AssetFilter> {
    const ret: Array<AssetFilter> = [];

    if (search?.searchFacet?.searchAttributeList) {
      search.searchFacet.allMustMatch = true;
      search.searchFacet.searchAttributeList.forEach((attr) => {
        const filter = filters.find((filter) => filter.groupName === attr.group && filter.attribute.name === attr.name);

        if (filter && attr.valueList) {
          if (attr.name === 'articleNumber') {
            filter.changeValue(attr.valueList.map((val) => val.value).join(', '));
          } else {
            attr.valueList.forEach((val) => {
              filter.changeValue(val.value);
            });
          }

          ret.push(filter);
        }
      });
    }

    return ret;
  }

  @action
  private async loadUserColumns(): Promise<void> {
    const columns = await this.getUserColumnSelection();

    runInAction(() => {
      this.listViewCols = columns;
    });

    UtilityStore.setColumnList(this.listViewCols);
  }

  private checkForDateFilters(searchAttributes: Array<IMetadataSearchAttribute>): void {
    for (const searchAttribute of searchAttributes) {
      if (FilterDisplayTypeConfig.date.includes(searchAttribute.name) && searchAttribute.valueList?.length) {
        const searchDate = searchAttribute.valueList[0].value;

        searchAttribute.valueList = undefined;
        searchAttribute.valueRange = {
          lowerBound: new Date(searchDate).toISOString(),
          lowerBoundIncluded: true,
          upperBound: addDays(new Date(searchDate), 1).toISOString(),
          upperBoundIncluded: false,
        };
      } else if (
        FilterDisplayTypeConfig.dateTime.includes(searchAttribute.name) &&
        searchAttribute.valueList?.length &&
        searchAttribute.valueList[0].value.includes(DATE_RANGE_PREFIX)
      ) {
        const searchDate = searchAttribute.valueList[0].value;
        const withoutPrefix = searchDate.substring(searchDate.indexOf(DATE_RANGE_PREFIX[DATE_RANGE_PREFIX.length - 1]));
        const [firstDate, secondDate] = withoutPrefix.split(DATE_RANGE_SEPARATOR);

        searchAttribute.valueList = undefined;
        searchAttribute.valueRange = {
          lowerBound: new Date(firstDate).toISOString(),
          lowerBoundIncluded: true,
          upperBound: new Date(secondDate).toISOString(),
          upperBoundIncluded: true,
        };
      }
    }
  }
}
