import React from 'react';
import { computed, action, makeObservable } from 'mobx';
import {
  IMetadataSearch,
  IMetadataSearchAttribute,
  IMatrixAxis,
  MatrixItemType,
  IMatrixAsset,
  Brand,
  IEventItem,
  IMetadata,
  HandledError,
  ErrorMessage,
  LogLevelEnum,
  EventTypeEnum,
} from '@cdam/shared';
import ClientHttpService, { UrlType, IClientHttpServiceOptions } from 'services/ClientHttpService';
import { showConfidential } from 'utils/AssetDisplayUtils';
import MatrixStore from '../MatrixStore';
import NavigationService from '../../../services/NavigationService';
import DialogStore from '../../dialog/DialogStore';
import AdvancedDownload from '../../../containers/assets/dialog/AdvancedDownload';
import UploadAssets from '../../../containers/upload/UploadAssets';
import LoggingService from '../../../services/LoggingService';
import { AssetData } from './AssetData';

export class AssetCell {
  public readonly count: string;
  public readonly isGroupItem: boolean;
  public readonly isStandaloneItem: boolean;
  public readonly cellData: IEventItem | undefined;

  private readonly store: MatrixStore;
  private readonly rowIndex: number;
  private readonly columnIndex: number;
  private readonly matrixAssetID: string;

  @computed
  public get highlightRow(): boolean {
    return this.rowIndex === this.store.selectedRowIndex && this.columnIndex <= this.store.selectedColumnIndex;
  }

  @computed
  public get highlightCell(): boolean {
    if (this.store.isGroupItemSelected) {
      return (
        this.columnIndex === this.store.selectedColumnIndex &&
        this.rowIndex <= this.store.groupHighlightEndIndex &&
        this.rowIndex >= this.store.groupHighlightStartIndex
      );
    }

    return this.columnIndex === this.store.selectedColumnIndex && this.rowIndex <= this.store.selectedRowIndex;
  }

  public get yAxis(): IMatrixAxis {
    if (!this.store.selectedEvent) {
      const error = new HandledError(ErrorMessage.MatrixError, undefined, undefined, undefined, undefined, 4);

      LoggingService.log({ message: 'Event was is empty', level: LogLevelEnum.ERROR, event: EventTypeEnum.MATRIX_GET }, error);

      throw error;
    }

    return this.store.selectedEvent.matrix.yAxis[this.rowIndex];
  }

  public get xAxis(): IMatrixAxis {
    if (!this.store.selectedEvent) {
      const error = new HandledError(ErrorMessage.MatrixError, undefined, undefined, undefined, undefined, 4);

      LoggingService.log({ message: 'Event was is empty', level: LogLevelEnum.ERROR, event: EventTypeEnum.MATRIX_GET }, error);

      throw error;
    }

    return this.store.selectedEvent.matrix.xAxis[this.columnIndex];
  }

  public constructor(
    store: MatrixStore,
    rowIndex: number,
    columnIndex: number,
    cellData: IEventItem | undefined,
    count: string,
    matrixAssetID: string,
  ) {
    makeObservable(this);
    this.store = store;
    this.rowIndex = rowIndex;
    this.columnIndex = columnIndex;
    this.isGroupItem = this.yAxis.type === MatrixItemType.Group;
    this.isStandaloneItem = this.yAxis.type === MatrixItemType.Standalone;
    this.count = count;
    this.cellData = cellData;
    this.matrixAssetID = matrixAssetID;
  }

  @action
  public async onClick(): Promise<void> {
    const distinctFilters: { [key: string]: IMetadataSearchAttribute } = {};

    if (this.xAxis.filters?.length) {
      this.addToDistinctFilters(distinctFilters, this.xAxis.filters);
    }

    if (this.yAxis.filters?.length) {
      this.addToDistinctFilters(distinctFilters, this.yAxis.filters);
    }

    if (this.store.selectedEvent?.generalFilters.length) {
      this.addToDistinctFilters(
        distinctFilters,
        this.store.selectedEvent.generalFilters.filter((gf) => gf.valueList?.length),
      );
    }

    const search: IMetadataSearch = {
      searchFacet: {
        allMustMatch: true,
        searchAttributeList: Object.values(distinctFilters),
      },
    };

    if (!this.isGroupItem) {
      NavigationService.navigateTo(`/assets?q=${encodeURIComponent(JSON.stringify(search))}`);
    } else if (this.isGroupItem && this.store.selectedEvent && Number(this.count) > 0) {
      try {
        const assetsData = await DialogStore.execFullScreen(this.getDownloadItems());

        this.openAdvancedDownloadDialog(assetsData);
      } catch (e) {
        DialogStore.error(e);
      }
    }
  }

  public async openUploadDialog(): Promise<void> {
    DialogStore.addDialog(
      <UploadAssets
        name={[this.xAxis.name, this.yAxis.name]}
        filters={this.cellData?.uploadFilters}
        brand={this.store.brand ?? Brand.Adidas}
        owners={[...(this.cellData?.owners ?? [])]}
        filenameConvention={this.store.selectedEvent?.filenameConvention ?? ''}
        onClose={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }

  private async getDownloadItems(): Promise<Array<AssetData>> {
    const {
      matrixAsset: { assetIds },
    } = await ClientHttpService.fetch<Record<string, IMatrixAsset>>({
      urlType: UrlType.Backend,
      url: `/matrix-assets/${this.matrixAssetID}`,
      method: 'GET',
    });

    const assetsData: Array<AssetData> = await Promise.all(
      assetIds.map(async (assetId) => {
        try {
          const options: IClientHttpServiceOptions = {
            url: `/metadata/${assetId}`,
            urlType: UrlType.Metadata,
            method: 'GET',
          };
          const response = await ClientHttpService.fetch<IMetadata>(options);

          return new AssetData(response);
        } catch (e) {
          const error = e as HandledError;

          LoggingService.log({ message: 'Failed to retrive donwload items', level: LogLevelEnum.WARN, event: EventTypeEnum.FILE_DOWNLOAD }, error);

          throw error;
        }
      }),
    );

    return assetsData;
  }

  private addToDistinctFilters(distinctFilters: { [key: string]: IMetadataSearchAttribute }, filters: Array<IMetadataSearchAttribute>): void {
    filters.forEach((f) => {
      if (distinctFilters[`${f.group}-${f.name}`]) {
        f.valueList?.forEach((value) => {
          const exists = distinctFilters[`${f.group}-${f.name}`].valueList?.findIndex((v) => v.value === value.value);

          if (exists === -1) {
            distinctFilters[`${f.group}-${f.name}`].valueList?.push(value);
          }
        });
      } else {
        distinctFilters[`${f.group}-${f.name}`] = f;
      }
    });
  }

  private async openAdvancedDownloadDialog(assetsData: Array<AssetData>): Promise<void> {
    const confidential = (assetsData?.length && showConfidential(undefined, assetsData)) || false;

    DialogStore.addDialog(
      <AdvancedDownload
        confidential={confidential}
        selectedAssets={assetsData}
        onClose={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }
}
