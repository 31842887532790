/* eslint-disable complexity */
import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IFiltersSearchResult, HighlightedCollection } from '@cdam/shared';
import DropdownItem from 'components/dropdown/DropdownItem';
import Dropdown from 'components/dropdown/Dropdown';
import { ListLoader } from '../../../components/loader/ListLoader';
import SearchStore from '../../../stores/assets/SearchStore';
import Button, { ButtonType, ButtonSize } from '../../../components/input/Button';
import TextBoxUncontrolled, { TextBoxUncontrolledInputHandles } from '../../../components/input/TextBoxUncontrolled';
import NavigationService from '../../../services/NavigationService';
import SearchArticleNumbers from './SearchArticleNumbers';

import './SearchInput.scss';

interface ISearchInputProps {
  store: SearchStore;
}

const SearchInput: React.FC<ISearchInputProps> = observer(({ store }: ISearchInputProps) => {
  const { t } = useTranslation();
  const popup: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const searchInputContainer: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const input = useRef<TextBoxUncontrolledInputHandles>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [selectedFilterIndex, setFilterIndex] = useState(-1);
  const [selectedAssetIndex, setAssetIndex] = useState(-1);
  const [selectedArticleIndex, setArticleIndex] = useState(-1);
  const [articleNumberSearch, setArticleNumberSearch] = useState<Array<string> | undefined>(undefined);
  const assetGroupOptions = ['All', 'Marketing Content', 'Product Images'];

  useEffect(() => {
    store.load();
  }, [store, store.searchText, store.selectedContentType, store.selectedFilters]);

  function onBlur(): void {
    setIsFocused(false);
    setFilterIndex(-1);
    setAssetIndex(-1);
    setArticleIndex(-1);
  }

  function changeHighlight(index: number, collection?: HighlightedCollection): void {
    setFilterIndex(-1);
    setAssetIndex(-1);
    setArticleIndex(-1);

    switch (collection) {
      case 'filters':
        if (store.items?.filters && index >= 0 && store.items.filters[index]) {
          setFilterIndex(index);
        }

        break;
      case 'assets':
        if (store.items?.assets && index >= 0 && store.items.assets[index]) {
          setAssetIndex(index);
        }

        break;
      case 'articles':
        if (store.items?.articles && index >= 0 && store.items.articles[index]) {
          setArticleIndex(index);
        }

        break;
      default:
        break;
    }
  }

  function handleOnKeyDown(e: React.KeyboardEvent): void {
    if (e.key === 'Enter' || e.key === 'Backspace') {
      switch (e.key) {
        case 'Enter':
          e.preventDefault();
          store.search();
          break;
        case 'Backspace':
          if (!store.searchText && store.selectedFilters.length) {
            store.removePreselectedFilter(store.selectedFilters[store.selectedFilters.length - 1]);
          }

          break;
        default:
          break;
      }
    }
  }

  function handleBadgeApply(e: React.MouseEvent, filters: IFiltersSearchResult): void {
    e.preventDefault();
    store.addPreselectedFilter(filters);
    input?.current?.setValue('');
    store.setSearchText('');
  }

  function handleResultItemClick(group: string, name: string, value: string): void {
    store.search({
      group,
      name,
      valueList: [
        {
          value,
        },
      ],
    });
  }

  function handleSearchSubmit(e: FormEvent<HTMLFormElement>): void {
    e.preventDefault();

    if (articleNumberSearch) {
      store.search({
        group: 'Asset Information',
        name: 'articleNumber',
        valueList: articleNumberSearch.map((value) => ({
          value,
        })),
      });
    } else {
      store.search();
    }
  }

  return (
    <form className="SearchInput jumbotron" onSubmit={(e) => handleSearchSubmit(e)}>
      <Dropdown
        value={store.getAssetContentType}
        closeWhenItemSelect={true}
        maxFilterWidth={'12.5rem'}
        className={'SearchInput-dropdown'}
        disableLabel={true}
        iconColor={'#000'}
        data-testid="search-view-asset-group-filter"
      >
        {assetGroupOptions.map((item, index) => (
          <DropdownItem
            key={index}
            value={item}
            className="DropdownCheckboxItem space-p-h-1 space-p-v-1"
            onClick={() => store.addContentType(item)}
          />
        ))}
      </Dropdown>
      <SearchArticleNumbers
        hideLeftBorder={true}
        hideRightBorder={true}
        refEl={searchInputContainer}
        onSearch={(values) => setArticleNumberSearch(values)}
      />
      <div className="SearchInput-container" ref={searchInputContainer}>
        <TextBoxUncontrolled
          ref={input}
          className="SearchInput-search"
          placeholder={!store.selectedFilters.length ? t('matrix.search_input_placeholder') : ''}
          choices={true}
          filterChoices={store.selectedFilters}
          data-testid="search-view-search-input"
          onFocus={() => setIsFocused(true)}
          onBlur={onBlur}
          onRemoveChoices={(item) => store.removePreselectedFilter(item)}
          onChange={(e) => store.setSearchText(e.target.value)}
          onKeyDown={(e) => handleOnKeyDown(e)}
        />
        {isFocused && (store.items || store.isLoading || store.error) && (
          <div className="SearchInput-popup box-shadow-2 space-p-b-4" ref={popup}>
            {store.items?.filters && store.items.filters.length > 0 && (
              <div className="SearchInput-suggestion-cell-divider space-m-l-3 space-m-b-2 space-m-t-4">
                <strong>{t('matrix.search_filters_divider')}</strong>
              </div>
            )}

            {store.items?.filters?.map((item, index) => (
              <div
                key={index}
                className={`SearchInput-popup-suggestion space-m-l-3 ${selectedFilterIndex === index ? 'highlight' : ''}`}
                onMouseOver={() => changeHighlight(index, 'filters')}
                onMouseDown={(e) => handleBadgeApply(e, item)}
              >
                <div className="SearchInput-suggestion-cell">
                  <div className="SearchInput-suggestion-cell-text">
                    {item.pre}
                    <strong>{item.bold}</strong>
                    {item.post}
                  </div>
                  <div className="SearchInput-suggestion-cell-attribute text-nowrap space-m-l-6 space-m-r-3">{item.attributeLabel}</div>
                </div>
              </div>
            ))}

            {store.items?.assets && store.items.assets.length > 0 && (
              <div className="SearchInput-suggestion-cell-divider space-m-l-3 space-m-b-2 space-m-t-4">
                <strong>{t('matrix.search_assets_divider')}</strong>
              </div>
            )}

            {store.items?.assets?.slice(0, store.maxAssetItems).map((item, index) => (
              <div
                key={index}
                className={`SearchInput-popup-suggestion space-m-l-3 ${selectedAssetIndex === index ? 'highlight' : ''}`}
                onMouseOver={() => changeHighlight(index, 'assets')}
                onMouseDown={() => NavigationService.navigateTo(`/details/${item.id}`)}
              >
                <div className="SearchInput-suggestion-cell">
                  <div className="SearchInput-suggestion-cell-text">
                    {item.pre}
                    <strong>{item.bold}</strong>
                    {item.post}
                  </div>
                </div>
              </div>
            ))}

            {store.items?.assets && store.items?.assets.length > store.maxAssetItems && (
              <div className="space-m-l-6 space-m-t-2 space-m-b-2 SearchInput-suggestion-cell-type">
                {t('matrix.search_assets_count_prefix')} {store.items.assets.length - store.maxAssetItems} {t('matrix.search_assets_count_postfix')}
              </div>
            )}

            {store.items?.articles && store.items.articles.length > 0 && (
              <div className="SearchInput-suggestion-cell-divider space-m-l-3 space-m-b-2 space-m-t-4">
                <strong>{t('matrix.search_articles_divider')}</strong>
              </div>
            )}

            {store.items?.articles?.map((item, index) => (
              <div
                key={index}
                className={`SearchInput-popup-suggestion space-m-l-3 ${selectedArticleIndex === index ? 'highlight' : ''}`}
                onMouseOver={() => changeHighlight(index, 'articles')}
                onMouseDown={() => handleResultItemClick('Asset Information', 'articleNumber', item.articleNumber)}
              >
                <div className="SearchInput-suggestion-cell">
                  <div className="SearchInput-suggestion-cell-text">
                    {item.pre}
                    <strong>{item.bold}</strong>
                    {item.post}
                  </div>
                </div>
              </div>
            ))}

            <ListLoader isLoading={store.isLoading} className="space-m-b-6 space-m-t-6" error={store.error} small={true} itemsLength={3} />
          </div>
        )}
      </div>
      <Button
        buttonType={ButtonType.Primary}
        size={ButtonSize.Default}
        className="SearchInput-button space-m-l-3"
        data-testid="search-view-search-button"
        type="submit"
      >
        {t('common.search')}
      </Button>
    </form>
  );
});

export default SearchInput;
