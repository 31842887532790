import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ErrorUtils from '../../utils/ErrorUtils';
import { Loader } from './Loader';
import './Loader.scss';

interface IListLoaderProps {
  isLoading: boolean;
  error: Error | null | undefined;
  itemsLength: number;
  emptyMessage?: string;
  small?: boolean;
  className?: string;
}

export const ListLoader = observer((props: IListLoaderProps) => {
  const { t } = useTranslation();

  if (props.isLoading) {
    return <Loader small={props.small} className={props.className ? props.className : ''} />;
  }

  if (props.error) {
    return <div className="loader-error-message loader-list-message">{ErrorUtils.getErrorMessage(props.error)}</div>;
  }

  if (props.itemsLength === 0) {
    return <div className="loader-empty-message loader-list-message">{props.emptyMessage ?? t('list.empty')}</div>;
  }

  return null;
});
