import React, { useEffect, useState } from 'react';
import { AppVariantEnum, FeatureFlagsEnum } from '@cdam/shared';
import ViewStore from '../../stores/view/ViewStore';

enum LogicalOperatorEnum {
  AND,
  OR,
}
interface IFeatureFlags {
  operator: LogicalOperatorEnum;
  flags: Array<boolean>;
}

const flags = new Map<FeatureFlagsEnum, IFeatureFlags>([
  [FeatureFlagsEnum.DeleteBulk, { operator: LogicalOperatorEnum.AND, flags: [ViewStore.appVariant === AppVariantEnum.ArchiveUI] }],
  [FeatureFlagsEnum.EditBulk, { operator: LogicalOperatorEnum.AND, flags: [ViewStore.appVariant === AppVariantEnum.ArchiveUI] }],
  [
    FeatureFlagsEnum.TemplateUpload,
    { operator: LogicalOperatorEnum.OR, flags: [window.ENV_LABEL !== 'prd', ViewStore.appVariant !== AppVariantEnum.GateUI] },
  ],
  [FeatureFlagsEnum.EditColumns, { operator: LogicalOperatorEnum.AND, flags: [ViewStore.appVariant === AppVariantEnum.ArchiveUI] }],
]);

interface IFeatureFlagProps {
  additionalConstrains?: Array<boolean>;
  children: JSX.Element;
  feature: FeatureFlagsEnum;
}

export function FeatureFlag({ feature, children, additionalConstrains }: IFeatureFlagProps): JSX.Element | null {
  const [canDisplay, setCanDisplay] = useState(true);

  useEffect(() => {
    let isEnabled = flags.get(feature) ?? { operator: LogicalOperatorEnum.AND, flags: [canDisplay] };

    if (additionalConstrains) {
      isEnabled = { operator: isEnabled.operator, flags: [...isEnabled.flags, ...additionalConstrains] };
    }

    switch (isEnabled.operator) {
      case LogicalOperatorEnum.AND:
        setCanDisplay(isEnabled.flags.every(Boolean));
        break;
      case LogicalOperatorEnum.OR:
        setCanDisplay(isEnabled.flags.some(Boolean));
        break;
      default:
        break;
    }
  }, [additionalConstrains, canDisplay, feature]);

  if (!canDisplay) {
    return null;
  }

  return <>{children}</>;
}
