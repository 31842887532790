import React from 'react';
import { AssetData } from 'stores/assets/data/AssetData';
import AssetCardImage from 'components/coredam/AssetCardImage';
import AssetUtils from 'utils/AssetUtils';
import { useTranslation } from 'react-i18next';
import Formatter from 'utils/Formatter';

import './AdvancedDownloadMultiple.scss';

interface IAdvancedDownloadMultiple {
  items: Array<AssetData>;
  format: string;
}

const AdvancedDownloadMultiple = ({ items, format }: IAdvancedDownloadMultiple): JSX.Element => {
  const { t } = useTranslation();

  const numberOfAssets = items.length;

  const getFullFileSize = (): string => {
    const sizes: Array<number> = [];

    items.forEach((item) => {
      const size = item.activeDigitalContent && AssetUtils.getFullFileSize(item.activeDigitalContent);

      if (size) {
        sizes.push(size);
      }
    });

    return Formatter.getSumFileSizes(sizes);
  };

  return (
    <div className="AdvancedDownloadMultiple space-p-6">
      <div className="AdvancedDownloadMultiple-images">
        <div className="AdvancedDownloadMultiple-image-layer2" />
        <div className="AdvancedDownloadMultiple-image-layer">
          <div className="AdvancedDownloadMultiple-image-content">
            <AssetCardImage height={260} width={260} item={items[0]} hideBadge={true} className="AdvancedDownloadMultiple-image" />
            <div className="AdvancedDownloadMultiple-image-content-badge space-p-h-2 space-p-h-1">
              {numberOfAssets} {t('advancedDownload.multiple_assets').toUpperCase()}
            </div>
          </div>
        </div>
      </div>
      <div className="space-m-l-6 AdvancedDownloadMultiple-content">
        <h6>{t('advancedDownload.multiple_title')}</h6>
        <p className="AdvancedDownloadMultiple-description">{t('advancedDownload.multiple_description')}</p>
        <div className="space-m-t-4">
          <p className="AdvancedDownloadMultiple-header-label">{t('advancedDownload.multiple_size')}</p>
          <p>{getFullFileSize()}</p>
        </div>
        <div>
          <p className="AdvancedDownloadMultiple-header-label">{t('advancedDownload.multiple_format')}</p>
          <p>{format}</p>
        </div>
      </div>
    </div>
  );
};

export default AdvancedDownloadMultiple;
