/* eslint-disable complexity */
import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import { v4 } from 'uuid';
import DialogStore from 'stores/dialog/DialogStore';
import { ErrorMessage, HandledError, IEventUploadFilters, fileNameIsInvalid, Name, appLocationEnum, assetGroupsEnum } from '@cdam/shared';
import UploadDownloadManagerStore from 'stores/uploadDownloadManager/UploadDownloadManagerStore';
import { UploadItem } from 'stores/uploadDownloadManager/data/UploadItem';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import { getUnderscoreCountFromFilename } from 'utils/UploadedFilenameValidation';
import FilterUtils from 'utils/FilterUtils';
import { BrandCodes } from './UploadProductImagesStore';

export interface IUploadFile {
  id?: string;
  name: string;
  extension: string;
  mimeType: string;
  size: number;
  file: Blob;
  checksum: string;
  presetUploadFilters: Array<AssetFilter>;
  displayedUploadFilters: Array<AssetFilter>;
  validFilename: boolean;
  metadataId?: string;
  articleNumberMissing?: boolean;
  productViewMissing?: boolean;
  brand?: BrandCodes;
  workerReference?: Worker | null;
  checksumCalculationInProgress?: boolean;
  checksumLoaderMouseHovered?: boolean;
}

export class UploadStore {
  @observable public uploadAssets: Array<IUploadFile> = [];
  @observable public isLoading = false;
  public name: string;
  private readonly filenameConvention: string | undefined = undefined;
  private readonly uploadFiltersMetadata: IEventUploadFilters | undefined;
  private readonly owners: Array<string> | undefined = undefined;

  // private readonly taxonomyFilters = TaxonomyStore.getTaxonomyAttributeFilters();

  public constructor(name: string, filters: IEventUploadFilters | undefined, filenameConvention?: string, owners?: Array<string>) {
    makeObservable(this);
    this.name = name;
    this.uploadFiltersMetadata = filters;
    this.filenameConvention = filenameConvention;
    this.owners = owners;
  }

  @computed
  public get allMandatoryFiltersSelected(): boolean {
    return this.uploadAssets.every((item) =>
      item.displayedUploadFilters.filter((f) => f.isMandatory === true).every((f) => f.selectedSingleValue ?? f.value),
    );
  }

  @computed
  public get allAssetsChecksumCalculated(): boolean {
    return this.uploadAssets.every((item) => item.checksumCalculationInProgress === false);
  }

  @computed
  public get filenameError(): boolean {
    return !this.uploadAssets.every((asset) => asset.validFilename);
  }

  @action
  public changeFileName(file: IUploadFile, name: string): void {
    file.name = name;
  }

  @action
  public changeChecksumLoaderHover(file: IUploadFile, value: boolean): void {
    file.checksumLoaderMouseHovered = value;
  }

  @action
  public beginUploadingFiles(): void {
    UploadDownloadManagerStore.addUploadItem(new UploadItem(this.name, this.uploadAssets));
  }

  // eslint-disable-next-line complexity
  @action
  public handleFileDrop(fullName: string, file: Blob, mimeType: string, fileSize: number, metadataId = ''): void {
    this.isLoading = true;

    const presetFilters = this.uploadFiltersMetadata
      ? FilterUtils.findAndPreselectFilters(this.uploadFiltersMetadata.preset, TaxonomyStore.getTaxonomyAttributeFiltersWithRules())
      : [];

    const splitAt = fullName.lastIndexOf('.');
    const name = fullName;
    const extension = fullName.slice(splitAt + 1);
    const uploadFile: IUploadFile = {
      id: v4(),
      name,
      extension,
      file,
      size: fileSize,
      mimeType,
      checksum: '',
      metadataId,
      presetUploadFilters: presetFilters,
      displayedUploadFilters: this.uploadFiltersMetadata
        ? FilterUtils.findAndPreselectFilters(
            this.uploadFiltersMetadata.display,
            TaxonomyStore.getTaxonomyAttributeFiltersWithRules(presetFilters, undefined, appLocationEnum.UPLOAD_MCT, [
              assetGroupsEnum.MARKETING_CONTENT,
            ]),
          )
        : [],
      validFilename: true,
      workerReference: null,
      checksumCalculationInProgress: true,
      checksumLoaderMouseHovered: false,
    };

    // Set owners to presetUploadFilters and double check for preexisting filters
    if (this.owners) {
      const uniqueOwners = this.owners.filter((owner, index) => this.owners?.lastIndexOf(owner) === index);

      const ownersAssetFilter = FilterUtils.findAndPreselectFilters(
        [
          {
            group: Name.Miscellaneous,
            name: 'owners',
            valueList: uniqueOwners.map((owner) => ({ value: owner })),
          },
        ],
        TaxonomyStore.getTaxonomyAttributeFiltersWithRules(),
      );

      // The owners filter can not be set in the matrix and on the tab at the same time.
      // This is just a defensive meassurment.
      if (uploadFile.presetUploadFilters.length === 0) {
        uploadFile.presetUploadFilters = ownersAssetFilter;
      } else {
        const ownersIndex = uploadFile.presetUploadFilters.findIndex((assetFilter) => assetFilter.attribute.name.toLowerCase() === 'owners');

        if (ownersIndex === -1) {
          uploadFile.presetUploadFilters = [...uploadFile.presetUploadFilters, ...ownersAssetFilter];
        } else {
          uploadFile.presetUploadFilters[ownersIndex] = ownersAssetFilter[0];
        }
      }
    }

    for (const filter of uploadFile.displayedUploadFilters) {
      if (filter.isMandatory === true && filter.selectedReferenceItems.length === 1) {
        filter.setSingleSelectedValue(filter.selectedReferenceItems[0]);
        filter.setIsPreset(true);
      }

      if (filter.attribute.type && filter.attribute.type === 'string' && filter.values.length === 1) {
        filter.setSingleSelectedValue(filter.values[0]);
        filter.setIsPreset(true);
      } else if (filter.attribute.type && filter.value) {
        filter.setIsPreset(true);
      }
    }

    this.uploadAssets.push(uploadFile);
    this.calculateChecksum(uploadFile);

    this.setLoading(false);
  }

  @action
  public calculateChecksum(uploadFile: IUploadFile): void {
    const worker = new Worker('/calculateHashWorker.js');

    worker.postMessage(uploadFile.file);

    worker.onmessage = (e) => {
      const checksum = e.data as string;

      const asset = this.uploadAssets.find((asset) => asset.id === uploadFile.id);

      if (asset) {
        runInAction(() => {
          asset.workerReference = worker;
          asset.checksum = checksum;
          asset.checksumCalculationInProgress = false;
        });
      }

      worker.terminate();
    };
  }

  @action
  public validateFilenames(): void {
    const underscoreCounterConvention = getUnderscoreCountFromFilename(this.filenameConvention ?? '');

    if (underscoreCounterConvention === 0) {
      for (const asset of this.uploadAssets) {
        if (!fileNameIsInvalid(asset.name)) {
          asset.validFilename = true;
        } else {
          asset.validFilename = false;
        }
      }

      return;
    }

    for (const asset of this.uploadAssets) {
      const underscoreCounter = getUnderscoreCountFromFilename(asset.name);

      if (!fileNameIsInvalid(asset.name) && underscoreCounter === underscoreCounterConvention) {
        asset.validFilename = true;
      } else {
        asset.validFilename = false;
      }
    }
  }

  @action
  public setLoading(value: boolean): void {
    this.isLoading = value;
  }

  @action
  public removeFile(index: number): void {
    this.uploadAssets[index].workerReference?.terminate();
    this.uploadAssets.splice(index, 1);
  }

  @action
  public changeFilename(file: IUploadFile, value: string): void {
    file.name = value;
  }

  public throwUploadError(): void {
    DialogStore.error(new HandledError(ErrorMessage.noDownloadPackageNameProvided));
  }
}
