import React from 'react';
import { observer } from 'mobx-react-lite';
import { AssetType } from '@cdam/shared';
import { AssetData } from 'stores/assets/data/AssetData';
import AssetsStore from '../../stores/assets/AssetsStore';
import AssetCards from './components/CardView/AssetCards';
import ArticleCardRow from './components/CardView/ArticleCardRow';

import './AssetsCardView.scss';

interface IAssetsCardViewProps {
  className?: string;
  assetsStore: AssetsStore;
}

const AssetsCardView: React.FC<IAssetsCardViewProps> = observer(({ assetsStore }: IAssetsCardViewProps) => {
  let View;

  const isChecked = (items: Array<AssetData>): boolean => items.every((e) => assetsStore.selectedAssets.includes(e));

  const openAssetDetails = (item: AssetData, assetType: AssetType): void => {
    assetsStore.openAssetDetailsDialog(item, assetType);
  };

  if (assetsStore.selectedType?.showArticles && assetsStore.displayItems?.articles) {
    View = assetsStore.displayItems.articles.map((item, index) => (
      <ArticleCardRow
        key={index}
        assetsStore={assetsStore}
        checked={isChecked(item.items)}
        openAssetDetails={() => openAssetDetails(item.items[0], AssetType.Article)}
        type={assetsStore.selectedType}
        item={item}
      />
    ));
  } else if (assetsStore.selectedType?.showAssets && assetsStore.displayItems) {
    View = <AssetCards assetsStore={assetsStore} items={assetsStore.displayItems.items} />;
  }

  return <React.Fragment>{View && assetsStore.filteredItems && assetsStore.filteredItems.items.length !== 0 && View}</React.Fragment>;
});

export default AssetsCardView;
