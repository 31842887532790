import { UIAssetDomainsNamesEnums } from '@cdam/shared';

export enum LocalStorageType {
  SessionStorage,
  LocalStorage,
}

class StorageService {
  private readonly storage: Storage;
  private readonly assetDomainKey = 'ui_asset_domain';

  public constructor(localStorageType = LocalStorageType.SessionStorage) {
    this.storage = localStorageType === LocalStorageType.SessionStorage ? sessionStorage : localStorage;
  }

  public get selectedAssetDomain(): UIAssetDomainsNamesEnums | null {
    return this.storage.getItem(this.assetDomainKey) as UIAssetDomainsNamesEnums;
  }

  public set selectedAssetDomain(assetDomain: UIAssetDomainsNamesEnums | null) {
    this.storage.setItem(this.assetDomainKey, assetDomain as string);
  }
}

// Singelton
export default new StorageService();
