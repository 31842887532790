/* eslint-disable complexity */
import React from 'react';
import { v4 } from 'uuid';
import { action, computed, observable, toJS, makeObservable } from 'mobx';
import _ from 'lodash';
import {
  CustomType,
  EventTypeEnum,
  HandledError,
  HttpMethodEnum,
  IDownloadFileFormat,
  IDownloadItem,
  IDownloadRequest,
  IDownloadType,
  IMetadata,
  IMetadataDigitalContent,
  IZip,
  LogLevelEnum,
  WatermarkNameType,
} from '@cdam/shared';
import { validateCustomImageValues } from 'utils/CustomFormatValidation';
import DialogStore from 'stores/dialog/DialogStore';
import { DownloadType } from 'containers/assets/dialog/data/DownloadType';
import { DownloadDialog } from 'containers/assets/dialog/DownloadDialog';
import UploadDownloadManagerStore from 'stores/uploadDownloadManager/UploadDownloadManagerStore';
import ValidationDialog from 'containers/dialog/dialogs/ValidationDialog';
import { getFileFormatTypeFromExtension, readFileExtension } from '../../utils/FileFormatParser';
import { DownloadItem } from '../uploadDownloadManager/data/DownloadItem';
import HttpServiceApi, { UrlType } from '../../services/ClientHttpService';
import { AlertDialog } from '../../containers/dialog/dialogs/AlertDialog';
import LoggingService from '../../services/LoggingService';
import ViewStore from '../view/ViewStore';
import { AssetData } from './data/AssetData';

export class DownloadableAssetData {
  @observable public readonly asset: IMetadata;
  @observable public selectedFileFormats: Array<IDownloadFileFormat> = [];
  @observable public readonly activeDigitalContent: IMetadataDigitalContent | undefined;

  public constructor(asset: IMetadata) {
    makeObservable(this);
    this.asset = asset;
    this.activeDigitalContent = asset?.digitalContentList?.find((content) => content.version === asset.activeDigitalContent);
  }

  @action
  public toggleSelectFileFormat(format: IDownloadFileFormat): void {
    const index = this.selectedFileFormats.map((e) => e).findIndex((f) => f.format === format.format && f.customFormat === format.customFormat);

    if (index === -1) {
      this.selectedFileFormats.push(format);

      return;
    }

    this.selectedFileFormats.splice(index, 1);
  }

  @action
  public handleCustomValueChange(format: IDownloadFileFormat, changeValue: string, type: CustomType): void {
    const index = this.selectedFileFormats.map((e) => e).findIndex((f) => f.format === format.format && f.customFormat === format.customFormat);

    if (index !== -1 && format.parameters && format.validation) {
      switch (type) {
        case CustomType.Width:
          if (!validateCustomImageValues(format, changeValue, type)) {
            format.validation.width = 1;
          } else {
            format.validation.width = 0;
          }

          format.valid = this.validateCustomFormat(format);
          format.parameters.width = changeValue;
          break;
        case CustomType.Height:
          if (!validateCustomImageValues(format, changeValue, type)) {
            format.validation.height = 1;
          } else {
            format.validation.height = 0;
          }

          format.valid = this.validateCustomFormat(format);
          format.parameters.height = changeValue;
          break;
        case CustomType.Resolution:
          if (!validateCustomImageValues(format, changeValue, type)) {
            format.validation.resolution = 1;
          } else {
            format.validation.resolution = 0;
          }

          format.valid = this.validateCustomFormat(format);
          format.parameters.resolution = changeValue;
          break;
      }

      this.selectedFileFormats[index] = format;
    }
  }

  private validateCustomFormat(format: IDownloadFileFormat): boolean {
    if (format.parameters && format.validation) {
      if (
        format.parameters.height !== '' &&
        format.parameters.resolution !== '' &&
        format.parameters.width !== '' &&
        format.validation.height === 0 &&
        format.validation.width === 0 &&
        format.validation.resolution === 0
      ) {
        return true;
      }
    }

    return false;
  }
}

export default class AdvancedDownloadStore {
  @observable public assets: Array<DownloadableAssetData> = [];
  @observable public downloadType: IDownloadType | null = null;
  @observable public downloadWatermark: string | undefined = undefined;
  @observable public headerSelectedFormats: Array<IDownloadFileFormat> = [];
  @observable public watermarkChecked = false;
  @observable public isDownloading = false;

  public constructor() {
    makeObservable(this);
  }

  @action
  public init(selectedAssets: Array<AssetData>): void {
    this.assets = [];

    selectedAssets.forEach((item) => {
      const metadata = new DownloadableAssetData(item.asset);

      this.assets.push(metadata);
    });
    this.downloadType = this.getDefaultDownloadType();
  }

  @action
  public getDefaultDownloadType(): IDownloadType {
    // todo handle default selected download type according to selected items
    // if Only Models are selected one_asset should be preselected otherwise all_assets
    return DownloadType.find((e) => e.type === 'all_assets') as IDownloadType;
  }

  @computed
  public get filteredItems(): Array<DownloadableAssetData> {
    // todo implement filtering items
    return this.assets;
  }

  @action
  public toggleWatermark(): void {
    this.watermarkChecked = !this.watermarkChecked;

    if (this.watermarkChecked) {
      this.downloadWatermark = undefined;
    }
  }

  @action
  public setDownloadType(type: IDownloadType): void {
    this.downloadType = type;
  }

  @action
  public setDownloadWatermark(watermark?: string): void {
    this.downloadWatermark = watermark;
  }

  @computed
  public get formatIsMixed(): boolean {
    if (!this.assets.length) {
      return false;
    }

    const formats = toJS(this.assets[0].selectedFileFormats);

    let isMixed = false;

    this.assets.forEach((a) => {
      const assetFormats = toJS(a.selectedFileFormats);

      if (!_.isEqual(assetFormats, formats)) {
        isMixed = true;
      }
    });

    return isMixed;
  }

  @computed
  public get globalAssetFormats(): Array<IDownloadFileFormat> | undefined {
    if (!this.headerSelectedFormats) {
      return undefined;
    }

    return toJS(this.headerSelectedFormats);
  }

  @action
  public toggleGlobalAssetFormat(format: IDownloadFileFormat): void {
    if (!this.globalAssetFormats) {
      this.setGlobalAssetFormats([format]);

      return;
    }

    const index = this.globalAssetFormats.findIndex((f) => _.isEqual(f, format));

    if (index === -1) {
      this.setGlobalAssetFormats([...this.globalAssetFormats, format]);

      return;
    }

    // unselect format
    this.setGlobalAssetFormats([...this.globalAssetFormats.slice(0, index), ...this.globalAssetFormats.slice(index + 1)]);
  }

  public async validateZipFilename(filename: string): Promise<boolean> {
    const { content: fileExists } = await HttpServiceApi.fetch<{ content: boolean | Array<IZip> }>({
      url: `/downloads?filename=${filename}`,
      urlType: UrlType.Backend,
      method: 'GET',
    });

    if (fileExists) {
      DialogStore.addDialog(
        <AlertDialog
          text="A file with that name already exists"
          onClick={() => {
            DialogStore.removeLastDialog();
          }}
        />,
      );
    }

    return !!fileExists;
  }

  public async validateDownloadDialog(filename: string): Promise<void> {
    const invalidAssets: Array<DownloadableAssetData> = this.assets.filter((item) => !item.activeDigitalContent);

    if (invalidAssets.length) {
      DialogStore.addDialog(
        <ValidationDialog
          descriptionTranslationKey={'advancedDownload.validate_description'}
          tableLabel={'advancedDownload.validate_title'}
          titleTranslationKey={'advancedDownload.validate_table_label'}
          actionTranslationKey={'common.download'}
          icon={'icon-information-big'}
          duplicateFiles={invalidAssets.map((item) => item.asset.attributeGroups['Asset Information'].assetName as string)}
          onCancel={() => DialogStore.removeLastDialog()}
          onConfirm={() => {
            DialogStore.removeLastDialog();

            this.downloadAssetsAsZip(
              filename,
              this.assets.filter((item) => item.activeDigitalContent),
            );
          }}
        />,
      );
    } else if (this.headerSelectedFormats.length || this.assets.filter((asset) => asset.selectedFileFormats.length).length) {
      DialogStore.addDialog(
        <DownloadDialog
          text="All compatible files will be downloaded in the selected format(s), any non-compatible files will be downloaded in their original format."
          actionTo={() => {
            this.downloadAssetsAsZip(filename, this.assets);
          }}
          onClose={() => {
            DialogStore.removeLastDialog();
          }}
        />,
      );
    } else {
      this.downloadAssetsAsZip(filename, this.assets);
    }
  }

  @action
  public downloadAssetsAsZip(fileName: string, assets: Array<DownloadableAssetData>): void {
    if (!fileName) {
      const error = new HandledError('No archive name provided');

      LoggingService.log({ message: 'User did not provide an archive name.', level: LogLevelEnum.DEBUG, event: EventTypeEnum.FILE_DOWNLOAD }, error);

      DialogStore.error(error);

      return;
    }

    this.isDownloading = true;

    const itemsPlain = toJS(assets);

    const downloadAssetsMeta: Array<IDownloadItem> = [];

    for (const item of itemsPlain) {
      if (item.selectedFileFormats.length) {
        for (let i = 0; i < item.selectedFileFormats.length; i++) {
          const fileFormat = item.selectedFileFormats[i];

          downloadAssetsMeta.push({
            id: item.asset.id,
            fileName: this.setFileName(item, fileFormat.formatValue, i + 1),
            originalExtension: item.activeDigitalContent?.fileName && item.activeDigitalContent.fileName.split('.').pop(),
            versionNumber: item.asset.activeDigitalContent,
            checksum: item.activeDigitalContent?.checksum ?? v4(),
            fileSize: item.activeDigitalContent?.fileSize ?? 0,
            transformationArgs: {
              watermark: this.watermarkChecked,
              watermarkName: (this.downloadWatermark as WatermarkNameType) || undefined,
              format: fileFormat.formatValue,
              height: (fileFormat.parameters?.height && parseInt(fileFormat.parameters.height)) || undefined,
              width: (fileFormat.parameters?.width && parseInt(fileFormat.parameters.width)) || undefined,
              resolution: (fileFormat.parameters?.resolution && parseInt(fileFormat.parameters.resolution)) || undefined,
            },
            downloaded: false,
          });
        }
      } else {
        downloadAssetsMeta.push({
          id: item.asset.id,
          fileName: this.setFileName(item),
          originalExtension: item.activeDigitalContent?.fileName && item.activeDigitalContent.fileName.split('.').pop(),
          versionNumber: item.asset.activeDigitalContent,
          checksum: item.activeDigitalContent?.checksum ?? v4(),
          fileSize: item.activeDigitalContent?.fileSize ?? 0,
          transformationArgs: {
            watermark: this.watermarkChecked,
            watermarkName: (this.downloadWatermark as WatermarkNameType) || undefined,
          },
          downloaded: false,
        });
      }
    }

    const downloadRequest: IDownloadRequest = {
      id: v4(),
      fileName,
      createdOn: new Date().toUTCString(),
      files: downloadAssetsMeta,
      assetDomain: ViewStore.selectedAssetDomain,
    };

    LoggingService.log({
      message: 'New advanced download requested',
      level: LogLevelEnum.INFO,
      event: EventTypeEnum.FILE_DOWNLOAD,
      http: { request: { method: HttpMethodEnum.POST, body: { content: JSON.stringify(downloadRequest) } } },
    });

    UploadDownloadManagerStore.addDownloadItem(new DownloadItem(downloadRequest));
  }

  @action
  private setFileName(file: DownloadableAssetData, format?: string, versionPostfix?: number): string {
    const fileName = file.activeDigitalContent?.fileName;

    if (fileName) {
      if (format && versionPostfix) {
        const baseFileName = fileName.slice(0, fileName.lastIndexOf('.'));

        return `${baseFileName}(${versionPostfix}).${format}`;
      }

      return fileName;
    }

    return v4.toString();
  }

  @action
  private setGlobalAssetFormats(formats: Array<IDownloadFileFormat>): void {
    this.headerSelectedFormats = formats;

    this.assets.forEach((asset) => {
      asset.selectedFileFormats = formats.filter((f) =>
        f.extensions.includes(
          getFileFormatTypeFromExtension(
            (asset.activeDigitalContent?.storageURL && readFileExtension(asset.activeDigitalContent.storageURL)) ||
              (asset.asset.attributeGroups['Asset Information'] &&
                readFileExtension(asset.asset.attributeGroups['Asset Information'].assetName as string)) ||
              '',
          ),
        ),
      );
    });
  }
}
