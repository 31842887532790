import React from 'react';
import { useTranslation } from 'react-i18next';

interface IPreviewNotAvailable {
  classname?: string;
}

const PageErrors = ({ classname }: IPreviewNotAvailable): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={classname}>
      <h2>{t('error.page_not_found')}</h2>
      <p>{t('error.page_not_found_description')}</p>
    </div>
  );
};

export default PageErrors;
