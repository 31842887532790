import React, { useState, useRef, useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import TextBoxUncontrolled, { TextBoxUncontrolledInputHandles } from 'components/input/TextBoxUncontrolled';
import { ListLoader } from 'components/loader/ListLoader';
import TypeaheadSearchStore, { ITypeaheadSearchItem } from './store/TypeaheadSearchStore';

import './TypeaheadSearch.scss';

interface ITypeaheadSearchProps<T extends ITypeaheadSearchItem> {
  store: TypeaheadSearchStore<T>;
  getAllItems?(): Promise<Array<T>>;
  allItems?: Array<T>;
  inputLabel: string;
}

function TypeaheadSearch<T extends ITypeaheadSearchItem>({ allItems, store, inputLabel, getAllItems }: ITypeaheadSearchProps<T>): JSX.Element {
  const { t } = useTranslation();

  const [isFocused, setIsFocused] = useState(false);
  const popup: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
  const input = useRef<TextBoxUncontrolledInputHandles>(null);

  useEffect(() => {
    store.load(allItems, getAllItems);
  }, [allItems, getAllItems, store]);

  function onSearchChange(value: string): void {
    if (!isFocused) {
      setIsFocused(true);
    }

    store.search(value);
    store.changeHighlight(0);
  }

  function onSuggestionClick(): void {
    if (!input.current || !store.highlightedValue) {
      return;
    }

    store.addItem();
    input.current.setValue('');
    input.current.focus();
  }

  function onKeyDown(e: React.KeyboardEvent): void {
    let nextIndex: number;

    switch (e.key) {
      case 'Enter':
        if (store.highlightedValue) {
          onSuggestionClick();
          setIsFocused(false);
        }

        return;
      case 'ArrowUp':
        nextIndex = store.highlightedIndex - 1;
        break;
      case 'ArrowDown':
        nextIndex = store.highlightedIndex + 1;
        break;
      default:
        return;
    }

    e.preventDefault();

    if (!store.items || nextIndex < -1) {
      nextIndex = -1;
    } else if (nextIndex >= store.items.length) {
      nextIndex = store.items.length - 1;
    }

    store.changeHighlight(nextIndex);

    if (nextIndex >= 0) {
      setTimeout(() => {
        if (popup.current && nextIndex >= 0) {
          const child = popup.current.childNodes[nextIndex] as HTMLElement;

          const childOffsetTop = child.offsetTop;
          const popupScrollTop = popup.current.scrollTop;

          if (childOffsetTop < popupScrollTop) {
            popup.current.scrollTop = childOffsetTop;

            return;
          }

          const childOffsetHeight = child.offsetHeight;
          const popupOffsetHeight = popup.current.offsetHeight;

          const diff = childOffsetTop + childOffsetHeight - (popupScrollTop + popupOffsetHeight);

          if (diff > 0) {
            popup.current.scrollTop = popupScrollTop + diff;
          }
        }
      });
    }
  }

  function onBlur(): void {
    setIsFocused(false);
    store.changeHighlight(0);
  }

  return useObserver(() => (
    <div className="TypeaheadSearch-container">
      <TextBoxUncontrolled
        ref={input}
        className="TypeaheadSearch-search"
        label={inputLabel}
        onFocus={() => setIsFocused(true)}
        onBlur={onBlur}
        onChange={(e) => onSearchChange(e.target.value)}
        onKeyDown={onKeyDown}
      />
      {isFocused && (store.items || store.isLoading || store.error) && (
        <div className="TypeaheadSearch-popup" ref={popup}>
          {
            <ListLoader
              isLoading={store.isLoading}
              error={store.error}
              itemsLength={store.items?.length ?? 0}
              emptyMessage={t('matrix.suggestions_empty')}
            />
          }
          {store.items?.map((item, index) => (
            <div
              key={index}
              className={`TypeaheadSearch-popup-suggestion caption ${store.highlightedIndex === index ? 'highlight' : ''}`}
              onMouseOver={() => store.changeHighlight(index)}
              onMouseDown={onSuggestionClick}
            >
              {item.pre}
              <span className="bold">{item.bold}</span>
              {item.post}
            </div>
          ))}
        </div>
      )}
    </div>
  ));
}

export default TypeaheadSearch;
