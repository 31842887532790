import { IEvent, Release, Brand } from '@cdam/shared';

export const getEmptyEvent = (brand: Brand): IEvent => ({
  id: '',
  brand,
  createDate: new Date().toISOString(),
  modifyDate: new Date().toISOString(),
  name: '',
  season: '',
  releaseStatus: Release.Draft,
  releaseDate: new Date().toISOString(),
  generalFilters: [],
  generalOwners: [],
  matrix: {
    yAxis: [],
    xAxis: [],
  },
  reebokFilters: [],
  filenameConvention: '',
  whereToFindWhatUploaded: false,
  whereToFindWhatFilename: '',
});
