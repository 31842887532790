/* eslint-disable @typescript-eslint/unbound-method, complexity */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Brand, MatrixItemType } from '@cdam/shared';
import { RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import AssetsFilter from 'containers/assets/components/AssetsFilter';
import MatrixSettingsStore, { Axis } from '../../../stores/matrix/MatrixSettingsStore';
import DialogStore from '../../../stores/dialog/DialogStore';
import NavigationService from '../../../services/NavigationService';
import { ReactComponent as AdidasIcon } from '../../../assets/icon/adidas.svg';
import { ReactComponent as ReebokIcon } from '../../../assets/icon/reebok.svg';
import Button, { ButtonType, ButtonSize } from '../../../components/input/Button';
import { Draggable } from '../../../components/dragdrop/Draggable';
import TextBoxControlled from '../../../components/input/TextBoxControlled';
import WhereToFindFileUploadZone from '../../../components/fileUpload/WhereToFindFileUploadZone';
import { ListLoader } from '../../../components/loader/ListLoader';
import MatrixUnsavedChangesDialog from '../dialogs/MatrixUnsavedChangesDialog';
import MatrixYItemAddModal from '../dialogs/components/MatrixYItemAddModal';
import MatrixSettingsOwners from '../dialogs/components/MatrixSettingsOwners';
import MatrixStateSelect from './MatrixStateSelect';

import './MatrixSettings.scss';

interface IMatrixSettingsRouteProps {
  brand: Brand;
  eventId?: string;
}

const MatrixSettings = observer(({ match }: RouteComponentProps<IMatrixSettingsRouteProps>) => {
  const [store] = useState(() => new MatrixSettingsStore());
  const { t } = useTranslation();
  const [draggedItem, setDraggedItem] = useState(null as unknown | null);
  const [showYItemAddModal, setShowYItemAddModal] = useState(false);

  const { brand, eventId } = match.params;

  useEffect(() => {
    if (eventId) {
      store.load(brand, eventId);
    } else {
      store.load(brand);
    }

    return store.initGroupedItemsAutorun();
  }, [brand, eventId, store]);

  const onCancelClick = (): void => {
    if (store.unsavedChanges()) {
      DialogStore.addDialog(
        <MatrixUnsavedChangesDialog
          translationKey="matrix.unsaved_changes"
          onCancel={() => {
            NavigationService.navigateTo('/');
            DialogStore.removeLastDialog();
          }}
          onConfirm={() => {
            store.updateMatrix();
            DialogStore.removeLastDialog();
          }}
        />,
      );
    } else {
      NavigationService.navigateTo('/');
    }
  };

  return (
    <React.Fragment>
      <div className="MatrixSettings">
        <div className="MatrixSettings-navigation space-m-4 space-m-b-0">
          <div className="MatrixSettings-heading">
            <h4 className="space-m-r-3">{eventId ? t('matrix.edit_matrix_title') : t('matrix.add_matrix_title')}</h4>
            {brand === Brand.Adidas ? <AdidasIcon className="MatrixSettings-heading-icon" /> : <ReebokIcon className="MatrixSettings-heading-icon" />}
          </div>
          <div>
            <Button
              data-testid="matrix-settings-cancel-btn"
              className="space-m-r-3"
              buttonType={ButtonType.Secondary}
              size={ButtonSize.Default}
              onClick={() => onCancelClick()}
            >
              {t('common.cancel')}
            </Button>
            <Button
              data-testid="matrix-settings-save-btn"
              buttonType={ButtonType.Primary}
              size={ButtonSize.Default}
              onClick={async () => store.updateMatrix()}
            >
              {t('common.save')}
            </Button>
          </div>
        </div>
        <ListLoader isLoading={store.isLoading} error={store.error} itemsLength={1} />
        {store.event && (
          <div className="MatrixSettings-view">
            <div className="MatrixSettings-scroll-container space-p-6">
              <p className="MatrixSettings-section-info">{t('matrix.info.matrix_name')}</p>
              <div className="row">
                <div className="col-md-2">
                  <TextBoxControlled
                    className="MatrixSettings-input"
                    label="Name"
                    name="eventName"
                    value={store.event.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      store.changeEditingEventName(e.target.value);
                    }}
                  />
                </div>
              </div>
              <p className="MatrixSettings-section-info space-m-t-6">{t('matrix.info.add_owner')}</p>
              <div className="row">
                <div className="col-md-5">
                  <div className="MatrixSettingsDialog-container-items">
                    <MatrixSettingsOwners
                      addItem={store.addToGeneralItemOwners}
                      removeItem={store.removeGeneralItemOwners}
                      itemList={store.generalItemOwners}
                      getAllItems={store.getAllMatrixSettingsOwners}
                    />
                  </div>
                </div>
              </div>
              <p className="MatrixSettings-section-info space-m-t-6">{t('matrix.info.filters')}</p>
              <div className="row space-p-l-2">
                <AssetsFilter
                  className="space-m-h-2"
                  filterClassName="space-m-r-3 space-m-t-4"
                  maxFilterWidth="15rem"
                  minFilterWidth="15rem"
                  filters={store.generalFilterTaxonomy}
                  selectedFilters={store.generalFilters}
                  clearAllFilters={store.clearAllGeneralFilters}
                  toggleSelectedFilter={store.toggleSelectedFilter}
                  // singleChoice
                  checkList
                  brand={brand}
                />
              </div>
              <div className="space-m-t-6">
                <p className="MatrixSettings-section-info">{t('matrix.info.creation')}</p>
                <div className="MatrixSettings-table-container">
                  <div className="MatrixSettings-table-content">
                    <table className="MatrixSettings-table">
                      <thead>
                        <tr>
                          <th />
                          {store.event.matrix.xAxis.map((x) => (
                            <th className={`MatrixSettings-table-header draggable-item${draggedItem === x ? ' draggable-original' : ''}`} key={x.id}>
                              <div className="MatrixSettings-table-header-relative-container">
                                <div className="MatrixSettings-left-table-item-vertical">
                                  <Draggable
                                    tag="xAxis"
                                    item={x}
                                    items={store.event ? store.event.matrix.xAxis : []}
                                    allowTouchDrag={false}
                                    canDrop={(tag) => tag === 'xAxis'}
                                    subtractHeightOnDrag={true}
                                    onDragStart={(item) => setDraggedItem(item)}
                                    onDragEnd={() => setDraggedItem(null)}
                                  >
                                    <div className="MatrixSettings-drag-icon">
                                      <Icon icon="icon-grab-object" color="#999999" height={22} width={22} />
                                    </div>
                                  </Draggable>
                                  <div className="MatrixSettings-left-table-item-vertical-text">{x.name}</div>
                                </div>
                                <div
                                  className="MatrixSettings-edit-icon-vertical"
                                  onClick={() => {
                                    DialogStore.matrixSettingsModal(store, Axis.xAxis, x);
                                  }}
                                >
                                  <Icon icon="icon-edit" color="#999999" height={22} width={22} />
                                </div>
                              </div>
                            </th>
                          ))}
                          <th
                            className="MatrixSettings-table-header-add"
                            onClick={() => {
                              DialogStore.matrixSettingsModal(store, Axis.xAxis);
                            }}
                          >
                            <Icon icon="icon-plus" color="#737373" height={22} width={22} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {store.groupedItemsForDraggable?.length &&
                          store.groupedItemsForDraggable.map((group, groupIndex) =>
                            group.map((item) => (
                              <tr
                                key={item.id}
                                className={`${
                                  item.type === MatrixItemType.Group || item.type === MatrixItemType.Standalone ? 'MatrixSettings-bold-row' : ''
                                }`}
                              >
                                <td className={`MatrixSettings-table-data draggable-item${draggedItem === item ? ' draggable-original' : ''}`}>
                                  <div className="MatrixSettings-left-table-item">
                                    <Draggable
                                      tag={
                                        item.type === MatrixItemType.Group || item.type === MatrixItemType.Standalone
                                          ? 'groupOrStandalone'
                                          : group[0].name
                                      }
                                      item={
                                        item.type === MatrixItemType.Group || item.type === MatrixItemType.Standalone
                                          ? store.groupedItemsForDraggable
                                            ? store.groupedItemsForDraggable[groupIndex]
                                            : []
                                          : item
                                      }
                                      items={
                                        item.type === MatrixItemType.Group || item.type === MatrixItemType.Standalone
                                          ? store.groupedItemsForDraggable ?? []
                                          : store.event
                                          ? store.event.matrix.yAxis
                                          : []
                                      }
                                      canDrop={
                                        item.type === MatrixItemType.Group || item.type === MatrixItemType.Standalone
                                          ? (tag) => tag === 'groupOrStandalone'
                                          : (tag) => tag === group[0].name
                                      }
                                      allowTouchDrag={false}
                                      onDragStart={(item) => setDraggedItem(item)}
                                      onDragEnd={() => {
                                        setDraggedItem(null);
                                        store.updateEventMatrixYAxis();
                                      }}
                                    >
                                      <span className="MatrixSettings-drag-icon">
                                        <Icon icon="icon-grab-object" color="#999999" height={22} width={22} />
                                      </span>
                                    </Draggable>
                                    <div
                                      className={`MatrixSettings-left-table-item-text ${
                                        item.type === MatrixItemType.Group || item.type === MatrixItemType.Standalone ? 'bold' : ''
                                      }`}
                                    >
                                      {item.name}
                                    </div>
                                    <div
                                      className="MatrixSettings-edit-icon"
                                      onClick={() => {
                                        DialogStore.matrixSettingsModal(store, Axis.yAxis, item);
                                      }}
                                    >
                                      <Icon icon="icon-edit" color="#999999" height={22} width={22} />
                                    </div>
                                  </div>
                                </td>
                                {store.event?.matrix.xAxis.map((_, index) => (
                                  <td className="MatrixSettings-table-data-empty" key={index} />
                                ))}
                              </tr>
                            )),
                          )}
                        <tr>
                          <td
                            className="MatrixSettings-table-data-add"
                            onMouseEnter={() => setShowYItemAddModal(true)}
                            onMouseLeave={() => setShowYItemAddModal(false)}
                          >
                            <Icon icon="icon-plus" color="#737373" height={22} width={22} />
                          </td>
                          {showYItemAddModal && (
                            <MatrixYItemAddModal
                              store={store}
                              handleMouseEnter={() => setShowYItemAddModal(true)}
                              handleMouseLeave={() => setShowYItemAddModal(false)}
                            />
                          )}
                          {store.event &&
                            store.event.matrix.yAxis.length === 0 &&
                            store.event.matrix.xAxis.map((_, index) => <td className="MatrixSettings-table-data-empty" key={index} />)}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  {brand === Brand.Reebok && (
                    <div className="MatrixSettings-table-content">
                      <div className="MatrixSettings-v-separator space-m-r-2" />
                      <div className="MatrixSettings-right-table">
                        {store.event.reebokFilters &&
                          store.event.reebokFilters.length > 0 &&
                          store.event.reebokFilters.map((reebokItem) => (
                            <div
                              className={`MatrixSettings-right-table-item draggable-item${draggedItem === reebokItem ? ' draggable-original' : ''}`}
                              key={reebokItem.id}
                            >
                              <Draggable
                                tag="reebokAxis"
                                item={reebokItem}
                                items={store.event ? store.event.reebokFilters : []}
                                canDrop={(tag) => tag === 'reebokAxis'}
                                subtractHeightOnDrag={true}
                                onDragStart={(item) => setDraggedItem(item)}
                                onDragEnd={() => setDraggedItem(null)}
                              >
                                <div className="MatrixSettings-right-table-drag-icon">
                                  <Icon icon="icon-grab-object" color="#999999" height={22} width={22} />
                                </div>
                              </Draggable>
                              <div className="MatrixSettings-right-table-item-text">{reebokItem.name.toLowerCase()}</div>
                              <div
                                className="MatrixSettings-edit-icon-vertical"
                                onClick={() => {
                                  DialogStore.matrixSettingsModal(store, Axis.reebokAxis, reebokItem);
                                }}
                              >
                                <Icon icon="icon-edit" color="#999999" height={22} width={22} />
                              </div>
                            </div>
                          ))}
                        <div
                          className="MatrixSettings-right-table-add"
                          onClick={() => {
                            DialogStore.matrixSettingsModal(store, Axis.reebokAxis);
                          }}
                        >
                          <Icon icon="icon-plus" color="#737373" height={22} width={22} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="space-m-t-6">
                <div className="MatrixSettings-file-upload">
                  <WhereToFindFileUploadZone store={store} whereToFindWhatUploaded={store.event.whereToFindWhatUploaded} />
                </div>
              </div>

              <div className="space-m-t-6">
                <p className="MatrixSettings-section-info">{t('matrix.info.release')}</p>
                <MatrixStateSelect store={store} />
              </div>

              <div className="row space-m-t-6">
                <p className="MatrixSettings-section-info">{t('matrix.info.file_naming')}</p>
                <div className="col-md-4">
                  <TextBoxControlled
                    label="Asset Name description"
                    value={store.event.filenameConvention}
                    onChange={(e) => store.changeFilenameConvention(e.target.value)}
                  />
                </div>
              </div>
              {store.event?.id && (
                <div className="row space-m-t-4">
                  <div className="col-md-2">
                    <Button
                      className="MatrixSettings-delete-matrix-button"
                      buttonType={ButtonType.Secondary}
                      size={ButtonSize.Default}
                      onClick={() => {
                        store.deleteMatrix();
                      }}
                    >
                      {t('common.delete_matrix')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

export default MatrixSettings;
