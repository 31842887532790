/* eslint-disable complexity */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import Dropdown from 'components/dropdown/Dropdown';
import DropdownItem from 'components/dropdown/DropdownItem';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import { ReferenceDataItem } from 'stores/assets/data/ReferenceDataItem';
import { useTranslation } from 'react-i18next';
import DateTimePicker from '../../../components/date/DateTimePicker';
import AssetsFilterInput from '../../assets/components/Filters/AssetsFilterInput';
import { UploadFilterArticleNumber } from './UploadFilterArticleNumber';

import '../UploadAssets.scss';

interface IUploadFilterDropdownProps {
  filter: AssetFilter;
  onItemClick(value: ReferenceDataItem | string): void;
}

const UploadFilterDropdown = observer(({ filter, onItemClick }: IUploadFilterDropdownProps): JSX.Element | null => {
  const { t } = useTranslation();

  const [textValue, setTextValue] = useState('');

  // article number extraction filter
  if (filter.attribute.masterDataType && filter.attribute.masterDataType === 'SPM_article') {
    return <UploadFilterArticleNumber filter={filter} />;
  }

  // if filter is preset or already has a value
  if (filter.isPreset || filter.value.length !== 0 || filter.values.length !== 0) {
    if (filter.attribute.type === 'boolean') {
      return <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">{filter.value === 'true' ? 'Yes' : 'No'}</div>;
    } else if (filter.attribute.type) {
      return (
        <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">
          {filter.values.length !== 0 ? filter.values.join(',') : filter.value}
        </div>
      );
    }

    if (filter.selectedReferenceItems.length !== 0) {
      return (
        <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">
          {filter.selectedReferenceItems.map((item) => item.text).join(', ')}
        </div>
      );
    }

    return <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">{filter.selectedSingleValue?.text}</div>;
  }

  // filter is of type date/datetime
  if (filter.attribute.type && (filter.attribute.type === 'date' || filter.attribute.type === 'datetime')) {
    return (
      <div className="space-m-2">
        <DateTimePicker
          value={textValue}
          className={filter.value || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
          minFilterWidth="15rem"
          closeWhenItemSelect={true}
          disableLabel
          onChangeValue={(val) => {
            setTextValue(val);
            filter.changeValue(val);
          }}
        />
      </div>
    );
  }

  // filter is of type string and has predefined multiple choices to choose one
  if (filter.attribute && filter.attribute.type === 'string' && filter.values && filter.values.length) {
    return (
      <Dropdown
        value={filter.value ? filter.value : t('common.select')}
        className={filter.value || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
        minFilterWidth="15rem"
        closeWhenItemSelect={true}
        disableLabel
      >
        <ul className="list dropdown__options">
          {filter.values.map((value, index) => (
            <DropdownItem key={index} className="UploadAssets-upload-dropdown-item" value={value} onClick={() => onItemClick(value)} />
          ))}
        </ul>
      </Dropdown>
    );
  }

  // filter is number, string or boolean and without a value
  if (filter.attribute.type) {
    return (
      <Dropdown
        value={filter.value ? filter.value : t('common.select')}
        className={filter.value || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
        minFilterWidth="15rem"
        disableLabel
        closeWhenItemSelect={filter.attribute.type === 'boolean'}
      >
        <ul className="list dropdown__options" style={{ maxHeight: 'none' }}>
          {filter.attribute.type && filter.attribute.type === 'boolean' ? (
            <>
              <DropdownItem value="Yes" onClick={() => filter.changeValue('true')} />
              <DropdownItem value="No" onClick={() => filter.changeValue('false')} />
            </>
          ) : (
            <AssetsFilterInput
              attributeName={filter.attribute.name}
              group={filter.groupName}
              type={filter.attribute.type ?? filter.attribute.masterDataType}
              value={filter.value}
              submitValue={(val) => filter.changeValue(val)}
            />
          )}
        </ul>
      </Dropdown>
    );
  }

  // filter has multiple referenceItems to choose and select one
  return (
    <Dropdown
      value={filter.selectedSingleValue ? filter.selectedSingleValue.text : t('common.select')}
      className={filter.selectedSingleValue || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
      minFilterWidth="15rem"
      closeWhenItemSelect={true}
      disableLabel
    >
      <ul className="list dropdown__options">
        {filter.selectedReferenceItems.length > 0
          ? filter.selectedReferenceItems.map((item, index) => (
              <DropdownItem key={index} className="UploadAssets-upload-dropdown-item" value={item.text} onClick={() => onItemClick(item)} />
            ))
          : filter.referenceItems?.map((item, index) => (
              <DropdownItem key={index} className="UploadAssets-upload-dropdown-item" value={item.text} onClick={() => onItemClick(item)} />
            ))}
      </ul>
    </Dropdown>
  );
});

export default UploadFilterDropdown;
