import { computed, runInAction, makeObservable, observable, action } from 'mobx';
import {
  AttributeDisplayType,
  ITaxonomyAttributeGroup,
  IReferenceData,
  ITaxonomyAttribute,
  IMatrixOwnersItem,
  appLocationEnum,
  assetGroupsEnum,
  AssetDomainsNamesEnums,
  AttributeDataType,
  ITaxonomy,
  ITaxonomyResponse,
} from '@cdam/shared';
import { ReferenceDataItem } from 'stores/assets/data/ReferenceDataItem';
import SecurityUtils from 'utils/SecurityUtils';
import AuthStore from '../auth/AuthStore';
import { AssetFilter } from '../assets/data/AssetFilter';
import FilterDisplayTypeConfig, { customFilters } from '../../configs/FilterDisplayTypeConfig';
import ConfigUtils from '../../utils/ConfigUtils';
import ClientHttpService, { UrlType } from '../../services/ClientHttpService';
import GateDB from '../../db/indexdb';
import DialogStore from '../dialog/DialogStore';
import i18n from '../../i18n';

type AssetDataAttributeType = AttributeDataType | 'referenceData';

// TODO: TaxonomyStore.appendCustomReferenceData(); after load of taxonomy

class TaxonomyStore {
  @observable private selectedTaxonomy: ITaxonomy | undefined;

  private customReferenceData: { [key: string]: Array<IReferenceData> } = {};

  private readonly referenceDataDisplayNames: Array<string> = ['desc', 'name'];

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get attributeGroupList(): Array<AssetFilter> {
    if (!this.selectedTaxonomy) {
      return [] as Array<AssetFilter>;
    }

    this.setAttributesDisplayType(this.selectedTaxonomy.attributeGroupList);

    this.appendCustomAttributes(this.selectedTaxonomy.attributeGroupList);

    return this.selectedTaxonomy.attributeGroupList.reduce<Array<AssetFilter>>(
      (list, group) => list.concat(group.attributeList.map((attribute) => new AssetFilter(group.name, attribute))),
      [],
    );
  }

  public getAttribute(groupName: string, attrName: string): ITaxonomyAttribute | undefined {
    if (!this.selectedTaxonomy) {
      return undefined;
    }

    let attributeData: ITaxonomyAttribute | undefined;

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === groupName);

    if (group?.attributeList) {
      attributeData = group.attributeList.find((attr) => attr.name === attrName);
    }

    return attributeData;
  }

  public async init(selectedAssetDomain: AssetDomainsNamesEnums): Promise<void> {
    let taxonomies: Array<ITaxonomy> = [];

    try {
      const response = await ClientHttpService.fetch<ITaxonomyResponse>({
        urlType: UrlType.Backend,
        url: '/taxonomy',
        method: 'GET',
      });

      taxonomies = response.data;
    } catch (e) {
      DialogStore.error(e as Error);
    }

    if (taxonomies.length !== 0) {
      try {
        await GateDB.taxonomy.bulkPut(taxonomies);
      } catch (e) {
        DialogStore.alert(i18n.t('error.cache_not_updated', { resource: 'taxonomies' }));
      }

      const selectedTaxonomy = taxonomies.find((taxonomy) => taxonomy.name === selectedAssetDomain);

      if (selectedTaxonomy) {
        this.setSelectedTaxonomy(selectedTaxonomy.name, selectedTaxonomy);
      }
    }
  }

  @action.bound
  public async setSelectedTaxonomy(selectedTaxonomyName: string, selectedTaxonomy?: ITaxonomy): Promise<void> {
    if (selectedTaxonomy) {
      runInAction(() => {
        this.selectedTaxonomy = selectedTaxonomy;
      });
    }

    try {
      const taxonomy = await GateDB.taxonomy.get({ name: selectedTaxonomyName });

      this.appendCustomReferenceData();
      runInAction(() => {
        this.selectedTaxonomy = taxonomy;
      });
    } catch (e) {
      DialogStore.error(e as Error);
    }
  }

  public getAttributeType(groupName: string, attrName: string): AssetDataAttributeType | undefined {
    const attributeData = this.getAttribute(groupName, attrName);

    if (!attributeData) {
      return undefined;
    }

    return attributeData.type ? (attributeData.type as AssetDataAttributeType) : 'referenceData';
  }

  public getTaxonomyReferenceValue(groupName: string, attrName: string, attrId: Array<string> | undefined): string {
    if (!AuthStore.token || !attrId || !this.selectedTaxonomy) {
      return '';
    }

    let value: string | Array<string> = '';

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === groupName);

    if (group?.attributeList) {
      const attr = group.attributeList.find((attr) => attr.name === attrName);

      if (attr?.referenceDataList) {
        value = [];

        for (const attrItem of attrId) {
          const refData = attr.referenceDataList.find((data) => attrItem === data.id);

          if (refData?.attributeList) {
            const attrValueData = refData.attributeList.find((attributeItem) =>
              this.referenceDataDisplayNames.includes(attributeItem.name.toLowerCase()),
            )?.value;

            if (attrValueData) {
              value.push(attrValueData);
            }
          }
        }
      }
    }

    return Array.isArray(value) ? value.join(', ') : value;
  }

  public getTaxonomyReferenceDescription(groupName: string, attrName: string, attrId: string): string {
    if (!AuthStore.token || !this.selectedTaxonomy) {
      return '';
    }

    let value = '';

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === groupName);

    if (group?.attributeList) {
      const attr = group.attributeList.find((attr) => attr.name === attrName);

      if (attr?.referenceDataList) {
        const refData = attr.referenceDataList.find((data) => attrId === data.id);

        if (refData?.attributeList) {
          const attrValueData = refData.attributeList.find((attributeItem) => attributeItem.name.toLowerCase().includes('desc'));

          if (attrValueData) {
            value = attrValueData.value;
          }
        }
      }
    }

    return value;
  }

  public getAllTaxonomyReferenceData(groupName: string, attrName: string): Array<IReferenceData> {
    if (!this.selectedTaxonomy) {
      return [] as Array<IReferenceData>;
    }

    let referenceData: Array<IReferenceData> = [];

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === groupName);

    if (group?.attributeList) {
      const data = group.attributeList.find((attr) => attr.name === attrName);

      if (data?.referenceDataList) {
        referenceData = data.referenceDataList;
      }
    }

    return referenceData;
  }

  public getTaxonomyReferenceData(groupName: string, attrName: string, attrId: string): IReferenceData | undefined {
    if (!AuthStore.token || !this.selectedTaxonomy) {
      return undefined;
    }

    let referenceData: IReferenceData | undefined;

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === groupName);

    if (group?.attributeList) {
      const attr = group.attributeList.find((attr) => attr.name === attrName);

      if (attr?.referenceDataList) {
        referenceData = attr.referenceDataList?.find((referenceData) => referenceData.id === attrId);
      }
    }

    return referenceData;
  }

  public getAssetGroupReferenceDataId(assetGroupEnum: assetGroupsEnum): string | undefined {
    if (!AuthStore.token || !this.selectedTaxonomy) {
      return undefined;
    }

    let referenceData: string | undefined;

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === 'General');

    if (group?.attributeList) {
      const attr = group.attributeList.find((attr) => attr.name === 'assetGroup');

      if (attr?.referenceDataList) {
        referenceData = attr.referenceDataList?.find((referenceData) => referenceData.attributeList.find((a) => a.value === assetGroupEnum))?.id;
      }
    }

    return referenceData;
  }

  public getTaxonomyAttributeFiltersWithRules(
    selectedFilters?: Array<AssetFilter>,
    taxonomy?: Array<AssetFilter>,
    appLocation?: appLocationEnum,
    assetGroups?: Array<assetGroupsEnum>,
  ): Array<AssetFilter> {
    if (!appLocation) {
      return this.attributeGroupList;
    }

    const allFilters = taxonomy ?? this.attributeGroupList;

    return ConfigUtils.taxonomyWithAppliedRules(selectedFilters, allFilters, appLocation, assetGroups);
  }

  public getProductViewReferenceItem(filename: string): ReferenceDataItem | null {
    if (!AuthStore.token || !this.selectedTaxonomy) {
      return null;
    }

    const group = this.selectedTaxonomy.attributeGroupList.find((group) => group.name === 'Asset Information');

    if (group?.attributeList) {
      const attr = group.attributeList.find((attr) => attr.name === 'productView');

      if (attr?.referenceDataList) {
        for (const referenceData of attr.referenceDataList) {
          if (referenceData.abbreviations) {
            for (const abbreviation of referenceData.abbreviations) {
              if (filename.includes(abbreviation)) {
                return new ReferenceDataItem(referenceData);
              }
            }
          }
        }
      }
    }

    return null;
  }

  public async appendCustomReferenceData(): Promise<void> {
    try {
      const users = await SecurityUtils.getUsers();

      runInAction(() => {
        this.customReferenceData = { owners: this.appendCustomReferenceItems(users) };
      });
    } catch {}
  }

  public getCustomReferenceData(attributeName: string): Array<IReferenceData> {
    return this.customReferenceData[attributeName];
  }

  private setAttributesDisplayType(attributeGroupList: Array<ITaxonomyAttributeGroup>): void {
    for (const attributeGroup of attributeGroupList) {
      for (const attribute of attributeGroup.attributeList) {
        for (const [key, values] of Object.entries(FilterDisplayTypeConfig)) {
          if (values.includes(attribute.name)) {
            attribute.displayType = key as AttributeDisplayType;
            break;
          }
        }
      }
    }
  }

  private appendCustomReferenceItems(items: Array<IMatrixOwnersItem>): Array<IReferenceData> {
    return items.reduce((acc: Array<IReferenceData>, customReferenceData: IMatrixOwnersItem) => {
      if (!customReferenceData.values || !customReferenceData.values.length) {
        return acc;
      }

      const referenceData: IReferenceData = {
        id: customReferenceData.values[0],
        attributeList: [
          {
            name: 'Desc',
            value: customReferenceData.searchableText || customReferenceData.username || '',
          },
        ],
      };

      if (referenceData) {
        acc.push(referenceData);
      }

      return acc;
    }, []);
  }

  private appendCustomAttributes(attributeGroupList: Array<ITaxonomyAttributeGroup>): void {
    attributeGroupList.forEach((group) => {
      group.attributeList.forEach((attribute) => {
        if (customFilters.includes(attribute.name)) {
          const customAttributes = this.customReferenceData[attribute.name];

          if (customAttributes) {
            attribute.referenceDataList = customAttributes;
          }
        }
      });
    });
  }
}

export default new TaxonomyStore();
