import React from 'react';
import { observer } from 'mobx-react-lite';
import { Release } from '@cdam/shared';
import MatrixSettingsStore from 'stores/matrix/MatrixSettingsStore';
import Chip from 'components/chip/Chip';
import TimePicker from 'components/input/TimePicker';
import { useTranslation } from 'react-i18next';

interface IMatrixStateSelectProps {
  store: MatrixSettingsStore;
}

const MatrixStateSelect = observer(({ store }: IMatrixStateSelectProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (!store.event) {
    return null;
  }

  return (
    <React.Fragment>
      <div className="row space-m-t-2">
        <div className="col-md-12">
          {Object.values(Release).map((releaseStatus, index) => {
            const active = (store.event?.releaseStatus && store.event.releaseStatus === releaseStatus) || false;

            return (
              <Chip
                active={active}
                type={releaseStatus}
                choice
                interactive
                key={index}
                className="space-m-r-3"
                onClick={() => {
                  if (active || !store.event) {
                    return;
                  }

                  store.setReleaseStatus(releaseStatus);
                }}
              >
                {releaseStatus}
              </Chip>
            );
          })}
        </div>
      </div>
      {store.event.releaseStatus && store.event.releaseStatus === Release.Released && (
        <div className="row space-m-t-2">
          <div className="col-md-2">
            <TimePicker
              value={store.event.releaseDate}
              label={t('matrix.released_on')}
              handleChange={(date) => {
                store.setReleaseDate(date);
              }}
            />
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default MatrixStateSelect;
