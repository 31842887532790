import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';

import './SearchEventCard.scss';

interface ISearchEventCard {
  className?: string;
  path: string;
  text: string;
}

const SearchEventCard = ({ className, path, text }: ISearchEventCard): JSX.Element => (
  <Link to={path} className={cn('search-event-card box-shadow-2', className)}>
    <p className="search-event-card-text space-p-v-3">{text}</p>
  </Link>
);

export default SearchEventCard;
