/* eslint-disable complexity */
import { action, observable, runInAction, makeObservable, computed } from 'mobx';
import {
  appLocationEnum,
  AssetContentType,
  assetGroupIdsEnum,
  assetGroupsEnum,
  IArticleSearchResult,
  IAssetSearchResult,
  IFiltersSearchResult,
  IMetadataSearch,
  IMetadataSearchAttribute,
  IMetadataSearchRoot,
  IMetadataSearchTarget,
  ISearchStore,
  UIAssetDomainsNamesEnums,
} from '@cdam/shared';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import TaxonomyStore from '../taxonomy/TaxonomyStore';
import NavigationService from '../../services/NavigationService';
import ViewStore from '../view/ViewStore';

export default class SearchStore {
  @observable public isLoading = false;
  @observable public error: Error | null = null;
  @observable public items: ISearchStore | null = null;
  @observable public searchText = '';
  @observable public selectedFilters: Array<IFiltersSearchResult> = [];
  @observable public selectedContentType: AssetContentType = AssetContentType.Unknown;
  public readonly maxAssetItems = 7;
  private loadingIndex = 0;
  private abortController: AbortController | null = null;
  private articleNumberSearch = false;
  private readonly maxFilterItems = 3;
  private readonly maxArticleItems = 3;

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get getAssetContentType(): string {
    if (this.selectedContentType === AssetContentType.PI) {
      return 'Product Images';
    } else if (this.selectedContentType === AssetContentType.MCT) {
      return 'Marketing Content';
    }

    return 'All';
  }

  @action
  public async load(): Promise<void> {
    const currentLoadingIndex = ++this.loadingIndex;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.error = null;
    this.items = null;

    if (!this.searchText) {
      this.isLoading = false;

      return;
    }

    this.isLoading = true;

    try {
      this.abortController = new AbortController();

      if (currentLoadingIndex !== this.loadingIndex) {
        return;
      }

      const filters = this.sortByString(this.searchFilters(), 'attributeName');
      const assets = this.sortAssetsByDate(await this.searchAssets());
      const articles = this.selectedContentType !== AssetContentType.MCT ? this.sortArticlesByDate(await this.searchArticles()) : undefined;

      runInAction(() => {
        if (!this.items) {
          this.items = {};
        }

        this.items.filters = filters;
        this.items.assets = assets;
        this.items.articles = articles;
        this.isLoading = false;
      });
    } catch (e) {
      if (currentLoadingIndex !== this.loadingIndex) {
        return;
      }

      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public setSearchText(searchText?: string): void {
    this.searchText = searchText ? searchText.trim() : '';
  }

  @action
  public addContentType(item: string): void {
    if (item === 'Product Images') {
      this.selectedContentType = AssetContentType.PI;

      ViewStore.changeSelectedGateUIAssetDomainSync(UIAssetDomainsNamesEnums.MARVIN_PI);
    } else if (item === 'Marketing Content') {
      this.selectedContentType = AssetContentType.MCT;

      ViewStore.changeSelectedGateUIAssetDomainSync(UIAssetDomainsNamesEnums.MARVIN_MCT);
    } else {
      this.selectedContentType = AssetContentType.Unknown;
    }
  }

  @action
  public addPreselectedFilter(item: IFiltersSearchResult): void {
    this.selectedFilters = [...this.selectedFilters, item];
  }

  @action
  public removePreselectedFilter(item: IFiltersSearchResult): void {
    const existingIndex = this.selectedFilters.findIndex(
      (filter) => filter.attributeName === item.attributeName && filter.attributeGroup === item.attributeGroup,
    );

    if (existingIndex !== -1) {
      this.selectedFilters = this.selectedFilters.filter((_, index) => index !== existingIndex);
    }
  }

  @action
  public search(searchAttribute?: IMetadataSearchAttribute): void {
    const search: IMetadataSearch = {};

    if (!searchAttribute && this.searchText) {
      search.searchText = this.searchText;
    }

    this.articleNumberSearch = searchAttribute?.name === 'articleNumber';

    const searchAttributeList = this.constructSearchAttributeList();

    if (searchAttributeList.length) {
      search.searchFacet = {
        allMustMatch: true,
        searchAttributeList,
      };
    }

    if (searchAttribute) {
      if (!search.searchFacet) {
        search.searchFacet = {
          allMustMatch: true,
          searchAttributeList: [],
        };
      }

      search.searchFacet.searchAttributeList.push(searchAttribute);
    }

    const url = Object.keys(search).length ? `/assets?q=${encodeURIComponent(JSON.stringify(search))}` : '/assets';

    NavigationService.navigateTo(url);
  }

  @action
  public browseByContentType(assetGroupId?: assetGroupIdsEnum): void {
    const searchAttributeList: Array<IMetadataSearchAttribute> = [];

    if (assetGroupId) {
      searchAttributeList.push({
        group: 'General',
        name: 'assetGroup',
        valueList: [{ value: assetGroupId }],
      });
    }

    const search: IMetadataSearch = {
      searchFacet: {
        allMustMatch: true,
        searchAttributeList,
      },
    };

    NavigationService.navigateTo(`/assets?q=${encodeURIComponent(JSON.stringify(search))}`);
  }

  @action
  private constructSearchAttributeList(): Array<IMetadataSearchAttribute> {
    const searchAttributeList: Array<IMetadataSearchAttribute> = [];

    if (this.selectedFilters.length) {
      for (const filter of this.selectedFilters) {
        searchAttributeList.push({
          group: filter.attributeGroup,
          name: filter.attributeName,
          valueList: [
            {
              value: filter.refId,
            },
          ],
        });
      }
    }

    let refIds: Array<string> = [];

    if (this.articleNumberSearch) {
      refIds = [assetGroupIdsEnum.PRODUCT_IMAGES];
    } else if (this.selectedContentType !== AssetContentType.Unknown) {
      refIds = this.selectedContentType === AssetContentType.MCT ? [assetGroupIdsEnum.MARKETING_CONTENT] : [assetGroupIdsEnum.PRODUCT_IMAGES];
    }

    searchAttributeList.push({
      group: 'General',
      name: 'assetGroup',
      valueList: refIds.map((refId) => ({ value: refId })),
    });

    return searchAttributeList;
  }

  @action
  private async getMetadataResults(searchTarget: IMetadataSearchTarget): Promise<IMetadataSearchRoot> {
    const search: IMetadataSearch = {};

    if (this.searchText) {
      search.onlyRecent = true;
      search.searchText = this.searchText;
      search.exactSearch = false;
      search.textSearchTargetList = [searchTarget];
    }

    const searchAttributeList = this.constructSearchAttributeList();

    if (searchAttributeList.length) {
      search.searchFacet = {
        allMustMatch: true,
        searchAttributeList,
      };
    }

    return ClientHttpService.fetch<IMetadataSearchRoot>({
      urlType: UrlType.Metadata,
      url: '/metadata/search',
      method: 'POST',
      body: search,
      signal: this.abortController ? this.abortController.signal : undefined,
    });
  }

  @action
  private searchFilters(): Array<IFiltersSearchResult> {
    const filters: Array<IFiltersSearchResult> = [];
    const attributeNames: Array<string> = [];
    const attributeFilters = TaxonomyStore.getTaxonomyAttributeFiltersWithRules(
      undefined,
      undefined,
      appLocationEnum.SEARCH_RESULTS,
      this.getAssetGroupEnums(),
    );

    for (const attributeFilter of attributeFilters) {
      if (attributeFilter.attribute.referenceDataList && attributeFilter.groupName) {
        for (const referenceData of attributeFilter.attribute.referenceDataList) {
          if (referenceData.attributeList && referenceData.id) {
            for (const attribute of referenceData.attributeList) {
              if (attribute.value && attributeFilter.attribute.name) {
                const filterAlreadySelected = this.selectedFilters.map((filter) => filter.attributeName).includes(attributeFilter.attribute.name);
                const index = attribute.value.toLowerCase().indexOf(this.searchText.toLowerCase());

                if (index !== -1 && !attributeNames.includes(attributeFilter.attribute.name) && !filterAlreadySelected) {
                  attributeNames.push(attributeFilter.attribute.name);

                  filters.push({
                    pre: attribute.value.substring(0, index),
                    bold: attribute.value.substr(index, this.searchText.length),
                    post: attribute.value.substring(index + this.searchText.length),
                    refId: referenceData.id,
                    attributeName: attributeFilter.attribute.name,
                    attributeGroup: attributeFilter.groupName,
                    attributeLabel: attributeFilter.attribute.name.replace(/([a-z0-9])([A-Z])/g, '$1 $2').toUpperCase(),
                  });

                  if (filters.length === this.maxFilterItems) {
                    return filters;
                  }
                }
              }
            }
          }
        }
      }
    }

    return filters;
  }

  @action
  private async searchAssets(): Promise<Array<IAssetSearchResult>> {
    const searchResult = await this.getMetadataResults({ attributeGroup: 'Asset Information', attributeName: 'assetName' });
    const assets: Array<IAssetSearchResult> = [];

    if (searchResult?._embedded?.metadataSearchResourceList) {
      for (const resourceList of searchResult._embedded.metadataSearchResourceList) {
        if (resourceList.metadataList) {
          for (const metadata of resourceList.metadataList) {
            if (metadata.attributeGroups['Asset Information'].assetName) {
              const assetName = metadata.attributeGroups['Asset Information'].assetName as string;
              const index = assetName.toLowerCase().indexOf(this.searchText.toLowerCase());

              if (index !== -1) {
                assets.push({
                  pre: assetName.substring(0, index),
                  bold: assetName.substr(index, this.searchText.length),
                  post: assetName.substring(index + this.searchText.length),
                  id: metadata.id,
                  assetName,
                  lastModifiedOn: metadata.attributeGroups.General && (metadata.attributeGroups.General.lastModifiedOn as string),
                });
              }
            }
          }
        }
      }
    }

    return assets;
  }

  @action
  private async searchArticles(): Promise<Array<IArticleSearchResult>> {
    const searchResult = await this.getMetadataResults({ attributeGroup: 'Asset Information', attributeName: 'articleNumber' });
    const articles: Array<IArticleSearchResult> = [];
    const uniqueArticles: Array<string> = [];

    if (searchResult?._embedded?.metadataSearchResourceList) {
      for (const resourceList of searchResult._embedded.metadataSearchResourceList) {
        if (resourceList.metadataList) {
          for (const metadata of resourceList.metadataList) {
            if (metadata.attributeGroups['Asset Information'].articleNumber) {
              const articleNumber = metadata.attributeGroups['Asset Information'].articleNumber as string;
              const index = articleNumber.toLowerCase().indexOf(this.searchText.toLowerCase());

              if (index !== -1 && !uniqueArticles.includes(articleNumber)) {
                uniqueArticles.push(articleNumber);

                articles.push({
                  pre: articleNumber.substring(0, index),
                  bold: articleNumber.substr(index, this.searchText.length),
                  post: articleNumber.substring(index + this.searchText.length),
                  articleNumber,
                  createdOn: metadata.attributeGroups.General && (metadata.attributeGroups.General.createdOn as string),
                });

                if (articles.length === this.maxArticleItems) {
                  return articles;
                }
              }
            }
          }
        }
      }
    }

    return articles;
  }

  @action
  private sortByString(resultsArray: Array<IFiltersSearchResult>, sortProperty: keyof IFiltersSearchResult): Array<any> {
    return resultsArray.sort((a, b) => {
      const textA = (a[sortProperty] as string).toUpperCase();
      const textB = (b[sortProperty] as string).toUpperCase();

      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }

  @action
  private sortAssetsByDate(resultsArray: Array<IAssetSearchResult>): Array<any> {
    return resultsArray.sort((a, b) => {
      const dateA = new Date(a.lastModifiedOn ?? '');
      const dateB = new Date(b.lastModifiedOn ?? '');

      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    });
  }

  @action
  private sortArticlesByDate(resultsArray: Array<IArticleSearchResult>): Array<any> {
    return resultsArray.sort((a, b) => {
      const dateA = new Date(a.createdOn ?? '');
      const dateB = new Date(b.createdOn ?? '');

      return dateA > dateB ? -1 : dateA < dateB ? 1 : 0;
    });
  }

  private getAssetGroupEnums(): Array<assetGroupsEnum> {
    if (this.selectedContentType === AssetContentType.MCT) {
      return [assetGroupsEnum.MARKETING_CONTENT];
    } else if (this.selectedContentType === AssetContentType.PI) {
      return [assetGroupsEnum.PRODUCT_IMAGES];
    }

    return [assetGroupsEnum.MARKETING_CONTENT, assetGroupsEnum.PRODUCT_IMAGES];
  }
}
