import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import DropdownCheckboxItem from 'components/dropdown/DropdownCheckboxItem';

import './AssetsFilterBoolean.scss';

interface IAssetsFilterBoolean {
  selectedValue?: string;
  onChangeValue?(val: string): void;
}

const AssetsFilterBoolean = ({ selectedValue, onChangeValue }: IAssetsFilterBoolean): JSX.Element => {
  const { t } = useTranslation();

  return (
    <label className="AssetsFilterBoolean">
      <DropdownCheckboxItem
        className={cn('cb-selected')}
        labelClassName="AssetsFilterDropDown-checkbox-label"
        checked={selectedValue === 'true'}
        value={t('common.yes')}
        onClick={() => onChangeValue?.('true')}
      />
      <DropdownCheckboxItem
        className={cn('cb-selected')}
        labelClassName="AssetsFilterDropDown-checkbox-label"
        checked={selectedValue === 'false'}
        value={t('common.no')}
        onClick={() => onChangeValue?.('false')}
      />
    </label>
  );
};

export default AssetsFilterBoolean;
