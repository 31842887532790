import React, { useRef, useState, useEffect } from 'react';
import { AssetData } from 'stores/assets/data/AssetData';
import { Loader } from 'components/loader/Loader';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import DownloadUtils from 'utils/DownloadUtils';
import PreviewNotAvailable from 'components/coredam/PreviewNotAvailable';

import './AudioVideoPreview.scss';

interface IAssetsMultiplePreview {
  item: AssetData | null;
  itemType: string;
}

const AudioVideoPreview = ({ item, itemType }: IAssetsMultiplePreview): JSX.Element => {
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [loading, setLoading] = useState(true);
  const currentAbortController: React.MutableRefObject<null | AbortController> = useRef(null);

  const [url, setUrl] = useState('');

  useEffect(() => {
    const getBlob = async (): Promise<void> => {
      try {
        const formatUrlParams = DownloadUtils.transformationArgsToQuery({ format: itemType === 'audio' ? 'mp3' : 'mp4' });
        const metadatId = item?.asset?.id;

        if (!metadatId) {
          setFailedToLoad(true);

          return;
        }

        const url = `/metadata/${metadatId}/activeDigitalContent${formatUrlParams}`;
        const urlType = UrlType.Metadata;

        currentAbortController.current = new AbortController();
        const blob = await ClientHttpService.fetchBlob({ urlType, url, method: 'GET', signal: currentAbortController.current.signal });

        const objectUrl = URL.createObjectURL(blob);

        setUrl(objectUrl);
      } catch (e) {
        setFailedToLoad(true);
      }

      setLoading(false);
    };

    if (currentAbortController.current) {
      currentAbortController.current.abort();
    }

    getBlob();

    // eslint-disable-next-line consistent-return
    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [failedToLoad, item, itemType, loading]);

  return (
    <div className="AudioVideoPreview">
      {loading ? (
        <div className="AudioVideoPreview-loading">
          <Loader />
        </div>
      ) : !failedToLoad ? (
        <div className="AudioVideoPreview-content">
          {itemType === 'audio' ? (
            <audio controls>
              <source src={url} type="audio/mpeg" />
            </audio>
          ) : (
            <video controls className="AudioVideoPreview-video">
              <source src={url} type="video/mp4" />
            </video>
          )}
        </div>
      ) : (
        <PreviewNotAvailable height={500} width={500} backgroundColor={'#ededed'} classname={'AudioVideoPreview-preview'} />
      )}
    </div>
  );
};

export default AudioVideoPreview;
