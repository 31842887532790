import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { readFileExtension } from 'utils/FileFormatParser';
import { AssetType, AssetPreview as AssetPreviewHelper } from '@cdam/shared';
import AssetCardImage from 'components/coredam/AssetCardImage';
import PreviewNotAvailable from 'components/coredam/PreviewNotAvailable';
import DetailsStore from '../../../../stores/assets/DetailsStore';
import { Loader } from '../../../../components/loader/Loader';
import AssetsMultiplePreview from './AssetsMultiplePreview';
import AudioVideoPreview from './AudioVideoPreview';

import './AssetPreview.scss';

interface IAssetPreview {
  store: DetailsStore;
}

// eslint-disable-next-line complexity
const AssetPreview: React.FC<IAssetPreview> = observer(({ store }) => {
  if (store.isLoading) {
    return <Loader large />;
  }

  const assetType = store.assetType;

  if (!store.displayItem?.item) {
    return (
      <div className={`AssetPreview ${assetType === AssetType.Article && 'AssetPreview-preview-article'}`}>
        <PreviewNotAvailable height={500} width={500} backgroundColor={'#ededed'} classname={'AssetPreview-preview'} />
      </div>
    );
  }

  const [layoutView, setLayoutView] = useState<JSX.Element | null>(null);
  const { item } = store.displayItem;
  const fileExtension = item.activeDigitalContent && readFileExtension(item.activeDigitalContent.storageURL);

  const fileType = fileExtension ? AssetPreviewHelper.getFileType(fileExtension) : '';

  useEffect(() => {
    if (assetType === AssetType.Article) {
      setLayoutView(
        item && (
          <AssetCardImage className="AssetPreview-display-image" height={500} width={500} assetType={AssetType.Article} item={item} preview={true} />
        ),
      );
    }
    switch (fileType) {
      case 'image':
        setLayoutView(item && <AssetCardImage className="AssetPreview-display-image" item={item} preview={true} />);
        break;
      case 'document':
        setLayoutView(item && <AssetsMultiplePreview item={item} />);
        break;
      case 'video':
        setLayoutView(item && <AudioVideoPreview item={item} itemType={fileType} />);
        break;
      case 'audio':
        setLayoutView(item && <AudioVideoPreview item={item} itemType={fileType} />);
        break;
      default:
        break;
    }
  }, [setLayoutView, item, assetType, fileType]);

  return (
    <div className={`AssetPreview ${assetType === AssetType.Article && 'AssetPreview-preview-article'}`}>
      {layoutView ? layoutView : <PreviewNotAvailable height={500} width={500} backgroundColor={'#ededed'} classname={'AssetPreview-preview'} />}
    </div>
  );
});

export default AssetPreview;
