import { observable, action, runInAction, computed, toJS, makeObservable } from 'mobx';
import { AssetContentType, UIAssetDomainsNamesEnums } from '@cdam/shared';
import TaxonomyStore from '../taxonomy/TaxonomyStore';
import ViewStore from '../view/ViewStore';
import { IDisplayColumns } from './AssetsStore';
import { AssetFilter } from './data/AssetFilter';

export class EditColumnsStore {
  @observable public isLoading = true;
  @observable public error: Error | null = null;

  @observable public selectedColumns: Array<AssetFilter> = [];
  @observable public availableColumns: Array<AssetFilter> = [];

  private readonly selectedAssetGroup: AssetContentType;
  private readonly data: Array<IDisplayColumns>;

  @computed
  public get updatedData(): Array<IDisplayColumns> {
    return this.updateSelectedColumns(this.selectedColumns);
  }

  public constructor(selectedColumns: Array<IDisplayColumns>) {
    makeObservable(this);
    this.data = selectedColumns;
    this.selectedAssetGroup =
      ViewStore.selectedUiAssetDomain === UIAssetDomainsNamesEnums.MARVIN_MCT ? AssetContentType.MCT : AssetContentType.Unknown;

    runInAction(() => {
      this.availableColumns = TaxonomyStore.attributeGroupList;

      const selected = selectedColumns.find((settings) => settings.assetGroup === this.selectedAssetGroup);

      this.selectedColumns = selected
        ? this.availableColumns.filter((filter) => selected.assetColumns.find((f) => filter.attribute.name === f.attribute.name))
        : [];
    });
  }

  @action
  public toggleSelect(column: AssetFilter): void {
    const index = this.selectedColumns.findIndex((f) => f.attribute.name === column.attribute.name);

    if (index !== -1) {
      this.selectedColumns.splice(index, 1);
    } else {
      this.selectedColumns.push(column);
    }
  }

  @action
  private updateSelectedColumns(selectedColumns: Array<AssetFilter>): Array<IDisplayColumns> {
    const data = toJS(this.data);
    const selected = data.find((settings) => settings.assetGroup === this.selectedAssetGroup);

    if (selected) {
      // Preserve the original columns order
      selected.assetColumns = selected.assetColumns.filter((col) => selectedColumns.find((f) => f.attribute.name === col.attribute.name));

      selected.assetColumns = [
        ...selected.assetColumns,
        ...selectedColumns.filter((filter) => !selected.assetColumns.find((f) => f.attribute.name === filter.attribute.name)),
      ];
    }

    return data;
  }
}
