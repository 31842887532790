import React, { useCallback } from 'react';
import cn from 'classnames';
import Checkbox from './Checkbox';

import './CheckboxItem.scss';

interface ICheckboxItemProps {
  className?: string;
  checked: boolean;
  onClick(): void;
}

const CheckboxItem = ({ className, checked, onClick }: ICheckboxItemProps): JSX.Element => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick],
  );

  return (
    <div className={cn('CheckboxItem', className)} onClick={handleClick}>
      <Checkbox checked={checked} onChange={() => handleClick} />
    </div>
  );
};

export default CheckboxItem;
