import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import { HandledError, LogLevelEnum, EventTypeEnum } from '@cdam/shared';
import { IInProgressItem } from '../interfaces/IInProgressItem';
import { IUploadFile } from '../../upload/UploadStore';
import UploadDownloadManagerStore from '../UploadDownloadManagerStore';
import UploadUtils from '../../../utils/UploadUtils';
import LoggingService from '../../../services/LoggingService';

// import ProfileStore from '../../profile/ProfileStore';

export enum metadataOperation {
  Create,
  PartialUpdate,
  FullUpdate,
  EditMetadata,
  Delete,
}

export enum UploadType {
  MCT = 'mct', // Marketing Content
  PI = 'pi', // Product Images
}

export class UploadItem implements IInProgressItem {
  @observable public error: Error | null = null;
  @observable public isLoading = false;
  @observable public success = false;
  @observable public retryCount = 0;
  public title: string;
  public files: Array<IUploadFile> = [];
  private readonly uploadType: UploadType | undefined;

  public constructor(title: string, files: Array<IUploadFile>, uploadType?: UploadType) {
    makeObservable(this);
    this.title = title;
    this.files = files;
    this.uploadType = uploadType;
  }

  @computed
  public get showCancel(): boolean {
    return this.retryCount === 3;
  }

  @computed
  public get fileCount(): number {
    return this.files.length;
  }

  @action
  public onTryAgainClick(): void {
    if (this.retryCount === 3) {
      return;
    }

    ++this.retryCount;
    this.error = null;
    this.isLoading = false;
    this.success = false;
    this.startProcess();
  }

  @action
  public remove(): void {
    UploadDownloadManagerStore.removeUploaditem(this);
  }

  @action
  public async startProcess(): Promise<boolean> {
    if (this.files.length > 500) {
      const error = new HandledError('Maximum number of upload files is 500');

      this.error = error;

      LoggingService.log(
        { message: 'User tried to upload more than 500 files at once', level: LogLevelEnum.DEBUG, event: EventTypeEnum.FILE_UPLOAD },
        error,
      );

      return false;
    }

    this.isLoading = true;

    try {
      for (const file of this.files) {
        // eslint-disable-next-line no-await-in-loop
        await UploadUtils.uploadFile(file, this.uploadType);
      }
    } catch (e) {
      this.handleError(e);

      return false;
    }

    runInAction(() => {
      this.isLoading = false;
      this.success = true;
    });

    // ProfileStore.updateProfile();

    return true;
  }

  private async handleError(err: Error): Promise<void> {
    runInAction(() => {
      this.isLoading = false;
      this.error = err;
    });
  }
}
