import { observable, action, computed, runInAction, makeObservable } from 'mobx';
import {
  IDownloadRequest,
  IDownloadItem,
  HandledError,
  ErrorMessage,
  LogLevelEnum,
  EventTypeEnum,
  AssetDomainsNamesEnums,
  IDownloadResponse,
} from '@cdam/shared';
import HttpService, { UrlType } from 'services/ClientHttpService';
import { IInProgressItem } from '../interfaces/IInProgressItem';
import UploadDownloadManagerStore from '../UploadDownloadManagerStore';
import LoggingService from '../../../services/LoggingService';
import DialogStore from '../../dialog/DialogStore';

export class DownloadItem implements IInProgressItem {
  public files: Array<IDownloadItem> = [];
  public title: string;
  public createdOn = '';
  public downloadUrl = '';
  public id = '';

  @observable public error: Error | null = null;
  @observable public isLoading = false;
  @observable public success = false;

  @observable private retryCount = 0;

  private readonly assetDomain: AssetDomainsNamesEnums;

  public constructor(downloadRequest: IDownloadRequest) {
    makeObservable(this);
    const { id, fileName, files, createdOn, assetDomain = AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME } = downloadRequest;

    this.id = id;
    this.title = fileName;
    this.createdOn = (createdOn && new Date(createdOn).toISOString()) || '';
    this.files = files;
    this.assetDomain = assetDomain;
  }

  @computed
  public get showCancel(): boolean {
    return this.retryCount === 3;
  }

  @computed
  public get fileCount(): number {
    return this.files.length;
  }

  @action
  public async startProcess(): Promise<void> {
    const url = `${process.env.REACT_APP_PROXY ?? ''}${HttpService.getAbsoluteUrl(UrlType.Backend, '/downloads')}`;

    try {
      runInAction(() => {
        this.error = null;
        this.isLoading = true;
      });

      const { allFilesFailed, failedFiles } = await HttpService.fetch<IDownloadResponse>({
        url,
        urlType: UrlType.Raw,
        method: 'POST',
        body: { id: this.id, fileName: this.title, files: this.files, createdOn: this.createdOn, assetDomain: this.assetDomain } as IDownloadRequest,
      });

      if (allFilesFailed) {
        runInAction(() => {
          this.error = new Error('Invalid permissions for downloading selected files');
          this.isLoading = false;
        });
      } else if (failedFiles && failedFiles.length > 0) {
        runInAction(() => {
          this.error = new Error('Incomplete download');
          this.isLoading = false;
        });

        DialogStore.alert(`You don't have permissions to download listed files:\n${failedFiles.map((file) => `- ${file.fileName} (${file.id})\n`)}`);
      }
    } catch (e) {
      const err = e as HandledError;

      LoggingService.log({ message: `Failed to download file ${this.id}`, level: LogLevelEnum.ERROR, event: EventTypeEnum.FILE_DOWNLOAD }, err);

      if (err.code === 404 || err.message === ErrorMessage.noDownloadPackageNameProvided || err.message === ErrorMessage.BackendError) {
        runInAction(() => {
          this.retryCount = 3;
        });
      }

      runInAction(() => {
        this.error = e as Error;
        this.isLoading = false;
      });
    }
  }

  @action
  public resumeDownload(): void {
    this.isLoading = true;
  }

  @action
  public setDownloadUrl(downloadUrl: string): void {
    this.success = true;
    this.downloadUrl = downloadUrl;
  }

  @action
  public onTryAgainClick(): void {
    this.retryCount++;
    this.startProcess();
  }

  @action
  public async remove(): Promise<void> {
    !this.downloadUrl ? UploadDownloadManagerStore.removeDownloadItem(this) : UploadDownloadManagerStore.removeDownloadReadyItem(this);

    try {
      const url = `${process.env.REACT_APP_PROXY ?? ''}${HttpService.getAbsoluteUrl(UrlType.Backend, `/downloads/${this.id}`)}`;

      await HttpService.fetch({
        url,
        urlType: UrlType.Raw,
        method: 'DELETE',
      });
    } catch (e) {
      const { message } = e as HandledError;

      LoggingService.log({ message: `Failed to delete archive ${this.id}`, level: LogLevelEnum.ERROR, event: EventTypeEnum.FILE_DELETE }, e);

      if (message !== ErrorMessage.NotFound) {
        runInAction(() => {
          this.error = e as Error;
        });
      }
    }
  }
}
