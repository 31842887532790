import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Brand, AppVariantEnum } from '@cdam/shared';
import { RouteComponentProps } from 'react-router';
import ViewStore from '../../stores/view/ViewStore';
import SearchStore from '../../stores/assets/SearchStore';
import MatrixStore from '../../stores/assets/MatrixStore';
import { ListLoader } from '../../components/loader/ListLoader';
import SearchInput from './components/SearchInput';
import SearchMatrix from './components/SearchMatrix';
import ArchiveSearchFilter from './components/ArchiveSearchFilter';
import GateSearchFilter from './components/GateSearchFilter';
import SearchBrandSelector from './components/SearchBrandSelector';
import MatrixAdminArea from './components/MatrixAdminArea';
import SearchMatrixReebok from './components/SearchMatrixReebok';

import './SearchView.scss';

interface ISearchViewRouteProps {
  brand?: Brand;
  eventId?: string;
  metadataId?: string;
}

const SearchView = observer(
  ({ match }: RouteComponentProps<ISearchViewRouteProps>): JSX.Element => {
    const [searchStore] = useState(() => new SearchStore());
    const [matrixStore] = useState(() => new MatrixStore());

    const brand = match.params.brand ?? Brand.Adidas;

    useEffect(() => {
      matrixStore.load(brand, match.params.eventId, match.params.metadataId);
    }, [brand, match.params.eventId, match.params.metadataId, matrixStore]);

    return (
      <div className="SearchView" data-testid="search-view">
        <div className="SearchView-scroll-container">
          {ViewStore.appVariant === AppVariantEnum.ArchiveUI ? (
            <ArchiveSearchFilter store={searchStore} />
          ) : (
            <>
              <SearchInput store={searchStore} data-testid="search-view-search-input" />
              <SearchBrandSelector data-testid="search-view-brand-selector" />
              {matrixStore.brand === Brand.Adidas ? (
                <SearchMatrix store={matrixStore} data-testid="search-view-matrices" />
              ) : (
                <SearchMatrixReebok store={matrixStore} data-testid="search-view-matrices" />
              )}
              <ListLoader isLoading={matrixStore.isLoading} error={matrixStore.error} itemsLength={1} />
              {matrixStore.events && (
                <React.Fragment>
                  {ViewStore.isAdmin && <MatrixAdminArea store={matrixStore} data-testid="search-view-admin" />}
                  <GateSearchFilter store={searchStore} />
                </React.Fragment>
              )}
            </>
          )}
        </div>
      </div>
    );
  },
);

export default SearchView;
