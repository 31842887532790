import React, { useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Loader } from '../../components/loader/Loader';
import DialogStore from '../../stores/dialog/DialogStore';

import './Dialog.scss';

const Dialog: React.FC = observer(() => {
  const focusBtnRef = useRef<HTMLButtonElement | null>(null);
  const modalRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const focusRestrict = (event: FocusEvent): void => {
      if (modalRef.current && DialogStore.showModalPage && !modalRef.current.contains(event.target as Node)) {
        event.stopPropagation();

        if (focusBtnRef.current) {
          focusBtnRef.current.focus();
        }
      }
    };

    document.addEventListener('focus', focusRestrict, true);

    return () => {
      document.removeEventListener('focus', focusRestrict, true);
    };
  }, []);

  useEffect(() => {
    if (!DialogStore.showModalPage) {
      return;
    }

    if (focusBtnRef.current) {
      focusBtnRef.current.focus();
    }
  });

  if (!DialogStore.showModalPage) {
    document.body.style.overflow = '';

    return null;
  }

  document.body.style.overflow = 'hidden';

  const startingZIndex = 9999;

  return (
    <div className="Dialog" ref={modalRef}>
      <button className="Dialog-focus-button" ref={focusBtnRef} />

      {DialogStore.dialogs.map((dialog, index) => (
        <React.Fragment key={index}>
          <div className="Dialog-item-backdrop" style={{ zIndex: startingZIndex * (index + 1) }} />
          <div className="Dialog-item-container" style={{ zIndex: startingZIndex * (index + 1) }}>
            {dialog}
          </div>
        </React.Fragment>
      ))}

      {DialogStore.fullScreenLoaders > 0 && (
        <>
          <div className="Dialog-item-backdrop" style={{ zIndex: 10000 }} />
          <div className="Dialog-item-container" style={{ zIndex: 10000 }}>
            <Loader large />
          </div>
        </>
      )}
    </div>
  );
});

export default Dialog;
