import { AssetDomainsNamesEnums } from '@cdam/shared';

interface IAssetViewSelectedFilters {
  [AssetDomainsNamesEnums: string]: Array<string>;
}

export const assetViewSelectedFilters: IAssetViewSelectedFilters = {
  [AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME]: [
    'assetName',
    'jobno',
    'Kalido_MDT_BRAND',
    'subBrand',
    'category',
    'communicationChannel',
    'season',
    'year',
  ],
  [AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME]: [
    'assetName',
    'archiveDate',
    'subBrand_pm',
    'category_pm',
    'DABOX_campaign_pm',
    'campaignName_pm',
    'season_pm',
    'year_pm',
    'jobn_pm',
  ],
};
