import { observable, action, computed, makeObservable } from 'mobx';
import { IZip } from '@cdam/shared';
import HttpServiceApi, { UrlType } from '../../services/ClientHttpService';
import { UploadItem } from './data/UploadItem';
import { DownloadItem } from './data/DownloadItem';
import { IInProgressItem } from './interfaces/IInProgressItem';

class UploadDownloadManagerStore {
  @observable public showManager = false;
  @observable public uploadItems: Array<UploadItem> = [];
  @observable public downloadItems: Array<DownloadItem> = [];
  @observable public downloadReadyItems: Array<DownloadItem> = [];

  public isReady = false;

  private readonly intervals: Record<string, NodeJS.Timeout> = {};

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get emptyManager(): boolean {
    return this.uploadItems.length === 0 && this.downloadItems.length === 0 && this.downloadReadyItems.length === 0;
  }

  @computed
  public get inProgress(): boolean {
    const allItems: Array<IInProgressItem> = [...this.uploadItems, ...this.downloadItems];
    let inProgress = false;

    for (const item of allItems) {
      if (item.isLoading) {
        inProgress = true;
        break;
      }
    }

    return inProgress;
  }

  @action
  public async showOrCloseManager(clearStore = false): Promise<void> {
    this.showManager = !this.showManager;

    if (clearStore) {
      this.uploadItems = this.uploadItems.filter((uploadItem) => uploadItem.isLoading);
    }
  }

  @action
  public async addUploadItem(item: UploadItem): Promise<void> {
    this.uploadItems.push(item);

    if (!this.showManager) {
      this.showOrCloseManager();
    }

    await item.startProcess();
  }

  @action
  public removeUploaditem(item: UploadItem): void {
    const index = this.uploadItems.indexOf(item);

    if (index !== -1) {
      this.uploadItems.splice(index, 1);
    }
  }

  @action
  public addDownloadItem(item: DownloadItem, startProcess = true): void {
    if (!this.downloadItems.find((i) => i.id === item.id)) {
      this.downloadItems.push(item);

      this.intervals[item.id] = setInterval(() => {
        this.checkDownloadItems(item);
      }, 10000);
    }

    if (!this.showManager) {
      this.showOrCloseManager();
    }

    if (startProcess) {
      item.startProcess();
    }
  }

  @action
  public removeDownloadItem(item: DownloadItem): void {
    const index = this.downloadItems.indexOf(item);

    if (index !== -1) {
      this.downloadItems.splice(index, 1);
    }
  }

  @action
  public removeDownloadReadyItem(item: DownloadItem): void {
    const index = this.downloadReadyItems.indexOf(item);

    if (index !== -1) {
      this.downloadReadyItems.splice(index, 1);
    }
  }

  @action
  public addDownloadReadyItem(item: DownloadItem): void {
    if (!this.downloadReadyItems.find((i) => i.id === item.id)) {
      this.downloadReadyItems.push(item);
    }

    if (!this.showManager) {
      this.showOrCloseManager();
    }
  }

  @action
  public async preFillStore(): Promise<void> {
    if (!this.isReady) {
      const { content: downloads } = await HttpServiceApi.fetch<{ content: Array<IZip> }>({
        url: '/downloads',
        urlType: UrlType.Backend,
        method: 'GET',
      });

      for (const item of downloads) {
        const downloadItem = new DownloadItem({
          id: item.id,
          fileName: item.fileName,
          createdOn: item.createdOn,
          files: item.files,
        });

        if (item.prepared) {
          downloadItem.setDownloadUrl(
            `${process.env.REACT_APP_PROXY ?? ''}${HttpServiceApi.getAbsoluteUrl(UrlType.Backend, `/downloads/${item.id}`)}`,
          );
          this.addDownloadReadyItem(downloadItem);
        }
      }

      const resumedDownloads = await HttpServiceApi.fetch<Array<IZip>>({
        url: '/downloads/in-progress',
        urlType: UrlType.Backend,
        method: 'GET',
      });

      for (const item of resumedDownloads) {
        const downloadItem = new DownloadItem({
          id: item.id,
          fileName: item.fileName,
          createdOn: item.createdOn,
          files: item.files,
        });

        this.addDownloadItem(downloadItem, false);

        downloadItem.resumeDownload();
      }

      this.isReady = true;
    }
  }

  private async checkDownloadItems(item: DownloadItem): Promise<void> {
    const { id: downloadItemId } = item;
    const { ready } = await HttpServiceApi.fetch<{ ready: boolean }>({
      urlType: UrlType.Backend,
      url: `/downloads/${downloadItemId}/ready`,
      method: 'GET',
    });

    if (ready) {
      clearInterval(this.intervals[downloadItemId]);

      delete this.intervals[downloadItemId];

      item.setDownloadUrl(`${process.env.REACT_APP_PROXY ?? ''}${HttpServiceApi.getAbsoluteUrl(UrlType.Backend, `/downloads/${downloadItemId}`)}`);

      this.removeDownloadItem(item);
      this.addDownloadReadyItem(item);
    }
  }
}

export default new UploadDownloadManagerStore();
