import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthStore from 'stores/auth/AuthStore';
import DialogStore from 'stores/dialog/DialogStore';
import UploadDownloadManagerStore from 'stores/uploadDownloadManager/UploadDownloadManagerStore';
import ViewStore from '../../stores/view/ViewStore';

import './Settings.scss';

const Settings = (): JSX.Element => {
  const { t } = useTranslation();

  const onLogout = (): void => {
    DialogStore.execFullScreen(AuthStore.logout(false));
  };

  return (
    <div className="settings-container">
      {ViewStore.canCreateTemplates && (
        <div className="settings-item">
          <Link to="/edit-templates" className="settings-link">
            {t('common.edit_templates')}
          </Link>
        </div>
      )}
      <div className="settings-item">
        <span className="settings-link" onClick={async () => UploadDownloadManagerStore.showOrCloseManager(true)}>
          {t('common.downloads_and_uploads')}
        </span>
      </div>
      <div className="settings-item">
        <Link to="/profile" className="settings-link">
          {t('profile.profile')}
        </Link>
      </div>
      <div className="settings-item">
        <span className="settings-link" onClick={onLogout}>
          {t('common.logout')}
        </span>
      </div>
    </div>
  );
};

export default Settings;
