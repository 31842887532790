/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import AssetsFilter from 'containers/assets/components/AssetsFilter';
import MatrixSettingsStore, { Axis } from '../../../../stores/matrix/MatrixSettingsStore';
import TextBoxControlled from '../../../../components/input/TextBoxControlled';
import MatrixGroupItemsDropdown from './MatrixGroupItemsDropdown';

interface IMatrixSettingsDimensionProps {
  axis: Axis;
  store: MatrixSettingsStore;
}

const MatrixSettingsDimension = observer(
  ({ axis, store }: IMatrixSettingsDimensionProps): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div>
        {axis === Axis.yAxis && <MatrixGroupItemsDropdown store={store} />}
        <div className="space-p-2" />
        <TextBoxControlled
          className="MatrixSettingsDialog-fieldname-input"
          name="fieldName"
          value={store.editingItemName}
          label={t('common.name')}
          error={store.editingItemNameError}
          onChange={(e) => store.changeEditingItemName(e.target.value)}
        />
        <div className="space-p-2" />
        <AssetsFilter
          className="MatrixSettingsDialog-filter-container"
          filterClassName="space-m-b-3 MatrixSettingsDialog-filter-stretch"
          filters={store.editingItemsTaxonomy}
          selectedFilters={store.editingItemFilters}
          toggleSelectedFilter={store.toggleSelectedFilter}
          clearAllFilters={store.clearAllEditingFilters}
          maxFilterWidth="25.7rem"
          // singleChoice
          checkList
          brand={store.event ? store.event.brand : undefined}
          invalidFilters={store.invalidEditingItemFilters}
        />
      </div>
    );
  },
);

export default MatrixSettingsDimension;
