import React from 'react';
import { observer } from 'mobx-react-lite';

import './AssetType.scss';

interface IAssetType {
  onClick?(): void;
  disabled?: boolean | null;
  border: boolean;
  value?: number;
  type: string;
  className?: string;
}

const AssetTypeSelector: React.FC<IAssetType> = observer(({ type, onClick, disabled, border, value, className }: IAssetType) => {
  const typecolor = (type: string): string => {
    switch (type) {
      case 'model':
        return '#579bc8';
      case 'asset':
        return '#af86ab';
      case 'article':
        return '#82ac82';
      default:
        return '#579bc8';
    }
  };

  return (
    <button
      className={`AssetType ${className && className} space-m-2 ${disabled ? 'AssetType-disabled' : null} ${
        border ? 'AssetType-border' : 'AssetType-disable-border'
      } ${onClick ? 'AssetType-pointer' : null}`}
      onClick={onClick}
    >
      <div className="AssetType-square space-m-r-2" style={{ backgroundColor: typecolor(type) }} />
      {type.toUpperCase()} {value && ` (${value})`}
    </button>
  );
});

export default AssetTypeSelector;
