import { IMetadataDigitalContentAttribute } from '@cdam/shared';

/* eslint-disable complexity */
export const getFileFormatFromExtension = (extension: string): string => {
  switch (extension?.toLowerCase()) {
    case 'png':
      return 'PNG Image Format';
    case 'jpg':
      return 'JPG Image Format';
    case 'tif':
      return 'TIF Image Format';
    case 'tiff':
      return 'TIF Image Format';
    case 'pptx':
      return 'Power Point Presentation';
    case 'ppt':
      return 'Power Point Presentation';
    case 'zip':
      return 'Archived Files';
    case 'pdf':
      return 'PDF Document';
    case 'docx':
      return 'Word Document';
    case 'doc':
      return 'Word Document';
    case 'swf':
      return 'SWF Video Format';
    case 'xlsx':
      return 'Excel Document';
    case 'xls':
      return 'Excel Document';
    case 'gif':
      return 'GIF Image format';
    case 'mp4':
      return 'MP4 Video Format';
    default:
      return 'Unknown';
  }
};

export const getFileFormatTypeFromExtension = (extension: string): string => {
  switch (extension?.toLowerCase()) {
    case 'png':
      return 'image';
    case 'jpg':
      return 'image';
    case 'jpeg':
      return 'image';
    case 'tif':
      return 'image';
    case 'tiff':
      return 'image';
    case 'bmp':
      return 'image';
    case 'psd':
      return 'image';
    case 'eps':
      return 'image';
    case 'gif':
      return 'image';
    case 'doc':
      return 'document';
    case 'docx':
      return 'document';
    case 'msg':
      return 'document';
    case 'pdf':
      return 'document';
    case 'ppt':
      return 'document';
    case 'pptx':
      return 'document';
    case 'swf':
      return 'document';
    case 'xls':
      return 'document';
    case 'xlsx':
      return 'document';
    case 'zip':
      return 'document';
    case 'aac':
      return 'audio';
    case 'ac3':
      return 'audio';
    case 'aif':
      return 'audio';
    case 'alac':
      return 'audio';
    case 'dts':
      return 'audio';
    case 'flac':
      return 'audio';
    case 'ogg':
      return 'audio';
    case 'opus':
      return 'audio';
    case 'm4a':
      return 'audio';
    case 'mp3':
      return 'audio';
    case 'wav':
      return 'audio';
    case 'wma':
      return 'audio';
    case 'asf':
      return 'video';
    case 'avi':
      return 'video';
    case 'fli':
      return 'video';
    case 'flc':
      return 'video';
    case 'fvl':
      return 'video';
    case 'm4v':
      return 'video';
    case 'mkv':
      return 'video';
    case 'mov':
      return 'video';
    case 'mp4':
      return 'video';
    case 'mpg':
      return 'video';
    case 'mpeg':
      return 'video';
    case 'vob':
      return 'video';
    case 'webm':
      return 'video';
    case 'webp':
      return 'video';
    case 'wmv':
      return 'video';
    default:
      return 'Unknown';
  }
};

export const readFileExtension = (fileUrl: string): string => {
  if (!fileUrl) {
    return '';
  }

  return fileUrl.substring(fileUrl.lastIndexOf('.') + 1, fileUrl.length) || fileUrl;
};

export const getContentValueByType = (data: Array<IMetadataDigitalContentAttribute> | undefined, wantType: string): string => {
  if (!data) {
    return '';
  }

  let value = '';

  data.forEach((element) => {
    if (element.name === wantType) {
      value = element.value;
    }
  });

  return value;
};
