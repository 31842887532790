import React from 'react';
import { useTranslation } from 'react-i18next';
import { AppVariantEnum } from '@cdam/shared';
import ViewStore from '../../stores/view/ViewStore';

import './Version.scss';

const Version = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="Version space-p-h-3 space-p-v-1">
      <div className="Version-text">
        {t('common.version').toUpperCase()}{' '}
        {ViewStore.appVariant === AppVariantEnum.ArchiveUI ? ViewStore.clientArchiveUIVersion : ViewStore.clientGateUIVersion}
      </div>
    </div>
  );
};

export default Version;
