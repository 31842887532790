import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AssetFilterInputStore } from '../../../../stores/filters/AssetFilterInputStore';
import { ListLoader } from '../../../../components/loader/ListLoader';
import DropdownItem from '../../../../components/dropdown/DropdownItem';

import './AssetsFilterInput.scss';

export interface IAssetsFilterInputProps {
  type?: string;
  value?: string | number;
  numberStep?: number;
  numberMin?: number;
  numberMax?: number;
  maxLength?: number;
  validateValue?: boolean;
  submitValue?(val: string, openAfter?: boolean): void;
  searchMode?: boolean;
  attributeName: string;
  group: string;
}

const AssetsFilterInput = observer(
  ({ submitValue, ...props }: IAssetsFilterInputProps): JSX.Element => {
    const { t } = useTranslation();
    const [store] = useState(() => new AssetFilterInputStore(props));

    const submit = (value: string) => {
      if (store.hasError(value)) {
        return;
      }

      submitValue?.(value);
    };

    useEffect(() => {
      store.init();

      return () => {
        store.disposeInterval();
      };
    }, [store]);

    return (
      <div className="AssetsFilterInput">
        <input
          className="AssetsFilterInput-input"
          type={store.type}
          step={store.numberStep}
          min={store.numberMin}
          max={store.numberMax}
          maxLength={store.maxLength}
          defaultValue={store.value}
          placeholder={t('common.start_typing')}
          onChange={(e) => store.setValue(e.target.value)}
        />
        {store.errorMessage && <div className="AssetsFilterInput-validation space-m-t-1">{store.errorMessage}</div>}

        {store.type === 'text' && (
          <div className="AssetsFilterInput-typeahead space-m-t-2">
            <ul className="list dropdown__options" style={{ boxShadow: 'none', position: 'static', maxHeight: '18.75rem' /* disable-stylelint */ }}>
              {(store.isLoading || store.error) && (
                <ListLoader
                  className="AssetsFilterInput-typeahead__loader"
                  isLoading={store.isLoading}
                  itemsLength={store.displayResultsLimit}
                  error={store.error}
                />
              )}
              {store.suggestions.map((s) => (
                <DropdownItem key={s} value={s} onClick={() => submit(s)} />
              ))}
            </ul>
          </div>
        )}

        {(!store.searchMode || store.type === 'number') && (
          <button className="AssetsFilterInput-button" onClick={() => submit(store.valueAsString)}>
            {t('common.update')}
          </button>
        )}
      </div>
    );
  },
);

export default AssetsFilterInput;
