import React from 'react';
import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import {
  AssetContentType,
  AssetType,
  AssetSearch,
  IMasterdata,
  IMasterdataResponse,
  IMetadata,
  IMetadataSearch,
  ISubscribeRequest,
  ISubscriptions,
  SubscriptionType,
  IMatrixOwnersItem,
  Name,
  AuthGroup,
  AssetDomainsNamesEnums,
  AppVariantEnum,
} from '@cdam/shared';
import AssetUtils from 'utils/AssetUtils';
import DetailsConfig from 'configs/DetailsConfig';
import AdvancedDownload from 'containers/assets/dialog/AdvancedDownload';
import DialogStore from 'stores/dialog/DialogStore';
import MatrixItemDeleteDialog from 'containers/search/dialogs/MatrixItemDeleteDialog';
import NavigationService from 'services/NavigationService';
import { metadataOperation, UploadItem } from 'stores/uploadDownloadManager/data/UploadItem';
import { getAssetContentType, getDetailsValues, showConfidential } from 'utils/AssetDisplayUtils';
import UtilityStore from 'stores/utility/UtilityStore';
import EditMetadata from 'containers/assets/details/components/EditMetadata';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import UploadUtils from 'utils/UploadUtils';
import FilterUtils from 'utils/FilterUtils';
import { IMatrixOwnerSearchable } from 'containers/search/dialogs/components/MatrixSettingsOwners';
import SecurityUtils from 'utils/SecurityUtils';
import i18n from '../../i18n';
import AuthStore from '../auth/AuthStore';
import ViewStore from '../view/ViewStore';
import MetadataUtils from '../../utils/MetadataUtils';
import { AssetData } from './data/AssetData';
import { AssetFilter } from './data/AssetFilter';
import { ReferenceDataItem } from './data/ReferenceDataItem';

export interface IDisplayItem {
  item: AssetData;
  articleItems?: Array<AssetData>;
  articleAttributes?: { [key: string]: string };
}

const USERNAME_PREFIX = 'emea\\';

export default class DetailsStore {
  @observable public displayItem: IDisplayItem | null = null;
  @observable public isLoading = true;
  @observable public failedToLoad = false;
  @observable public subscribedItems: Array<ISubscribeRequest> = [];
  @observable public assetType: AssetType | null = null;
  @observable public contentType: AssetContentType | null = null;
  @observable public versioningFile: UploadItem | null = null;
  @observable public pairedArticles: Array<AssetData> = [];
  @observable public navigationItems: Array<string> | null = null;

  @observable public editMetadataError = false;
  @observable public editMetadataFilters: Array<AssetFilter> = [];
  @observable public editMetadataOwners: Array<IMatrixOwnersItem> = [];
  @observable public filters: Array<AssetFilter> = [];

  public metadataId = '';
  public downloadUrl = '';

  private allUsers: Array<IMatrixOwnersItem> = [];

  public constructor() {
    makeObservable(this);
  }

  public getAssetName(): string {
    if (!this.displayItem) {
      return '';
    }

    return AssetUtils.getAssetName(this.displayItem.item) ?? '';
  }

  public canBeEdited(): boolean {
    if (!this.displayItem) {
      return false;
    }

    if (ViewStore.appVariant === AppVariantEnum.ArchiveUI) {
      return ViewStore.canEdit;
    }

    return this.contentType === AssetContentType.MCT && AssetUtils.isAssetOwnerAndHasCorrectUserType(this.displayItem.item, this.contentType);
  }

  @computed
  public get NonEditableMetadata(): Array<AssetFilter> {
    return this.filters.filter(
      (filter) =>
        !this.editMetadataFilters.find((e) => e.attribute.name === filter.attribute.name && e.groupName === filter.groupName) &&
        filter.attribute.name !== 'owners' &&
        filter.groupName !== Name.Miscellaneous,
    );
  }

  @action
  public async init(metadataId: string | undefined, assetType: AssetType | undefined): Promise<void> {
    if (!metadataId) {
      return;
    }

    this.metadataId = metadataId;
    this.downloadUrl = `/metadata/${metadataId}/activeDigitalContent`;

    if (assetType) {
      this.assetType = assetType;
    }

    this.navigationItems = UtilityStore.getNavigationIds();

    if (assetType === AssetType.Article) {
      this.loadArticle(metadataId);
    } else {
      this.load();
    }

    this.getSubscriptions();
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public addToEditMetadataOwners(owner: IMatrixOwnersItem): void {
    if (this.editMetadataOwners.find((e) => e.userId === owner.userId && e.username === owner.username)) {
      DialogStore.alert(i18n.t('details.owner_duplicate'));

      return;
    }

    this.editMetadataOwners.push(owner);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public async removeFromEditMetadataOwners(index: number): Promise<void> {
    if (this.editMetadataOwners.length === 2) {
      const tempOwners = this.editMetadataOwners.filter((_, idx) => idx !== index);
      const lastOwner = tempOwners[0];

      // Check if lastOwner is admin
      if (SecurityUtils.checkIfUserIsInAnyOfGroups(lastOwner.username, [AuthGroup.CDAM_UI_adidas_MCT_Admin, AuthGroup.CDAM_UI_Reebok_MCT_Admin])) {
        DialogStore.alert(i18n.t('details.admin_only_owner'));

        return;
      }
    } else if (this.editMetadataOwners.length === 1) {
      DialogStore.alert(i18n.t('details.asset_one_owner'));

      return;
    }

    runInAction(() => {
      if (index !== -1) {
        this.editMetadataOwners.splice(index, 1);
      }
    });
  }

  public async getAllOwners(): Promise<Array<IMatrixOwnerSearchable>> {
    const users = await SecurityUtils.getUsers();

    return users.reduce<Array<IMatrixOwnerSearchable>>((acc, item) => {
      acc.push({ ...item, searchableText: item.username });

      return acc;
    }, []);
  }

  @action
  public clearAllFilters(): void {
    this.filters = [];
  }

  @action
  public toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    return FilterUtils.toggleSelectedFilter(item, filters);
  }

  @action
  public async loadArticle(articleId: string): Promise<void> {
    const search = new AssetSearch();

    try {
      const abortController = new AbortController();
      const signal = abortController.signal;
      const body: IMetadataSearch = {
        searchFacet: {
          allMustMatch: true,
          searchAttributeList: [
            {
              group: 'Asset Information',
              name: 'articleNumber',
              valueList: [{ value: articleId }],
            },
          ],
        },
      };

      const response = await search.search(
        body,
        0,
        100,
        // eslint-disable-next-line @typescript-eslint/return-await
        async (s, url) =>
          await ClientHttpService.fetch({
            urlType: UrlType.Metadata,
            url,
            method: 'POST',
            body: s,
            signal,
          }),
        // eslint-disable-next-line @typescript-eslint/return-await
        async (url) =>
          await ClientHttpService.fetch<IMasterdataResponse>({
            urlType: UrlType.Metadata,
            url,
            method: 'GET',
            signal,
          }),
      );

      const articleAttr = await this.getArticleAttributes(articleId);
      const allUsers = await SecurityUtils.getUsers();

      runInAction(() => {
        this.displayItem = {
          item: new AssetData(response.assets[0]),
          articleItems: response.assets.map((asset) => new AssetData(asset)),
          articleAttributes: articleAttr?.attributes,
        };
        this.allUsers = allUsers;

        this.isLoading = false;

        this.loadPairedArticles(articleId);
      });
    } catch (e) {
      runInAction(() => {
        this.failedToLoad = true;
        this.isLoading = false;
      });
    }
  }

  @action
  public async load(): Promise<void> {
    this.isLoading = true;

    try {
      const url = `/metadata/${this.metadataId}`;
      const urlType = UrlType.Metadata;

      const abortController = new AbortController();
      const response = await ClientHttpService.fetch<IMetadata>({ urlType, url, method: 'GET', signal: abortController.signal });
      const allUsers = await SecurityUtils.getUsers();

      runInAction(() => {
        this.displayItem = { item: new AssetData(response) };
        this.contentType = getAssetContentType(this.displayItem.item);
        this.setDisplayFilters();
        this.allUsers = allUsers;
        this.isLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.failedToLoad = true;
        this.isLoading = false;
      });
    }
  }

  public getDisplayValues(filters: Array<AssetFilter>): Array<AssetFilter> {
    if (!this.displayItem) {
      return [];
    }

    return getDetailsValues(this.displayItem, filters);
  }

  @action
  public async loadPairedArticles(articleId: string): Promise<void> {
    const search = new AssetSearch();

    try {
      const abortController = new AbortController();
      const signal = abortController.signal;
      const body: IMetadataSearch = {
        searchText: articleId,
        exactSearch: true,
        textSearchTargetList: [
          {
            attributeGroup: 'Asset Information',
            attributeName: 'pairedArticles',
          },
        ],
      };

      const response = await search.search(
        body,
        0,
        100,
        // eslint-disable-next-line @typescript-eslint/return-await
        async (s, url) =>
          await ClientHttpService.fetch({
            urlType: UrlType.Metadata,
            url,
            method: 'POST',
            body: s,
            signal,
          }),
        // eslint-disable-next-line @typescript-eslint/return-await
        async (url) =>
          await ClientHttpService.fetch<IMasterdataResponse>({
            urlType: UrlType.Metadata,
            url,
            method: 'GET',
            signal,
          }),
      );

      runInAction(() => {
        this.pairedArticles = this.getRelatedArticles(response.assets.map((asset) => new AssetData(asset)));
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('PairedArticles Error', e);
    }
  }

  @action
  public removeUploadVersioningFile(): void {
    this.versioningFile = null;
  }

  public openAssetDetailsPage(item: AssetData, assetType?: AssetType): void {
    if (assetType === AssetType.Article) {
      UtilityStore.storeAssetDetailsNavigation(
        this.pairedArticles.map((article) => article.asset.attributeGroups['Asset Information'].articleNumber as string),
      );
      const articleNumber = item.asset.attributeGroups['Asset Information'].articleNumber;

      NavigationService.replace(`/details/${articleNumber}/article`);

      return;
    }

    this.displayItem?.articleItems && UtilityStore.storeAssetDetailsNavigation(this.displayItem.articleItems.map((item) => item.asset.id));
    NavigationService.replace(`/details/${item.asset.id}`);
  }

  @action
  public async setUploadVersioningFile(file: UploadItem): Promise<void> {
    this.versioningFile = file;
  }

  @action
  public async getSubscriptions(): Promise<void> {
    try {
      const response = await ClientHttpService.fetch<ISubscriptions>({
        urlType: UrlType.Backend,
        url: '/subscriptions',
        method: 'GET',
      });

      runInAction(() => {
        this.subscribedItems = response.subscriptions;
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Subscriptions Error', e);
    }
  }

  @action
  public async handleNotificationClick(assetType: AssetType): Promise<void> {
    const assetId = this.displayItem?.item.asset.id;

    if (!assetId || !this.displayItem) {
      return;
    }

    let body: Array<ISubscribeRequest> = [];

    if (assetType === AssetType.Article) {
      body = [];

      if (this.displayItem.articleItems) {
        for (const item of this.displayItem.articleItems) {
          body.push({ id: item.asset.id, type: SubscriptionType.Asset });
        }
      }
    } else {
      body.push({
        id: assetId,
        type: SubscriptionType.Asset,
      });
    }

    try {
      if (this.subscribedItems.map((e) => e.id).includes(assetId)) {
        await ClientHttpService.fetch({
          urlType: UrlType.Backend,
          url: '/subscriptions',
          method: 'DELETE',
          body,
        });

        runInAction(() => {
          for (const item of body) {
            const index = this.subscribedItems.indexOf(item);

            this.subscribedItems.splice(index, 1);
          }
        });
      } else {
        await ClientHttpService.fetch({
          urlType: UrlType.Backend,
          url: '/subscriptions',
          method: 'POST',
          body,
        });

        runInAction(() => {
          this.subscribedItems = [...this.subscribedItems, ...body];
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  public isSubscribed(): boolean {
    if (!this.displayItem) {
      return false;
    }

    if (this.assetType === AssetType.Article && this.displayItem) {
      if (!this.displayItem.articleItems) {
        return false;
      }

      return this.displayItem.articleItems.every((e) => this.subscribedItems.map((d) => d.id).includes(e.asset.id));
    }

    return this.displayItem && this.subscribedItems.map((e) => e.id).includes(this.displayItem.item.asset.id);
  }

  public openAdvancedDownloadDialog(items?: Array<AssetData>): void {
    const confidential = (items?.length && showConfidential(undefined, items)) || false;

    DialogStore.addDialog(
      <AdvancedDownload
        confidential={confidential}
        selectedAssets={items ? items : []}
        onClose={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }

  @action
  public deleteAssetConfirmation(): void {
    DialogStore.addDialog(
      <MatrixItemDeleteDialog
        translationKey={'common.delete_asset'}
        deleteBtnTranslationKey={'common.delete'}
        onCancel={() => DialogStore.removeLastDialog()}
        onConfirm={async () => {
          await this.handleDeleteAsset();
        }}
      />,
    );
  }

  public openMetadataEditor(): void {
    this.setEditFilters();

    DialogStore.addDialog(
      <EditMetadata
        store={this}
        onClose={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }

  public getHeadercolor(assetContentType: AssetContentType): string {
    if (assetContentType === AssetContentType.MCT) {
      return '#f5eaef';
    }

    if (this.assetType === AssetType.Asset) {
      return '#c7e3f7';
    } else if (this.assetType === AssetType.Article) {
      return '#deedde';
    }

    return '#f5eaef';
  }

  public toggleDetailsItem(item: string): void {
    if (!this.navigationItems || !this.displayItem) {
      return;
    }

    const itemIndex = this.navigationItems.indexOf(this.metadataId);

    let path = '/details/';

    if (item === 'prev') {
      const metadataId = this.navigationItems[itemIndex - 1];

      path = path + metadataId;
    } else {
      const metadataId = this.navigationItems[itemIndex + 1];

      path = path + metadataId;
    }

    if (this.assetType === AssetType.Article) {
      path = `${path}/article`;
    }

    NavigationService.replace(path);
  }

  @action
  public async updateAssetMetadata(): Promise<void> {
    if (!this.displayItem) {
      return;
    }

    this.editMetadataFilters.forEach((element) => {
      const findIndex = this.filters.findIndex((f) => f.attribute.name === element.attribute.name);

      if (findIndex !== -1) {
        this.filters[findIndex] = element;
      } else {
        this.filters.push(element);
      }
    });

    this.NonEditableMetadata.forEach((element) => {
      const findIndex = this.filters.findIndex((f) => f.attribute.name === element.attribute.name);

      if (findIndex !== -1) {
        this.filters[findIndex] = element;
      } else {
        this.filters.push(element);
      }
    });

    const filtered = this.filters.filter((e) => !DetailsConfig.customFields.includes(e.attribute.name));

    const ownersFilter = filtered.find((f) => f.attribute.name === 'owners');

    if (ownersFilter) {
      const editMetadataOwnersRefIds = this.editMetadataOwners.map((e) => `${USERNAME_PREFIX}${e.username}`);

      ownersFilter.clearAllSelectedValues();

      for (const ownerRefId of editMetadataOwnersRefIds) {
        const referenceData = TaxonomyStore.getTaxonomyReferenceData(Name.Miscellaneous, 'owners', ownerRefId);

        if (referenceData) {
          const referenceDataItem = new ReferenceDataItem(referenceData);

          ownersFilter.toggleSelectedReference(referenceDataItem);
        }
      }
    }

    try {
      const attributeGroupList = this.displayItem.item.getUpdatedAttributeList(filtered);

      const metadata = await DialogStore.execFullScreen(
        UploadUtils.metadataFullUpdate(
          this.displayItem.item.asset.id,
          this.displayItem.item.asset.etag,
          attributeGroupList,
          metadataOperation.EditMetadata,
          undefined,
          undefined,
        ),
      );

      if (metadata) {
        this.displayItem.item.update(metadata);
        this.setDisplayFilters();
      }

      DialogStore.alert(i18n.t('common.metadata_success'), () => DialogStore.removeLastDialog());
    } catch (e) {
      DialogStore.error(e);
      this.editMetadataError = true;
    }
  }

  public displayItemMetadata(assetType: AssetType): Array<AssetFilter> {
    let config: Array<string> = [];

    switch (ViewStore.selectedAssetDomain) {
      case AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
        config = DetailsConfig.daboxDisplayFilters;
        break;
      case AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        config = DetailsConfig.splashDisplayFilters;
        break;
      case AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME:
      default:
        if (this.contentType === AssetContentType.MCT) {
          config = DetailsConfig.mctDisplayFilters;
        } else if (this.contentType === AssetContentType.PI) {
          if (assetType === AssetType.Article) {
            config = DetailsConfig.piArticleDisplayFilters;
          } else {
            config = DetailsConfig.piDisplayFilters;
          }
        } else {
          config = DetailsConfig.defaultDisplayFilters;
        }
    }

    return this.orderDisplayFilters(config, this.filters);
  }

  public filterEditFiltersByContent(filters: Array<AssetFilter>): Array<AssetFilter> {
    let config: Array<string> = [];

    switch (ViewStore.selectedAssetDomain) {
      case AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
        config = DetailsConfig.daboxEditFilter;
        break;
      case AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        config = DetailsConfig.splashEditFilter;
        break;
      case AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME:
      default:
        config = DetailsConfig.mctEditFilters;
    }

    return this.orderDisplayFilters(config, filters);
  }

  @action
  private setDisplayFilters(): void {
    if (!this.displayItem || !this.contentType) {
      return;
    }

    const filters = TaxonomyStore.getTaxonomyAttributeFiltersWithRules();

    filters.push(
      new AssetFilter('originalFileSize', {
        name: 'originalFileSize',
        list: 'false',
        type: 'string',
        indexed: 'false',
        uiVisible: 'false',
        description: 'false',
      }),
    );

    this.filters = getDetailsValues(this.displayItem, filters);
  }

  // TODO: refactor to computed
  @action
  private setEditFilters(): void {
    if (!AuthStore.token) {
      return;
    }

    const filters = TaxonomyStore.attributeGroupList;

    switch (ViewStore.selectedAssetDomain) {
      case AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
      case AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        this.editMetadataFilters = this.filterEditFiltersByContent(filters);
        this.getDisplayValues(this.editMetadataFilters);
        break;
      case AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME:
        this.editMetadataFilters = this.filterEditFiltersByContent(filters);
        // eslint-disable-next-line no-case-declarations
        const result: Array<IMatrixOwnersItem> = [];
        // eslint-disable-next-line no-case-declarations
        const owners = this.displayItem?.item.asset.attributeGroups.Miscellaneous.owners;

        if (owners) {
          for (const owner of owners) {
            for (const user of this.allUsers) {
              if (user.values.includes(owner)) {
                result.push(user);
                break;
              }
            }
          }
        }

        this.editMetadataOwners = result;

        this.getDisplayValues(this.editMetadataFilters);
    }
  }

  private orderDisplayFilters(config: Array<string>, filters: Array<AssetFilter>): Array<AssetFilter> {
    const displayFilters: Array<AssetFilter> = [];

    config.forEach((element) => {
      const filter = filters.find((f) => f.attribute.name === element);

      if (filter) {
        displayFilters.push(filter);
      }
    });

    // TODO: temporary hack!! **********
    const quarterFilter = displayFilters.find((f) => f.groupName === 'Asset Information' && f.attribute.name === 'quarter');

    if (quarterFilter) {
      quarterFilter.setAllowedReferenceItemsIds(['1', '12', '2', '3', '34', '4']);
    }

    // **********

    return displayFilters;
  }

  private async getArticleAttributes(articleId: string): Promise<IMasterdata | undefined> {
    const url = `/masterData/SPM_article/${articleId}`;
    const urlType = UrlType.Metadata;

    try {
      const response = await ClientHttpService.fetch<IMasterdata>({ urlType, url, method: 'GET' });

      return response;
    } catch {
      return undefined;
    }
  }

  private getRelatedArticles(items: Array<AssetData>): Array<AssetData> {
    const articleList: Array<AssetData> = [];

    for (const item of items) {
      const articleName = item.asset.attributeGroups['Asset Information'].articleNumber;

      if (!articleList.map((e) => e.asset.attributeGroups['Asset Information'].articleNumber).includes(articleName)) {
        articleList.push(item);
      }
    }

    return articleList;
  }

  @action
  private async handleDeleteAsset(): Promise<void> {
    if (!this.displayItem) {
      return;
    }

    const id = this.displayItem.item.asset.id;

    try {
      await DialogStore.execFullScreen(MetadataUtils.deleteAsset(id));

      DialogStore.removeLastDialog();
      DialogStore.alert(i18n.t('common.item_delete_success'), () => NavigationService.goBack());
    } catch (error) {
      DialogStore.removeLastDialog();
      DialogStore.error(error, () => NavigationService.goBack());
    }
  }
}
