/* eslint-disable complexity */
import React from 'react';
import { observer } from 'mobx-react-lite';
import Dropdown from 'components/dropdown/Dropdown';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import { ReferenceDataItem } from 'stores/assets/data/ReferenceDataItem';
import { useTranslation } from 'react-i18next';
import { IAssetsFilterItem } from '@cdam/shared';
import DateTimePicker from '../../../components/date/DateTimePicker';
import AssetsFilterPopup from '../../assets/components/Filters/AssetsFilterPopup';
import { UploadFilterArticleNumber } from './UploadFilterArticleNumber';

import '../UploadAssets.scss';

interface IUploadArchiveFilterDropdownProps {
  filter: AssetFilter;
}

const UploadArchiveFilterDropdown = observer(({ filter }: IUploadArchiveFilterDropdownProps): JSX.Element | null => {
  const { t } = useTranslation();

  const getDisplayValue = (filter: AssetFilter): string | null => {
    if (filter.selectedReferenceItems.length !== 0) {
      return filter.selectedReferenceItems.map((item) => item.text).join(', ');
    }

    if (filter.value.length !== 0 || filter.values.length !== 0) {
      if (filter.attribute.type === 'boolean') {
        return filter.value === 'true' ? 'Yes' : 'No';
      }

      return filter.values.length !== 0 ? filter.values.join(', ') : filter.value;
    }

    if (filter.selectedSingleValue) {
      return filter.selectedSingleValue.text;
    }

    return null;
  };

  // article number extraction filter
  if (filter.attribute.masterDataType && filter.attribute.masterDataType === 'SPM_article') {
    return <UploadFilterArticleNumber filter={filter} />;
  }

  if (filter.isPreset) {
    return <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">{getDisplayValue(filter)}</div>;
  }

  // filter is of type date/datetime
  if (filter.attribute.type && (filter.attribute.type === 'date' || filter.attribute.type === 'datetime')) {
    return (
      <div className="space-m-2">
        <DateTimePicker
          value={filter.value}
          className={filter.value || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
          minFilterWidth="15rem"
          closeWhenItemSelect={true}
          disableLabel
          onChangeValue={(val) => {
            filter.changeValue(val);
          }}
        />
      </div>
    );
  }

  return (
    <Dropdown
      value={getDisplayValue(filter) ?? t('common.select')}
      className={filter.valueOrValues || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
      minFilterWidth="15rem"
      disableLabel
      // closeWhenItemSelect={filter.attribute.type === 'boolean'}
    >
      <AssetsFilterPopup
        attributeName={filter.attribute.name}
        group={filter.groupName}
        type={filter.attribute.type ?? filter.attribute.masterDataType}
        list={filter.asList}
        items={filter.referenceItems as Array<IAssetsFilterItem>}
        selectedItems={filter.selectedReferenceItems}
        isLoading={filter.referenceItemsLoading}
        error={filter.referenceItemsError}
        selectedValue={filter.value}
        selectedValues={filter.values}
        multipleChoices={filter.asList}
        onChangeValue={(val) => filter.changeValue(val)}
        onToggleSelectedItem={(item) => filter.toggleSelectedReference(item as ReferenceDataItem)}
        onToggleSingleSelectedItem={(item) => {
          filter.toggleSingleSelectedReference(item as ReferenceDataItem);
        }}
        onClearAllClick={() => filter.clearAllSelectedValues()}
        onMultipleRemoveItem={(val) => {
          filter.removeMultipleValue(val);
        }}
      />
    </Dropdown>
  );
});

export default UploadArchiveFilterDropdown;
