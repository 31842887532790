/* eslint-disable complexity */
import {
  AppVariantEnum,
  AssetDomainsNamesEnums,
  ErrorMessage,
  EventTypeEnum,
  FileSystemItemEnum,
  HandledError,
  IDigitalContentAssignment,
  IMetadata,
  IMetadataAttribute,
  IMetadataAttributeGroup,
  IMultipartCommit,
  IMultipartUploadInit,
  IUploadResponse,
  LogLevelEnum,
  Name,
} from '@cdam/shared';
import { v4 } from 'uuid';
import jwt from 'jwt-decode';
import { BrandCodes } from 'stores/upload/UploadProductImagesStore';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import { IUploadFile } from '../stores/upload/UploadStore';
import ClientHttpService, { UrlType } from '../services/ClientHttpService';
import AuthStore from '../stores/auth/AuthStore';
import ViewStore from '../stores/view/ViewStore';
import { metadataOperation, UploadType } from '../stores/uploadDownloadManager/data/UploadItem';
import LoggingService from '../services/LoggingService';
import DialogStore from '../stores/dialog/DialogStore';
import { AssetCategoryMetadataValues, ImageStyleMetadataValues, UsageMetadataValues } from './enums/MetadataEnums';
import Formatter from './Formatter';
import FileUtils from './FileUtils';

export default {
  getMaxUploadSize(): number {
    return 1048576 * 5;
  },
  getChunkSize(): number {
    return 1048576 * 25;
  },
  getUploadUrl(): string {
    return `${ViewStore.uploadUrl}/${ViewStore.uploadClientName}/${ViewStore.uploadAssetKindName}/${ViewStore.uploadAssetGroup}`;
  },
  getHeaders(file: IUploadFile): Headers {
    const headers = new Headers();

    headers.append('x-original-file-name', file.name);
    headers.append('x-original-extension', file.extension);
    headers.append('x-file-size', file.file.size.toString());
    headers.append('x-checksum', file.checksum);

    return headers;
  },
  async uploadFile(file: IUploadFile, uploadType?: UploadType): Promise<boolean> {
    let repositoryItem: IUploadResponse | null = null;
    let currentMetadata: IMetadata | null = null;

    try {
      LoggingService.log({
        message: 'Uploading new file',
        level: LogLevelEnum.INFO,
        event: EventTypeEnum.FILE_UPLOAD,
        file: { name: file.name, size: file.file.size, extension: file.extension, type: FileSystemItemEnum.FILE },
      });

      if (file.file.size > this.getMaxUploadSize()) {
        repositoryItem = await this.multipartUpload(file);
      } else {
        repositoryItem = await this.simpleUpload(file);
      }
    } catch (e) {
      const error = new HandledError(`${ErrorMessage.ApiError} Could not upload file to the repository`, undefined, undefined, (e as Error).stack);

      LoggingService.log({ message: error.message, level: LogLevelEnum.ERROR, event: EventTypeEnum.FILE_UPLOAD }, error);

      throw error;
    }

    if (!repositoryItem) {
      return false;
    }

    const {
      _links: {
        self: { href },
      },
      assetKey,
    } = repositoryItem;

    try {
      if (!file.metadataId || file.metadataId.length === 0) {
        currentMetadata = await this.createMetadata(file, href, uploadType);
      } else {
        currentMetadata = await ClientHttpService.fetch<IMetadata>({
          urlType: UrlType.Metadata,
          url: `/metadata/${file.metadataId}`,
          method: 'GET',
        });
      }
    } catch (e) {
      await this.deleteAsset(href);

      const error = new HandledError(
        `${ErrorMessage.UnhandledError}, Failed to create metadata. Uploaded item was deleted.`,
        undefined,
        undefined,
        (e as Error).stack,
      );

      LoggingService.log({ message: error.message, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_CREATE }, error);

      throw error;
    }

    if (!currentMetadata) {
      return false;
    }

    let metadataWithDigitalContent: IDigitalContentAssignment | null = null;

    try {
      metadataWithDigitalContent = await this.assignDigitalContent(currentMetadata.id, currentMetadata.etag, href, assetKey, file);
    } catch (e) {
      if (!file.metadataId || file.metadataId.length === 0) {
        await this.deleteMetadata(currentMetadata);
      }

      await this.deleteAsset(href);

      throw new HandledError(`${ErrorMessage.ApiError}, Failed to assign asset to metadata. Uploaded asset and data was deleted.`);
    }

    if (!metadataWithDigitalContent) {
      return false;
    }

    const { etag: updatedEtag, fileName, version } = metadataWithDigitalContent;

    try {
      await this.metadataFullUpdate(
        currentMetadata.id,
        updatedEtag,
        currentMetadata.attributeGroupList,
        metadataOperation.FullUpdate,
        version,
        fileName,
      );

      // ProfileStore.updateUploadItem({
      //   assetName: file.name,
      //   uploadDate: new Date().toISOString(),
      //   link: `/details/${currentMetadata.id}`,
      //   status: 'success',
      // });
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Could not update metadata`);

      // ProfileStore.updateUploadItem({ assetName: file.name, uploadDate: new Date().toISOString(), status: 'fail' });
    }

    // TODO: handle metadata revert
    return true;
  },
  async simpleUpload(file: IUploadFile): Promise<IUploadResponse | null> {
    const headers = this.getHeaders(file);

    const form = new FormData();

    form.append('file', file.file);

    let response: IUploadResponse | null = null;

    try {
      response = await ClientHttpService.fetch<IUploadResponse>({
        urlType: UrlType.Raw,
        url: `${this.getUploadUrl()}`,
        headers,
        method: 'POST',
        body: form,
      });
    } catch (e) {
      throw new HandledError(`${ErrorMessage.UnhandledError}, Failed to upload a file`);
    }

    return response;
  },
  async multipartUpload(file: IUploadFile): Promise<IUploadResponse | null> {
    let response: IUploadResponse | null = null;

    const uploadInitResponse = await this.uploadInitiation(file);

    if (!uploadInitResponse) {
      return null;
    }

    const { assetKey, uploadId } = uploadInitResponse;

    try {
      const abortController = new AbortController();
      const { signal } = abortController;
      let uploadIndex = 1;

      for (let chunkIndex = 0; chunkIndex < file.file.size; chunkIndex += this.getChunkSize()) {
        const chunk = file.file.slice(chunkIndex, chunkIndex + this.getChunkSize());

        // For-loop implemented to limit heap size
        // eslint-disable-next-line no-await-in-loop
        await this.uploadChunk(chunk, assetKey, uploadId, uploadIndex, signal, abortController);
        ++uploadIndex;
      }

      response = await this.multipartCommit(uploadId, assetKey);
    } catch (e) {
      this.cancelUpload(assetKey, uploadId);

      throw new HandledError(`${ErrorMessage.UnhandledError}, Failed to execute multipart upload`);
    }

    return response;
  },
  async uploadInitiation(file: IUploadFile): Promise<IMultipartUploadInit | null> {
    const headers = this.getHeaders(file);

    let json: IMultipartUploadInit | null = null;

    try {
      json = await ClientHttpService.fetch({
        urlType: UrlType.Raw,
        url: `${this.getUploadUrl()}/upload`,
        headers,
        method: 'POST',
      });
    } catch (e) {
      throw new HandledError(`${ErrorMessage.UnhandledError}, Failed to initialize upload process`);
    }

    return json;
  },
  async uploadChunk(
    chunk: Blob,
    assetKey: string,
    uploadId: string,
    chunkIndex: number,
    signal: AbortSignal,
    controller: AbortController,
  ): Promise<void> {
    const form = new FormData();

    form.append('file', chunk);

    try {
      await ClientHttpService.fetch({
        urlType: UrlType.Raw,
        url: `${this.getUploadUrl()}/uploads/${assetKey}/${uploadId}/${chunkIndex}`,
        method: 'PUT',
        signal,
        body: form,
      });
    } catch (e) {
      controller.abort();

      throw new HandledError(`${ErrorMessage.UnhandledError}, Failed to upload a file`);
    }
  },
  async multipartCommit(uploadId: string, assetKey: string): Promise<IUploadResponse | null> {
    let response: IUploadResponse | null = null;

    try {
      response = await ClientHttpService.fetch<IMultipartCommit>({
        urlType: UrlType.Raw,
        url: `${this.getUploadUrl()}/uploads/${assetKey}/${uploadId}`,
        method: 'PUT',
      });
    } catch (e) {
      // TODO: log and report
      // eslint-disable-next-line
      console.error('Could not cancel upload while uploading upload ID ${uploadId}');
    }

    return response;
  },
  async cancelUpload(assetKey: string, uploadId: string): Promise<void> {
    try {
      await ClientHttpService.fetch({
        urlType: UrlType.Raw,
        url: `${this.getUploadUrl()}/${assetKey}/${uploadId}`,
        method: 'DELETE',
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Could not cancel upload process`);
    }
  },
  async createMetadata(file: IUploadFile, assetUrl?: string, uploadType?: UploadType): Promise<IMetadata | null> {
    const attributeGroupList = this.combineFilters(file);
    const { email, marvinUsername } = this.getUniqUserNameAndEmail();

    if (ViewStore.appVariant === AppVariantEnum.GateUI) {
      this.injectValueToMetadata(attributeGroupList, Name.General, 'active', 'true');
      this.injectValueToMetadata(attributeGroupList, Name.General, 'createdBy', marvinUsername);
      this.injectValueToMetadata(attributeGroupList, Name.General, 'checkInUser', marvinUsername);
      this.injectValueToMetadata(attributeGroupList, Name.General, 'createdOn', new Date().toISOString());
      this.injectValueToMetadata(attributeGroupList, Name.General, 'lastModifiedBy', marvinUsername);
      this.injectValueToMetadata(attributeGroupList, Name.General, 'lastModifiedOn', this.getDate(false));
      this.injectValueToMetadata(attributeGroupList, Name.AssetInformation, 'assetName', file.name);
      email && this.injectValueToMetadata(attributeGroupList, Name.Miscellaneous, 'contact', email);

      // Data e2e flow expects to have Owners set: marvin expects emea\{username} CDAM expects the unique, e.g. emea.biz....\username
      marvinUsername && this.injectValueToMetadata(attributeGroupList, Name.Miscellaneous, 'owners', [marvinUsername]);

      if (uploadType && uploadType === UploadType.PI) {
        this.injectValueToMetadata(attributeGroupList, Name.General, 'assetGroup', '9');
        this.injectValueToMetadata(attributeGroupList, Name.General, 'policy', this.getPolicyValue(file));
        file.brand && this.injectValueToMetadata(attributeGroupList, Name.AssetInformation, 'brand', file.brand.toString());
      }
    } else if (ViewStore.selectedAssetDomain === AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME) {
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'assetName', file.name);
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'archiveDate', this.getDate(true));
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'uploadedBy', marvinUsername);
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedBy', marvinUsername);
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedOn', this.getDate(true));
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'fileType', FileUtils.composeFileExtensionFromBlob(file.file));
    } else if (ViewStore.selectedAssetDomain === AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME) {
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'assetName', file.name);
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'archiveDate', this.getDate(true));
      this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'updatedDate', this.getDate(false));
    }

    let metadata: IMetadata | null = null;

    try {
      metadata = await ClientHttpService.fetch({
        urlType: UrlType.Metadata,
        url: '/metadata',
        method: 'POST',
        body: { attributeGroupList },
      });
    } catch (e) {
      if (assetUrl) {
        this.deleteAsset(assetUrl);
      }

      throw new HandledError(`${ErrorMessage.UnhandledError}, Could not create metadata`);
    }

    return metadata;
  },
  async deleteMetadata(metadata: IMetadata): Promise<void> {
    await this.metadataFullUpdate(metadata.id, metadata.etag, metadata.attributeGroupList, metadataOperation.Delete, undefined, undefined);

    try {
      await ClientHttpService.fetch<IMetadata>({
        urlType: UrlType.Metadata,
        url: `/metadata/${metadata.id}`,
        method: 'DELETE',
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Could not delete metadata`);
    }
  },
  async metadataFullUpdate(
    metadataId: string,
    etag: string,
    attributeGroupList: Array<IMetadataAttributeGroup> = [],
    operation: metadataOperation,
    activeDigitalContent: number | undefined,
    fileName: string | undefined,
  ): Promise<IMetadata> {
    const { marvinUsername } = this.getUniqUserNameAndEmail();

    switch (ViewStore.selectedAssetDomain) {
      case AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
        if (operation === metadataOperation.Delete) {
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'updatedDate', this.getDate());
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'deleted', 'true');
        } else if (operation === metadataOperation.FullUpdate) {
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'archiveDate', this.getDate(true));
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'updatedDate', this.getDate());
        } else if (operation === metadataOperation.EditMetadata) {
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'updatedDate', this.getDate());
        }

        break;
      case AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        if (operation === metadataOperation.Delete) {
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedBy', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedOn', this.getDate(true));
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'deleted', 'true');
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'deletedOn', this.getDate(true));
        } else if (operation === metadataOperation.FullUpdate) {
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedBy', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedOn', this.getDate(true));
        } else if (operation === metadataOperation.EditMetadata) {
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedBy', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.ArchiveAssetInformation, 'lastModifiedOn', this.getDate(true));
        }

        break;
      case AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME:
      default:
        if (operation === metadataOperation.Delete) {
          this.injectValueToMetadata(attributeGroupList, Name.General, 'deleted', 'true');
          this.injectValueToMetadata(attributeGroupList, Name.General, 'deletedBy', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.General, 'deletedOn', new Date().toISOString());
        } else if (operation === metadataOperation.FullUpdate) {
          this.injectValueToMetadata(attributeGroupList, Name.General, 'XMarvinUID', `MRV4-${metadataId}`);
          this.injectValueToMetadata(attributeGroupList, Name.AssetInformation, 'assetName', fileName ?? v4());
          this.injectValueToMetadata(attributeGroupList, Name.General, 'AssetMetadataVersion', activeDigitalContent?.toString() ?? '0');
          this.injectValueToMetadata(attributeGroupList, Name.General, 'lastModifiedBy', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.General, 'checkInUser', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.General, 'lastModifiedOn', this.getDate(false));
        } else if (operation === metadataOperation.EditMetadata) {
          this.injectValueToMetadata(attributeGroupList, Name.General, 'lastModifiedBy', marvinUsername);
          this.injectValueToMetadata(attributeGroupList, Name.General, 'lastModifiedOn', this.getDate(false));
        }
    }

    const body = {
      etag,
      attributeGroupList,
    };

    let metadata: IMetadata | null = null;

    try {
      metadata = await ClientHttpService.fetch<IMetadata>({
        urlType: UrlType.Metadata,
        url: `/metadata/${metadataId}`,
        method: 'PUT',
        body,
      });
    } catch (e) {
      // TODO: reset metadata
      // TODO: handle errors in metadata service, eg. outdatedError on root is etag error
      const error = e as HandledError;

      throw new HandledError(`${error.message}, Could not update metadata`);
    }

    return metadata;
  },
  async editMetadata(metadataId: string, etag: string, itemFilters: Array<AssetFilter>): Promise<IMetadata> {
    const filters = this.combineFilters(undefined, itemFilters);

    try {
      return await this.metadataFullUpdate(metadataId, etag, filters, metadataOperation.EditMetadata, undefined, undefined);
    } catch (e) {
      DialogStore.error(e);

      return {} as IMetadata;
    }
  },
  combineFilters(file: IUploadFile | undefined, editFilters?: Array<AssetFilter>): Array<IMetadataAttributeGroup> {
    const attributeGroupList: Array<IMetadataAttributeGroup> = [];

    const filters = file ? [...file.presetUploadFilters, ...file.displayedUploadFilters] : editFilters ? editFilters : [];

    for (const filter of filters) {
      const {
        attribute: { name, list, referenceType: filterHasType },
        groupName: filterGroupName,
        selectedReferenceItems,
        selectedSingleValue,
        values,
        value,
      } = filter;

      const isList = list === 'true';
      let attributeValueArray: Array<string> = [];

      // Values are set according to business requirements that are still being defined,
      // so all possible values have to be checked
      if (filterHasType && selectedSingleValue) {
        attributeValueArray.push(selectedSingleValue.referenceData.id);
      } else if (!filterHasType && value) {
        attributeValueArray.push(value);
      } else if (filterHasType && selectedReferenceItems.length !== 0) {
        attributeValueArray = selectedReferenceItems.map((referenceItem) => referenceItem.referenceData.id);
      } else if (!filterHasType && values.length !== 0) {
        attributeValueArray = values;
      }

      if (attributeValueArray.length) {
        const groupIndex = attributeGroupList.findIndex((group) => group.name === filterGroupName);
        const newAttribute: IMetadataAttribute = { name };

        if (isList) {
          newAttribute.values = attributeValueArray;
        } else {
          newAttribute.value = attributeValueArray[0];
        }

        if (groupIndex === -1) {
          attributeGroupList.push({ name: filterGroupName as Name, attributeList: [newAttribute] });
        } else {
          const attributeIndex = attributeGroupList[groupIndex].attributeList.findIndex((attribute) => attribute.name === name);

          if (attributeIndex === -1) {
            attributeGroupList[groupIndex].attributeList.push(newAttribute);
          } else {
            // TODO: check if typeof existing and new values is same, if not throw exception
            const attribute = attributeGroupList[groupIndex].attributeList[attributeIndex];

            // NOTE: The code below is just a safety measure and should be prevented by the UI on the matrix/template level
            // If the taxonomy allows an attribute to have a list, e.g. true, then the attribute values are the combined
            // unique values, else override the previous value with the last filter, so that upload filters get
            // preference treatment.
            if (!isList) {
              attribute.value = attributeValueArray[0];
            } else {
              attribute.values = this.getUniqueAttributeValues(attribute.values, attributeValueArray);
            }
          }
        }
      }
    }

    return attributeGroupList;
  },
  getUniqueAttributeValues(attributeValues: Array<string> | undefined, newValue: Array<string>): Array<string> {
    return [...(attributeValues ?? []), ...newValue].reduce((acc: Array<string>, attr) => {
      if (!acc.find((valInAcc) => valInAcc === attr)) {
        acc.push(attr);
      }

      return acc;
    }, []);
  },
  getUniqUserNameAndEmail(): { userName: string; email: string; marvinUsername: string } {
    let userName = '';
    let email = '';
    let marvinUsername = '';

    if (AuthStore.token) {
      const { sAMAccountName: accountName, distinguishedname, email: mail } = jwt<{
        sAMAccountName: string;
        distinguishedname: string;
        email: string;
      }>(AuthStore.token);

      email = mail || '';

      if (accountName) {
        marvinUsername = `emea\\${accountName}`;
      }

      const distinguishedNameParts: Array<string> | null = distinguishedname.match(/DC=\w+/g);
      const parsedDistinguishedNameParts = distinguishedNameParts?.map((rawPart) => {
        const valuePart = rawPart.split('=');

        return valuePart.length > 1 ? valuePart[1] : rawPart;
      });

      userName = parsedDistinguishedNameParts ? `${parsedDistinguishedNameParts.join('.')}\\${accountName}` : email;
    }

    return { userName, email, marvinUsername };
  },
  injectValueToMetadata(
    attributeGroupList: Array<IMetadataAttributeGroup>,
    groupName: Name,
    attributeName: string,
    value: Array<string> | string,
  ): void {
    const indexOfGroup = attributeGroupList.findIndex((group) => group.name === groupName);
    const newAttribute: IMetadataAttribute = { name: attributeName };

    if (Array.isArray(value)) {
      newAttribute.values = value;
    } else {
      newAttribute.value = value;
    }

    if (indexOfGroup === -1) {
      attributeGroupList.push({ name: groupName, attributeList: [newAttribute] });
    } else {
      const attributeList = attributeGroupList[indexOfGroup].attributeList;
      const index = attributeList.findIndex((attr) => attr.name === attributeName);

      if (index === -1) {
        attributeGroupList[indexOfGroup].attributeList.push(newAttribute);
      } else if (attributeList[index].name.toLowerCase() === 'owners') {
        attributeGroupList[indexOfGroup].attributeList[index].values = [
          ...(attributeList[index].values ?? []),
          ...((Array.isArray(value) && value) || []),
        ];
      } else {
        attributeGroupList[indexOfGroup].attributeList[index] = newAttribute;
      }
    }
  },
  async assignDigitalContent(
    metadataId: string,
    etag: string,
    uploadUrl: string,
    assetKey: string,
    file: IUploadFile,
  ): Promise<IDigitalContentAssignment | null> {
    let digitalContentAssignment: IDigitalContentAssignment | null = null;

    const { marvinUsername } = this.getUniqUserNameAndEmail();

    try {
      const body = {
        storage: ViewStore.uploadUrl,
        storageId: assetKey,
        storageURL: uploadUrl.startsWith('http') ? uploadUrl : `${ViewStore.repositoryUrl}${uploadUrl}`,
        etag,
        fileSize: file.size,
        fileName: file.name,
        uploadTime: new Date().toISOString(),
        checksum: file.checksum,
        attributeList: [
          {
            name: 'Size',
            value: Formatter.getFileSizeFromBytes(file.size, 1),
          },
          {
            name: 'File Format',
            value: file.extension,
          },
          {
            name: 'File Size Bytes',
            value: file.size,
          },
          {
            name: 'File Name',
            value: file.name,
          },
          {
            name: 'File Updated',
            value: new Date().toISOString(),
          },
          {
            name: 'createdBy',
            value: marvinUsername,
          },
        ],
      };

      digitalContentAssignment = await ClientHttpService.fetch({
        urlType: UrlType.Metadata,
        url: `/metadata/${metadataId}/digitalContent`,
        method: 'POST',
        body,
      });
    } catch (e) {
      throw new HandledError(`${ErrorMessage.UnhandledError} Could not assign digital content to metadata.`);
    }

    return digitalContentAssignment;
  },
  async deleteAsset(assetUrl: string): Promise<void> {
    const url = assetUrl.startsWith('http') ? assetUrl : `${ViewStore.uploadUrl}${assetUrl}`;

    try {
      await ClientHttpService.fetch({
        urlType: UrlType.Raw,
        url,
        method: 'DELETE',
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Could not delete asset`);
    }
  },
  getPolicyValue(file: IUploadFile): string {
    if (file.brand) {
      const assetCategoryMetadata = file.presetUploadFilters
        .find((filter) => filter.groupName === 'Asset Information' && filter.attribute.name === 'assetCategory')
        ?.getMetadataSearchValueList();

      if (file.brand === BrandCodes.ADIDAS) {
        const imageStyleMetadata = file.presetUploadFilters
          .find((filter) => filter.groupName === 'Asset Information' && filter.attribute.name === 'imageStyle')
          ?.getMetadataSearchValueList();

        const usageMetadata = file.presetUploadFilters
          .find((filter) => filter.groupName === 'Asset Information' && filter.attribute.name === 'usage')
          ?.getMetadataSearchValueList();

        if (assetCategoryMetadata?.find((am) => am.value === AssetCategoryMetadataValues.DesignSketch)) {
          return 'adidas_DesignUpload';
        } else if (imageStyleMetadata?.find((ism) => ism.value === ImageStyleMetadataValues.OnModel)) {
          if (
            usageMetadata?.find(
              (um) =>
                um.value === UsageMetadataValues.ECommerce ||
                um.value === UsageMetadataValues.ECommerceLocal ||
                um.value === UsageMetadataValues.ECommerceEU ||
                um.value === UsageMetadataValues.ECommerceUS ||
                um.value === UsageMetadataValues.ECommerceHBS,
            )
          ) {
            return 'adidas_eCom_OnModel';
          }
        }

        return 'default';
      } else if (file.brand === BrandCodes.REEBOK) {
        if (assetCategoryMetadata?.find((am) => am.value === AssetCategoryMetadataValues.DesignSketch)) {
          return 'Reebok_DesignUpload';
        }

        return 'Reebok_default';
      }
    }

    return 'default';
  },
  getDate(dateOnly = false): string {
    const date = new Date().toISOString();

    return dateOnly ? date.split('T')[0] : date;
  },
};
