import { IMetadataSearchAttribute } from '@cdam/shared';
import { AssetFilter } from 'stores/assets/data/AssetFilter';

export default {
  findAndPreselectFilters(filters: Array<IMetadataSearchAttribute>, lookupFilters: Array<AssetFilter>): Array<AssetFilter> {
    const ret: Array<AssetFilter> = [];

    filters.forEach((f) => {
      const filter = lookupFilters.find((x) => x.groupName === f.group && x.attribute.name === f.name);

      if (filter) {
        filter.clearAllSelectedValues();

        if (f.valueList) {
          f.valueList.forEach((val) => {
            filter.changeValue(val.value);
          });
        }

        if (f.mandatory !== undefined) {
          filter.setMandatory(f.mandatory);
        }

        if (f.isDisabled !== undefined) {
          filter.setDisabled(f.isDisabled);
        }

        ret.push(filter);
      }
    });

    return ret;
  },
  filtersToMetadataAttributes(filters: Array<AssetFilter>): Array<IMetadataSearchAttribute> {
    const ret: Array<IMetadataSearchAttribute> = [];

    filters.forEach((gf) => {
      const att: IMetadataSearchAttribute = { group: gf.groupName, name: gf.attribute.name, valueList: [] };

      if (gf.isMandatory !== undefined) {
        att.mandatory = gf.isMandatory;
      }

      if (gf.isDisabled !== undefined) {
        att.isDisabled = gf.isDisabled;
      }

      gf.getMetadataSearchValueList().forEach((vl) => att.valueList?.push(vl));
      ret.push(att);
    });

    return ret;
  },
  toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    const existingIndex = filters.indexOf(item);

    if (existingIndex === -1) {
      filters.push(item);

      return true;
    }

    item.clearAllSelectedValues();
    filters.splice(existingIndex, 1);

    return false;
  },
};
