import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IMetadataDigitalContent, AssetType, AssetContentType, AppVariantEnum, FeatureFlagsEnum } from '@cdam/shared';
import { useTranslation } from 'react-i18next';
import Icon from '../../../../components/icons/Icon';
import Button, { ButtonType, ButtonSize } from '../../../../components/input/Button';
import DownloadUtils from '../../../../utils/DownloadUtils';
import AssetUtils from '../../../../utils/AssetUtils';
import DetailsStore, { IDisplayItem } from '../../../../stores/assets/DetailsStore';
import { UploadStore } from '../../../../stores/upload/UploadStore';
import VersioningUploadZone from '../../../../components/fileUpload/VersioningUploadZone';
import ViewStore from '../../../../stores/view/ViewStore';
import { FeatureFlag } from '../../../../components/feature-flag';
import AssetsInfo from '../components/AssetsInfo';
import AssetsHistory from '../components/AssetsHistory';

import './AsideDetails.scss';

interface IAsideDetails {
  digitalContent: IMetadataDigitalContent | undefined;
  displayItem: IDisplayItem;
  assetType: AssetType;
  contentType: AssetContentType | null;
  detailsStore: DetailsStore;
}

// eslint-disable-next-line complexity
const AsideDetails: React.FC<IAsideDetails> = observer(({ digitalContent, displayItem, assetType, contentType, detailsStore }) => {
  const { t } = useTranslation();
  const [hideSideBar, sethideSideBar] = useState(false);
  const [details, setDetailsActive] = useState(true);

  const onArticleNavigateClick = (): void => {
    detailsStore.openAssetDetailsPage(displayItem.item, AssetType.Article);
  };

  const isSubscribed = detailsStore.isSubscribed();

  const canBeDeleted =
    detailsStore.canBeEdited() &&
    ((detailsStore.displayItem &&
      detailsStore.assetType === AssetType.Asset &&
      AssetUtils.isAssetOwnerAndHasCorrectUserType(detailsStore.displayItem.item, contentType)) ||
      ViewStore.canEdit);

  const editable =
    detailsStore.canBeEdited() &&
    (AssetUtils.isAssetOwnerAndHasCorrectUserType(detailsStore.displayItem?.item ?? null, contentType) || ViewStore.canEdit);
  const [uploadStore] = useState(() => new UploadStore('AssetHistory', undefined));

  return (
    <React.Fragment>
      <div className="AsideDetails-aside-content-close" onClick={() => sethideSideBar(!hideSideBar)}>
        <Icon icon={`${!hideSideBar ? 'icon-arrow-dropdown-right' : 'icon-arrow-dropdown-left'}`} color="#737373" height={30} width={30} />
      </div>
      {!hideSideBar && (
        <div className="AsideDetails-aside-content">
          <div className="AsideDetails-content-header space-p-t-4 space-p-l-3 space-p-r-4">
            <div className="AsideDetails-content-header-icons">
              <div
                className="AssetsDetails-icon"
                onClick={async () => detailsStore.assetType && detailsStore.handleNotificationClick(detailsStore.assetType)}
              >
                {ViewStore.appVariant === AppVariantEnum.GateUI && (
                  <>
                    <Icon icon={'icon-notifications'} color={isSubscribed ? '#000' : '#737373'} height={30} width={30} />
                    {isSubscribed && <div className="AssetsDetails-notification-indicator" />}
                  </>
                )}
              </div>
              {canBeDeleted && (
                <div className="AssetsDetails-content-header-delete">
                  <div className="AssetsDetails-header-icon-separator space-m-2" />
                  <div className="AssetsDetails-icon" onClick={() => detailsStore.deleteAssetConfirmation()}>
                    <Icon icon={'icon-delete'} color="#737373" height={30} width={30} />
                  </div>
                </div>
              )}
              {editable && (
                <FeatureFlag feature={FeatureFlagsEnum.EditSingle}>
                  <div className="AssetsDetails-content-header-delete">
                    <div className="AssetsDetails-header-icon-separator space-m-2" />
                    <div className="AssetsDetails-icon" onClick={() => detailsStore.openMetadataEditor()}>
                      <Icon icon={'icon-edit'} color={'#737373'} height={30} width={30} />
                    </div>
                  </div>
                </FeatureFlag>
              )}
            </div>
            <Button
              buttonType={ButtonType.Primary}
              size={ButtonSize.Default}
              onClick={() => {
                if (detailsStore.assetType === AssetType.Article && detailsStore.displayItem) {
                  detailsStore.openAdvancedDownloadDialog(detailsStore.displayItem.articleItems);
                } else {
                  DownloadUtils.handleItemDownload(null, detailsStore.downloadUrl, DownloadUtils.getFileNameWithExtension(digitalContent));
                }
              }}
            >
              {detailsStore.assetType === AssetType.Article ? t('details.download_all').toUpperCase() : t('details.download').toUpperCase()}
            </Button>
          </div>
          {AssetUtils.isAssetOwnerAndHasCorrectUserType(displayItem.item, contentType) && (
            <VersioningUploadZone
              store={uploadStore}
              className={'space-m-v-4 space-m-h-4'}
              detailsStore={detailsStore}
              metadataFileName={AssetUtils.getAssetName(displayItem.item)}
              metadataId={displayItem.item.asset.id}
            />
          )}
          <div className="space-p-t-4 space-p-l-4 AsideDetails-aside-content-main">
            <div className="AsideDetails-aside-details space-p-r-4">
              {digitalContent && assetType === AssetType.Asset && (
                <div className="AsideDetails-aside-details-header">
                  <div
                    className={`AsideDetails-tab-bar-item  ${details && 'AsideDetails-tab-bar-item-active'}`}
                    onClick={() => setDetailsActive(true)}
                  >
                    <strong>{t('details.details_tab').toLocaleUpperCase()}</strong>
                  </div>
                  <div
                    className={`AsideDetails-tab-bar-item  ${!details && 'AsideDetails-tab-bar-item-active'}`}
                    onClick={() => setDetailsActive(false)}
                  >
                    <strong>{t('details.history_tab').toLocaleUpperCase()}</strong>
                  </div>
                </div>
              )}
              {details && displayItem.item ? (
                <AssetsInfo
                  assetType={assetType}
                  item={displayItem}
                  detailsStore={detailsStore}
                  contentType={contentType}
                  onNavigateClick={onArticleNavigateClick}
                />
              ) : (
                displayItem.item && digitalContent && <AssetsHistory item={displayItem.item} />
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
});

export default AsideDetails;
