import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import AssetsStore from 'stores/assets/AssetsStore';
import { AssetData } from 'stores/assets/data/AssetData';
import { AssetContentType, AssetType, ContextAction, ISelectedType, UIAssetDomainsNamesEnums } from '@cdam/shared';
import AssetTypeSelector from 'components/coredam/AssetTypeSelector';
import { IDisplayArticles } from 'utils/AssetDisplayUtils';
import { Draggable } from 'components/dragdrop/Draggable';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import UtilityStore from 'stores/utility/UtilityStore';
import Sortable from '../../../../components/sort/Sortable';
import TableListViewConfig from '../../../../configs/TableListViewConfig';
import ViewStore from '../../../../stores/view/ViewStore';
import ArticleRowView from './ArticleRowView';
import AssetRowView from './AssetRowView';
import ContextMenuModal from './ContextMenuView';
import ActionMenuModal from './ActionMenuView';

import './TableListView.scss';

interface ITableListViewProps {
  store: AssetsStore;
  treeNumber?: number;
  checkClass?: string;
  hideLine?: boolean;
  items: Array<AssetData>;
  articleItems?: Array<IDisplayArticles>;
  type: ISelectedType | null;
  displayType: AssetType | null;
}

interface IEditIndex {
  newIndex: number;
  move: string;
}

// eslint-disable-next-line complexity
const TableListView: React.FC<ITableListViewProps> = observer(({ store, type, hideLine, treeNumber, displayType, articleItems, items }) => {
  const handleCheckbox = (item: AssetData): void => {
    store.toggleAsset(item);
  };

  const [draggedItem, setDraggedItem] = useState(null as unknown | null);
  const [onHover, setOnHover] = useState(null as number | null);
  const [editIndex, setEditIndex] = useState(null as IEditIndex | null);

  const openAssetDetails = (item: AssetData, assetType: AssetType): void => {
    store.openAssetDetailsDialog(item, assetType);
  };

  const isChecked = (items: Array<AssetData>): boolean => items.every((e) => store.selectedAssets.includes(e));

  const onEditClick = (e: React.MouseEvent, index: number): void => {
    e.preventDefault();

    store.handleContextMenu(true, e.clientX, e.clientY, index);
  };

  let displayValues: Array<AssetFilter> = [];

  const groupIndex = UtilityStore.listViewCols.findIndex((e) => e.assetGroup === (store.UIAssetDomainFromAssetContent as string));

  if (groupIndex !== -1) {
    if (displayType === AssetType.Article) {
      displayValues = UtilityStore.listViewCols[groupIndex].articleColumns.map((e) => e);
    } else {
      displayValues = UtilityStore.listViewCols[groupIndex].assetColumns.map((e) => e);
    }
  }

  const columnEditable = ViewStore.selectedUiAssetDomain === UIAssetDomainsNamesEnums.MARVIN_MCT;

  let rows;

  if (type && displayType === AssetType.Article && articleItems) {
    rows = articleItems.map((item, index) => (
      <ArticleRowView
        key={index}
        store={store}
        type={type && type}
        treeNumber={treeNumber && treeNumber}
        checked={isChecked(item.items)}
        items={item}
        openAssetDetails={() => openAssetDetails(item.items[0], AssetType.Article)}
        hideLine={hideLine && hideLine}
      />
    ));
  } else if (type && displayType === AssetType.Asset && items) {
    rows = items.map((item, index) => (
      <AssetRowView
        item={item}
        key={index}
        highlightAction={store.contextAction?.contextMenu ? store.contextAction.index : null}
        assetType={AssetType.Asset}
        assetContetType={store.itemsType}
        checked={store.selectedAssets.includes(item)}
        openAssetDetails={() => openAssetDetails(item, AssetType.Asset)}
        treeNumber={treeNumber && treeNumber}
        onChecked={() => handleCheckbox(item)}
      />
    ));
  }

  const getTreeClass = (treeNumber: number): string => {
    if (treeNumber === 2) {
      return 'second-square';
    } else if (treeNumber === 3) {
      return 'third-square';
    }

    return 'first-square';
  };

  const handleMouseHover = (index?: number): void => {
    if (index !== undefined) {
      setOnHover(index);

      return;
    }

    setOnHover(null);
  };

  return (
    <React.Fragment>
      <table className="TableListView">
        {treeNumber && <div className="TableListView-verticalLine" />}
        {store.contextAction?.actionMenu && (
          <ActionMenuModal
            clientX={store.contextAction.positionX}
            clientY={store.contextAction.positionY}
            actionMenu={store.contextAction.actionMenu}
            actionType={store.contextAction.actionType}
            itemsType={store.itemsType}
            items={store.filtersByType}
            onToggleSingleSelectedItem={(item: AssetFilter) => {
              store.contextAction && store.handleEditAction(store.contextAction?.actionType, item);
            }}
            onHide={() => {
              setEditIndex(null);
              store.handleActionMenu(false);
            }}
          />
        )}
        {store.contextAction?.contextMenu && (
          <ContextMenuModal
            clientX={store.contextAction.positionX}
            clientY={store.contextAction.positionY}
            contextMenu={store.contextAction.contextMenu}
            columnsLenght={UtilityStore.listViewCols[groupIndex].assetColumns.length}
            store={store}
            onHide={() => {
              setEditIndex(null);
              store.handleContextMenu(false);
            }}
            onClick={(type: ContextAction) => {
              if (type === ContextAction.Remove) {
                store.handleEditAction(type);
                store.handleContextMenu(false);

                return;
              } else if (type === ContextAction.Add && store.contextAction) {
                setEditIndex({ newIndex: store.contextAction.index, move: 'right' });
              }

              store.handleActionMenu(true, type);
              store.handleContextMenu(false);
            }}
          />
        )}
        <thead>
          <tr className="TableListView-header">
            <th className="TableListView-expand">&nbsp;</th>
            <th className={`TableListView-${treeNumber ? getTreeClass(treeNumber) : 'first-square'}`} />
            <th className={`TableListView-image-cell ${treeNumber && 'TableListView-top-border'}`}>
              <AssetTypeSelector type={(displayType && displayType) || ''} border={false} />
            </th>
            {/* eslint-disable-next-line complexity*/}
            {displayValues.map((item, index) => (
              <th
                className={`TableListView-header-text ${treeNumber && 'TableListView-top-border'} ${
                  store.contextAction?.contextMenu === true && store.contextAction.index === index && 'TableListView-header-text-action'
                } ${index !== 0 ? 'draggable-item' : 'TableListView-header-text-noaction'}${draggedItem === item ? ' draggable-original' : ''} ${
                  editIndex &&
                  editIndex.newIndex === index &&
                  (editIndex.move === 'left' ? 'TableListView-header-text-left' : 'TableListView-header-text-right')
                }`}
                key={index}
                onMouseEnter={() => handleMouseHover(index)}
                onMouseLeave={() => handleMouseHover()}
                onContextMenu={(e: React.MouseEvent) => columnEditable && onEditClick(e, index)}
              >
                <div className="TableListView-header-content space-p-r-1">
                  {store.itemsType !== AssetContentType.PI ? (
                    <React.Fragment>
                      <Draggable
                        tag="header"
                        item={item}
                        itemIndex={index}
                        items={displayValues ? displayValues : []}
                        columnsView={true}
                        allowTouchDrag={false}
                        canDrop={(tag) => tag === 'header'}
                        subtractHeightOnDrag={true}
                        onDragStart={(item) => {
                          setDraggedItem(item);
                        }}
                        onDragEnd={() => {
                          setDraggedItem(null);
                          setEditIndex(null);
                        }}
                        onDragOver={(sourceItems: Array<unknown>, currentIndex: number, newIndex: number) => {
                          store.handleColumnMove(sourceItems as Array<AssetFilter>, store.itemsType);

                          if (currentIndex > newIndex) {
                            setEditIndex({ newIndex, move: 'left' });

                            return;
                          }

                          setEditIndex({ newIndex, move: 'right' });
                        }}
                      >
                        <div className="TableListView-header-content-text space-p-l-3">
                          <div>{item.text}</div>
                        </div>
                      </Draggable>
                      {store.itemsType === AssetContentType.MCT && (
                        <React.Fragment>
                          <Sortable
                            enabled={TableListViewConfig.sortableAttributes.includes(item.attribute.name)}
                            className={item.sort || (onHover !== null && onHover === index) ? 'Sortable-visible' : ''}
                            direction={item.sort}
                            onClick={(direction) => {
                              store.resetOrderBy(displayValues, index);
                              store.setOrderBy(item, direction);
                            }}
                          />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <div className="TableListView-header-content-text space-p-l-3">
                      <div>{item.text}</div>
                    </div>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className={`${draggedItem && 'TableListView-edit-mode'}`}>{rows && rows}</tbody>
      </table>
    </React.Fragment>
  );
});

export default TableListView;
