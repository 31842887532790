import { IMetadataSearchAttribute } from '@cdam/shared';

export interface IBrands {
  adidas: IBrandFilters;
  reebok: IBrandFilters;
}

interface IBrandFilters {
  filters: Array<IMetadataSearchAttribute>;
}

export const brands = {
  adidas: {
    filters: [
      {
        group: 'General',
        name: 'assetGroup',
        isDisabled: true,
      },
      {
        group: 'Asset Information',
        name: 'brand',
        isDisabled: true,
      },
      {
        group: 'Asset Information',
        name: 'marketingSeason',
      },
      {
        group: 'Asset Information',
        name: 'milestones',
      },
      {
        group: 'Asset Information',
        name: 'keyCategory',
      },
      {
        group: 'Asset Information',
        name: 'market',
      },
      {
        group: 'Asset Information',
        name: 'functions',
      },
      {
        group: 'Asset Information',
        name: 'distribution',
      },
      {
        group: 'General',
        name: 'policy',
      },
    ],
  },
  reebok: {
    filters: [
      {
        group: 'General',
        name: 'assetGroup',
        isDisabled: true,
      },
      {
        group: 'Asset Information',
        name: 'brand',
        isDisabled: true,
      },
      {
        group: 'Asset Information',
        name: 'marketingSeason',
      },
      {
        group: 'Asset Information',
        name: 'keyCategory',
      },
      {
        group: 'Asset Information',
        name: 'market',
      },
      {
        group: 'Asset Information',
        name: 'functions',
      },
      {
        group: 'Asset Information',
        name: 'milestones',
      },
      {
        group: 'Asset Information',
        name: 'distribution',
      },
    ],
  },
};
