import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import TextBoxControlled from '../../../../components/input/TextBoxControlled';
import MatrixSettingsStore from '../../../../stores/matrix/MatrixSettingsStore';

interface IMatrixSettingsGroupProps {
  store: MatrixSettingsStore;
}

const MatrixSettingsGroup = observer(
  ({ store }: IMatrixSettingsGroupProps): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div>
        <TextBoxControlled
          className="MatrixSettingsDialog-fieldname-input"
          name="fieldName"
          value={store.editingItemName}
          label={t('common.name')}
          error={store.editingItemNameError}
          onChange={(e) => store.changeEditingItemName(e.target.value)}
        />
      </div>
    );
  },
);

export default MatrixSettingsGroup;
