import React from 'react';
import { computed, action, makeObservable } from 'mobx';
import {
  IMetadataSearch,
  IMetadataSearchAttribute,
  IMatrixAxis,
  IEventItem,
  HandledError,
  ErrorMessage,
  Brand,
  LogLevelEnum,
  EventTypeEnum,
} from '@cdam/shared';
import DialogStore from 'stores/dialog/DialogStore';
import UploadAssets from 'containers/upload/UploadAssets';
import MatrixStore from '../MatrixStore';
import NavigationService from '../../../services/NavigationService';
import LoggingService from '../../../services/LoggingService';

export class AssetReebokCell {
  public readonly count: string;
  public readonly cellData: IEventItem | undefined;

  private readonly store: MatrixStore;
  private readonly itemIndex: number;

  @computed
  public get highlightItem(): boolean {
    return this.itemIndex === this.store.selectedReebokCellIndex;
  }

  public get item(): IMatrixAxis {
    if (!this.store.selectedEvent) {
      const error = new HandledError(ErrorMessage.MatrixError, undefined, undefined, undefined, undefined, 4);

      LoggingService.log({ message: 'Event was is empty', level: LogLevelEnum.ERROR, event: EventTypeEnum.MATRIX_GET }, error);

      throw error;
    }

    return this.store.selectedEvent.reebokFilters[this.itemIndex];
  }

  public constructor(store: MatrixStore, itemIndex: number, count: string, cellData: IEventItem | undefined) {
    makeObservable(this);
    this.store = store;
    this.itemIndex = itemIndex;
    this.cellData = cellData;
    this.count = count;
  }

  @action
  public onClick(): void {
    let filter: Array<IMetadataSearchAttribute> = [];

    if (this.item.filters?.length) {
      // spread operator beacause of mobx 4 limitation
      filter = filter.concat(...this.item.filters);
    }

    if (this.store.selectedEvent?.generalFilters.length) {
      filter = filter.concat(...this.store.selectedEvent.generalFilters.filter((gf) => gf.valueList?.length));
    }

    const search: IMetadataSearch = {
      searchFacet: {
        allMustMatch: true,
        searchAttributeList: filter,
      },
    };

    NavigationService.navigateTo(`/assets?q=${encodeURIComponent(JSON.stringify(search))}`);
  }

  public openUploadDialog(): void {
    DialogStore.addDialog(
      <UploadAssets
        name={[this.item.name]}
        filters={this.cellData?.uploadFilters}
        brand={this.store.brand ?? Brand.Reebok}
        owners={this.cellData?.owners}
        filenameConvention={this.store.selectedEvent?.filenameConvention ?? ''}
        onClose={() => {
          DialogStore.removeLastDialog();
        }}
      />,
    );
  }
}
