/* eslint-disable @typescript-eslint/unbound-method */
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { appLocationEnum, AppVariantEnum, Brand, IAssetsFilterItem } from '@cdam/shared';
import { IInvalidFilter, FilterType } from 'stores/matrix/MatrixSettingsStore';
import { customFilters } from 'configs/FilterDisplayTypeConfig';
import { AssetFilter } from '../../../stores/assets/data/AssetFilter';
import { ReferenceDataItem } from '../../../stores/assets/data/ReferenceDataItem';
import ViewStore from '../../../stores/view/ViewStore';
import AssetsFilterDropDown from './Filters/AssetsFilterDropDown';

import './AssetsFilter.scss';

interface IAssetsFilterProps {
  className?: string;
  checkList?: boolean;
  singleChoice?: boolean;
  filterClassName?: string;
  filters: Array<AssetFilter>;
  selectedFilters: Array<AssetFilter>;
  toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean;
  clearAllFilters(): void;
  maxFilterWidth?: string;
  minFilterWidth?: string;
  brand?: Brand;
  validate?: boolean;
  invalidFilters?: Array<IInvalidFilter>;
  disableDeleting?: boolean;
  disableAdding?: boolean;
  choicesMandatory?: boolean;
  validateFilters?(): void;
  disableAddFilter?: boolean;
  appLocation?: appLocationEnum;
  searchMode?: boolean; // String have a typeahead functionality
}

const AssetsFilter: React.FC<IAssetsFilterProps> = observer(
  ({
    className,
    checkList,
    singleChoice,
    filterClassName,
    filters,
    selectedFilters,
    toggleSelectedFilter,
    clearAllFilters,
    maxFilterWidth,
    minFilterWidth,
    brand = undefined,
    invalidFilters,
    disableDeleting,
    disableAdding,
    validate,
    choicesMandatory,
    validateFilters,
    disableAddFilter,
    appLocation,
    searchMode,
  }) => {
    const { t } = useTranslation();
    const [openedFilter, setOpenedFilter] = useState(-999);

    function closeOpenedFilter(ev: Event): void {
      const clickedElement = ev.target as HTMLElement;

      if (clickedElement?.closest) {
        const closestFilterDropDown = clickedElement.closest('.AssetsFilterDropDown');

        if (closestFilterDropDown) {
          return;
        }
      }

      setOpenedFilter(-999);
    }

    function onSelectorClick(filterIndex: number): void {
      validateFilters?.();
      setOpenedFilter(openedFilter === filterIndex ? -999 : filterIndex);
    }

    function getInvalidFilterErrorMessage(filter: AssetFilter): string | undefined {
      if (!invalidFilters) {
        return undefined;
      }

      const errorFilter = invalidFilters.find((f) => f.filter.name === filter.attribute.name && f.filter.group === filter.groupName);

      if (errorFilter) {
        return `Filter attribute already used in ${errorFilter.item.name}. (${
          errorFilter.filterType === FilterType.EditFilter ? 'Edit tab' : 'Upload tab'
        })`;
      }

      return undefined;
    }

    function checkDisableDeleteButton(filter: AssetFilter) {
      if (ViewStore.appVariant === AppVariantEnum.ArchiveUI && appLocation === appLocationEnum.EDIT_TEMPLATE) {
        return filter.isMandatory === true && !filter.canToggleMandatory;
      }

      return disableDeleting;
    }

    useEffect(() => {
      document.addEventListener('click', closeOpenedFilter, true);
      document.addEventListener('touch', closeOpenedFilter, true);

      return () => {
        document.removeEventListener('click', closeOpenedFilter, true);
        document.removeEventListener('touch', closeOpenedFilter, true);
      };
    }, []);

    const getFilterType = (filter: AssetFilter): string | undefined => {
      const filterAttribute = filter.attribute.name;

      if (customFilters.includes(filterAttribute)) {
        return;
      }

      // eslint-disable-next-line consistent-return
      return filter.attribute.type ?? filter.attribute.masterDataType;
    };

    return (
      <div className={cn('AssetsFilter', className)}>
        {selectedFilters &&
          selectedFilters.length > 0 &&
          selectedFilters.map((filter, index) => (
            <div className={cn(filterClassName)} key={`${filter.text}:${filter.attribute.name}`}>
              <AssetsFilterDropDown
                canBeRemoved={filter.canBeRemoved}
                isOpen={index === openedFilter}
                text={filter.text}
                attributeName={filter.attribute.name}
                group={filter.groupName}
                type={getFilterType(filter)}
                items={filter.referenceItems as Array<IAssetsFilterItem>}
                isLoading={filter.referenceItemsLoading}
                maxFilterWidth={maxFilterWidth}
                minFilterWidth={minFilterWidth}
                error={filter.referenceItemsError}
                validate={validate}
                selectedItems={filter.selectedReferenceItems}
                selectedValue={filter.value}
                selectedValues={filter.values}
                choicesMandatory={choicesMandatory}
                isMandatory={filter.isMandatory !== null ? filter.isMandatory : undefined}
                toggleMandatory={filter.canToggleMandatory ?? true}
                handleMandatoryToggle={filter.toggleMandatory}
                multipleChoices={filter.asList}
                list={checkList ? filter.asList : undefined}
                singleChoice={singleChoice}
                brand={brand}
                disabled={filter.isDisabled}
                errorMessage={getInvalidFilterErrorMessage(filter)}
                disableDelete={checkDisableDeleteButton(filter)}
                displayType={filter.attribute.displayType}
                searchMode={searchMode}
                onSelectorClick={() => onSelectorClick(index)}
                onDeleteClick={() => {
                  toggleSelectedFilter(filter, selectedFilters);
                  setOpenedFilter(-999);
                }}
                onChangeValue={(val, open) => {
                  filter.changeValue(val);

                  if (!open) {
                    setOpenedFilter(-999);
                  }
                }}
                onMultipleRemoveItem={(val) => {
                  filter.removeMultipleValue(val);
                }}
                onClearAllClick={() => filter.clearAllSelectedValues()}
                onToggleSelectedItem={(item) => filter.toggleSelectedReference(item as ReferenceDataItem)}
                onToggleSingleSelectedItem={(item) => {
                  filter.toggleSingleSelectedReference(item as ReferenceDataItem);
                  setOpenedFilter(-999);
                }}
              />
            </div>
          ))}

        {!disableAddFilter && filters && filters.length > 0 && !disableAdding && filters.length !== selectedFilters.length && (
          <div className={cn(filterClassName)}>
            <AssetsFilterDropDown
              text={t('common.add_filter')}
              attributeName={''}
              group={''}
              filterSelector
              items={filters}
              validate={validate}
              selectedItems={selectedFilters}
              isOpen={openedFilter === -2}
              brand={brand}
              onSelectorClick={() => onSelectorClick(-2)}
              onClearAllClick={() => {
                clearAllFilters();
                setOpenedFilter(-999);
              }}
              onToggleSelectedItem={(item) => {
                if (toggleSelectedFilter(item as AssetFilter, selectedFilters)) {
                  setOpenedFilter(selectedFilters.length - 1);
                }
              }}
            />
          </div>
        )}
      </div>
    );
  },
);

export default AssetsFilter;
