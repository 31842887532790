import React from 'react';

export const MatrixSearch: React.FC = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="20" viewBox="0 0 16 20">
    <g fill="#000000" fillRule="evenodd">
      <path
        stroke="#000000"
        strokeWidth=".5"
        d="M11.588 19.095H1.298a.293.293 0 0 1-.219-.099.324.324 0 0 1-.078-.235V1.31a.319.319 0 0 1 .09-.218.291.291 0 0 1 .212-.088h9.75a.287.287 0 0 1 .112 0l.052.034.046.03 3.76 3.79a.32.32 0 0 1 .094.223v9.49h-.6v-9.18H11.05a.292.292 0 0 1-.21-.09.318.318 0 0 1-.087-.22v-3.48H1.595L1.6 18.48h9.988v.615zm-.242-17.072v2.744h2.749l-2.749-2.744z"
      />
      <path d="M7.825 13.562c-1.535 0-2.785-1.28-2.785-2.853 0-1.574 1.25-2.854 2.785-2.854 1.536 0 2.786 1.28 2.786 2.854 0 1.573-1.25 2.853-2.786 2.853zm6.41 2.719l-3.008-3.093a4.33 4.33 0 0 0 .776-2.48c0-2.363-1.87-4.28-4.178-4.28-2.307 0-4.178 1.917-4.178 4.28 0 2.364 1.871 4.281 4.178 4.281a4.09 4.09 0 0 0 2.417-.793l3.005 3.09.988-1.005z" />
    </g>
  </svg>
);
