import React from 'react';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import {
  AssetSearch,
  EventTypeEnum,
  fileNameIsInvalid,
  HandledError,
  IMasterdataResponse,
  IMetadataSearch,
  ISPMSearch,
  IUploadTemplate,
  IUploadTemplateInfo,
  LogLevelEnum,
} from '@cdam/shared';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import { getUnderscoreCountFromFilename } from 'utils/UploadedFilenameValidation';
import UploadDownloadManagerStore from 'stores/uploadDownloadManager/UploadDownloadManagerStore';
import { UploadItem, UploadType } from 'stores/uploadDownloadManager/data/UploadItem';
import DialogStore from 'stores/dialog/DialogStore';
import FilterUtils from 'utils/FilterUtils';
import ValidationDialog from '../../containers/dialog/dialogs/ValidationDialog';
import LoggingService from '../../services/LoggingService';
import ViewStore from '../view/ViewStore';
import { IUploadFile } from './UploadStore';

export interface IVerifyDuplicates {
  isVerifying: boolean;
  successful: boolean;
  files: Array<IUploadFile>;
  show: boolean;
}

export enum BrandCodes {
  ADIDAS = 11,
  TAYLOR_MADE = 13,
  REEBOK = 26,
  ROCKPORT = 27,
  ASHWORTH = 33,
}

export default class UploadProductImagesStore {
  @observable public isLoading = false;
  @observable public isUploadLoading = false;
  @observable public error: Error | null = null;
  @observable public templates: Array<IUploadTemplateInfo> | null = null;
  @observable public selectedTemplate: IUploadTemplate | null = null;
  @observable public uploadAssets: Array<IUploadFile> = [];
  @observable public verifyDuplicates: IVerifyDuplicates = {
    isVerifying: false,
    successful: false,
    files: [],
    show: false,
  };

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get filenameError(): boolean {
    return !this.uploadAssets.every((asset) => asset.validFilename);
  }

  @computed
  public get articleNumberError(): boolean {
    return !this.uploadAssets.every((asset) => asset.articleNumberMissing === false);
  }

  @computed
  public get productViewError(): boolean {
    return !this.uploadAssets.every((asset) => asset.productViewMissing === false);
  }

  @computed
  public get allFiltersSelected(): boolean {
    return this.uploadAssets.every((item) => item.presetUploadFilters.every((f) => (f.selectedSingleValue ?? f.value) || f.selectedReferenceItems));
  }

  @computed
  public get duplicateFilenames(): boolean {
    for (let i = 0; i < this.uploadAssets.length; i++) {
      if (this.uploadAssets.filter((_, index) => index !== i).find((a) => a.name === this.uploadAssets[i].name)) {
        return true;
      }
    }

    return false;
  }

  @action
  public async load(): Promise<void> {
    this.isLoading = true;
    this.error = null;

    this.selectedTemplate = null;

    try {
      if (!this.templates) {
        const templateList = await ClientHttpService.fetch<Array<IUploadTemplateInfo>>({
          urlType: UrlType.Backend,
          url: `/upload-templates/${ViewStore.selectedAssetDomain}`,
          method: 'GET',
        });

        runInAction(() => {
          this.templates = templateList;
          this.isLoading = false;
        });
      }
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public async selectTemplate(templateId: string): Promise<void> {
    this.isLoading = true;
    this.error = null;

    this.selectedTemplate = null;
    this.uploadAssets = [];

    try {
      const template = await ClientHttpService.fetch<IUploadTemplate>({
        urlType: UrlType.Backend,
        url: `/upload-templates/template/${templateId}`,
        method: 'GET',
      });

      runInAction(() => {
        this.selectedTemplate = template;
        this.isLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public async handleFileDrop(fullName: string, file: Blob, mimeType: string, fileSize: number, checksum: string): Promise<void> {
    const splitAt = fullName.lastIndexOf('.');
    const name = fullName;
    const extension = fullName.slice(splitAt + 1);
    const uploadFile: IUploadFile = {
      name,
      extension,
      file,
      size: fileSize,
      mimeType,
      checksum,
      presetUploadFilters: this.selectedTemplate?.attributes
        ? FilterUtils.findAndPreselectFilters([...this.selectedTemplate.attributes], TaxonomyStore.getTaxonomyAttributeFiltersWithRules())
        : [],
      displayedUploadFilters: [],
      validFilename: false,
      articleNumberMissing: true,
      productViewMissing: true,
    };

    for (const filter of uploadFile.presetUploadFilters) {
      if (filter.selectedReferenceItems && filter.selectedReferenceItems.length === 1) {
        filter.setSingleSelectedValue(filter.selectedReferenceItems[0]);
      }
    }

    if (this.selectedTemplate?.extractArticleNumbers) {
      await this.validateExtractedArticleNumber(uploadFile);
    } else {
      this.validateArticleNumber(uploadFile);
    }

    if (this.selectedTemplate?.extractProductViews) {
      this.validateExtractedProductView(uploadFile);
    } else {
      this.validateProductView(uploadFile);
    }

    runInAction(() => {
      this.uploadAssets.push(uploadFile);
    });
  }

  @action
  public async beginUploadingFiles(): Promise<void> {
    this.verifyDuplicates.show = true;

    const response = await this.checkAssetDuplicates(this.uploadAssets);

    if (!this.verifyDuplicates.successful) {
      return;
    }

    for (const asset of this.uploadAssets) {
      const articleNumber = asset.presetUploadFilters.find((f) => f.groupName === 'Asset Information' && f.attribute.name === 'articleNumber');

      if (articleNumber) {
        // eslint-disable-next-line no-await-in-loop
        const brand = await this.getBrandByArticleNumber(articleNumber.value);

        runInAction(() => {
          asset.brand = brand;
        });
      }
    }

    runInAction(() => {
      this.verifyDuplicates.files = response;
    });

    if (this.verifyDuplicates.files.length) {
      DialogStore.addDialog(
        <ValidationDialog
          descriptionTranslationKey={'upload.upload_duplicates'}
          actionTranslationKey={'common.upload'}
          tableLabel={'upload.table_label'}
          titleTranslationKey={'upload.duplicates_found'}
          icon={'icon-information-big'}
          duplicateFiles={this.verifyDuplicates.files.map((e) => e.name)}
          onCancel={() => DialogStore.removeLastDialog()}
          onConfirm={() => {
            DialogStore.removeLastDialog();

            runInAction(() => {
              this.verifyDuplicates.files = [];
            });

            this.startProcess();
          }}
        />,
      );

      runInAction(() => {
        this.verifyDuplicates.show = false;
      });
    } else {
      this.startProcess();
    }
  }

  @action
  public validateFilenames(): void {
    for (const asset of this.uploadAssets) {
      if (!fileNameIsInvalid(asset.name)) {
        asset.validFilename = true;
      } else {
        asset.validFilename = false;
      }
    }
  }

  @action
  public changeFileName(file: IUploadFile, name: string): void {
    file.name = name;
  }

  @action
  public removeFile(index: number): void {
    this.uploadAssets.splice(index, 1);
  }

  @action
  public setUploadLoading(value: boolean): void {
    this.isUploadLoading = value;
  }

  @action
  public async validateExtractedArticleNumber(uploadFile: IUploadFile): Promise<void> {
    const articleNumber = this.getArticleNumberFromFilename(uploadFile.name);

    if (articleNumber !== '') {
      try {
        await ClientHttpService.fetch({ urlType: UrlType.Metadata, url: `/masterData/SPM_article/${articleNumber}`, method: 'GET' });

        const articleNumberFilter = uploadFile.presetUploadFilters.find(
          (filter) => filter.groupName === 'Asset Information' && filter.attribute.name === 'articleNumber',
        );

        articleNumberFilter?.setSingleSelectedValue(articleNumber);

        runInAction(() => {
          uploadFile.articleNumberMissing = false;
        });
      } catch (e) {
        runInAction(() => {
          uploadFile.articleNumberMissing = true;
        });

        LoggingService.log({
          message: `Could not extract article number from file name (${uploadFile.name})`,
          level: LogLevelEnum.DEBUG,
          event: EventTypeEnum.FILE_UPLOAD,
        });
      }
    } else {
      runInAction(() => {
        uploadFile.articleNumberMissing = true;
      });
    }
  }

  @action
  public validateExtractedProductView(uploadFile: IUploadFile): void {
    const productView = TaxonomyStore.getProductViewReferenceItem(uploadFile.name);

    if (productView) {
      const productViewFilter = uploadFile.presetUploadFilters.find(
        (pf) => pf.groupName === 'Asset Information' && pf.attribute.name === 'productView',
      );

      productViewFilter?.setSingleSelectedValue(productView);

      uploadFile.productViewMissing = false;
    } else {
      uploadFile.productViewMissing = true;
    }
  }

  @action
  public validateProductView(uploadFile: IUploadFile): void {
    const productViewFilter = uploadFile.presetUploadFilters.find(
      (pf) => pf.groupName === 'Asset Information' && pf.attribute.name === 'productView',
    );

    if (productViewFilter?.selectedSingleValue) {
      uploadFile.productViewMissing = false;
    }
  }

  @action
  public validateArticleNumber(uploadFile: IUploadFile): void {
    const articleNumberFilter = uploadFile.presetUploadFilters.find(
      (pf) => pf.groupName === 'Asset Information' && pf.attribute.name === 'articleNumber',
    );

    if (articleNumberFilter?.selectedSingleValue || articleNumberFilter?.value) {
      uploadFile.articleNumberMissing = false;
    }
  }

  @action
  private async checkAssetDuplicates(uploadItems: Array<IUploadFile>): Promise<Array<IUploadFile>> {
    this.verifyDuplicates.successful = true;
    this.verifyDuplicates.isVerifying = true;

    const duplicatedFiles: Array<IUploadFile> = [];

    await Promise.all(
      uploadItems.map(async (item) => {
        const metadataId = await this.duplicateLookup(item);

        if (metadataId.length !== 0) {
          // eslint-disable-next-line require-atomic-updates
          item.metadataId = metadataId;
          duplicatedFiles.push(item);
        }
      }),
    ).catch(() => {
      runInAction(() => {
        this.verifyDuplicates.successful = false;
      });
    });

    runInAction(() => {
      this.verifyDuplicates.isVerifying = false;
    });

    return duplicatedFiles;
  }

  private async duplicateLookup(item: IUploadFile): Promise<string> {
    const search = new AssetSearch();

    try {
      const body: IMetadataSearch = {
        searchText: item.name,
        searchFacet: {
          allMustMatch: true,
          searchAttributeList: item.presetUploadFilters
            .map((filter) => ({
              group: filter.groupName,
              name: filter.attribute.name,
              valueList: filter.getMetadataSearchValueList(),
            }))
            .filter(
              (filter) =>
                filter.name === 'articleNumber' ||
                filter.name === 'imageStyle' ||
                filter.name === 'productView' ||
                filter.name === 'assetCategory' ||
                filter.name === 'vendor' ||
                filter.name === 'usage',
            ),
        },
      };

      const abortController = new AbortController();
      const signal = abortController.signal;
      const response = await search.search(
        body,
        0,
        1,
        // eslint-disable-next-line @typescript-eslint/return-await
        async (s, url) =>
          await ClientHttpService.fetch({
            urlType: UrlType.Metadata,
            url,
            method: 'POST',
            body: s,
            signal,
          }),
        // eslint-disable-next-line @typescript-eslint/return-await
        async (url) =>
          await ClientHttpService.fetch<IMasterdataResponse>({
            urlType: UrlType.Metadata,
            url,
            method: 'GET',
            signal,
          }),
      );

      if (response.pagination.totalElements !== 0) {
        return response.assets[0].id;
      }
    } catch (e) {
      const error = new HandledError('Cannot verify duplicate.', undefined, undefined, (e as Error).stack);

      LoggingService.log({ message: error.message, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_SEARCH }, error);

      throw error;
    }

    return '';
  }

  private getArticleNumberFromFilename(filename: string): string {
    const underscoreCount = getUnderscoreCountFromFilename(filename);

    if (underscoreCount === 0) {
      const endOfFilenameIndex = filename.indexOf('.');

      return filename.substring(0, endOfFilenameIndex);
    }

    const articleNumberEndIndex = filename.indexOf('_');

    return filename.substring(0, articleNumberEndIndex);
  }

  private async getBrandByArticleNumber(articleNumber: string): Promise<BrandCodes | undefined> {
    try {
      const article = await ClientHttpService.fetch<ISPMSearch>({
        urlType: UrlType.Metadata,
        url: `/masterData/SPM_article/${articleNumber}`,
        method: 'GET',
      });

      const model = await ClientHttpService.fetch<ISPMSearch>({
        urlType: UrlType.Metadata,
        url: `/masterData/SPM_model/${article.attributes.GrpMdlNo}`,
        method: 'GET',
      });

      if (model.attributes.Brand !== '') {
        return parseInt(model.attributes.Brand) as BrandCodes;
      }

      return undefined;
    } catch (e) {
      runInAction(() => {
        this.error = e as Error;
      });

      const error = new HandledError('Cannot verify duplicate.', undefined, undefined, (e as Error).stack);

      LoggingService.log({ message: error.message, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_SEARCH }, error);

      return undefined;
    }
  }

  private startProcess(): void {
    UploadDownloadManagerStore.addUploadItem(new UploadItem('', this.uploadAssets.slice(0), UploadType.PI));
  }
}
