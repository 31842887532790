import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { ReactComponent as ImageIcon } from '../../../assets/icon/icon_image.svg';

import './TemplateItem.scss';

interface ITemplateItemProps {
  className?: string;
  path: string;
  text: string;
  selected: boolean;
}

const TemplateItem = ({ className, path, text, selected }: ITemplateItemProps): JSX.Element => (
  <Link to={path} className={cn('TemplateItem box-shadow-2 space-m-r-5', className, { selected })}>
    <ImageIcon className="space-m-l-3 space-m-r-4" />
    {text}
  </Link>
);

export default TemplateItem;
