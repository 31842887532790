import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Dropdown from 'components/dropdown/Dropdown';
import cn from 'classnames';
import DropdownCheckboxItem from 'components/dropdown/DropdownCheckboxItem';
import AdvancedDownloadStore from 'stores/assets/AdvancedDownloadStore';
import { IDownloadFileFormat } from '@cdam/shared/src/interfaces/Asset';
import _ from 'lodash';
import { DownloadFormats } from '@cdam/shared';
import { useTranslation } from 'react-i18next';

interface IDownloadFormatSelectDropdownProps {
  className?: string;
  advancedDownloadStore: AdvancedDownloadStore;
  dropdownValue: string;
}

const GlobalDownloadFormatSelectDropdown: React.FC<IDownloadFormatSelectDropdownProps> = observer(
  ({ advancedDownloadStore, className, dropdownValue }) => {
    const { t } = useTranslation();
    const handleDropdownCheckboxItemClick = useCallback(
      (format: IDownloadFileFormat) => {
        advancedDownloadStore.toggleGlobalAssetFormat(format);
      },
      [advancedDownloadStore],
    );

    const globalFileFormats = advancedDownloadStore.globalAssetFormats ?? [];

    return (
      <Dropdown className={cn('DownloadFormatSelectDropdown', className)} value={dropdownValue} label={t('advancedDownload.global_dropdown_formats')}>
        {DownloadFormats.filter((e) => !e.customFormat).map((format) => (
          <DropdownCheckboxItem
            key={format.name}
            labelClassName="space-m-t-1"
            value={format.name}
            checked={globalFileFormats.some((g) => _.isEqual(format, g))}
            onClick={() => handleDropdownCheckboxItemClick(format)}
          />
        ))}
      </Dropdown>
    );
  },
);

export default GlobalDownloadFormatSelectDropdown;
