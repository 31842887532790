import {
  IEvent,
  IMetadataSearchAttribute,
  IMatrixAxis,
  Release,
  Brand,
  MatrixItemType,
  IGroup,
  fileNameIsInvalid,
  IMatrixOwnersItem,
  Name,
  appLocationEnum,
  assetGroupsEnum,
  assetGroupIdsEnum,
} from '@cdam/shared';
// eslint-disable-next-line import/named
import { action, observable, runInAction, computed, autorun, IReactionDisposer, makeObservable } from 'mobx';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import { overrideFilterList } from 'stores/assets/data/AssetFilterOverrides';
import FilterUtils from 'utils/FilterUtils';
import { IMatrixOwnerSearchable } from 'containers/search/dialogs/components/MatrixSettingsOwners';
import SecurityUtils from 'utils/SecurityUtils';
import { AssetFilter } from '../assets/data/AssetFilter';
import ConfigUtils from '../../utils/ConfigUtils';
import TaxonomyStore from '../taxonomy/TaxonomyStore';
import NavigationService from '../../services/NavigationService';
import DialogStore from '../dialog/DialogStore';
import { brands, IBrands } from './data/BrandData';
import { getEmptyEvent } from './data/EmptyEvent';
import { getEmptyAxisItem } from './data/EmptyAxisItem';

export enum Axis {
  xAxis,
  yAxis,
  reebokAxis,
}

export enum MatrixEditType {
  Group,
  Dimension,
}

export enum MatrixSettingsMode {
  Edit,
  Create,
}

export enum FilterType {
  EditFilter,
  UploadFilter,
}

export interface IInvalidFilter {
  filter: IMetadataSearchAttribute;
  item: IMatrixAxis;
  filterType: FilterType;
}

export default class MatrixSettingsStore {
  public mandatoryFilters: Array<AssetFilter> = [];

  @observable public generalSettings: IBrands = JSON.parse(JSON.stringify(brands)) as IBrands;
  @observable public editingItemFilters: Array<AssetFilter> = [];
  @observable public editingItemUploadFilters: Array<AssetFilter> = [];
  @observable public editingItemPermissionGroups: Array<IGroup> = [];
  @observable public editingItemName = '';
  @observable public editingItemNameError = '';
  @observable public editingItemOwners: Array<IMatrixOwnersItem> = [];
  @observable public generalItemOwners: Array<IMatrixOwnersItem> = [];
  @observable public currentItem: IMatrixAxis | null = null;
  @observable public editType: MatrixEditType = -1;
  @observable public error: Error | null = null;
  @observable public event: IEvent | null = null;
  @observable public isLoading = false;
  @observable public generalFilters: Array<AssetFilter> = [];
  @observable public selectedGroupItem: IMatrixAxis | null = null;
  @observable public groupedItemsForDraggable: Array<Array<IMatrixAxis>> | null = null;
  @observable public whereToFindWhatFile: File | null = null;

  @observable public invalidEditingItemFilters: Array<IInvalidFilter> = [];
  @observable public invalidEditingUploadFilters: Array<IInvalidFilter> = [];

  private taxonomy: Array<AssetFilter> = [];
  private uploadTaxonomy: Array<AssetFilter> = [];

  private brand: Brand = Brand.Adidas;
  private currentAxis: Axis = -1;
  private cachedEditingGroupItem: IMatrixAxis | null = null;
  private cachedEvent = '';

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get allEditingFilters(): Array<IMetadataSearchAttribute> {
    if (!this.event) {
      return [];
    }

    const allEditingFilters: Array<IMetadataSearchAttribute> = [];

    this.event.matrix.yAxis.forEach((yItem) => {
      if (yItem.filters && yItem.filters.length > 0) {
        allEditingFilters.push(...yItem.filters);
      }
    });

    this.event.matrix.xAxis.forEach((xItem) => {
      if (xItem.filters && xItem.filters.length > 0) {
        allEditingFilters.push(...xItem.filters);
      }
    });

    this.event.reebokFilters.forEach((reebokItem) => {
      if (reebokItem.filters && reebokItem.filters.length > 0) {
        allEditingFilters.push(...reebokItem.filters);
      }
    });

    return allEditingFilters;
  }

  public get editingItemsTaxonomy(): Array<AssetFilter> {
    return this.taxonomy.filter(
      (filter) =>
        !this.generalFilters.find(
          (generaFilter) => generaFilter.groupName === filter.groupName && generaFilter.attribute.name === filter.attribute.name,
        ) &&
        !this.editingItemUploadFilters.find(
          (uploadFilter) => uploadFilter.groupName === filter.groupName && uploadFilter.attribute.name === filter.attribute.name,
        ),
    );
  }

  public get uploadingItemsTaxonomy(): Array<AssetFilter> {
    return this.uploadTaxonomy.filter(
      (filter) =>
        !this.generalFilters.find(
          (generaFilter) => generaFilter.groupName === filter.groupName && generaFilter.attribute.name === filter.attribute.name,
        ) &&
        !this.editingItemFilters.find(
          (editingItem) => editingItem.groupName === filter.groupName && editingItem.attribute.name === filter.attribute.name,
        ),
    );
  }

  public get generalFilterTaxonomy(): Array<AssetFilter> {
    const editingFilters = FilterUtils.findAndPreselectFilters(this.allEditingFilters, this.taxonomy);

    return this.taxonomy.filter(
      (filter) =>
        !editingFilters.find((editingItem) => editingItem.groupName === filter.groupName && editingItem.attribute.name === filter.attribute.name),
    );
  }

  @computed
  public get editFilters(): Array<AssetFilter> {
    return this.taxonomy
      .filter(
        (filter) =>
          !this.generalFilters.find(
            (generalFilter) => generalFilter.groupName === filter.groupName && generalFilter.attribute.name === filter.attribute.name,
          ),
      )
      .filter(
        (filter) =>
          !this.editingItemUploadFilters.find(
            (editingUploadFilter) =>
              editingUploadFilter.groupName === filter.groupName && editingUploadFilter.attribute.name === filter.attribute.name,
          ),
      );
  }

  public get taxonomyUploadFilters(): Array<AssetFilter> {
    return ConfigUtils.taxonomyWithAppliedRules(
      [...this.generalFilters, ...this.editingItemFilters, ...this.editingItemUploadFilters],
      undefined,
      appLocationEnum.UPLOAD_MCT,
      [assetGroupsEnum.MARKETING_CONTENT],
    );
  }

  @computed
  public get uploadFilters(): Array<AssetFilter> {
    return this.taxonomyUploadFilters
      .filter(
        (filter) =>
          !this.generalFilters.find(
            (generalFilter) => generalFilter.groupName === filter.groupName && generalFilter.attribute.name === filter.attribute.name,
          ),
      )
      .filter(
        (filter) =>
          !this.editingItemFilters.find(
            (editingFilter) => editingFilter.groupName === filter.groupName && editingFilter.attribute.name === filter.attribute.name,
          ),
      );
  }

  @computed
  public get groupItems(): Array<IMatrixAxis> | null {
    if (!this.event) {
      return null;
    }

    if (this.selectedGroupItem !== null) {
      const restOfGroupItems = this.event.matrix.yAxis.filter(
        (y) => y.type === MatrixItemType.Group && y.id !== (this.selectedGroupItem as IMatrixAxis).id,
      );

      return [this.selectedGroupItem, ...restOfGroupItems];
    }

    return this.event.matrix.yAxis.filter((y) => y.type === MatrixItemType.Group);
  }

  @computed
  public get invalidWhereToFindWhatFilename(): boolean {
    if (!this.whereToFindWhatFile) {
      return false;
    }

    return fileNameIsInvalid(this.whereToFindWhatFile.name);
  }

  public initGroupedItemsAutorun(): IReactionDisposer {
    return autorun(() => {
      if (!this.event) {
        return;
      }

      const groups: Array<Array<IMatrixAxis>> = [];

      this.event.matrix.yAxis.forEach((item) => {
        if (item.type === MatrixItemType.Group) {
          groups.push([item]);
        } else if (item.type === MatrixItemType.Standalone) {
          groups.push([item]);
        } else {
          const groupsWithGroupItem = groups.filter((g) => g[0].type === MatrixItemType.Group);

          groupsWithGroupItem[groupsWithGroupItem.length - 1].push(item);
        }
      });

      runInAction(() => {
        this.groupedItemsForDraggable = groups;
      });
    });
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public addPermissionToCurrentItem(group: IGroup): void {
    this.editingItemPermissionGroups.push(group);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public deletePermissionOfCurrentItem(index: number): void {
    if (index !== -1) {
      this.editingItemPermissionGroups.splice(index, 1);
    }
  }

  @action
  public changeFilenameConvention(value: string): void {
    if (!this.event) {
      return;
    }

    this.event.filenameConvention = value;
  }

  @action
  public changeGroupDownloadPermission(group: IGroup): void {
    group.download = !group.download;
  }

  @action
  public changeGroupUploadPermission(group: IGroup): void {
    group.upload = !group.upload;
  }

  @action
  public updateEventMatrixYAxis(): void {
    if (!this.event) {
      return;
    }

    this.event.matrix.yAxis = this.transformDraggableGroupsIntoYaxis();
  }

  @action
  public changeEditType(type: MatrixEditType): void {
    this.editType = type;

    if (this.editType === MatrixEditType.Group) {
      this.currentItem = JSON.parse(JSON.stringify(getEmptyAxisItem(MatrixItemType.Group))) as IMatrixAxis;
    } else if (this.editType === MatrixEditType.Dimension) {
      this.currentItem = JSON.parse(JSON.stringify(getEmptyAxisItem(MatrixItemType.Standalone))) as IMatrixAxis;
    }
  }

  @action
  public changeCurrentItemGroup(groupItem: IMatrixAxis | null): void {
    this.selectedGroupItem = groupItem;
  }

  @action
  public changeEditingItemName(name: string): void {
    this.editingItemName = name;
  }

  @action
  public changeEditingEventName(name: string): void {
    if (!this.event) {
      return;
    }

    this.event.name = name;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public addToEditingItemOwners(owner: IMatrixOwnersItem): void {
    this.editingItemOwners.push(owner);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public removeEditingItemOwners(index: number): void {
    this.editingItemOwners = this.editingItemOwners.filter((_, i) => index !== i);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public addToGeneralItemOwners(owner: IMatrixOwnersItem): void {
    this.generalItemOwners.push(owner);
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public removeGeneralItemOwners(index: number): void {
    this.generalItemOwners = this.generalItemOwners.filter((_, i) => index !== i);
  }

  @action
  public async load(brand: Brand, eventId?: string): Promise<void> {
    this.error = null;
    this.event = null;
    this.brand = brand;

    this.isLoading = true;

    try {
      if (eventId) {
        const event = await ClientHttpService.fetch<IEvent>({ urlType: UrlType.Backend, url: `/matrix/${brand}/${eventId}/edit`, method: 'GET' });

        this.normalizeEvent(event);

        runInAction(() => {
          this.event = event;
          this.loadGeneralSettings(brand);
          this.generalItemOwners = this.event.generalOwners ?? [];
          this.isLoading = false;
        });
      } else {
        runInAction(() => {
          this.event = getEmptyEvent(brand);
          this.loadGeneralSettings(brand);
          this.generalItemOwners = [];
          this.isLoading = false;
        });
      }

      this.cachedEvent = JSON.stringify(this.event);
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public loadGeneralSettings(brand: Brand): void {
    const assetGroupFilter = ConfigUtils.createFilter(Name.General, 'assetGroup', [assetGroupIdsEnum.MARKETING_CONTENT], true, true);
    const brandFilter = ConfigUtils.createFilter(Name.AssetInformation, 'brand', [brand === Brand.Adidas ? '11' : '26'], true, true);

    if (!assetGroupFilter || !brandFilter) {
      return;
    }

    this.mandatoryFilters = [assetGroupFilter, brandFilter];

    this.taxonomy = [
      ...this.mandatoryFilters,
      ...TaxonomyStore.getTaxonomyAttributeFiltersWithRules(this.mandatoryFilters, undefined, appLocationEnum.EDIT_MATRIX, [
        assetGroupsEnum.MARKETING_CONTENT,
      ]),
    ];

    this.uploadTaxonomy = TaxonomyStore.getTaxonomyAttributeFiltersWithRules(this.mandatoryFilters, undefined, appLocationEnum.UPLOAD_MCT, [
      assetGroupsEnum.MARKETING_CONTENT,
    ]);

    // if adding new matrix
    if (!(this.event?.generalFilters && this.event.generalFilters.length)) {
      this.generalFilters = [
        ...this.mandatoryFilters,
        ...ConfigUtils.getPreselectedFilter(appLocationEnum.EDIT_MATRIX, undefined, [assetGroupsEnum.MARKETING_CONTENT]),
      ];
    } else {
      this.generalFilters = FilterUtils.findAndPreselectFilters(this.event.generalFilters, this.taxonomy);
      this.generalFilters.forEach((filter) => this.overrideFilterList(filter));
    }

    ConfigUtils.revalidateSelectedFilters(this.generalFilters, appLocationEnum.EDIT_MATRIX, [assetGroupsEnum.MARKETING_CONTENT]);
  }

  @action
  // eslint-disable-next-line complexity
  public loadCurrentEditingItem(axis: Axis, item?: IMatrixAxis): void {
    this.currentAxis = axis;
    this.editingItemFilters = [];
    this.editingItemUploadFilters = [];
    this.editingItemPermissionGroups = [];
    this.editingItemName = '';
    this.editingItemNameError = '';
    this.editingItemOwners = [];

    this.clearInvalidEditItemFilter();
    this.clearInvalidEditItemUploadFilter();

    this.editFilters.forEach((f) => f.clearAllSelectedValues());
    this.uploadFilters.forEach((f) => f.clearAllSelectedValues());

    if (item === undefined) {
      if (axis === Axis.xAxis || axis === Axis.reebokAxis) {
        this.editType = MatrixEditType.Dimension;
        this.currentItem = JSON.parse(JSON.stringify(getEmptyAxisItem(MatrixItemType.Standalone))) as IMatrixAxis;
      }

      if (axis !== Axis.reebokAxis) {
        this.selectedGroupItem = this.getCurrentItemGroup();
        this.cachedEditingGroupItem = this.selectedGroupItem;
      }

      return;
    }

    if (item?.filters && item.filters.length > 0) {
      this.editingItemFilters = FilterUtils.findAndPreselectFilters(item.filters, this.editingItemsTaxonomy);
    }

    if (item?.uploadFilters && item.uploadFilters.length > 0) {
      this.editingItemUploadFilters = FilterUtils.findAndPreselectFilters(item.uploadFilters, this.uploadingItemsTaxonomy);
    }

    if (item?.groupPermissions && item.groupPermissions.length > 0) {
      this.editingItemPermissionGroups = item.groupPermissions;
    }

    if (item?.owners && item.owners.length > 0) {
      this.editingItemOwners = item.owners;
    }

    this.editingItemName = item.name;
    this.currentItem = item;
    this.editType = item.type === MatrixItemType.Group ? MatrixEditType.Group : MatrixEditType.Dimension;

    if (axis !== Axis.reebokAxis) {
      this.selectedGroupItem = this.getCurrentItemGroup();
      this.cachedEditingGroupItem = this.selectedGroupItem;
    }
  }

  @action
  public deleteCurrentEditingItem(): void {
    if (!this.currentItem || !this.event) {
      return;
    }

    if (this.currentAxis === Axis.xAxis) {
      const index = this.event.matrix.xAxis.indexOf(this.currentItem);

      if (index !== -1) {
        this.event.matrix.xAxis.splice(index, 1);
      }
    } else if (this.currentAxis === Axis.yAxis) {
      if (this.currentItem.type === MatrixItemType.Group) {
        return;
      }

      const index = this.event.matrix.yAxis.indexOf(this.currentItem);

      if (index !== -1) {
        this.event.matrix.yAxis.splice(index, 1);
      }
    } else if (this.currentAxis === Axis.reebokAxis) {
      const index = this.event.reebokFilters.indexOf(this.currentItem);

      if (index !== -1) {
        this.event.reebokFilters.splice(index, 1);
      }
    }
  }

  @action
  public deleteGroupItem(): void {
    if (!this.currentItem || !this.groupedItemsForDraggable) {
      return;
    }

    if (this.currentItem.type !== MatrixItemType.Group) {
      return;
    }

    const groupIndex = this.groupedItemsForDraggable.findIndex((g) => g[0].id === (this.currentItem as IMatrixAxis).id);

    if (groupIndex !== -1) {
      this.groupedItemsForDraggable.splice(groupIndex, 1);
      this.updateEventMatrixYAxis();
    }
  }

  @action
  public AddOrUpdateCurrentEditingItem(axis?: Axis): void {
    if (!this.currentItem) {
      return;
    }

    this.editingItemNameError = '';

    if (this.editingItemName === '') {
      this.editingItemNameError = 'Item name is required.';

      return;
    }

    this.currentItem.filters = FilterUtils.filtersToMetadataAttributes(this.editingItemFilters);
    this.currentItem.uploadFilters = FilterUtils.filtersToMetadataAttributes(this.editingItemUploadFilters);
    this.currentItem.groupPermissions = this.editingItemPermissionGroups;
    this.currentItem.name = this.editingItemName;
    this.currentItem.owners = this.editingItemOwners;

    if (axis !== undefined) {
      this.addItemOnAxis(axis);
    }

    if (this.currentAxis === Axis.yAxis && this.cachedEditingGroupItem !== this.selectedGroupItem) {
      this.saveCurrentItemGroup();
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    const result = FilterUtils.toggleSelectedFilter(item, filters);

    this.filterCollisionOnXAndYAxis();
    this.uploadFilterCollision();

    // TODO: investigate possible bug for different app locations, edit and upload
    ConfigUtils.revalidateSelectedFilters([...this.mandatoryFilters, ...filters], appLocationEnum.EDIT_MATRIX, [assetGroupsEnum.MARKETING_CONTENT]);

    return result;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public clearAllEditingFilters(): void {
    this.editingItemFilters = this.mandatoryFilters;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public clearAllGeneralFilters(): void {
    this.generalFilters = this.mandatoryFilters;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public clearAllUploadFilters(): void {
    this.editingItemUploadFilters = [];
  }

  public unsavedChanges(): boolean {
    const event = JSON.stringify(this.event);

    if (event !== this.cachedEvent) {
      return true;
    }

    return false;
  }

  @action
  public async updateMatrix(): Promise<void> {
    // TODO: matrix validation as separate file/method
    if (!this.event) {
      return;
    }

    if (!this.event.name) {
      DialogStore.alert('Event name is required.');

      return;
    }

    if (!this.generalFilters.find((filter) => filter.attribute.name === 'policy' && filter.value)) {
      DialogStore.alert('Matrix must have a policy defined in General filters.');

      return;
    }

    if (this.invalidWhereToFindWhatFilename) {
      DialogStore.alert('Where to find what file name is invalid.');

      return;
    }

    this.event.season = this.getMarketingSeasonValue();
    this.event.generalFilters = FilterUtils.filtersToMetadataAttributes(this.generalFilters);
    this.event.generalOwners = this.generalItemOwners;

    try {
      const addedEvent = await DialogStore.execFullScreen(
        ClientHttpService.fetch<IEvent>({ urlType: UrlType.Backend, url: `/matrix/${this.brand}`, method: 'POST', body: this.event }),
      );

      if (this.whereToFindWhatFile) {
        const formData = new FormData();

        formData.append('file', this.whereToFindWhatFile, this.whereToFindWhatFile.name);

        await DialogStore.execFullScreen(
          ClientHttpService.fetch({
            urlType: UrlType.Backend,
            url: `/matrix/where-to-find-what/${addedEvent.id}`,
            method: 'POST',
            body: formData,
          }),
        );
      }

      NavigationService.navigateTo('/');
    } catch (e) {
      DialogStore.error(e);
    }
  }

  @action
  public async deleteMatrix(): Promise<void> {
    if (!this.event) {
      return;
    }

    try {
      await DialogStore.execFullScreen(
        ClientHttpService.fetch({ urlType: UrlType.Backend, url: `/matrix/${this.brand}/${this.event.id}`, method: 'DELETE' }),
      );

      NavigationService.navigateTo('/');
    } catch (e) {
      DialogStore.error(e);
    }
  }

  @action
  public async setReleaseStatus(releaseStatus: Release): Promise<void> {
    if (!this.event) {
      return;
    }

    this.event.releaseStatus = releaseStatus;
  }

  @action
  public async setReleaseDate(releaseDate: string): Promise<void> {
    if (!this.event) {
      return;
    }

    this.event.releaseDate = releaseDate;
  }

  @action
  public handleFileDrop(file: File): void {
    this.whereToFindWhatFile = file;
  }

  @action
  public async deleteWhereToFindWhatFile(): Promise<void> {
    if (!this.event) {
      return;
    }

    try {
      await DialogStore.execFullScreen(
        ClientHttpService.fetch({ urlType: UrlType.Backend, url: `/matrix/where-to-find-what/${this.event.id}`, method: 'DELETE' }),
      );

      runInAction(() => {
        if (this.event) {
          this.event.whereToFindWhatUploaded = false;
        }
      });
    } catch (error) {
      DialogStore.error(error);
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public async getAllMatrixSettingsOwners(): Promise<Array<IMatrixOwnerSearchable>> {
    const { adminGroups } = await ClientHttpService.fetch<{ adminGroups: string }>({
      method: 'GET',
      urlType: UrlType.Backend,
      url: `/matrix/${this.event?.brand}/admin-group`,
    });

    const newGroups = this.editingItemPermissionGroups.length ? this.editingItemPermissionGroups.map((group) => group.groupId) : [adminGroups];

    const items = await SecurityUtils.getUsers(newGroups);
    const generalSettingsOwners = [...this.editingItemOwners, ...(this.generalItemOwners ?? [])];

    const filterdOwners = generalSettingsOwners
      ? items.filter((item) => !generalSettingsOwners.find((owner) => owner.searchableText === item.searchableText))
      : items;

    return filterdOwners.reduce<Array<IMatrixOwnerSearchable>>((acc, item) => {
      acc.push({ ...item, searchableText: item.username });

      return acc;
    }, []);
  }

  @action
  // eslint-disable-next-line complexity
  private filterCollisionOnXAndYAxis(): void {
    if (!this.currentItem) {
      return;
    }

    this.clearInvalidEditItemFilter();

    for (const filter of this.editingItemFilters) {
      if (this.currentAxis === Axis.yAxis) {
        if (this.event) {
          for (const xAxis of this.event.matrix.xAxis) {
            if (xAxis.filters) {
              for (const xFilter of xAxis.filters) {
                if (filter.attribute.name === xFilter.name && filter.groupName === xFilter.group && !filter.asList) {
                  this.pushInvalidEditItemFilter(xFilter, xAxis, FilterType.EditFilter);
                }
              }
            }

            if (xAxis.uploadFilters) {
              for (const xFilter of xAxis.uploadFilters) {
                if (filter.attribute.name === xFilter.name && filter.groupName === xFilter.group) {
                  this.pushInvalidEditItemFilter(xFilter, xAxis, FilterType.UploadFilter);
                }
              }
            }
          }
        }
      } else if (this.currentAxis === Axis.xAxis) {
        if (this.event) {
          for (const yAxis of this.event.matrix.yAxis) {
            if (yAxis.filters) {
              for (const yFilter of yAxis.filters) {
                if (filter.attribute.name === yFilter.name && filter.groupName === yFilter.group && !filter.asList) {
                  this.pushInvalidEditItemFilter(yFilter, yAxis, FilterType.EditFilter);
                }
              }
            }

            if (yAxis.uploadFilters) {
              for (const yFilter of yAxis.uploadFilters) {
                if (filter.attribute.name === yFilter.name && filter.groupName === yFilter.group) {
                  this.pushInvalidEditItemFilter(yFilter, yAxis, FilterType.UploadFilter);
                }
              }
            }
          }
        }
      }
    }
  }

  @action
  // eslint-disable-next-line complexity
  private uploadFilterCollision(): void {
    if (!this.currentItem) {
      return;
    }

    this.clearInvalidEditItemUploadFilter();

    for (const filter of this.editingItemUploadFilters) {
      if (this.currentAxis === Axis.yAxis) {
        if (this.event) {
          for (const xAxis of this.event.matrix.xAxis) {
            if (xAxis.filters) {
              for (const xFilter of xAxis.filters) {
                if (filter.attribute.name === xFilter.name && filter.groupName === xFilter.group) {
                  this.pushInvalidEditItemUploadFilter(xFilter, xAxis, FilterType.EditFilter);
                }
              }
            }
          }
        }
      } else if (this.currentAxis === Axis.xAxis) {
        if (this.event) {
          for (const yAxis of this.event.matrix.yAxis) {
            if (yAxis.filters) {
              for (const yFilter of yAxis.filters) {
                if (filter.attribute.name === yFilter.name && filter.groupName === yFilter.group) {
                  this.pushInvalidEditItemUploadFilter(yFilter, yAxis, FilterType.EditFilter);
                }
              }
            }
          }
        }
      }
    }
  }

  @action
  private pushInvalidEditItemFilter(filter: IMetadataSearchAttribute, item: IMatrixAxis, filterType: FilterType): void {
    this.invalidEditingItemFilters.push({ filter, item, filterType });
  }

  @action
  private pushInvalidEditItemUploadFilter(filter: IMetadataSearchAttribute, item: IMatrixAxis, filterType: FilterType): void {
    this.invalidEditingUploadFilters.push({ filter, item, filterType });
  }

  @action
  private clearInvalidEditItemFilter(): void {
    this.invalidEditingItemFilters = [];
  }

  @action
  private clearInvalidEditItemUploadFilter(): void {
    this.invalidEditingUploadFilters = [];
  }

  private getMarketingSeasonValue(): string {
    const marketingSeason = this.generalFilters.find((f) => f.attribute.name === 'marketingSeason');

    if (marketingSeason?.selectedReferenceItems && marketingSeason.selectedReferenceItems.length > 0) {
      const marketingSeasonValue = marketingSeason.selectedReferenceItems[0].referenceData.attributeList.find((al) => al.name === 'MASTER_DATA_DESC');

      if (marketingSeasonValue) {
        const { value } = marketingSeasonValue;

        if (value) {
          return value.replace(/[^A-Z\d]/g, '').replace(/\d\d(\d\d)/, '$1');
        }
      }
    }

    return '';
  }

  @action
  private addItemOnAxis(axis: Axis): void {
    if (!this.event || !this.currentItem) {
      return;
    }

    switch (axis) {
      case Axis.xAxis:
        this.event.matrix.xAxis.push(this.currentItem);
        break;
      case Axis.yAxis:
        this.event.matrix.yAxis.push(this.currentItem);
        break;
      case Axis.reebokAxis:
        this.event.reebokFilters.push(this.currentItem);
        break;
      default:
        break;
    }
  }

  @action
  private saveCurrentItemGroup(): void {
    if (!this.event || !this.currentItem) {
      return;
    }

    if (this.selectedGroupItem === null) {
      // value none was selected
      const index = this.event.matrix.yAxis.indexOf(this.currentItem);

      this.event.matrix.yAxis.splice(index, 1);
      this.currentItem.type = MatrixItemType.Standalone;
      this.event.matrix.yAxis.push(this.currentItem);

      return;
    }

    const index = this.event.matrix.yAxis.indexOf(this.currentItem);

    this.event.matrix.yAxis.splice(index, 1);
    const groupIndex = this.event.matrix.yAxis.indexOf(this.selectedGroupItem);

    if (this.currentItem.type !== MatrixItemType.Group) {
      this.currentItem.type = MatrixItemType.Subitem;
    }

    this.event.matrix.yAxis.splice(groupIndex + 1, 0, this.currentItem);
  }

  @action
  private getCurrentItemGroup(): IMatrixAxis | null {
    if (!this.event) {
      return null;
    }

    let ret: IMatrixAxis | null = null;

    if (this.currentAxis === Axis.yAxis) {
      if ((this.currentItem as IMatrixAxis).type === MatrixItemType.Standalone) {
        // Null value represents 'none' in group item dropdown. That means that item has type standalone.
        return null;
      }

      const groups: Array<IMatrixAxis> = [];

      this.event.matrix.yAxis.forEach((y) => {
        if (y.type === MatrixItemType.Group) {
          groups.push(y);
        }

        if (y.id === (this.currentItem as IMatrixAxis).id && y.name === (this.currentItem as IMatrixAxis).name) {
          if (groups.length > 0) {
            ret = groups.pop() as IMatrixAxis;
          }
        }
      });
    }

    return ret;
  }

  @action
  private overrideFilterList(filter: AssetFilter): AssetFilter {
    return overrideFilterList(filter);
  }

  private transformDraggableGroupsIntoYaxis(): Array<IMatrixAxis> {
    const ret: Array<IMatrixAxis> = [];

    (this.groupedItemsForDraggable as Array<Array<IMatrixAxis>>).forEach((group) => {
      group.forEach((item) => {
        ret.push(item);
      });
    });

    return ret;
  }

  private normalizeEvent(event: IEvent): void {
    event.matrix.xAxis.forEach((xItem) => {
      if (!xItem.filters) {
        xItem.filters = [];
      }
    });

    event.matrix.yAxis.forEach((yItem) => {
      if (yItem.type !== MatrixItemType.Group && !yItem.filters) {
        yItem.filters = [];
      }
    });
  }
}
