import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import AssetsStore from 'stores/assets/AssetsStore';
import { ISelectedType, AssetType, AssetContentType } from '@cdam/shared';
import { IDisplayArticles, getValues } from 'utils/AssetDisplayUtils';
import plusImage from '../../../../assets/img/plus.png';
import minusImage from '../../../../assets/img/minus.png';
import AssetCardImage from '../../../../components/coredam/AssetCardImage';
import TableListView from './TableListView';

import './ArticleRowView.scss';

interface IArticleRowView {
  store: AssetsStore;
  type: ISelectedType | null;
  treeNumber?: number;
  hideLine?: boolean;
  checked: boolean;
  items: IDisplayArticles;
  openAssetDetails?(): void;
}

// eslint-disable-next-line complexity
const ArticleRowView: React.FC<IArticleRowView> = observer(({ store, type, treeNumber, hideLine, items, checked, openAssetDetails }) => {
  const [expanded, setExpanded] = useState(false);

  const displayValues = items.items?.length && getValues({ item: items.items[0] }, AssetContentType.PI, AssetType.Article);

  return (
    <React.Fragment>
      <tr className={`ArticleRowView ${expanded && 'ArticleRowView-border'}`}>
        <td className="ArticleRowView-expand">
          {hideLine && <div className="ArticleRowView-hideLine" />}
          {expanded && type && type.showAssets && <div className="ArticleRowView-shortLine" />}
          <div className="ArticleRowView-expandcell">
            {treeNumber && <div className="ArticleRowView-horizontal" />}
            {type?.showAssets && (
              <img
                src={expanded ? (minusImage as string) : (plusImage as string)}
                style={{ width: 13, height: 13 /* stylelint-disable */ }}
                alt=""
                onClick={() => setExpanded(!expanded)}
              />
            )}
          </div>
        </td>
        <td className={`${treeNumber ? 'ArticleRowView-second-square' : 'ArticleRowView-first-square'} ArticleRowView-checkbox-cell`}>
          <input
            type="checkbox"
            className="ArticleRowView-assets-list-view-checkbox"
            checked={checked}
            onClick={() => store.toggleAllSubItems(items.items, checked)}
          />
        </td>
        <td className="ArticleRowView-placeholder" onClick={openAssetDetails && openAssetDetails}>
          <AssetCardImage className={'ArticleRowView-display-image'} item={items.items[0]} width={62} height={62} hideBadge={true} />
        </td>
        {/* eslint-disable-next-line @typescript-eslint/prefer-optional-chain */}
        {displayValues &&
          displayValues.map((item, index) => (
            <td key={index} className="ArticleRowView-table-item space-p-h-3" onClick={openAssetDetails && openAssetDetails}>
              <p className="ArticleRowView-table-item-text">{item}</p>
            </td>
          ))}
      </tr>
      {expanded && type && type.showAssets && (
        <tr>
          <td className="space-p-t-2 ArticleRowView-next-table space-p-b-2" colSpan={10}>
            <TableListView store={store} type={type} displayType={AssetType.Asset} items={items.items} treeNumber={treeNumber === 2 ? 3 : 2} />
          </td>
        </tr>
      )}
    </React.Fragment>
  );
});

export default ArticleRowView;
