import React from 'react';
import { observer } from 'mobx-react-lite';
import Icon from 'components/icons/Icon';
import AssetCardImage from 'components/coredam/AssetCardImage';
import AssetTypeSelector from 'components/coredam/AssetTypeSelector';
import { ISelectedType } from '@cdam/shared';
import { IDisplayArticles } from 'utils/AssetDisplayUtils';
import AssetsStore from '../../../../stores/assets/AssetsStore';
import AssetCards from './AssetCards';

import './ArticleCardRow.scss';

interface IAssetsViewProps {
  assetsStore: AssetsStore;
  item: IDisplayArticles;
  type: ISelectedType | null;
  checked: boolean;
  openAssetDetails?(): void;
}

const ArticleCardRow: React.FC<IAssetsViewProps> = observer(({ assetsStore, type, item, checked, openAssetDetails }: IAssetsViewProps) => (
  <React.Fragment>
    <div className="ArticleCardRow-row space-p-h-4 space-p-v-2">
      <div className="ArticleCardRow-maininfo">
        <div className="ArticleCardRow-checkbox-container">
          <input
            type="checkbox"
            className="ArticleCardRow-checkbox-container-checkbox"
            checked={checked}
            onClick={() => assetsStore.toggleAllSubItems(item.items, checked)}
          />
        </div>
        <div className="space-m-l-4 ">
          <AssetTypeSelector type="article" border={false} />
        </div>
        <div className="space-m-l-4">
          <h5>{item.articleName}</h5>
        </div>
        <div className="space-m-l-6">
          <AssetCardImage hideBadge={true} className="ArticleCardRow-image" item={item.items[0]} width={74} height={74} />
        </div>
      </div>
      <div className="ArticleCardRow-inforow" onClick={openAssetDetails && openAssetDetails}>
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>
      <div className="ArticleCardRow-item ArticleCardRow-downloadicon space-m-l-6" onClick={() => assetsStore.openAdvancedDownloadDialog(item.items)}>
        <Icon icon="icon-download" color={'#737373'} height={22} width={22} />
      </div>
    </div>
    {type?.showAssets && <AssetCards assetsStore={assetsStore} items={item.items} />}
  </React.Fragment>
));

export default ArticleCardRow;
