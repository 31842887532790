import React, { ReactElement } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import './MinimalLayout.scss';
import Icon from 'components/icons/Icon';

interface IMinimalLayoutProps {
  children: Array<ReactElement>;
  header?: string;
  className?: string;
  onCloseButtonClick(): void;
  headerClassName?: string;
}

const MinimalLayout: React.FC<IMinimalLayoutProps> = observer(({ children, header, className, headerClassName, onCloseButtonClick }) => (
  <div className={`${cn('MinimalLayout', className)}`}>
    {header && (
      <section className={`MinimalLayout-container ${headerClassName ? headerClassName : ''}`}>
        <h1 className="h4">
          <strong>{header}</strong>
        </h1>
        <Icon height={30} width={30} icon="icon-close" className="MinimalLayout-close-button" onClick={onCloseButtonClick} />
      </section>
    )}
    {children}
  </div>
));

export default MinimalLayout;
