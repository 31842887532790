import React from 'react';
import cn from 'classnames';

import './Checkbox.scss';

interface ICheckboxProps extends Omit<React.ComponentProps<'input'>, 'type' | 'children'> {
  className?: string;
  onChange?(): void;
  disabled?: boolean;
}

const Checkbox = ({ className, onChange, checked, disabled }: ICheckboxProps): JSX.Element => (
  <label className={cn('checkbox', 'Checkbox', className, `${disabled ? 'disabled' : ''}`)}>
    <input type="checkbox" checked={checked} disabled={disabled} onChange={onChange} />
    <svg className="icon yarn-svg-icon Checkbox-checkbox-img" height="1.3rem" width="1.5rem">
      <use xlinkHref="#icon-checkbox-empty" />
    </svg>
    <svg className="icon yarn-svg-icon Checkbox-checkbox-img" height="1.3rem" width="1.5rem">
      <use xlinkHref="#icon-checkbox-filled" />
    </svg>
  </label>
);

export default Checkbox;
