import React from 'react';
import cn from 'classnames';
import { Release } from '@cdam/shared';

import './Chip.scss';

interface IChipProps extends React.ComponentProps<'span'> {
  children: React.ReactNode;
  className?: string;
  interactive?: boolean;
  active?: boolean;
  choice?: boolean;
  type?: Release;
}

const Chip = ({ active, choice, interactive, children, className, type, ...rest }: IChipProps): JSX.Element => {
  let releaseClassName;

  switch (type) {
    case Release.Draft:
      if (!active) {
        releaseClassName = '';
        break;
      }

      releaseClassName = 'Chip-draft';
      break;
    case Release.Expired:
      if (!active) {
        releaseClassName = '';
        break;
      }

      releaseClassName = 'Chip-expired';
      break;
    case Release.Released:
      if (!active) {
        releaseClassName = '';
        break;
      }

      releaseClassName = 'Chip-released';
      break;
    default:
      releaseClassName = '';
      break;
  }

  return (
    <span className={cn('Chip chip', { interactive, active, choice }, className, releaseClassName)} {...rest}>
      {children}
    </span>
  );
};

export default Chip;
