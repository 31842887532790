import React, { useCallback } from 'react';
import cn from 'classnames';
import Checkbox from 'components/checkbox/Checkbox';
import './DropdownCheckboxItem.scss';

interface DropdownCheckboxItemProps {
  value?: string;
  className?: string;
  labelClassName?: string;
  checked?: boolean;
  onClick?(): void;
  isDisabled?: boolean;
}

const DropdownCheckboxItem = ({ value, className, labelClassName, checked, onClick, isDisabled }: DropdownCheckboxItemProps): JSX.Element => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick],
  );

  return (
    <label className={cn('DropdownCheckboxItem space-p-h-1 space-p-v-1', className)} onClick={handleClick}>
      <div>
        <Checkbox checked={checked} disabled={isDisabled} onChange={() => handleClick} />
      </div>
      <div className={labelClassName}>{value}</div>
    </label>
  );
};

export default DropdownCheckboxItem;
