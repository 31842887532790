import React from 'react';
import { useTranslation } from 'react-i18next';
import DialogStore from 'stores/dialog/DialogStore';
import MatrixSettingsStore, { Axis, MatrixEditType } from 'stores/matrix/MatrixSettingsStore';

import './MatrixYItemAddModal.scss';

interface IMatrixYItemAddModalProps {
  store: MatrixSettingsStore;
  handleMouseLeave(): void;
  handleMouseEnter(): void;
}

const MatrixYItemAddModal = ({ store, handleMouseLeave, handleMouseEnter }: IMatrixYItemAddModalProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="MatrixYItemAddModal" onMouseLeave={handleMouseLeave} onMouseEnter={handleMouseEnter}>
      <div
        className="MatrixYItemAddModal-group"
        onClick={() => {
          store.changeEditType(MatrixEditType.Group);
          DialogStore.matrixSettingsModal(store, Axis.yAxis);
        }}
      >
        {t('common.group')}
      </div>
      <div
        className="MatrixYItemAddModal-dimension"
        onClick={() => {
          store.changeEditType(MatrixEditType.Dimension);
          DialogStore.matrixSettingsModal(store, Axis.yAxis);
        }}
      >
        {t('common.dimension')}
      </div>
    </div>
  );
};

export default MatrixYItemAddModal;
