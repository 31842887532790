import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { fileNameIsInvalid } from '@cdam/shared';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import './AdvancedDownloadBar.scss';
import FileUtils from 'utils/FileUtils';
import DialogStore from '../../../../stores/dialog/DialogStore';

interface IAdvancedDownloadBarProps {
  className?: string;
  onDownloadClick(zipName: string): void;
}

const AdvancedDownloadBar: React.FC<IAdvancedDownloadBarProps> = observer(({ className, onDownloadClick }) => {
  const { t } = useTranslation();
  const currentDateAsString = FileUtils.getDateTimeAsString();

  const [fileName, setFileName] = useState<{ value: string; error: boolean }>({ value: currentDateAsString, error: false });

  const handleChange = (value: string): void => {
    const error = fileNameIsInvalid(value);

    setFileName({ value, error });
  };

  return (
    <div className={cn('AdvancedDownloadBar', className)}>
      <div className="AdvancedDownloadBar-container">
        <div className="AdvancedDownloadBar-font space-m-l-4">
          <strong>{t('advancedDownload.name_your_download_package')}</strong>
        </div>
        <div className="AdvancedDownloadBar-input space-m-h-2">
          <input
            className="input ie-font-fix"
            type="text"
            placeholder={currentDateAsString}
            value={fileName.value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
        </div>
        <div className="AdvancedDownloadBar-font ie-font-fix">{t('advancedDownload.zip_extension')}</div>
        <div className="space-m-l-6">
          {fileName.error ? (
            <Button
              className="AdvancedDownloadBar-button disabled"
              buttonType={ButtonType.Primary}
              size={ButtonSize.Default}
              icon=""
              onClick={() => {
                DialogStore.alert(t('advancedDownload.file_name_format'));
              }}
            >
              {t('advancedDownload.invalid_file_name')}
            </Button>
          ) : (
            <Button
              className="AdvancedDownloadBar-button"
              buttonType={ButtonType.Primary}
              size={ButtonSize.Default}
              icon="icon-download"
              iconColor={'#fff'}
              onClick={() => onDownloadClick(fileName.value)}
            >
              {t('common.download')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
});

export default AdvancedDownloadBar;
