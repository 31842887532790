import { action, makeObservable, observable, runInAction } from 'mobx';
import ClientHttpService, { UrlType } from '../../services/ClientHttpService';
import NavigationService from '../../services/NavigationService';

const ACCESS_TOKEN = 'access_token';

export interface IAuthService {
  getAccessToken(): Promise<string | null | undefined>;
  login(username: string, password: string): Promise<void>;
  logout(): void;
}

class AuthStore {
  @observable public error: Error | null = null;
  @observable public state: 'notStarted' | 'inProgress' | 'done' = 'notStarted';
  @observable public token: string | undefined | null = undefined;

  public constructor() {
    makeObservable(this);
  }

  @action
  public async init(): Promise<void> {
    if (this.state === 'inProgress') {
      return;
    }

    if (window.localStorage.getItem(ACCESS_TOKEN)) {
      this.setToken(window.localStorage.getItem(ACCESS_TOKEN) ?? '');
      this.state = 'done';

      return;
    }

    this.state = 'inProgress';

    try {
      await this.adidasLogin();
    } catch (error) {
      console.error(error);
    } finally {
      this.state = 'done';
    }
  }
  @action
  public async logout(reloadPage: boolean): Promise<void> {
    this.token = null;
    window.localStorage.removeItem(ACCESS_TOKEN);

    if (reloadPage) {
      window.location.reload();
    }
  }

  @action
  public setToken(token: string) {
    this.token = token;
    window.localStorage.setItem(ACCESS_TOKEN, token);
  }

  public async handleWindowCallback() {
    if (this.state === 'inProgress') {
      return;
    }

    this.error = null;
    this.state = 'inProgress';

    const params = new URLSearchParams(window.location.search);
    const code = params.get('code');

    if (!code) {
      return;
    }

    params.delete('code');
    params.delete('client_info');
    params.delete('session_state');
    params.delete('state');

    let error: Error | null = null;

    try {
      const token = await ClientHttpService.fetch<{ accessToken: string }>({
        url: `/auth/adidas/code?code=${encodeURIComponent(code)}`,
        urlType: UrlType.Backend,
        method: 'POST',
      });

      this.setToken(token.accessToken);
    } catch (e) {
      error = e as Error;
    } finally {
      runInAction(() => {
        this.error = error;
        this.state = 'done';
      });

      NavigationService.navigateTo('/');
    }
  }

  public async adidasLogin() {
    try {
      const promise = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const returnUrl = urlParams.get('returnUrl') || undefined;
        const data = await ClientHttpService.fetch<{ uri: string }>({
          method: 'POST',
          url: '/auth/code',
          urlType: UrlType.Backend,
          body: returnUrl ? { returnUrl } : undefined,
        });

        window.location.href = data.uri;

        return new Promise<void>((resolve) => setTimeout(() => resolve(), 1000000));
      };

      await promise();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }
}

// singletone
export default new AuthStore();
