import React from 'react';
import { useTranslation } from 'react-i18next';
import NavigationService from 'services/NavigationService';
import AdidasShoesImage from '../../assets/img/adidas_shoes.png';

import './Logout.scss';

const Logout = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="Logout">
      <div>
        <img src={AdidasShoesImage as string} alt={t('common.adidas_shoes')} />
      </div>
      <div className="Logout-description space-m-t-6">
        <h3>{t('common.you_have_been_logged_out')}</h3>
        <h3 className="subtitle subtitle--lg">{t('common.logout_page_main_text')}.</h3>
        <p className="body body--sm Logout-subtext">
          {t('common.logout_page_subtext')}{' '}
          <span
            className="link"
            onClick={() => {
              NavigationService.navigateTo('/');
              window.location.reload();
            }}
          >
            {t('common.here').toLowerCase()}
          </span>
          .
        </p>
      </div>
    </div>
  );
};

export default Logout;
