import * as H from 'history';
import { observable, runInAction, makeObservable } from 'mobx';

class NavigationService {
  @observable public search = window.location.search;

  public readonly history: H.History = H.createBrowserHistory({
    basename: process.env.PUBLIC_URL ?? undefined,
  });
  private historyLength = 0;

  public constructor() {
    makeObservable(this);
    this.history.listen((location, action) => this.handleHistoryChange(location, action));
  }

  public goBack(): void {
    if (this.historyLength) {
      this.history.goBack();
    } else {
      const path = '/';

      --this.historyLength;
      this.history.push(path);
    }
  }

  public navigateTo(path: string): void {
    this.history.push(path);
  }

  public replace(path: string): void {
    this.history.replace(path);
  }

  private handleHistoryChange(location: H.Location<unknown>, action: H.Action): void {
    runInAction(() => {
      this.search = location.search;

      switch (action) {
        case 'PUSH':
          ++this.historyLength;
          break;
        case 'POP':
          --this.historyLength;
          break;
        case 'REPLACE':
        default:
          // ignore
          break;
      }
    });
  }
}

// singletone
export default new NavigationService();
