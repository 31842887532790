/* eslint-disable complexity */
import React from 'react';
import { observer } from 'mobx-react-lite';
import Icon from 'components/icons/Icon';
import DownloadUtils from 'utils/DownloadUtils';
import { AssetType } from '@cdam/shared';
import { getAssetContentType } from 'utils/AssetDisplayUtils';
import AssetsStore from '../../../stores/assets/AssetsStore';
import { AssetData } from '../../../stores/assets/data/AssetData';
import AssetCardImage from '../../../components/coredam/AssetCardImage';
import AssetCardBody from './AssetCardBody';

import './AssetsCard.scss';

interface IAssetsCardProps {
  store?: AssetsStore;
  item: AssetData;
  hideDetails?: boolean;
  assetType?: AssetType;
  displayArticle?: boolean;
  openAssetDetails?(): void;
}

// eslint-disable-next-line complexity
const AssetsCard: React.FC<IAssetsCardProps> = observer(
  ({ store, item, openAssetDetails, hideDetails, displayArticle, assetType }: IAssetsCardProps) => {
    const {
      asset: { id: metadataId },
      activeDigitalContent,
    } = item;

    const url = `/metadata/${metadataId}/activeDigitalContent`;

    const contentType = getAssetContentType(item);

    return (
      <div id={`asset-item-${item.asset.id}`} className="AssetsCard card card--overflow card-status-default">
        <div className="card__inner-container">
          <div className="card__header">
            {store && (
              <div className="card__select">
                <label className="checkbox checkbox--lg">
                  <input
                    className="checkbox"
                    name="optcheck"
                    type="checkbox"
                    checked={store.selectedAssets.includes(item)}
                    onChange={() => store.toggleAsset(item)}
                  />
                  <Icon icon="icon-square" color="#808080" height={22} width={22} />
                  <Icon icon="icon-checkbox-filled" height={22} width={22} />
                </label>
              </div>
            )}

            {activeDigitalContent && !displayArticle && (
              <div className="card__action-container bg-1.shape-rounded">
                <div className="card__actions">
                  <div className="overflow-menu">
                    <div className="overflow-menu__button-bar">
                      <a
                        className="downloadButtonAnchor btn btn-icon btn-sm btn-default btn-rounded"
                        href={url}
                        onClick={(e) => {
                          DownloadUtils.handleItemDownload(e, url, DownloadUtils.getFileNameWithExtension(activeDigitalContent));
                        }}
                      >
                        <Icon icon="icon-download" color="#808080" height={22} width={22} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <AssetCardImage
              className={`card__image-container AssetsCard-image ${openAssetDetails && 'AssetsCard-image-pointer'}`}
              width={260}
              height={260}
              assetType={assetType}
              item={item}
              contentType={contentType}
              onClick={openAssetDetails && openAssetDetails}
            />
          </div>
          <div className="card__status">
            <span className="card__status-text" />
          </div>
          <AssetCardBody
            activeDigitalContent={activeDigitalContent}
            contentType={contentType}
            displayArticle={displayArticle}
            hideDetails={hideDetails}
            item={item}
            openAssetDetails={openAssetDetails}
          />
        </div>
      </div>
    );
  },
);

export default AssetsCard;
