/* eslint-disable max-len */
import React from 'react';

interface LogoProps {
  className?: string;
  height: string;
  width: string;
}

const GateTitleText = ({ height, width, className = '' }: LogoProps): JSX.Element => (
  <svg height={height} width={width} viewBox="0 0 57 18" className={className}>
    <path
      fill="#fff"
      d="M18.077 8.987V7.703H8.751v3.978h3.926c-.75 1.259-1.926 1.863-3.376 1.863-2.35 0-4.25-1.813-4.25-4.43 0-2.67 1.8-4.532 4.225-4.532 1.6 0 2.776.705 3.776 1.989l3.875-2.795C15.827 1.964 13.327 0 9.401 0 3.675 0 0 4.33 0 9.038 0 13.998 3.9 18 9.251 18c4.85 0 8.826-3.55 8.826-9.013zM25.873 8.434c1.475 0 2.65 1.183 2.65 2.718 0 1.51-1.175 2.72-2.65 2.72-1.45 0-2.675-1.21-2.675-2.72 0-1.535 1.225-2.718 2.675-2.718zm2.6 9.188h4.4V4.582h-4.4v.856c-1.05-.68-2.15-.957-3.2-.957-3.5 0-6.4 2.87-6.4 6.596 0 3.776 2.9 6.62 6.4 6.62 1.05 0 2.15-.276 3.2-.956v.881zM40.19 4.582V1.385h-4.4v3.197h-1.826v3.952h1.825v4.406c0 3.222 1.426 4.682 4.851 4.682h2.475v-3.726H41.59c-.975 0-1.4-.453-1.4-1.535V8.534h2.925V4.582H40.19zM56.045 15.03l-2.976-2.065c-.625.78-1.6 1.385-2.85 1.385-1.3 0-2.35-.756-2.8-1.964h9.126c.025-.227.025-1.31.025-1.385 0-3.826-2.625-6.721-6.5-6.721-3.876 0-6.952 2.97-6.952 6.822 0 3.978 3.05 6.848 7.076 6.848 2.85 0 4.8-1.385 5.85-2.92zm-6.076-7.251c.875 0 1.875.403 2.3 1.636h-4.775c.425-1.007 1.25-1.636 2.475-1.636z"
    />
  </svg>
);

export default GateTitleText;
