import React from 'react';
import Icon from '../../components/icons/Icon';
import './Button.scss';

export enum ButtonType {
  Default,
  Primary,
  Secondary,
}

export enum ButtonSize {
  Small,
  Default,
  Big,
}

interface IButtonProps extends React.ComponentProps<'button'> {
  children: React.ReactNode;
  buttonType: ButtonType;
  size: ButtonSize;
  className?: string;
  disabled?: boolean;
  icon?: string;
  iconColor?: string;
  text?: string;
  css?: { [key: string]: string | number };
}

const Button = ({ className, buttonType, size, disabled, children, icon, iconColor, css = {}, ...rest }: IButtonProps): JSX.Element => {
  let buttonClass = 'btn';

  switch (size) {
    case ButtonSize.Default:
      break;
    case ButtonSize.Small:
      buttonClass += ' btn-sm';
      break;
    case ButtonSize.Big:
      buttonClass += ' btn-lg';
      break;
  }

  switch (buttonType) {
    case ButtonType.Primary:
      buttonClass += ' btn-primary';
      break;
    case ButtonType.Secondary:
      buttonClass += ' btn-secondary';
      break;
    case ButtonType.Default:
      buttonClass += ' btn-default';
      break;
  }

  return (
    <button className={`${buttonClass} ${disabled ? 'disabled' : ''} ${className ?? ''}`} style={css} disabled={!!disabled} {...rest}>
      {icon && <Icon icon={icon} height={22} width={22} color={iconColor} />}
      <span>{children}</span>
    </button>
  );
};

export default Button;
