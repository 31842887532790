import React from 'react';
import cn from 'classnames';
import { AssetData } from 'stores/assets/data/AssetData';
import DownloadUtils from 'utils/DownloadUtils';
import AssetUtils from 'utils/AssetUtils';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Formatter from 'utils/Formatter';

import './AssetsHistory.scss';

interface IAssetsHistory {
  className?: string;
  item: AssetData;
}

const AssetsHistory: React.FC<IAssetsHistory> = observer(({ className, item }) => {
  const { t } = useTranslation();
  const getSpecificDigitalContentUrl = `/metadata/${item.asset.id}/digitalContent`;
  const checkInUser = AssetUtils.getCheckInUser(item);
  const digitalList = item.asset.digitalContentList;

  return (
    <div className={cn('AssetsHistory space-m-t-4', className)}>
      {digitalList
        ?.slice()
        .sort((firstEl, secondEl) => {
          const { uploadTime: firstElUploadTime } = firstEl;
          const { uploadTime: secondElUploadTime } = secondEl;

          if (firstElUploadTime > secondElUploadTime) {
            return -1;
          } else if (firstElUploadTime < secondElUploadTime) {
            return 1;
          }

          return 0;
        })
        .map((item, index) => {
          const createdBy = item.attributeList?.find((attr) => attr.name === 'createdBy');

          return (
            <div className="space-m-t-4 AssetsHistory-asset-history" key={index}>
              <div className="AssetsHistory-asset-history-info">
                {item.version && (
                  <div className="AssetsHistory-asset-history-item space-m-b-3">
                    <div className="AssetsHistory-asset-history-item AssetsHistory-asset-history-item-head">{t('details.history.version')}</div>
                    <div className="">{item.version}</div>
                  </div>
                )}
                {item.uploadTime && (
                  <div className="AssetsHistory-asset-history-item space-m-b-3">
                    <div className="AssetsHistory-asset-history-item AssetsHistory-asset-history-item-head">{t('details.history.date')}</div>
                    <div>{Formatter.getDateTime(new Date(item.uploadTime).toISOString())}</div>
                  </div>
                )}
                {item.fileSize && (
                  <div className="AssetsHistory-asset-history-item space-m-b-3">
                    <div className="AssetsHistory-asset-history-item AssetsHistory-asset-history-item-head">{t('details.history.file_size')}</div>
                    <div className="">{item.fileSize && Formatter.getFileSizeFromBytes(item.fileSize, 1)}</div>
                  </div>
                )}
                {createdBy && (
                  <div className="AssetsHistory-asset-history-item space-m-b-3">
                    <div className="AssetsHistory-asset-history-item AssetsHistory-asset-history-item-head">{t('details.history.check_in')}</div>
                    <div className="">{createdBy ? createdBy.value : `${checkInUser}`}</div>
                  </div>
                )}
              </div>
              <div className="space-m-b-3">
                <span
                  className="link AssetsHistory-link"
                  onClick={(e) => {
                    DownloadUtils.handleItemDownload(
                      e,
                      `${getSpecificDigitalContentUrl}/${item.version}/download`,
                      DownloadUtils.getFileNameWithExtension(item),
                    );
                  }}
                >
                  {t('details.download').toUpperCase()}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
});

export default AssetsHistory;
