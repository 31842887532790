import React, { useEffect } from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { AssetType, UIAssetDomainsNamesEnums } from '@cdam/shared';
import AssetsStore from '../../stores/assets/AssetsStore';
import ViewStore from '../../stores/view/ViewStore';
import TableListView from './components/ListView/TableListView';

import './AssetsListView.scss';

interface IAssetsListViewProps {
  store: AssetsStore;
}

const AssetsListView: React.FC<IAssetsListViewProps> = observer(({ store }) => {
  const state = useLocalObservable(() => ({
    store,

    get displayType() {
      if (!this.store.selectedType) return null;

      if (this.store.selectedType.showModels) return AssetType.Model;
      else if (this.store.selectedType.showArticles) return AssetType.Article;

      return AssetType.Asset;
    },
  }));

  useEffect(() => {
    state.store = store;
  }, [state, store]);

  return (
    <React.Fragment>
      {store.filteredItems &&
        store.filteredItems.items.length !== 0 &&
        state.displayType &&
        store.displayItems &&
        (ViewStore.selectedUiAssetDomain === UIAssetDomainsNamesEnums.MARVIN_PI ? (
          state.displayType === AssetType.Article && store.displayItems.articles ? (
            <TableListView
              store={store}
              articleItems={store.displayItems.articles}
              type={store.selectedType}
              items={store.displayItems.items}
              displayType={state.displayType}
            />
          ) : (
            <TableListView store={store} type={store.selectedType} items={store.displayItems.items} displayType={state.displayType} />
          )
        ) : (
          <TableListView store={store} type={store.selectedType} items={store.displayItems.items} displayType={AssetType.Asset} />
        ))}
    </React.Fragment>
  );
});

export default AssetsListView;
