import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as EmptyIcon } from '../../../assets/icon/empty.svg';

import './UDMEmpty.scss';

const UDMEmpty = (): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="UDMEmpty-empty space-p-b-3">
      <EmptyIcon className="space-m-r-3" />
      <span className="UDMEmpty-empty-message">{t('common.nothing_here')}</span>
    </div>
  );
};

export default UDMEmpty;
