import React from 'react';
import { useTranslation } from 'react-i18next';
import { assetGroupIdsEnum, UIAssetDomainsNamesEnums } from '@cdam/shared';
import { ReactComponent as MarketingContentIcon } from '../../../assets/icon/marketing-content.svg';
import { ReactComponent as ProductImagesIcon } from '../../../assets/icon/product-images.svg';
import SearchStore from '../../../stores/assets/SearchStore';
import ViewStore from '../../../stores/view/ViewStore';

import './SearchFilter.scss';

interface ISearchFilterProps {
  store: SearchStore;
}

const GateSearchFilter = ({ store }: ISearchFilterProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="SearchFilter" data-testid="search-view-browse-by-content">
      <h4 className="SearchFilter-heading space-m-t-6">{t('matrix.browse_content_type_heading')}</h4>
      <div className="SearchFilter-conatiner space-m-t-6">
        {ViewStore.usersUiDomainsForSelectedAppVariant.includes(UIAssetDomainsNamesEnums.MARVIN_MCT) && (
          <div className="SearchFilter-item box-shadow-2 space-m-r-4" onClick={() => store.browseByContentType(assetGroupIdsEnum.MARKETING_CONTENT)}>
            <div className="SearchFilter-icon">
              <MarketingContentIcon data-testid="search-view-marketing-content" />
            </div>
            <p className="SearchFilter-description">{t('matrix.marketing_content')}</p>
          </div>
        )}
        {ViewStore.usersUiDomainsForSelectedAppVariant.includes(UIAssetDomainsNamesEnums.MARVIN_PI) && (
          <div className="SearchFilter-item box-shadow-2 space-m-r-4" onClick={() => store.browseByContentType(assetGroupIdsEnum.PRODUCT_IMAGES)}>
            <div className="SearchFilter-icon">
              <ProductImagesIcon data-testid="search-view-product-images" />
            </div>
            <p className="SearchFilter-description">{t('matrix.product_images')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default GateSearchFilter;
