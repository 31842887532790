import React, { useCallback, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from '../../../components/icons/Icon';
import AssetsFilterArticleNumbers from '../../assets/components/Filters/AssetsFilterArticleNumbers';

import './SearchArticleNumbers.scss';

interface ISearchArticleNumbers {
  onSearch(values: Array<string>): void;
  refEl: React.MutableRefObject<HTMLDivElement | null>;
  hideLeftBorder?: boolean;
  hideRightBorder?: boolean;
}

const SearchArticleNumbers = observer(
  ({ onSearch, refEl, hideLeftBorder, hideRightBorder }: ISearchArticleNumbers): JSX.Element => {
    const { t } = useTranslation();
    const className = 'ArticleNumberSearch';
    const searchContainer: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const [openUncontrolled, setOpenUncontrolled] = useState(false);
    const [popupWidth, setPopupWidth] = useState(0);
    const [validArticleNumbers, setValidArticleNumbers] = useState(0);
    const [searchValue, setSearchValue] = useState('');

    const closePopup = useCallback(
      (e: Event): void => {
        const clickedElement = e.target as HTMLElement;

        if (clickedElement?.closest) {
          const closestFilterDropDown = clickedElement.closest('.ArticleNumberSearch');

          if (closestFilterDropDown) {
            return;
          }
        }

        if (openUncontrolled) {
          setOpenUncontrolled(false);
        }
      },
      [openUncontrolled],
    );

    const handleClick = (): void => {
      if (refEl.current?.clientWidth && searchContainer.current?.offsetWidth) {
        setPopupWidth(refEl.current.clientWidth + searchContainer.current.offsetWidth - 1);
      }

      setOpenUncontrolled(!openUncontrolled);
    };

    useEffect(() => {
      document.addEventListener('click', closePopup);
      document.addEventListener('touch', closePopup);
      window.addEventListener('resize', () => setOpenUncontrolled(false));
    });

    return (
      <div className={className}>
        <div
          className={`${className}-container ${hideLeftBorder ? `${className}-no-left-border` : ''} ${
            hideRightBorder ? `${className}-no-right-border` : ''
          }`}
          ref={searchContainer}
          onClick={handleClick}
        >
          <div className={`${className}-info`}>
            {validArticleNumbers === 0 ? (
              <div className={`${className}-info-label-placeholder`}>{t('filters.article_number.keyword_search_label')}</div>
            ) : (
              <div className={`${className}-info-label`}>
                {t('filters.article_number.search_in')}
                <i>
                  {` ${validArticleNumbers} `}
                  {t('filters.article_number.articles')}
                </i>
              </div>
            )}
          </div>
          <div className={`${className}-icon`}>
            <Icon icon={`${openUncontrolled ? 'icon-arrow-dropdown-up' : 'icon-arrow-dropdown-down'}`} color={'#000'} height={22} width={22} />
          </div>
        </div>
        {openUncontrolled && popupWidth && (
          <div className={`${className}-popup`}>
            <AssetsFilterArticleNumbers
              showTopBorder={false}
              width={popupWidth}
              selectedValue={searchValue}
              onSearchComplete={(validValues) => {
                setValidArticleNumbers(validValues.length);
                onSearch(validValues);
              }}
              onChangeValue={(value) => {
                setSearchValue(value);
              }}
            />
          </div>
        )}
      </div>
    );
  },
);

export default SearchArticleNumbers;
