import React from 'react';

interface ITimePickerControlled extends Omit<React.ComponentProps<'input'>, 'onChange' | 'value'> {
  className?: string;
  label?: string;
  name?: string;
  handleChange(date: string): void;
  value: string | undefined;
}

function formatNumber(number: number): string {
  if (number < 10) {
    return `0${number}`;
  }

  return number.toString();
}

function normalizeDate(date: string | undefined): string | undefined {
  if (!date) {
    return undefined;
  }

  const d = new Date(date);

  return `${d.getFullYear()}-${formatNumber(d.getMonth() + 1)}-${formatNumber(d.getDate())}T${formatNumber(d.getHours())}:${formatNumber(
    d.getMinutes(),
  )}`;
}

const TimePicker = ({ className, label, name, handleChange, value, ...rest }: ITimePickerControlled): JSX.Element => (
  <div className={`${className} form-group`}>
    {label && (
      <label htmlFor={name} className="control__label">
        {label}
      </label>
    )}
    <div className="form-control__container">
      <input
        type="datetime-local"
        name={name}
        className="form-control"
        {...rest}
        value={normalizeDate(value)}
        onChange={(e) => handleChange(new Date(e.target.value).toISOString())}
      />
    </div>
  </div>
);

export default TimePicker;
