import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { v4 } from 'uuid';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import UploadArchiveAssetsStore from 'stores/upload/UploadArchiveAssetsStore';
import { Loader } from '../../components/loader/Loader';
import { ReactComponent as UploadIcon } from '../../assets/icon/upload-document.svg';

import './ProductImageUploadZone.scss';

interface IArchiveUploadZoneProps {
  className?: string;
  label?: string;
  store: UploadArchiveAssetsStore;
}

const ArchiveUploadZone = observer(
  ({ store, label, className }: IArchiveUploadZoneProps): JSX.Element => {
    const { t } = useTranslation();

    const onDrop = useCallback(
      async (acceptedFiles: Array<File>) => {
        store.setUploadLoading(true);

        const files = acceptedFiles.map(async (file) => store.handleFileDrop(file.name, file, file.type, file.size, v4()));

        await Promise.all(files);

        store.setUploadLoading(false);
      },
      [store],
    );

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { getRootProps, getInputProps, isDragAccept } = useDropzone({ onDrop });

    return (
      <section className={cn('ProductImageUploadZone', className)}>
        {label && <label className="control__label">{label}</label>}

        <div className="ProductImageUploadZone-square-container">
          <div
            {...getRootProps({ className: `ProductImageUploadZone-dropzone ${isDragAccept ? 'ProductImageUploadZone-dropzone-active' : undefined}` })}
          >
            {store.isUploadLoading ? (
              <Loader />
            ) : (
              <div className="ProductImageUploadZone-text-container">
                <UploadIcon />
                <input {...getInputProps()} />
                <div className="ProductImageUploadZone-dropzone-content">
                  <div className="body-xs space-m-l-4">
                    {t('common.drag_drop')} {t('common.or')} <span className="ProductImageUploadZone-browse-text">{t('common.browse')}</span>{' '}
                    {t('common.to_choose_file')}.
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    );
  },
);

export default ArchiveUploadZone;
