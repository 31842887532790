/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IMatrixOwnersItem } from '@cdam/shared';
import Icon from 'components/icons/Icon';
import TypeaheadSearchStore, { ITypeaheadSearchItem } from 'components/typeaheadSearch/store/TypeaheadSearchStore';
import TypeaheadSearch from 'components/typeaheadSearch/TypeaheadSearch';

import './MatrixSettingsOwners.scss';

export type IMatrixOwnerSearchable = ITypeaheadSearchItem & IMatrixOwnersItem;

interface IMatrixSettingsOwnersProps {
  addItem(owner: IMatrixOwnerSearchable): void;
  removeItem(index: number): void;
  getAllItems?(): Promise<Array<IMatrixOwnerSearchable>>;
  label?: string;
  allItems?: Array<IMatrixOwnerSearchable>;
  itemList: Array<IMatrixOwnersItem>;
}

const MatrixSettingsOwners = observer(
  ({ allItems, addItem, removeItem, itemList, getAllItems, label }: IMatrixSettingsOwnersProps): JSX.Element => {
    const { t } = useTranslation();

    const [searchStore] = useState(() => new TypeaheadSearchStore<IMatrixOwnerSearchable>(addItem, removeItem));

    return (
      <>
        <div className="MatrixSettingsOwners">
          <TypeaheadSearch store={searchStore} inputLabel={label ?? t('common.add_owner_label')} allItems={allItems} getAllItems={getAllItems} />
        </div>
        <div className="space-m-t-4">
          <div className="MatrixSettingsOwners-owners-list  space-m-b-2">
            <div className="caption caption--lg">{t('common.full_name')}</div>
          </div>
          <div className="MatrixSettingsOwners-container">
            {itemList.map((owner, index) => {
              const displayedName = owner.searchableText;

              return (
                <div key={index} className="MatrixSettingsOwners-owners-list MatrixSettingsOwners-owners-list-item space-p-2">
                  <div>
                    <span className="caption">{displayedName}</span>
                  </div>
                  <div className="MatrixSettingsOwners-owners-list-item-icon">
                    <Icon
                      icon="icon-delete"
                      className="MatrixSettingsPermission-delete-icon"
                      height={22}
                      width={22}
                      onClick={() => searchStore.removeItem(index)}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </>
    );
  },
);

export default MatrixSettingsOwners;
