import { AssetDomainsNamesEnums, IUploadTemplate } from '@cdam/shared';

export const getEmptyTemplate = (): IUploadTemplate => ({
  id: '',
  name: '',
  domain: AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME,
  createdOn: new Date().toISOString(),
  attributes: [],
  extractArticleNumbers: true,
  extractProductViews: true,
});
