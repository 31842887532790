import React from 'react';
import { observer } from 'mobx-react-lite';
import { AssetData } from 'stores/assets/data/AssetData';
import { AssetType } from '@cdam/shared';
import AssetsStore from '../../../../stores/assets/AssetsStore';
import AssetsCard from '../AssetsCard';

import './AssetCards.scss';

interface IAssetsViewProps {
  assetsStore: AssetsStore;
  items: Array<AssetData>;
}

function getEmptyItems(count: number): React.ReactNode {
  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(<div key={i} className="AssetCards-assets-card-view-empty" />);
  }

  return items;
}

const AssetCards: React.FC<IAssetsViewProps> = observer(({ assetsStore, items }: IAssetsViewProps) => {
  const openAssetDetails = (item: AssetData, assetType: AssetType): void => {
    assetsStore.openAssetDetailsDialog(item, assetType);
  };

  return (
    <div className="AssetCards-assets-card-view-cardlist">
      {items.map((item) => (
        <AssetsCard key={item.asset.id} store={assetsStore} item={item} openAssetDetails={() => openAssetDetails(item, AssetType.Asset)} />
      ))}
      {getEmptyItems(20)}
    </div>
  );
});

export default AssetCards;
