import { IDownloadFileFormat } from '@cdam/shared/src/interfaces/Asset';
import { CustomType } from '@cdam/shared';

// eslint-disable-next-line complexity
export const validateCustomImageValues = (format: IDownloadFileFormat, changeValue: string, type: CustomType): boolean => {
  const value = parseInt(changeValue);

  if (format.parameters && !isNaN(value)) {
    switch (type) {
      case CustomType.Resolution:
        if (value < 18 || value > 9999 || !value) {
          return false;
        }

        break;
      case CustomType.Height:
        if (value < 1 || value > 9999 || !value) {
          return false;
        }

        break;
      case CustomType.Width:
        if (value < 1 || value > 9999 || !value) {
          return false;
        }

        break;
    }

    return true;
  }

  return false;
};
