import { HandledError } from '@cdam/shared';
import i18n from '../i18n';

export default {
  getErrorMessage(error: Error): string {
    let text;

    // TODO: refactor to use HandledError translations
    if (error instanceof HandledError || error.message) {
      text = error.message;

      // const errorKey = `error.${error.message}`;

      // if (i18n.exists(errorKey)) {
      //   if (error.data) {
      //     text = i18n.t(errorKey, error.data);
      //   } else {
      //     text = i18n.t(errorKey);
      //   }
      // }
    }

    if (!text) {
      text = i18n.t('error.default');
    }

    return text;
  },
};
