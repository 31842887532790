import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ITransformationArguments } from '@cdam/shared';
import { AssetData } from 'stores/assets/data/AssetData';
import { Loader } from 'components/loader/Loader';
import { ImageStore } from '../../stores/images/ImageStore';

import './CoreDamImage.scss';

interface ICoreDamImage {
  metadataId: string;
  fileName: string;
  etag: string;
  width?: number;
  height?: number;
  transformationArgs?: ITransformationArguments;
  className?: string;
  imageExtension: string | undefined;
  item?: AssetData;
}

const CoreDamImage: React.FC<ICoreDamImage> = observer(
  ({ metadataId, className, width, transformationArgs, imageExtension, height, etag }: ICoreDamImage) => {
    const [store] = useState(() => new ImageStore(metadataId, etag, imageExtension, height, width));

    useEffect(() => {
      store.load(transformationArgs);

      return () => {
        store.cancelDownload();
      };
    }, [transformationArgs, store]);

    return store.isLoading ? (
      <Loader small={!!(width && width < 100)} />
    ) : (
      <div className={'CoreDamImage'}>
        <img
          className={className}
          src={store.blobUrl}
          alt=""
          style={{ maxWidth: store.imageWidth, maxHeight: width /* disable-stylelint */ }}
          onError={() => {
            store.setError();
          }}
        />
      </div>
    );
  },
);

export default CoreDamImage;
