import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import './TemplateSearch.scss';

interface ITemplateSearch {
  searchRef: React.MutableRefObject<HTMLInputElement | null>;
  onSearch(searchString: string): void;
}

function TemplateSearch({ searchRef, onSearch }: ITemplateSearch): JSX.Element {
  const { t } = useTranslation();
  const [searchString, setSearchString] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    onSearch(e.currentTarget.value);
    setSearchString(e.currentTarget.value);
  };

  return (
    <div className="form-group TemplateSearch">
      <div className="form-control__container TemplateSearch-form">
        <svg className="yarn-svg-icon space-m-r-1">
          <use xlinkHref="#icon-search" />
        </svg>
        <input
          ref={searchRef}
          type="text"
          className="TemplateSearch-input"
          placeholder={t('templates.search_placeholder')}
          value={searchString}
          onChange={(e) => {
            handleChange(e);
          }}
        />
      </div>
    </div>
  );
}

export default TemplateSearch;
