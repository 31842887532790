import React from 'react';
import { observer } from 'mobx-react-lite';
import { IFilterDropdownProps } from '@cdam/shared';
import { ListLoader } from '../../../../components/loader/ListLoader';
import DropdownItem from '../../../../components/dropdown/DropdownItem';

const AssetsFilterSingleSelect = observer(
  ({ isLoading, error, onToggleSingleSelectedItem, filteredItems }: IFilterDropdownProps): JSX.Element => (
    <React.Fragment>
      {isLoading !== null && isLoading !== undefined && <ListLoader isLoading={isLoading} itemsLength={filteredItems?.length ?? 0} error={error} />}
      <ul className="list dropdown__options" style={{ boxShadow: 'none', position: 'static', maxHeight: '18.75rem' /* disable-stylelint */ }}>
        {filteredItems?.map((item, index) => (
          <React.Fragment key={index}>
            <DropdownItem value={item.text} onClick={() => onToggleSingleSelectedItem?.(item)} />
          </React.Fragment>
        ))}
      </ul>
    </React.Fragment>
  ),
);

export default AssetsFilterSingleSelect;
