/* eslint-disable @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return*/
import { action, computed, observable, makeObservable, runInAction } from 'mobx';
import { AppVariantEnum, AssetDomainsNamesEnums, AssetDomainConfig, UIAssetDomainsNamesEnums, PermissionsEnums, ITaxonomy } from '@cdam/shared';
import StorageService from '../../services/StorageService';
import ProfileStore from '../profile/ProfileStore';
import TaxonomyStore from '../taxonomy/TaxonomyStore';
import i18n from '../../i18n';
import GateDb from '../../db/indexdb';
import { IDisplayColumns } from '../assets/AssetsStore';

class ViewStore {
  @observable public selectedUiAssetDomain: UIAssetDomainsNamesEnums = UIAssetDomainsNamesEnums.MARVIN_MCT;
  @observable public displayDomain = false;

  @observable public isLoading = true;

  public readonly clientArchiveUIVersion: number = 1.0;
  public readonly clientGateUIVersion: number = 1.0;
  public readonly metadataAkamaiUrl: string = '';
  public readonly metadataUrl: string = '';
  public readonly repositoryUrl: string = '';
  public readonly uploadUrl: string = '';

  private readonly assetDomainData: AssetDomainConfig;

  private readonly cachedImageMaxAge: Date;
  private readonly cachedImageMaxAgeDays = 7;

  public constructor() {
    makeObservable(this);

    const assetDomainDataString = window.ASSET_DOMAIN_DATA;

    this.assetDomainData = JSON.parse(assetDomainDataString) as AssetDomainConfig;

    const selectedUiAssetDomain = StorageService.selectedAssetDomain ?? this.resolveDefaultUiAssetDomain();

    this.setSelectedUiAssetDomain(selectedUiAssetDomain);

    this.clientArchiveUIVersion = window.APP_ARCHIVE_UI_VERSION;
    this.clientGateUIVersion = window.APP_GATE_UI_VERSION;
    this.metadataAkamaiUrl = window.COREDAM_API_METADATA_URL;
    this.metadataUrl = window.COREDAM_API_METADATA_URL;
    this.repositoryUrl = window.COREDAM_API_REPOSITORY_URL;
    this.uploadUrl = `${this.repositoryUrl}/assets`;

    const today = new Date();

    today.setDate(today.getDate() - this.cachedImageMaxAgeDays);
    this.cachedImageMaxAge = today;

    this.setDocumentTitle();
    // this.initReferenceData
    this.clearCache();
  }

  @computed
  public get appVariant(): AppVariantEnum {
    if (process.env.REACT_APP_APP_TYPE === 'archive') {
      return AppVariantEnum.ArchiveUI;
    }

    // const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    // if (selectedAssetDomainData) {
    //   return selectedAssetDomainData.appVariant;
    // }

    return window.location.hostname.includes('archive') ? AppVariantEnum.ArchiveUI : AppVariantEnum.GateUI;
  }

  @computed
  public get selectedAssetDomain(): AssetDomainsNamesEnums {
    return this.assetDomainData[this.selectedUiAssetDomain].assetDomainName ?? this.getDefaultAssetDomain();
  }

  @computed
  public get metadataAssetDomainList(): string {
    const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    if (selectedAssetDomainData) {
      return selectedAssetDomainData.metadataSearchAssetDomainList;
    }

    return AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME;
  }

  @computed
  public get uploadAssetDomainList(): string {
    const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    if (selectedAssetDomainData) {
      return selectedAssetDomainData.uploadAssetDomainList;
    }

    return AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME;
  }

  @computed
  public get uploadStorage(): string {
    const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    if (selectedAssetDomainData) {
      return selectedAssetDomainData.uploadStorage;
    }

    return 'MARVIN';
  }

  @computed
  public get uploadAssetKindName(): string {
    const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    if (selectedAssetDomainData) {
      return selectedAssetDomainData.uploadAssetKindName;
    }

    return 'private';
  }

  @computed
  public get uploadClientName(): string {
    const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    if (selectedAssetDomainData) {
      return selectedAssetDomainData.uploadClientName;
    }

    return 'marvin4';
  }

  @computed
  public get uploadAssetGroup(): string {
    const selectedAssetDomainData = this.assetDomainData[this.selectedUiAssetDomain];

    if (selectedAssetDomainData) {
      return selectedAssetDomainData.uploadAssetGroup;
    }

    return 'marvin4_test_content';
  }

  @computed
  public get navbarSubHeading(): string {
    switch (this.selectedUiAssetDomain) {
      case UIAssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
        return 'Dab';
      case UIAssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
        return 'Splash';
      default:
        return '';
    }
  }

  @computed
  public get tableColumns(): Array<IDisplayColumns> {
    return [] as Array<IDisplayColumns>;
  }

  // User Permissions
  @computed
  public get canView(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.READ);
  }

  @computed
  public get canEdit(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.EDIT);
  }

  @computed
  public get canUpload(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.CREATE);
  }

  @computed
  public get canDelete(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.DELETE);
  }

  @computed
  public get canAccessConfidential(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.CONFIDENTIAL);
  }

  @computed
  public get canCreateTemplates(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.TEMPLATE);
  }

  @computed
  public get isAdmin(): boolean {
    return ProfileStore.hasPermission(this.selectedUiAssetDomain, PermissionsEnums.ADMIN);
  }

  @computed
  public get showUploadButton(): boolean {
    if (this.appVariant === AppVariantEnum.GateUI) {
      return this.canUpload;
    }

    return this.canUpload && this.displayDomain;
  }

  // ZipTurnTable in the Marvin domain needs a special query param
  @computed
  public get zipTurntableId(): string {
    let zipTurntableId = '107';
    let marvinTaxonomy: ITaxonomy | undefined;

    (async () => {
      marvinTaxonomy = await GateDb.taxonomy.get(AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME);
    })();

    if (marvinTaxonomy?.attributeGroupList) {
      const group = marvinTaxonomy.attributeGroupList.find((group) => group.name === 'Asset Information');

      if (group) {
        const assetCategory = group.attributeList.find((attribute) => attribute.name === 'assetCategory');

        if (assetCategory?.referenceDataList) {
          const zipTurntable = assetCategory.referenceDataList.find((reference) =>
            reference.attributeList.find((attribute) => attribute.value === 'ZIP - Turntable'),
          );

          if (zipTurntable) {
            zipTurntableId = zipTurntable.id;
          }
        }
      }
    }

    return zipTurntableId;
  }

  // Single case check for splash archive domain, Agency users can not upload files with `hidden` `true`
  @computed
  public get canAddHiddenRefData(): boolean {
    if (this.selectedUiAssetDomain === UIAssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME) {
      return this.isAdmin;
    }

    return true;
  }

  @computed
  public get usersUiDomainsForSelectedAppVariant(): Array<UIAssetDomainsNamesEnums> {
    return ProfileStore.usersUiAssetDomains.filter((uiAssetDomain) => this.assetDomainData[uiAssetDomain].appVariant === this.appVariant);
  }

  @action
  public async initStores(): Promise<void> {
    this.isLoading = true;

    try {
      await ProfileStore.init();
      await TaxonomyStore.init(this.selectedAssetDomain);
    } catch (error) {
      console.error(error);
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  @action
  public async setSelectedUiAssetDomain(assetDomain: UIAssetDomainsNamesEnums): Promise<void> {
    runInAction(() => {
      this.isLoading = true;
    });

    let domain = assetDomain;

    if (!this.isSelectedAssetDomainValid(assetDomain)) {
      domain = this.resolveDefaultUiAssetDomain();
    }

    this.selectedUiAssetDomain = domain;
    StorageService.selectedAssetDomain = domain;

    await TaxonomyStore.setSelectedTaxonomy(this.selectedAssetDomain);

    runInAction(() => {
      this.isLoading = false;
    });
  }

  @action
  public changeSelectedGateUIAssetDomainSync(assetDomain: UIAssetDomainsNamesEnums): void {
    this.selectedUiAssetDomain = assetDomain;
    StorageService.selectedAssetDomain = assetDomain;
  }

  @action
  public toggleDisplayDomain(): void {
    this.displayDomain = !this.displayDomain;
  }

  public shouldGetDeprecatedReferenceData(referenceDataType: string): boolean {
    const config = this.assetDomainData[this.selectedUiAssetDomain];

    if (!config || !config.extendReferenceDataWithPrefix || config.extendReferenceDataWithPrefix.includes('*')) {
      return true;
    }

    return config.extendReferenceDataWithPrefix.some((substring) => referenceDataType.includes(substring));
  }

  public getUiAssetDomainName(uiAssetDomain: UIAssetDomainsNamesEnums): string {
    const assetDomainTranslation = `ui_asset_domains.${uiAssetDomain}`;

    return i18n.exists(assetDomainTranslation) ? i18n.t(assetDomainTranslation) : uiAssetDomain.replace(/_/g, ' ');
  }

  private resolveDefaultUiAssetDomain(): UIAssetDomainsNamesEnums {
    if (window.location.hostname.includes('archive') || process.env.REACT_APP_APP_TYPE === 'archive') {
      return UIAssetDomainsNamesEnums.DABOX_TAXONOMY_NAME;
    }

    return UIAssetDomainsNamesEnums.MARVIN_MCT;
  }

  private getDefaultAssetDomain(): AssetDomainsNamesEnums {
    if (window.location.hostname.includes('archive') || process.env.REACT_APP_APP_TYPE === 'archive') {
      return AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME;
    }

    return AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME;
  }

  private isSelectedAssetDomainValid(uiAssetDomain: UIAssetDomainsNamesEnums): boolean {
    return this.assetDomainData[uiAssetDomain].appVariant === this.appVariant;
  }

  private setDocumentTitle(): void {
    if (window.location.hostname.includes('archive') || this.assetDomainData[this.selectedUiAssetDomain].appVariant === AppVariantEnum.ArchiveUI) {
      document.title = 'Archive UI';
    } else {
      document.title = 'Gate UI';
    }
  }

  private async clearCache(): Promise<void> {
    try {
      await GateDb.activeDigitalContent.where('lastAccessedOn').belowOrEqual(this.cachedImageMaxAge).delete();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Could not clear cache.');
    }
  }
}

export default new ViewStore();
