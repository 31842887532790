/* eslint-disable no-await-in-loop, complexity */
import { observable, action, runInAction, computed, makeObservable } from 'mobx';
import { IMetadataAttribute } from '@cdam/shared';
import TaxonomyStore from '../taxonomy/TaxonomyStore';
import FilterUtils from '../../utils/FilterUtils';
import { ITableHeader, ITableItem } from '../../components/table/Table';
import UploadUtils from '../../utils/UploadUtils';
import { metadataOperation } from '../uploadDownloadManager/data/UploadItem';
import AssetUtils from '../../utils/AssetUtils';
import DialogStore from '../dialog/DialogStore';
import i18n from '../../i18n';
import { AssetData } from './data/AssetData';
import { AssetFilter } from './data/AssetFilter';

export default class BulkEditStore {
  @observable public editMetadataFilters: Array<AssetFilter> = [];
  @observable public assets: Array<AssetData> = [];

  public readonly headers: Array<ITableHeader> = [];

  public constructor(editMetadataFilters: Array<AssetFilter>, assets: Array<AssetData>, private readonly allowedFilters: Array<string>) {
    makeObservable(this);
    runInAction(() => {
      this.editMetadataFilters = editMetadataFilters;
      this.assets = assets;
    });

    this.headers = this.getTableHeaders(this.allowedFilters);
  }

  @computed
  public get tableItems(): Array<ITableItem> {
    return this.assets.map((asset) => {
      const attributes = asset.asset.attributeGroupList.reduce<Array<{ group: string; attr: IMetadataAttribute }>>((acc, group) => {
        const groupAttributes = group.attributeList.reduce<Array<{ group: string; attr: IMetadataAttribute }>>((groupAttr, attr) => {
          if (this.allowedFilters.includes(attr.name)) {
            groupAttr.push({ group: group.name, attr });
          }

          return groupAttr;
        }, []);

        return [...acc, ...groupAttributes];
      }, []);

      return {
        rowData: this.allowedFilters.map((filterName) => {
          const attribute = attributes.find((attr) => attr.attr.name === filterName);
          let displayValue = '';

          if (attribute) {
            const values = attribute.attr.values ? attribute.attr.values : [attribute.attr.value ?? ''];
            const referenceValue = TaxonomyStore.getTaxonomyReferenceValue(attribute.group, attribute.attr.name, values);

            displayValue =
              referenceValue && referenceValue.length !== 0
                ? referenceValue.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/_/g, ' ')
                : values.join(', ');
          }

          return displayValue;
        }),
      };
    });
  }

  @computed
  public get canSave(): boolean {
    return this.editMetadataFilters.some((filter) => !filter.hasNoValues);
  }

  @action
  public toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    return FilterUtils.toggleSelectedFilter(item, filters);
  }

  @action
  public clearAllFilters(): void {
    this.editMetadataFilters = [];
  }

  @action
  public async save(): Promise<void> {
    const failedUpdates: Array<string> = [];

    for (const asset of this.assets) {
      const attributeGroupList = asset.getUpdatedAttributeList(this.editMetadataFilters);

      try {
        const metadata = await DialogStore.execFullScreen(
          UploadUtils.metadataFullUpdate(asset.asset.id, asset.asset.etag, attributeGroupList, metadataOperation.EditMetadata, undefined, undefined),
        );

        if (metadata) {
          asset.update(metadata);
        }
      } catch (e) {
        failedUpdates.push(`Failed to updated asset ${AssetUtils.getAssetName(asset)} (id: ${asset.asset.id})`);
      }
    }

    if (failedUpdates.length !== 0) {
      DialogStore.alert(`Failed to updated items:\n${failedUpdates.join('\n')}`);
    } else {
      DialogStore.alert(i18n.t('common.asset_edit_success'));
    }

    runInAction(() => {
      this.editMetadataFilters.forEach((filter) => filter.clearAllSelectedValues());
    });
  }

  private getTableHeaders(headers: Array<string>): Array<ITableHeader> {
    return headers.map((header) => ({
      text: header
        .replace(/([a-z])([A-Z])/g, '$1 $2')
        .replace(/_/g, ' ')
        .toUpperCase(),
      mandatory: false,
    }));
  }
}
