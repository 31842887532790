/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { MatrixItemType, Brand, AppVariantEnum } from '@cdam/shared';
import MatrixStore from '../../../stores/assets/MatrixStore';
import ViewStore from '../../../stores/view/ViewStore';
import TableCell from './TableCell';
import SearchEventSelector from './SearchEventSelector';
import SearchMatrixDownloadFile from './SearchMatrixDownloadFile';

import './SearchMatrix.scss';

interface ISearchMatrixProps {
  store: MatrixStore;
}

const SearchMatrix: React.FC<ISearchMatrixProps> = observer(({ store }) => {
  const { selectedEvent, events, brand, selectedEventId } = store;

  if (ViewStore.appVariant !== AppVariantEnum.GateUI && ViewStore.canView) {
    return null;
  }

  return (
    <div className="SearchMatrix" data-testid="search-view-matrix-wrapper">
      {brand && events && events.released && (
        <SearchEventSelector events={events.released} brand={brand} selectedEventId={selectedEventId} data-testid="search-view-matrix-selector" />
      )}
      {selectedEvent && (
        <React.Fragment>
          <div className="SearchMatrix-container" data-testid="search-view-matrix">
            <table className="SearchMatrix-table">
              <thead>
                <tr className="SearchMatrix-table-row">
                  <th />
                  <th />
                  {selectedEvent.matrix.xAxis.map((as) => (
                    <th key={as.id}>
                      <div className="SearchMatrix-table-heading-relative-container">
                        <div className="SearchMatrix-table-heading-content">{as.name}</div>
                      </div>
                    </th>
                  ))}
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody onMouseLeave={() => store.selectCell(-1, -1, false)}>
                {selectedEvent.matrix.yAxis.map((yAxis, rowIndex) => (
                  <tr className="SearchMatrix-table-row" key={yAxis.id}>
                    <td className="SearchMatrix-cell-empty" />
                    <td
                      className={`SearchMatrix-table-cell-business-unit ${
                        yAxis.type === MatrixItemType.Group || yAxis.type === MatrixItemType.Standalone ? 'bold' : ''
                      }`}
                      onMouseEnter={() => store.selectCell(-1, -1, false)}
                    >
                      {yAxis.name.toUpperCase()}
                    </td>
                    {selectedEvent.matrix.xAxis.map((_, columnIndex) => (
                      <TableCell
                        key={`${rowIndex}${columnIndex}`}
                        brand={Brand.Adidas}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        store={store}
                        cell={store.cells[rowIndex * selectedEvent.matrix.xAxis.length + columnIndex]}
                        borderTop={yAxis.type === MatrixItemType.Group || yAxis.type === MatrixItemType.Standalone}
                        evenRow={rowIndex % 2 === 0}
                        data-testid="search-view-matrix-cell"
                      />
                    ))}
                    <td className="SearchMatrix-cell-business-empty" onMouseEnter={() => store.selectCell(-1, -1, false)} />
                    <td className="SearchMatrix-cell-empty" />
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {store.selectedEvent?.whereToFindWhatUploaded && <SearchMatrixDownloadFile handleClick={store.downloadWhereToFindWhatPdf} />}
        </React.Fragment>
      )}
    </div>
  );
});

export default SearchMatrix;
