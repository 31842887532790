import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import Dropdown from 'components/dropdown/Dropdown';
import './DownloadAssetCard.scss';
import cn from 'classnames';
import { CustomType, DownloadFormats } from '@cdam/shared';
import DropdownCheckboxItem from 'components/dropdown/DropdownCheckboxItem';
import DropdownCheckboxItemCustom from 'components/dropdown/DropdownCheckboxItemCustom';
import { IDownloadFileFormat } from '@cdam/shared/src/interfaces/Asset';
import { useTranslation } from 'react-i18next';
import { DownloadableAssetData } from '../../../../stores/assets/AdvancedDownloadStore';
import { getFileFormatTypeFromExtension, readFileExtension } from '../../../../utils/FileFormatParser';

import './DownloadFormatSelectDropdown.scss';

interface IDownloadFormatSelectDropdownProps {
  className?: string;
  asset: DownloadableAssetData;
}

const DownloadFormatSelectDropdown: React.FC<IDownloadFormatSelectDropdownProps> = observer(({ asset, className }) => {
  const { t } = useTranslation();
  const handleDropdownCheckboxItemClick = useCallback(
    (format: IDownloadFileFormat) => {
      asset.toggleSelectFileFormat(format);
    },
    [asset],
  );
  const { activeDigitalContent: digitalContent } = asset;

  const fileExtentsion =
    (digitalContent?.storageURL && readFileExtension(digitalContent.storageURL)) ||
    (asset.asset.attributeGroups['Asset Information'] && readFileExtension(asset.asset.attributeGroups['Asset Information'].assetName as string)) ||
    '';

  const handleCustomChange = (format: IDownloadFileFormat, changeValue: string, type: CustomType): void => {
    asset.handleCustomValueChange(format, changeValue, type);
  };

  return (
    <Dropdown
      className={cn('DownloadFormatSelectDropdown', className)}
      value={
        !asset.selectedFileFormats.length ? t('advancedDownload.dropdown_original_format') : asset.selectedFileFormats.map((e) => e.name).join(', ')
      }
      closeWhenItemSelect={false}
      disabled={getFileFormatTypeFromExtension(fileExtentsion) === 'Unknown'}
    >
      <div>
        {DownloadFormats.filter((e) => e.extensions.includes(getFileFormatTypeFromExtension(fileExtentsion)) && !e.customFormat).map(
          (format, index) => (
            <DropdownCheckboxItem
              key={index}
              labelClassName="space-m-t-1"
              value={format.name}
              checked={asset.selectedFileFormats
                .filter((e) => !e.customFormat)
                .map((e) => e.format)
                .includes(format.format)}
              onClick={() => handleDropdownCheckboxItemClick(format)}
            />
          ),
        )}
        {DownloadFormats.filter((e) => e.extensions.includes(getFileFormatTypeFromExtension(fileExtentsion)) && e.customFormat).map(
          (format, index) => (
            <div key={index}>
              <div className="DownloadFormatSelectDropdown-spacer" />
              <DropdownCheckboxItemCustom
                key={format.name}
                value={format.name}
                format={asset.selectedFileFormats.find((e) => e.format === format.format && e.customFormat)}
                checked={asset.selectedFileFormats
                  .filter((e) => e.customFormat)
                  .map((e) => e.format)
                  .includes(format.format)}
                onCustomChange={handleCustomChange}
                onClick={() => handleDropdownCheckboxItemClick(format)}
              />
            </div>
          ),
        )}
      </div>
    </Dropdown>
  );
});

export default DownloadFormatSelectDropdown;
