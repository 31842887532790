import { Brand, IAssetsFilterItem } from '@cdam/shared';
import { AssetFilter } from './AssetFilter';

const ADIDAS_POLICIES = [
  'adidas_MCT_GLOBAL_admin',
  'adidas_MCT_GLOBAL_upload',
  'adidas_MCT_Market_admin',
  'adidas_MCT_Market_Upload',
  'adidas_MCT_FOOTBALL_PLATFORM_ADMIN',
  'adidas_MCT_FOOTBALL_PLATFORM_UPLOAD',
  'adidas_GMM_Upload',
  'adidas_Brand_Kick_off',
  'adidas_MCT_default',
];
const REEBOK_POLICIES = ['Reebok_MCT_ADMIN', 'Reebok_MCT_UPLOAD', 'Reebok_MCT_Confidential_Upload', 'Reebok_MCT_default'];

const CREATION_CENTER_ADIDAS = ['CCH (Herzogenaurach)', 'CCP (Portland)', 'CCS (Shanghai)', 'CCSP (Sao Paolo)', 'CCT (Tokyo)', 'CCVIE (Ho Chi Minh)'];

const DISTRIBUTION = ['for free use', 'for internal use only', 'not for use'];

export const getFilterOverrides = (filterGroup: string, filterName: string, brand: Brand | undefined = undefined): Array<string> => {
  if (filterGroup === 'General' && filterName === 'policy') {
    if (brand) {
      return brand === Brand.Adidas ? ADIDAS_POLICIES : REEBOK_POLICIES;
    }

    return ADIDAS_POLICIES.concat(REEBOK_POLICIES);
  } else if (filterGroup === 'Asset Information' && filterName === 'creationCenter') {
    if (brand) {
      return brand === Brand.Adidas ? CREATION_CENTER_ADIDAS : [];
    }
  } else if (filterGroup === 'Asset Information' && filterName === 'distribution') {
    return DISTRIBUTION;
  }

  return [];
};

export const overrideFilterList = (filter: AssetFilter): AssetFilter => {
  if (filter.groupName === 'Asset Information' && filter.attribute.name === 'businessUnit') {
    filter.asList = false;
  }

  return filter;
};

const sortByYearOrLabel = (itemTextA: string, itemTextB: string): number => {
  const yearA = itemTextA.match(/\b\d{4}\b/g) ?? [];
  const yearB = itemTextB.match(/\b\d{4}\b/g) ?? [];

  if (yearA.length && yearB.length) {
    if (yearA < yearB) {
      return 1;
    }
    if (yearA > yearB) {
      return -1;
    }
    if (itemTextA > itemTextB) {
      return 1;
    }
    if (itemTextA < itemTextB) {
      return -1;
    }
  }

  return itemTextA < itemTextB ? -1 : 0;
};

const customSearchMarketingSeason = (item: IAssetsFilterItem, searchText: string): boolean => {
  const itemText = item.text || '';
  const words = itemText.split('/').map((word) => word.charAt(0)) || [];
  const years = (itemText.match(/\b\d{4}\b/g) ?? []).map((year) => year.slice(2)) || [];

  if (words.length && years.length) {
    const seasonAlias = `${words.join('')}${years.join('')}`;

    if (seasonAlias?.toLocaleUpperCase().includes(searchText.toLocaleUpperCase())) {
      return true;
    }
  }

  return itemText.toLocaleUpperCase().includes(searchText.toLocaleUpperCase());
};

const sortItemsByMarketingSeason = (
  items: Array<IAssetsFilterItem>,
  selectedItems?: Array<IAssetsFilterItem>,
  searchText?: string,
): Array<IAssetsFilterItem> => {
  const currentYear = new Date().getFullYear();

  return items
    .filter((item) => {
      if (searchText) {
        return customSearchMarketingSeason(item, searchText);
      }

      const itemYear = item.text.match(/\b\d{4}\b/g) ?? [];

      if (itemYear.length) {
        return parseInt(itemYear[0]) >= currentYear && parseInt(itemYear[0]) <= currentYear + 2;
      }

      return true;
    })
    .sort((a, b) => {
      const indexA = selectedItems ? selectedItems.indexOf(a) : -1;
      const indexB = selectedItems ? selectedItems.indexOf(b) : -1;

      // sort available items
      if (indexA === indexB) {
        return sortByYearOrLabel(a.text, b.text);
      }

      // sort selected items
      if (selectedItems?.[indexA] && selectedItems[indexB]) {
        return sortByYearOrLabel(selectedItems[indexA].text, selectedItems[indexB].text);
      }

      return indexA > indexB ? -1 : 1;
    });
};

export const filterAndSort = (
  attributeName: string,
  items: Array<IAssetsFilterItem>,
  selectedItems?: Array<IAssetsFilterItem>,
  searchText?: string,
): Array<IAssetsFilterItem> => {
  if (attributeName === 'marketingSeason') {
    return sortItemsByMarketingSeason(items, selectedItems, searchText).filter(
      (item) => !searchText || customSearchMarketingSeason(item, searchText),
    );
  }

  return items
    .slice()
    .filter((item) => !searchText || (item.text || '').toLocaleLowerCase().includes(searchText))
    .sort((a, b) => {
      const indexA = selectedItems ? selectedItems.indexOf(a) : -1;
      const indexB = selectedItems ? selectedItems.indexOf(b) : -1;

      if (indexA === indexB) {
        return a.text < b.text ? -1 : 1;
      }

      return indexA > indexB ? -1 : 1;
    });
};
