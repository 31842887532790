/* eslint-disable complexity, consistent-return */
import jwt from 'jsonwebtoken';
import { ITokenPayload, IMetadataDigitalContent, AssetContentType, UIAssetDomainsNamesEnums } from '@cdam/shared';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import AuthStore from '../stores/auth/AuthStore';
import { AssetData } from '../stores/assets/data/AssetData';
import ViewStore from '../stores/view/ViewStore';
import { getFileFormatFromExtension, readFileExtension } from './FileFormatParser';
import Formatter from './Formatter';

export default {
  isAssetOwnerAndHasCorrectUserType(assetData: AssetData | null, contentType: AssetContentType | null): boolean {
    if (ViewStore.isAdmin) {
      return true;
    }

    if (!AuthStore.token) {
      return false;
    }

    const { sAMAccountName } = jwt.decode(AuthStore.token) as ITokenPayload;

    const owners = this.getOwners(assetData);
    const assetOwners = owners?.split(',') ?? [];

    let isOwner = false;

    for (const owner of assetOwners) {
      if (sAMAccountName === owner.slice(owner.lastIndexOf('\\') + 1)) {
        isOwner = true;
        break;
      }
    }

    if (isOwner) {
      if (contentType === AssetContentType.MCT && ViewStore.selectedUiAssetDomain === UIAssetDomainsNamesEnums.MARVIN_MCT) {
        return true;
      } else if (contentType === AssetContentType.PI && ViewStore.selectedUiAssetDomain === UIAssetDomainsNamesEnums.MARVIN_PI) {
        return true;
      }
    }

    return false;
  },
  getValueByGroupAndAttributeName(item: AssetData | null, groupName: string, attributeName: string): Array<string> | undefined {
    if (!item) {
      return undefined;
    }

    const groupNameToLower = groupName.toLocaleLowerCase();

    const generalGroup = item.asset.attributeGroupList.find((group) => group.name.toLocaleLowerCase() === groupNameToLower);
    const attributeLookup = generalGroup?.attributeList?.find((attribute) => attribute.name === attributeName);

    return attributeLookup && ((attributeLookup.value && [attributeLookup.value]) || (attributeLookup.values && attributeLookup.values));
  },
  getCreatedBy(item: AssetData | null): string | undefined {
    const value = this.getValueByGroupAndAttributeName(item, 'General', 'createdBy');

    if (value) {
      return value.join(',');
    }
  },
  getCheckInUser(item: AssetData | null): string | undefined {
    const value = this.getValueByGroupAndAttributeName(item, 'General', 'checkInUser');

    if (value) {
      return value.join();
    }
  },
  getLastModifiedOn(item: AssetData): string | undefined {
    const value = this.getValueByGroupAndAttributeName(item, 'General', 'lastModifiedOn');

    const dateTime = value && value.length > 0 && new Date(value[0]).toISOString();

    if (dateTime) {
      return Formatter.getDateTime(dateTime);
    }
  },
  getAssetName(item: AssetData | null): string | undefined {
    const value = this.getValueByGroupAndAttributeName(item, 'Asset Information', 'assetName');

    if (value) {
      return value.join();
    }

    // TODO: dry
    const archiveValue = this.getValueByGroupAndAttributeName(item, 'Asset_Info', 'assetName');

    if (archiveValue) {
      return archiveValue.join();
    }
  },
  getAssetCategory(item: AssetData): string | undefined {
    const assetInformations = this.getValueByGroupAndAttributeName(item, 'Asset Information', 'assetCategory');

    return assetInformations && TaxonomyStore.getTaxonomyReferenceValue('Asset Information', 'assetCategory', assetInformations);
  },
  getContentType(contentType: string): string | undefined {
    if (contentType === 'MCT') {
      return 'Marketing Content';
    } else if (contentType === 'PI') {
      return 'Product images';
    }

    return '';
  },
  getMarketingSeason(item: AssetData): string | undefined {
    const mSeason = this.getValueByGroupAndAttributeName(item, 'Asset Information', 'marketingSeason');

    return mSeason && TaxonomyStore.getTaxonomyReferenceValue('Asset Information', 'marketingSeason', mSeason);
  },
  getArchiveMarketingSeason(item: AssetData): string | undefined {
    const mSeason = this.getValueByGroupAndAttributeName(item, 'ASSET_INFO', 'season');

    return mSeason && TaxonomyStore.getTaxonomyReferenceValue('ASSET_INFO', 'season', mSeason);
  },
  getName(item: AssetData, digitalContent: IMetadataDigitalContent | undefined): string | undefined {
    let assetName;

    assetName = this.getValueByGroupAndAttributeName(item, 'Asset Information', 'assetName');

    if (!assetName) {
      assetName = digitalContent?.fileName;
    } else if (assetName) {
      return assetName.join();
    }

    return assetName;
  },
  getFullFileSize(digitalContent: IMetadataDigitalContent | undefined): number | undefined {
    return digitalContent?.fileSize ? digitalContent.fileSize : undefined;
  },
  getArticleNumber(item: AssetData): string | undefined {
    const value = this.getValueByGroupAndAttributeName(item, 'Asset Information', 'articleNumber');

    if (value) {
      return value.join();
    }
  },
  getLastModifiedBy(item: AssetData): string | undefined {
    const value = this.getValueByGroupAndAttributeName(item, 'Asset Information', 'lastModifiedBy');

    if (value) {
      return value.join();
    }
  },
  getFileSize(digitalContent: IMetadataDigitalContent | undefined): string | undefined {
    return digitalContent?.fileSize ? Formatter.getFileSizeFromBytes(digitalContent.fileSize, 1) : undefined;
  },
  getFileType(digitalContent: IMetadataDigitalContent | undefined): string | undefined {
    return digitalContent?.storageURL && getFileFormatFromExtension(readFileExtension(digitalContent.storageURL));
  },
  getFileExtension(digitalContent: IMetadataDigitalContent | undefined): string | undefined {
    return digitalContent?.storageURL && readFileExtension(digitalContent.storageURL);
  },
  getExpired(item: AssetData): string | undefined {
    const expired = this.getValueByGroupAndAttributeName(item, 'Miscellaneous', 'expired');

    if (!expired) {
      return;
    }

    if (expired.join('') === 'true') {
      return 'Yes';
    }

    return 'No';
  },
  getConfidental(item: AssetData): string | undefined {
    const confidential = this.getValueByGroupAndAttributeName(item, 'Miscellaneous', 'confidential');

    if (!confidential) {
      return;
    }

    if (confidential.join('') === 'true') {
      return 'Yes';
    }

    return 'No';
  },
  getOwners(item: AssetData | null): string | undefined {
    if (!item) {
      return undefined;
    }

    const owners = this.getValueByGroupAndAttributeName(item, 'Miscellaneous', 'owners');

    if (owners) {
      return owners.join();
    }
  },
  getArticleLifecycle(item: { [key: string]: string } | undefined): string | undefined {
    if (!item) {
      return;
    }

    const value = item.ArtLifecycle;

    return value && TaxonomyStore.getTaxonomyReferenceValue('SPM_article', 'ArtLifecycle', [value]);
  },
  getProductDivision(item: { [key: string]: string } | undefined): string | undefined {
    if (!item) {
      return;
    }

    const value = item.ArtSeason_RMH_RMHProdDiv;

    return value && TaxonomyStore.getTaxonomyReferenceValue('SPM_article', 'ArtSeason_RMH_RMHProdDiv', [value]);
  },
  getCustomValue(item: AssetData | null, filter: AssetFilter): string | undefined {
    if (!item?.activeDigitalContent) {
      return;
    }

    switch (filter.attribute.name) {
      case 'originalFileSize':
        return this.getFileSize(item.activeDigitalContent);
      case 'fileType':
        return this.getFileType(item.activeDigitalContent);
      case 'fileFormat':
        return this.getFileExtension(item.activeDigitalContent);
    }
  },
  getValue(item: AssetData | null, filter: AssetFilter, displayValues?: boolean): string | undefined {
    const refValue = this.getValueByGroupAndAttributeName(item, filter.groupName, filter.attribute.name);

    let val;

    if (filter.referenceItems && refValue) {
      if (displayValues) {
        return refValue.join(',');
      }

      const refItem = TaxonomyStore.getTaxonomyReferenceValue(filter.groupName, filter.attribute.name, refValue);

      if (refItem) {
        val = refItem;
      }
    } else {
      val = refValue?.join();

      this.setDateDisplayValue(val, filter);
    }

    if (val) {
      return val;
    }
  },
  setDateDisplayValue(value: string | undefined, filter: AssetFilter): void {
    const isDate = filter.attribute.type === 'date' || filter.attribute.type === 'dateTime';

    if (!value || !isDate) {
      return;
    }

    const dateTime = value && Date.parse(value) && new Date(value).toISOString();

    filter.setDisplayValue(dateTime ? Formatter.getDateTime(dateTime) : '');
  },
};
