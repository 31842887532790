import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import Dropdown from 'components/dropdown/Dropdown';
import TextBoxControlled from 'components/input/TextBoxControlled';
import Button, { ButtonSize, ButtonType } from 'components/input/Button';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import { useTranslation } from 'react-i18next';
import { ReferenceDataItem } from 'stores/assets/data/ReferenceDataItem';

import '../UploadAssets.scss';

interface IUploadPIFilterDropdownProps {
  filter: AssetFilter;
  extractArticleNumbers: boolean | undefined;
  articleNumberMissing: boolean | undefined;
  onSubmit(): void;
}

function formatName(selectedItems: Array<ReferenceDataItem>): string {
  return selectedItems.length > 2 ? `${selectedItems[0].text}, ${selectedItems[1].text}, ...` : selectedItems.map((s) => s.text).join(', ');
}

const UploadPIFilterDropdown = observer(
  ({ filter, extractArticleNumbers, articleNumberMissing, onSubmit }: IUploadPIFilterDropdownProps): JSX.Element => {
    const { t } = useTranslation();

    const [textValue, setTextValue] = useState('');
    const [articleNumberError, setArticleNumberError] = useState(false);

    if (filter.attribute.masterDataType && filter.attribute.masterDataType === 'SPM_article' && !filter.value && extractArticleNumbers === false) {
      return (
        <Dropdown
          value={filter.value ? filter.value : t('common.select')}
          className={`${filter.value ? undefined : 'UploadAssets-white-background'} ${
            articleNumberMissing ? 'UploadAssets-upload-filter-cell-error' : ''
          }`}
          minFilterWidth="15rem"
          noBorder
          disableLabel
        >
          <ul className="list dropdown__options">
            <div className="space-m-2">
              <TextBoxControlled
                type="text"
                value={textValue}
                errorOutline={articleNumberError}
                error={articleNumberError ? t('error.article_not_found') : undefined}
                onChange={(e) => {
                  setTextValue(e.target.value);
                }}
              />
              <Button
                size={ButtonSize.Small}
                buttonType={ButtonType.Primary}
                className="UploadAssets-filter-update-button"
                onClick={async () => {
                  try {
                    setArticleNumberError(false);

                    await ClientHttpService.fetch({ urlType: UrlType.Metadata, url: `/masterData/SPM_article/${textValue}`, method: 'GET' });

                    filter.changeValue(textValue);
                    setTextValue('');
                    onSubmit();
                  } catch (e) {
                    setArticleNumberError(true);
                  }
                }}
              >
                {t('common.update')}
              </Button>
            </div>
          </ul>
        </Dropdown>
      );
    }

    if (filter.selectedReferenceItems.length) {
      return <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">{formatName(filter.selectedReferenceItems)}</div>;
    }

    return (
      <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">{filter.value || filter.selectedSingleValue?.text}</div>
    );
  },
);

export default UploadPIFilterDropdown;
