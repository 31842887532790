import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button, { ButtonSize, ButtonType } from '../../../components/input/Button';

import './ConfirmDialog.scss';

interface IConfirmDialogProps {
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  translationKey: string;
}

export const ConfirmDialog = observer(({ onCancel, onConfirm, translationKey }: IConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <div className="confirm-dialog modal">
      <div className="confirm-dialog-title">{t('common.confirm')}</div>
      <div className="confirm-dialog-text">{t(translationKey)}</div>
      <Button className="confirm-dialog-button space-m-r-2" buttonType={ButtonType.Primary} size={ButtonSize.Default} onClick={onCancel}>
        {t('common.cancel')}
      </Button>
      <Button className="confirm-dialog-button space-m-l-2" buttonType={ButtonType.Primary} size={ButtonSize.Default} onClick={onConfirm}>
        {t('common.yes')}
      </Button>
    </div>
  );
});
