/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { MatrixItemType, Brand, AppVariantEnum } from '@cdam/shared';
import MatrixStore from '../../../stores/assets/MatrixStore';
import ViewStore from '../../../stores/view/ViewStore';
import SearchEventSelector from './SearchEventSelector';
import ReebokRightTableCell from './ReebokRightTableCell';
import SearchMatrixDownloadFile from './SearchMatrixDownloadFile';
import TableCell from './TableCell';

import './SearchMatrixReebok.scss';

interface ISearchMatrixReebokProps {
  store: MatrixStore;
}

const SearchMatrixReebok: React.FC<ISearchMatrixReebokProps> = observer(({ store }) => {
  const { selectedEvent, events, brand, selectedEventId } = store;

  if (ViewStore.appVariant !== AppVariantEnum.GateUI && ViewStore.canView) {
    return null;
  }

  return (
    <div className="SearchMatrixReebok">
      {brand && events && events.released && <SearchEventSelector events={events.released} brand={brand} selectedEventId={selectedEventId} />}
      {selectedEvent && (
        <React.Fragment>
          <div className="SearchMatrixReebok-container">
            <table className="SearchMatrixReebok-table">
              <thead>
                <tr className="SearchMatrixReebok-table-row">
                  <th className="SearchMatrixReebok-table-heading" />
                  <th className="SearchMatrixReebok-table-heading" />
                  {selectedEvent.matrix.xAxis.map((as) => (
                    <th className="SearchMatrixReebok-table-heading" key={as.id}>
                      <div className="SearchMatrixReebok-table-heading-relative-container">
                        <div className="SearchMatrixReebok-table-heading-content">{as.name}</div>
                      </div>
                    </th>
                  ))}
                  <th />
                  {selectedEvent.reebokFilters.map((reebokFilter, reebokIndex) => (
                    <th key={reebokFilter.id} className={`SearchMatrixReebok-table-heading ${reebokIndex % 2 !== 0 ? 'dark-cell' : ''}`}>
                      <div className="SearchMatrixReebok-table-heading-relative-container">
                        <div className={'SearchMatrixReebok-table-heading-content'}>{reebokFilter.name}</div>
                      </div>
                    </th>
                  ))}
                  <th className="SearchMatrixReebok-table-heading" />
                  <th className="SearchMatrixReebok-table-heading" />
                </tr>
              </thead>
              <tbody
                onMouseLeave={() => {
                  store.selectCell(-1, -1, false);
                  store.selectReebokCell(-1);
                }}
              >
                {selectedEvent.matrix.yAxis.map((yAxis, rowIndex) => (
                  <tr className="SearchMatrixReebok-table-row" key={yAxis.id}>
                    <td className="SearchMatrixReebok-cell-empty" />
                    <td
                      className={`SearchMatrixReebok-table-cell-business-unit ${
                        yAxis.type === MatrixItemType.Group || yAxis.type === MatrixItemType.Standalone ? 'bold' : ''
                      }`}
                      onMouseEnter={() => store.selectCell(-1, -1, false)}
                    >
                      {yAxis.name.toUpperCase()}
                    </td>
                    {selectedEvent.matrix.xAxis.map((_, columnIndex) => (
                      <TableCell
                        key={`${rowIndex}${columnIndex}`}
                        brand={Brand.Reebok}
                        columnIndex={columnIndex}
                        rowIndex={rowIndex}
                        store={store}
                        cell={store.cells[rowIndex * selectedEvent.matrix.xAxis.length + columnIndex]}
                        borderTop={yAxis.type === MatrixItemType.Group || yAxis.type === MatrixItemType.Standalone}
                        evenRow={rowIndex % 2 === 0}
                        onMouseEnter={() => store.selectReebokCell(-1)}
                      />
                    ))}
                    {rowIndex === 0 && (
                      <td
                        rowSpan={1000}
                        style={{ width: '10px' } /* disable-stylelint */}
                        onMouseEnter={() => {
                          store.selectCell(-1, -1, false);
                          store.selectReebokCell(-1);
                        }}
                      />
                    )}
                    {selectedEvent.reebokFilters.map((_, reebokItemIndex) => {
                      if (rowIndex === 0) {
                        return (
                          <ReebokRightTableCell
                            key={reebokItemIndex}
                            store={store}
                            cell={store.reebokCells[reebokItemIndex]}
                            itemIndex={reebokItemIndex}
                            darkBackground={reebokItemIndex % 2 !== 0}
                          />
                        );
                      }

                      return null;
                    })}
                    {rowIndex === 0 && (
                      <React.Fragment>
                        <td
                          className="SearchMatrix-cell-business-empty"
                          rowSpan={1000}
                          onMouseEnter={() => {
                            store.selectCell(-1, -1, false);
                            store.selectReebokCell(-1);
                          }}
                        />
                        <td className="SearchMatrix-cell-empty" rowSpan={1000} />
                      </React.Fragment>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {store.selectedEvent?.whereToFindWhatUploaded && <SearchMatrixDownloadFile handleClick={store.downloadWhereToFindWhatPdf} />}
        </React.Fragment>
      )}
    </div>
  );
});

export default SearchMatrixReebok;
