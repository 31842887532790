import React from 'react';

interface IDropdownProps {
  icon: string;
  className?: string;
  height?: number;
  width?: number;
  onClick?(e: React.MouseEvent): void;
  color?: string;
}

const Icon = ({ className, icon, height, width, onClick, color }: IDropdownProps): JSX.Element => (
  <svg className={`icon ${icon} ${className ? className : ''}`} height={height} width={width} fill={color} onClick={onClick}>
    <use xlinkHref={`#${icon}`} />
  </svg>
);

export default Icon;
