import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import React from 'react';
import { IMatrixAxis } from '@cdam/shared';
import { AlertDialog } from '../../containers/dialog/dialogs/AlertDialog';
import { ConfirmDialog } from '../../containers/dialog/dialogs/ConfirmDialog';
import i18n from '../../i18n';
import ErrorUtils from '../../utils/ErrorUtils';
import MatrixSettingsStore, { Axis } from '../matrix/MatrixSettingsStore';
import MatrixSettingsDialog from '../../containers/search/dialogs/MatrixSettingsDialog';
import ClientHttpService from '../../services/ClientHttpService';

class DialogStore {
  @observable public fullScreenLoaders = 0;
  @observable public dialogs: Array<React.ReactNode> = [];

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get showModalPage(): boolean {
    return this.fullScreenLoaders > 0 || this.dialogs.length > 0;
  }

  @action
  public async execFullScreen<T>(fn: Promise<T>): Promise<T> {
    ++this.fullScreenLoaders;

    try {
      return await fn;
      // eslint-disable-next-line no-useless-catch
    } catch (e) {
      throw e;
    } finally {
      runInAction(() => --this.fullScreenLoaders);
    }
  }

  @action
  public addDialog(dialog: React.ReactNode): void {
    this.dialogs.push(dialog);
  }

  @action
  public removeLastDialog(): void {
    if (this.dialogs.length > 0) {
      this.dialogs.pop();
    }
  }

  @action
  public alert(text: string, onClose?: () => void): void {
    this.addDialog(
      <AlertDialog
        text={text}
        onClick={() => {
          this.removeLastDialog();

          if (onClose) {
            onClose();
          }
        }}
      />,
    );
  }

  @action
  public error(error: Error, onClose?: () => void): void {
    const text = ErrorUtils.getErrorMessage(error);

    if (!ClientHttpService.isLogoutDialogShown) {
      this.alert(text, onClose);
    } else if (onClose) {
      onClose();
    }
  }

  @action
  public confirm(translationKey: string, onConfirm?: () => void, onCancel?: () => void): void {
    this.addDialog(
      <ConfirmDialog
        translationKey={translationKey}
        onConfirm={() => {
          this.removeLastDialog();

          if (onConfirm) {
            onConfirm();
          }
        }}
        onCancel={() => {
          this.removeLastDialog();

          if (onCancel) {
            onCancel();
          }
        }}
      />,
    );
  }

  @action
  public matrixSettingsModal(store: MatrixSettingsStore, axis: Axis, item?: IMatrixAxis, onCancel?: () => void, onSave?: () => void): void {
    this.addDialog(
      <MatrixSettingsDialog
        store={store}
        item={item}
        axis={axis}
        onCancel={() => {
          this.removeLastDialog();

          if (onCancel) {
            onCancel();
          }
        }}
        onSave={() => {
          this.removeLastDialog();

          if (onSave) {
            onSave();
          }
        }}
      />,
    );
  }

  public alertValidationError(onClose?: () => void): void {
    this.alert(i18n.t('validation.default'), onClose);
  }
}

// singletone
export default new DialogStore();
