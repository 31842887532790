import { IDownloadType } from '@cdam/shared';

export const DownloadType: Array<IDownloadType> = [
  {
    name: 'All assets of article/model',
    type: 'all_assets',
  },
  {
    name: 'One asset per article/model',
    type: 'one_asset',
  },
];
