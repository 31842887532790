import React from 'react';
import MatrixSettingsStore from 'stores/matrix/MatrixSettingsStore';
import { IMatrixAxis } from '@cdam/shared';
import Dropdown from 'components/dropdown/Dropdown';
import DropdownItem from 'components/dropdown/DropdownItem';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

interface IMatrixGroupItemsDropdownProps {
  store: MatrixSettingsStore;
}

const MatrixGroupItemsDropdown = observer(({ store }: IMatrixGroupItemsDropdownProps): JSX.Element | null => {
  const { t } = useTranslation();

  if (!store.currentItem) {
    return null;
  }

  function handleGroupitemClick(groupItem: IMatrixAxis | null): void {
    store.changeCurrentItemGroup(groupItem);
  }

  return (
    store.groupItems && (
      <Dropdown
        label={t('matrix.choose_group')}
        value={store.selectedGroupItem === null ? 'none' : store.selectedGroupItem.name}
        closeWhenItemSelect={true}
      >
        <ul className="list dropdown__options">
          <DropdownItem value="none" onClick={() => handleGroupitemClick(null)} />
          {store.groupItems.map((groupItem, index) => (
            <DropdownItem key={index} value={groupItem.name} onClick={() => handleGroupitemClick(groupItem)} />
          ))}
        </ul>
      </Dropdown>
    )
  );
});

export default MatrixGroupItemsDropdown;
