import React, { useEffect, useState } from 'react';
import MinimalLayout from 'components/layout/MinimalLayout';
import { useTranslation } from 'react-i18next';
import NavigationService from 'services/NavigationService';
import { observer } from 'mobx-react-lite';
import UploadArchiveAssets from 'stores/upload/UploadArchiveAssetsStore';
import { ListLoader } from 'components/loader/ListLoader';
import ArchiveDataUploadZone from 'components/fileUpload/ArchiveDataUploadZone';
import ArchiveFilesTable from 'components/fileUpload/ArchiveFilesTable';
import { Link } from 'react-router-dom';
import AssetsFilter from '../assets/components/AssetsFilter';
import Button, { ButtonSize, ButtonType } from '../../components/input/Button';
import ViewStore from '../../stores/view/ViewStore';
import UploadTemplateItem from './components/UploadTemplateItem';

import './UploadAssets.scss';

const UploadArchive = observer(
  (): JSX.Element => {
    const { t } = useTranslation();

    const [store] = useState(() => new UploadArchiveAssets());

    useEffect(() => {
      store.load();
    }, [store]);

    return (
      <MinimalLayout
        header={t('common.upload')}
        headerClassName="space-m-h-6 space-m-t-6 space-m-b-4"
        onCloseButtonClick={() => {
          NavigationService.navigateTo('/');
        }}
      >
        <div>
          {store.templates && store.templates.length > 0 ? (
            <React.Fragment>
              <h3 className="subtitle subtitle--lg space-m-h-6 space-m-t-5 space-m-b-3">1. {t('common.select_template')}</h3>
              <div className="EditTemplates-template-list space-m-l-6">
                {store.templates?.map((templateInfo) => (
                  <UploadTemplateItem
                    key={templateInfo.id}
                    text={templateInfo.name}
                    selected={store.selectedTemplate ? store.selectedTemplate.id === templateInfo.id : false}
                    // eslint-disable-next-line @typescript-eslint/return-await
                    handleClick={async () => await store.selectTemplate(templateInfo.id)}
                  />
                ))}
              </div>
              {ViewStore.canCreateTemplates && (
                <div className="EditTemplates-template-list space-m-l-6">
                  {t('common.edit_template')}&nbsp;<Link to="/edit-templates">{t('common.here')}.</Link>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className="EditTemplates-template-list space-m-l-6">
              {t('common.no_templates_found')}&nbsp;
              {ViewStore.canCreateTemplates && (
                <React.Fragment>
                  {t('common.please_create_a_template')}&nbsp;<Link to="/edit-templates">{t('common.here')}.</Link>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <ListLoader isLoading={store.isLoading} error={store.error} itemsLength={1} />
        <div>
          {store.selectedTemplate && (
            <React.Fragment>
              <div>
                <h3 className="subtitle subtitle--lg space-m-h-6 space-m-t-5 space-m-b-3">2. {t('common.drag_drop_browse_choose')}</h3>
              </div>
              <div className="UploadAssets-square-content space-m-h-6 space-m-v-2">
                <div className="col-sm-7">
                  <ArchiveDataUploadZone store={store} />
                  {store.bulkUpdateFilters.length > 0 && store.uploadAssets.length > 0 && (
                    <section className="space-m-h-6 space-m-t-5 space-m-b-3">
                      <h4 className="subtitle subtitle--sm">{t('upload.bulk_update')}</h4>
                      <AssetsFilter
                        filterClassName="space-m-r-3 space-m-t-4"
                        filters={store.bulkUpdateFilters}
                        selectedFilters={store.bulkUpdateFilters}
                        clearAllFilters={() => store.clearAllFilters()}
                        toggleSelectedFilter={(item, filters) => store.toggleSelectedFilter(item, filters)}
                        minFilterWidth="20rem"
                        brand={undefined}
                        disableDeleting={true}
                        disableAdding={true}
                        checkList
                      />
                      <div className="space-m-t-4">
                        <Button
                          buttonType={ButtonType.Primary}
                          className="space-m-r-2"
                          size={ButtonSize.Default}
                          onClick={() => store.updateAssets(true)}
                        >
                          {t('upload.bulk_update_all')}
                        </Button>
                        <Button
                          buttonType={ButtonType.Primary}
                          className="space-m-r-2"
                          size={ButtonSize.Default}
                          onClick={() => store.updateAssets(false)}
                        >
                          {t('upload.bulk_update_empty')}
                        </Button>
                        <Button buttonType={ButtonType.Secondary} size={ButtonSize.Default} onClick={() => store.resetAll()}>
                          {t('upload.reset_al')}
                        </Button>
                      </div>
                    </section>
                  )}
                  {store.uploadAssets.length > 0 && <ArchiveFilesTable store={store} />}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </MinimalLayout>
    );
  },
);

export default UploadArchive;
