/* eslint-disable complexity */
import React, { useState } from 'react';
import { IEventInfo, Brand } from '@cdam/shared';
import Icon from 'components/icons/Icon';
import SearchEventCard from './SearchEventCard';

import './PaginatedEventCards.scss';

interface IPaginatedEventCardsProps {
  brand: Brand | null;
  events: Array<IEventInfo>;
  numberPerPages: number;
}

const PaginatedEventCards = ({ brand, events, numberPerPages }: IPaginatedEventCardsProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);

  const numberOfPages = Math.ceil(events.length / numberPerPages);
  const begin = (currentPage - 1) * numberPerPages;
  const end = begin + numberPerPages;

  const paginatedEvents = events.slice(begin, end);

  return (
    <div className="PaginatedEventCards">
      {events.length > 3 && (
        <Icon
          icon="icon-arrow-breadcrumb-left"
          className={`PaginatedEventCards-left-arrow-button ${currentPage > 1 ? 'visible' : ''}`}
          height={20}
          width={20}
          onClick={() => {
            setCurrentPage((prevState) => prevState - 1);
          }}
        />
      )}
      {paginatedEvents.map((d, index) => (
        <SearchEventCard path={`/matrix-settings/${brand}/${d.id}`} text={`${d.name} ${d.season}`} key={index} className="PaginatedEventCards-card" />
      ))}
      {events.length > 3 && (
        <Icon
          icon="icon-arrow-breadcrumb-right"
          className={`PaginatedEventCards-right-arrow-button ${currentPage < numberOfPages ? 'visible' : ''}`}
          height={22}
          width={22}
          onClick={() => {
            setCurrentPage((prevState) => prevState + 1);
          }}
        />
      )}
    </div>
  );
};

export default PaginatedEventCards;
