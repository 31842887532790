import React, { forwardRef, useRef, useImperativeHandle, RefForwardingComponent } from 'react';
import ChoicesSelection from 'components/dropdown/ChoicesSelection';
import { IFiltersSearchResult } from '@cdam/shared';

import './TextBoxUncontrolled.scss';

export interface TextBoxUncontrolledInputHandles {
  focus(): void;
  getValue(): string;
  setValue(val: string): void;
}

interface TextBoxUncontrolledProps {
  className?: string;
  error?: boolean;
  label?: string;
  name?: string;
  placeholder?: string;
  type?: string;
  defaultValue?: string;
  noMargin?: boolean;
  choices?: boolean;
  filterChoices?: Array<IFiltersSearchResult>;
  onBlur?(e: React.FocusEvent<HTMLInputElement>): void;
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void;
  onFocus?(e: React.FocusEvent<HTMLInputElement>): void;
  onKeyDown?(e: React.KeyboardEvent<HTMLInputElement>): void;
  onRemoveChoices?(item: IFiltersSearchResult): void;
}

const TextBoxUncontrolled: RefForwardingComponent<TextBoxUncontrolledInputHandles, TextBoxUncontrolledProps> = (
  {
    className,
    error,
    label,
    name,
    placeholder,
    noMargin,
    type,
    defaultValue,
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    choices,
    filterChoices,
    onRemoveChoices,
  }: TextBoxUncontrolledProps,
  ref,
): JSX.Element => {
  const inputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    focus() {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    },
    getValue() {
      const { current } = inputRef;

      return current === null ? '' : current.value;
    },
    setValue(val: string) {
      const { current } = inputRef;

      if (current !== null) {
        current.value = val;
      }
    },
  }));

  return (
    <div className={`${className} form-group ${error && 'has-error'} ${noMargin && 'TextBoxUncontrolled-remove-margin'}`}>
      {label && (
        <label htmlFor={name} className="control__label">
          {label}
        </label>
      )}
      <div className={`form-control__container ${className}-choices`}>
        {choices &&
          filterChoices &&
          filterChoices.map((item, index) => (
            <ChoicesSelection
              item={`${item.pre && item.pre}${item.bold && item.bold}${item.post && item.post}`}
              key={index}
              removeItem={() => onRemoveChoices?.(item)}
              backgroundColor={'#EDEDED'}
              color={'#333333'}
              uppercase={true}
            />
          ))}
        <input
          ref={inputRef}
          type={type ?? 'text'}
          placeholder={placeholder}
          name={name}
          className={`form-control ${className}-choices-input`}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
        />
      </div>
    </div>
  );
};

export default forwardRef(TextBoxUncontrolled);
