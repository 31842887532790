/* eslint-disable complexity */
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { AttributeDisplayType, Brand, IAssetsFilterItem } from '@cdam/shared';
import DropdownCheckboxItem from 'components/dropdown/DropdownCheckboxItem';
import { getFilterOverrides } from 'stores/assets/data/AssetFilterOverrides';
import AssetsFilterSelector from './AssetsFilterSelector';
import AssetsFilterPopup from './AssetsFilterPopup';
import AssetFilterAddNewButton from './AssetFilterAddNewButton';

import './AssetsFilterDropDown.scss';

interface IAssetsFilterDropDownProps {
  canBeRemoved?: boolean;
  isOpen: boolean;
  disabled?: boolean;
  onClearAllClick?(): void;
  onDeleteClick?(): void;
  onSelectorClick(): void;
  onToggleSelectedItem?(item: IAssetsFilterItem): void;
  onToggleSingleSelectedItem?(item: IAssetsFilterItem): void;
  onChangeValue?(val: string, openAfter?: boolean): void;
  onMultipleRemoveItem?(val: string): void;
  text: string;
  attributeName: string;
  group: string;
  items?: Array<IAssetsFilterItem>;
  selectedItems?: Array<IAssetsFilterItem>;
  isLoading?: boolean | null;
  error?: Error | null;
  type?: string;
  isMandatory?: boolean;
  toggleMandatory?: boolean;
  handleMandatoryToggle?(): void;
  selectedValue?: string;
  selectedValues?: Array<string>;
  filterSelector?: boolean;
  className?: string;
  maxFilterWidth?: string;
  minFilterWidth?: string;
  choicesMandatory?: boolean;
  list?: boolean;
  singleChoice?: boolean;
  multipleChoices?: boolean;
  brand?: Brand;
  validate?: boolean;
  errorMessage?: string;
  disableDelete?: boolean;
  displayType?: AttributeDisplayType;
  displayGrayedOut?: boolean;
  displayValue?: string;
  searchMode?: boolean;
}

const AssetsFilterDropDown: React.FC<IAssetsFilterDropDownProps> = observer(
  ({
    canBeRemoved,
    isOpen,
    disabled,
    text,
    attributeName,
    group,
    items,
    selectedItems,
    onClearAllClick,
    onDeleteClick,
    onSelectorClick,
    onToggleSelectedItem,
    onToggleSingleSelectedItem,
    onChangeValue,
    isLoading,
    error,
    validate,
    type,
    isMandatory,
    toggleMandatory,
    handleMandatoryToggle,
    selectedValue,
    selectedValues,
    filterSelector,
    className,
    maxFilterWidth,
    minFilterWidth,
    multipleChoices,
    choicesMandatory,
    onMultipleRemoveItem,
    list,
    singleChoice,
    brand = undefined,
    errorMessage,
    disableDelete,
    displayType,
    displayGrayedOut,
    displayValue,
    searchMode,
  }: IAssetsFilterDropDownProps) => {
    const { t } = useTranslation();
    const [showPopup, setShowPopup] = useState(false);
    const [value, setValue] = useState('');

    const getCustomFilterName = (): string => {
      if (!selectedItems || filterSelector) {
        return text;
      }

      if (type) {
        if (multipleChoices) {
          return selectedValues ? selectedValues.join(', ') : '';
        }
        if (type === 'boolean') {
          return (selectedValue && ((selectedValue === 'true' && 'Yes') || (selectedValue === 'false' && 'No'))) || '';
        }

        return selectedValue ?? '';
      }

      return selectedItems.length > 2 ? `${selectedItems[0].text}, ${selectedItems[1].text}, ...` : selectedItems.map((s) => s.text).join(', ');
    };

    const prevIsOpen = useRef(false);

    useEffect(() => {
      if (!isOpen) {
        setShowPopup(false);

        if (prevIsOpen.current && onChangeValue) {
          onChangeValue(value);
        }

        prevIsOpen.current = false;

        return;
      }

      if (selectedValue) {
        setValue(selectedValue);
      }

      if (displayType === 'date' || displayType === 'dateTime') {
        setShowPopup(false);

        return;
      }

      setShowPopup(true);

      // eslint disable because it bothers about value which we don't want to monitor
      // eslint-disable-next-line
    }, [isOpen, items, selectedItems, onChangeValue, selectedValue]);

    return (
      <div className={cn('AssetsFilterDropDown', className)}>
        {onDeleteClick ? (
          <AssetsFilterSelector
            canBeRemoved={canBeRemoved}
            label={text}
            value={getCustomFilterName()}
            displayType={displayType}
            className={cn('AssetsFilterDropDown', className)}
            minFilterWidth={minFilterWidth}
            maxFilterWidth={maxFilterWidth}
            closeOnSelection={false}
            disabled={disabled ?? (errorMessage ? true : undefined)}
            disableDelete={disableDelete}
            errorMessage={errorMessage}
            displayGrayedOut={displayGrayedOut}
            displayValue={displayValue}
            onSelectorClick={onSelectorClick}
            onChangeValue={onChangeValue}
            onDeleteClick={onDeleteClick}
          />
        ) : (
          <AssetFilterAddNewButton label={text} onClick={onSelectorClick} />
        )}
        {isOpen && showPopup && !errorMessage && (
          <AssetsFilterPopup
            attributeName={attributeName}
            group={group}
            list={list}
            isLoading={isLoading}
            error={error}
            items={items}
            selectedItems={selectedItems}
            selectedValue={selectedValue}
            selectedValues={selectedValues}
            singleChoice={singleChoice}
            type={type}
            multipleChoices={multipleChoices}
            filterOverrides={getFilterOverrides(group, attributeName, brand)}
            validate={validate}
            choicesMandatory={choicesMandatory}
            searchMode={searchMode}
            onToggleSingleSelectedItem={onToggleSingleSelectedItem}
            onToggleSelectedItem={onToggleSelectedItem}
            onClearAllClick={onClearAllClick}
            onChangeValue={onChangeValue}
            onMultipleRemoveItem={onMultipleRemoveItem}
          />
        )}
        {isMandatory !== undefined && (
          <DropdownCheckboxItem
            checked={isMandatory}
            isDisabled={!toggleMandatory}
            value={t('common.mandatory')}
            labelClassName="space-p-t-1 caption"
            onClick={toggleMandatory ? handleMandatoryToggle : undefined}
          />
        )}
      </div>
    );
  },
);

export default AssetsFilterDropDown;
