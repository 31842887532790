/* eslint-disable complexity */
import React from 'react';
import cn from 'classnames';
import { CustomType } from '@cdam/shared';
import { IDownloadFileFormat } from '@cdam/shared/src/interfaces/Asset';
import { observer } from 'mobx-react-lite';
import DropdownCheckboxItem from './DropdownCheckboxItem';

import './DropdownCheckboxItemCustom.scss';

interface DropdownCheckboxItemProps {
  value?: string;
  className?: string;
  checked?: boolean;
  format?: IDownloadFileFormat;
  onClick?(): void;
  onCustomChange(format: IDownloadFileFormat, custom: string, type: CustomType): void;
}

const DropdownCheckboxItemCustom = observer(
  ({ value, className, checked, onClick, format, onCustomChange }: DropdownCheckboxItemProps): JSX.Element => {
    const handleCustomValue = (e: React.FormEvent<HTMLInputElement>, type: CustomType): void => {
      e.preventDefault();
      format && onCustomChange(format, e.currentTarget.value, type);
    };

    return (
      <div className={cn('DropdownCheckboxItemCustom', className)}>
        <DropdownCheckboxItem value={value} checked={checked} labelClassName="space-m-t-1" onClick={onClick} />
        <div className="DropdownCheckboxItemCustom-custom">
          <div className="DropdownCheckboxItemCustom-input-container">
            <input
              className={`DropdownCheckboxItemCustom-input ie-font-fix ${
                checked && format && format.validation && format.validation.width === 1 ? 'DropdownCheckboxItemCustom-input-validation' : ''
              }`}
              type="number"
              placeholder="width"
              disabled={!checked}
              value={format?.parameters ? format.parameters.width : ''}
              onChange={(e) => handleCustomValue(e, CustomType.Width)}
            />
          </div>
          <div className="space-m-h-2">x</div>
          <div className="DropdownCheckboxItemCustom-input-container">
            <input
              className={`DropdownCheckboxItemCustom-input ie-font-fix ${
                checked && format && format.validation && format.validation.height === 1 ? 'DropdownCheckboxItemCustom-input-validation' : ''
              }`}
              type="number"
              placeholder="height"
              disabled={!checked}
              value={format?.parameters ? format.parameters.height : ''}
              onChange={(e) => handleCustomValue(e, CustomType.Height)}
            />
          </div>
        </div>
        <div className="DropdownCheckboxItemCustom-resolution">
          <div className="DropdownCheckboxItemCustom-input-container">
            <input
              className={`DropdownCheckboxItemCustom-input ie-font-fix ${
                checked && format && format.validation && format.validation.resolution === 1 ? 'DropdownCheckboxItemCustom-input-validation' : ''
              }`}
              type="number"
              placeholder="Resolution"
              disabled={!checked}
              value={format?.parameters && checked ? format.parameters.resolution : ''}
              onChange={(e) => handleCustomValue(e, CustomType.Resolution)}
            />
          </div>
        </div>
      </div>
    );
  },
);

export default DropdownCheckboxItemCustom;
