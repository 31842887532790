export enum AssetCategoryMetadataValues {
  DesignSketch = '25',
}

export enum ImageStyleMetadataValues {
  OnModel = '6',
}

export enum UsageMetadataValues {
  ECommerce = '8',
  ECommerceLocal = '12',
  ECommerceEU = '10',
  ECommerceUS = '14',
  ECommerceHBS = '11',
}
