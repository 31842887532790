import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { EditColumnsStore } from '../../../stores/assets/EditColumnsStore';
import { IDisplayColumns } from '../../../stores/assets/AssetsStore';
import DialogStore from '../../../stores/dialog/DialogStore';
import MinimalLayout from '../../../components/layout/MinimalLayout';
import Button, { ButtonSize, ButtonType } from '../../../components/input/Button';

import './EditColumns.scss';

export interface IEditColumnsDialogProps {
  onClose(columns: Array<IDisplayColumns>): void;
  selectedColumns: Array<IDisplayColumns>;
}

const EditColumnsDialog: React.FC<IEditColumnsDialogProps> = observer(({ onClose, selectedColumns }: IEditColumnsDialogProps) => {
  const { t } = useTranslation();
  const [store] = useState(() => new EditColumnsStore(selectedColumns));

  return (
    <MinimalLayout
      className="EditColumns"
      headerClassName="space-m-h-6 space-m-v-6"
      header={t('common.edit_columns')}
      onCloseButtonClick={() => DialogStore.removeLastDialog()}
    >
      <section className="space-p-4 EditColumns-scroll-container">
        <div className="EditColumns-filters">
          {store.availableColumns.map((filter) => (
            <div key={filter.text} className="EditColumns-item">
              <input
                type="checkbox"
                className="space-m-v-2"
                checked={store.selectedColumns.findIndex((f) => f.attribute.name === filter.attribute.name) !== -1}
                onChange={() => store.toggleSelect(filter)}
              />
              <div className="space-m-l-2">{filter.text}</div>
            </div>
          ))}
        </div>
      </section>
      <section className="space-p-4">
        <Button
          buttonType={ButtonType.Primary}
          size={ButtonSize.Default}
          data-testid="columns-edit-save"
          onClick={() => {
            onClose(store.updatedData);
            DialogStore.removeLastDialog();
          }}
        >
          {t('common.save')}
        </Button>
      </section>
    </MinimalLayout>
  );
});

export default EditColumnsDialog;
