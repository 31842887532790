import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import MinimalLayout from '../../components/layout/MinimalLayout';
import ProfileStore from '../../stores/profile/ProfileStore';
import NavigationService from '../../services/NavigationService';
import Table from '../../components/table/Table';

import './Profile.scss';

const Profile = observer(
  (): JSX.Element => {
    const { t } = useTranslation();

    useEffect(() => {
      ProfileStore.init();
    }, []);

    return (
      <MinimalLayout
        header={t('profile.profile')}
        headerClassName="space-m-h-6 space-m-t-6 space-m-b-4"
        onCloseButtonClick={() => NavigationService.goBack()}
      >
        <section className="space-m-h-6">
          <h3 className="subtitle subtitle--lg space-m-t-5 space-m-b-3">
            <strong>Username: </strong>
            {ProfileStore.displayName}
          </h3>
        </section>

        <section className="space-m-h-6">
          <h3 className="subtitle subtitle--lg space-m-t-5 space-m-b-3">{t('common.upload_plural')}</h3>
          <Table headers={ProfileStore.uploadTableHeaders} items={ProfileStore.uploadTableItems} />
        </section>
      </MinimalLayout>
    );
  },
);

export default Profile;
