import { assetGroupsEnum, appLocationEnum, Name as GroupName, UIAssetDomainsNamesEnums } from '@cdam/shared';
import FilterConfig, { IFilterRule, IDisplayRuleItem } from 'configs/FilterConfig';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import { UserType } from '../interfaces/Auth';
import ViewStore from '../stores/view/ViewStore';

const userTypeAssetGroupMapping = {
  [UserType.MCT]: assetGroupsEnum.MARKETING_CONTENT,
  [UserType.PI]: assetGroupsEnum.PRODUCT_IMAGES,
};

export default {
  taxonomyWithAppliedRules(
    selectedFilters?: Array<AssetFilter>,
    taxonomy?: Array<AssetFilter>,
    appLocation?: appLocationEnum,
    assetGroups?: Array<assetGroupsEnum>,
  ): Array<AssetFilter> {
    const localTaxonomy = taxonomy ?? TaxonomyStore.getTaxonomyAttributeFiltersWithRules();

    return localTaxonomy.reduce((acc: Array<AssetFilter>, filter) => {
      const filterRules = this.getFilterRules(filter, appLocation, assetGroups);

      const filterInSelectedFilter = selectedFilters?.find(
        (selectedFilter) => selectedFilter.groupName === filter.groupName && selectedFilter.attribute.name === filter.attribute.name,
      );

      if (!filterRules || filterRules.length === 0 || filterInSelectedFilter) {
        return acc;
      }

      const filterWithRules = this.applyRulesToFilter(filter, filterRules, selectedFilters);

      if (filterWithRules && !acc.find((filter) => filter.text === filterWithRules.text && filter.groupName !== filterWithRules.groupName)) {
        acc.push(filterWithRules);
      }

      return acc;
    }, []);
  },
  // eslint-disable-next-line complexity
  getPreselectedFilter(
    appLocation: appLocationEnum,
    filters?: Array<AssetFilter>,
    assetGroupsEnums?: Array<assetGroupsEnum>,
    injectFilters?: [
      {
        groupName: GroupName;
        filterName: string;
        selectedReferenceItems?: Array<string>;
        isDisabled?: boolean;
        isMandatory?: boolean;
      },
    ],
  ): Array<AssetFilter> {
    const localScopeTaxonomy = filters ?? TaxonomyStore.getTaxonomyAttributeFiltersWithRules();

    const preselectedFilters: Array<AssetFilter> = [];

    if (injectFilters) {
      for (const injected of injectFilters) {
        const injectedFilter = localScopeTaxonomy.find(
          (filter) => filter.attribute.name === injected.filterName && filter.groupName === injected.groupName,
        );

        if (injectedFilter) {
          if (injected.selectedReferenceItems) {
            for (const item of injected.selectedReferenceItems) {
              injectedFilter.changeValue(item);
            }
          }

          injectedFilter.setDisabled(injected.isDisabled ?? false);
          injectedFilter.setMandatory(injected.isMandatory ?? false);
          injectedFilter.setCanBeRemoved(!injected.isMandatory ?? true);
          preselectedFilters.push(injectedFilter);
        }
      }
    }

    const appLocationRuleItems = FilterConfig.filter((filterConfigItem) => filterConfigItem.appLocation?.[appLocation]);

    if (appLocationRuleItems.length !== 0) {
      const rules = assetGroupsEnums ? assetGroupsEnums : ['general'];

      for (const rule of rules) {
        const filterRulesItems = appLocationRuleItems.filter(
          (ruleItem) => ruleItem.appLocation?.[appLocation].rules?.[rule]?.preselected?.isPreselected,
        );

        for (const ruleItem of filterRulesItems) {
          const filter = localScopeTaxonomy.find((f) => f.groupName === ruleItem.groupName && f.attribute.name === ruleItem.name);

          const allowedReferenceValues = ruleItem.appLocation?.[appLocation].rules?.[rule]?.preselected?.preselectedValues ?? [];
          const isDisabled = ruleItem.appLocation?.[appLocation]?.isDisabled ?? ruleItem.appLocation?.[appLocation]?.rules?.[rule]?.isDisabled;
          const isMandatory = ruleItem.appLocation?.[appLocation]?.isDisabled ?? ruleItem.appLocation?.[appLocation]?.rules?.[rule]?.isMandatory;

          if (filter) {
            filter.setDisabled(!!isDisabled);
            filter.setCanBeRemoved(!isMandatory ?? true);

            // filter.setMandatory(!!isMandatory);

            for (const refValue of allowedReferenceValues) {
              filter.changeValue(refValue);
            }

            preselectedFilters.push(filter);
          }
        }
      }
    }

    return preselectedFilters;
  },
  getMandatoryFilter(appLocation: appLocationEnum, filters?: Array<AssetFilter>, assetGroupsEnums?: Array<assetGroupsEnum>): Array<AssetFilter> {
    const localScopeTaxonomy = filters ?? TaxonomyStore.getTaxonomyAttributeFiltersWithRules();
    const appLocationRuleItems = FilterConfig.filter((filterConfigItem) => filterConfigItem.appLocation?.[appLocation]);

    const mandatoryFilters: Array<AssetFilter> = [];

    if (appLocationRuleItems.length !== 0) {
      const rules = assetGroupsEnums ? assetGroupsEnums : ['general'];

      for (const rule of rules) {
        const filterRulesItems = appLocationRuleItems.filter(
          (ruleItem) => ruleItem.appLocation?.[appLocation].rules?.[rule]?.preselected?.isPreselected,
        );

        for (const ruleItem of filterRulesItems) {
          const filter = localScopeTaxonomy.find((f) => f.groupName === ruleItem.groupName && f.attribute.name === ruleItem.name);

          const isMandatory = ruleItem.appLocation?.[appLocation]?.rules?.[rule]?.isMandatory;

          if (filter && isMandatory) {
            filter.setCanBeRemoved(!isMandatory ?? true);
            mandatoryFilters.push(filter);
          }
        }
      }
    }

    return mandatoryFilters;
  },
  revalidateSelectedFilters(selectedFilters: Array<AssetFilter>, appLocation: appLocationEnum, assetGroups?: Array<assetGroupsEnum>): void {
    const assetGroupsEnums = assetGroups ?? this.getAssetGroupFromUserType();

    for (const filter of selectedFilters) {
      const filterRules = this.getFilterRules(filter, appLocation, assetGroupsEnums);

      if (filterRules && filterRules?.length !== 0) {
        this.applyRulesToFilter(filter, filterRules, selectedFilters);
      }
    }
  },
  createFilter(
    groupName: GroupName,
    filterName: string,
    selectedReferenceItems?: Array<string>,
    isDisabled?: boolean,
    isMandatory?: boolean,
  ): AssetFilter | undefined {
    let filter: AssetFilter | undefined;
    const filterTaxonomyData = TaxonomyStore.getAttribute(groupName, filterName);

    if (filterTaxonomyData) {
      filter = new AssetFilter(groupName, filterTaxonomyData);

      if (selectedReferenceItems) {
        for (const referenceItem of selectedReferenceItems) {
          filter.changeValue(referenceItem);
        }
      }

      if (typeof isDisabled !== 'undefined') {
        filter.setDisabled(isDisabled);
      }

      if (typeof isMandatory !== 'undefined') {
        filter.setCanBeRemoved(!isMandatory);
      }
    }

    return filter;
  },
  getFilterRules(filter: AssetFilter, appLocation?: appLocationEnum, assetGroups?: Array<assetGroupsEnum>): Array<IFilterRule> | undefined {
    const { name, group } = this.getFilterNameAndGroup(filter);

    const filterConfigItem = FilterConfig.find((filterRuleItem) => filterRuleItem.name === name && filterRuleItem.groupName === group);

    if (!filterConfigItem) {
      return undefined;
    }

    const filterRules: Array<IFilterRule> = [];

    if (filterConfigItem?.displayUnmodified) {
      filterRules.push({ displayUnmodified: true } as IFilterRule);
    } else if (appLocation) {
      const appLocationRule = filterConfigItem?.appLocation?.[appLocation];

      if (appLocationRule?.displayUnmodified) {
        filterRules.push({ displayUnmodified: true } as IFilterRule);
      } else if (appLocationRule?.isDisabled) {
        filterRules.push({ isDisabled: true } as IFilterRule);
      } else {
        if (appLocationRule?.rules?.general) {
          filterRules.push(appLocationRule?.rules?.general);
        }

        const assetGroupsItems = assetGroups ?? this.getAssetGroupFromUserType();

        assetGroupsItems.forEach((assetGroup) => {
          const rule = filterConfigItem?.appLocation?.[appLocation]?.rules?.[assetGroup];

          if (rule) {
            filterRules.push(rule);
          }
        });
      }
    }

    return filterRules;
  },
  applyRulesToFilter(filter: AssetFilter, filterRules: Array<IFilterRule>, selectedFilters?: Array<AssetFilter>): AssetFilter | undefined {
    // const canBeDisplayed = this.requirementsAreMet(filterRules, selectedFilters);

    // return canBeDisplayed ? this.applyDisplayRules(filter, filterRule, selectedFilters) : undefined;
    let filterWithRules = filter;

    if (selectedFilters) {
      filterRules.forEach((filterRule) => {
        if (!filterRule.displayUnmodified) {
          if (filterRule.displayRules) {
            const { displayRules } = filterRule;

            filterWithRules = this.applyDisplayRules(filterWithRules, displayRules, selectedFilters);
          }

          if (filterRule.isDisabled) {
            filterWithRules.setDisabled(filterRule.isDisabled);
          }
        }
      });
    }

    return filterWithRules;
  },
  applyDisplayRules(filter: AssetFilter, displayRules: Array<IDisplayRuleItem>, selectedFilters: Array<AssetFilter>): AssetFilter {
    let referenceItems: Array<string> = [];

    for (const displayRule of displayRules) {
      const applyDisplayRule = selectedFilters.find(
        (selectedFilter) => selectedFilter.attribute.name === displayRule.filterName && selectedFilter.groupName === displayRule.filterGroup,
      );

      if (applyDisplayRule && displayRule.allowedValuesMap) {
        for (const allowedValues of displayRule.allowedValuesMap) {
          const [selectedValue, allowedReferenceValues] = allowedValues;
          const valueIsSelected = applyDisplayRule.selectedReferenceItems.find(
            (selectedReferenceItem) => selectedReferenceItem.referenceData.id === selectedValue,
          );

          if (valueIsSelected) {
            // TODO: filter unique
            referenceItems = [...referenceItems, ...allowedReferenceValues];
          }
        }
      }
    }

    filter.setAllowedReferenceItemsIds(referenceItems);

    return filter;
  },

  getAssetGroupFromUserType(): Array<assetGroupsEnum> {
    const assetGroupsItems: Array<assetGroupsEnum> = [];

    if (ViewStore.usersUiDomainsForSelectedAppVariant.includes(UIAssetDomainsNamesEnums.MARVIN_MCT)) {
      assetGroupsItems.push(userTypeAssetGroupMapping[UserType.MCT]);
    }
    if (ViewStore.usersUiDomainsForSelectedAppVariant.includes(UIAssetDomainsNamesEnums.MARVIN_PI)) {
      assetGroupsItems.push(userTypeAssetGroupMapping[UserType.PI]);
    }

    return assetGroupsItems;
  },
  getFilterNameAndGroup(filter: AssetFilter): { name: string; group: string } {
    return {
      name: filter.attribute.name,
      group: filter.groupName,
    };
  },
};
