import { observable, action, computed, runInAction, makeObservable } from 'mobx';

export interface ISearchFieldItem<T> {
  bold?: string;
  post?: string;
  pre?: string;
  searchItem: T;
}

export interface ITypeaheadSearchItem {
  searchableText: string;
}

export default class TypeaheadSearchStore<T extends ITypeaheadSearchItem> {
  @observable public isLoading = false;
  @observable public error: Error | null = null;
  @observable public items: Array<ISearchFieldItem<T>> | null = null;
  @observable public highlightedIndex = -1;
  @observable public highlightedValue: string | null = null;

  private allItems: Array<T> | null = null;
  private readonly addItemToStore: (item: T) => void;
  private readonly removeItemFromStore: (index: number) => void;

  public constructor(addItemToStore: (item: T) => void, removeItemFromStore: (index: number) => void) {
    makeObservable(this);
    this.addItemToStore = addItemToStore;
    this.removeItemFromStore = removeItemFromStore;
  }

  @computed
  public get selectedItem(): T | null {
    if (!this.items) {
      return null;
    }

    return this.items[this.highlightedIndex].searchItem;
  }

  @action
  public async load(allItems?: Array<T>, getAllItems?: () => Promise<Array<T>>): Promise<void> {
    if (allItems) {
      this.allItems = allItems;
    } else if (getAllItems) {
      this.allItems = null;
      this.error = null;

      this.isLoading = true;

      try {
        const items = await getAllItems();

        runInAction(() => {
          this.allItems = items;
          this.isLoading = false;
        });
      } catch (e) {
        runInAction(() => {
          this.isLoading = false;
          this.error = e as Error;
        });
      }
    }
  }

  @action
  public search(searchText: string): void {
    this.items = null;
    this.highlightedIndex = -1;

    const trimmedSearchText = searchText.trim();

    runInAction(() => {
      if (this.allItems?.length) {
        this.items = this.allItems.reduce<Array<ISearchFieldItem<T>>>((items, item) => {
          let searchItem: ISearchFieldItem<T>;

          const index = item.searchableText.toLowerCase().indexOf(trimmedSearchText.toLowerCase());

          if (index !== -1) {
            searchItem = {
              pre: item.searchableText.substring(0, index),
              bold: item.searchableText.substr(index, trimmedSearchText.length),
              post: item.searchableText.substring(index + trimmedSearchText.length),
              searchItem: item,
            };

            items.push(searchItem);
          } else {
            searchItem = {
              pre: item.searchableText,
              searchItem: item,
            };
          }

          return items;
        }, []);
      } else {
        this.items = [];
      }
    });
  }

  @action
  public changeHighlight(index: number): void {
    this.highlightedIndex = index;

    if (this.items && index >= 0 && index < this.items.length) {
      const highlightedItem = this.items[index];

      this.highlightedValue = `${highlightedItem.pre}${highlightedItem.bold}${highlightedItem.post}`;
    } else {
      this.highlightedValue = null;
    }
  }

  @action
  public addItem(): void {
    if (!this.selectedItem) {
      return;
    }

    this.addItemToStore(this.selectedItem);
  }

  @action
  public removeItem(index: number): void {
    this.removeItemFromStore(index);
  }
}
