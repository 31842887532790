import React, { useCallback } from 'react';
import cn from 'classnames';

import './DropdownItem.scss';

interface IDropdownItemProps {
  value: string;
  className?: string;
  onClick?(): void;
}

const DropdownItem = ({ className, value, onClick }: IDropdownItemProps): JSX.Element => {
  const handleClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      onClick?.();
    },
    [onClick],
  );

  return (
    <li className={cn('list__item dropdown__option', className)} onClick={handleClick}>
      <span className="list__item-label" title={value}>
        {value}
      </span>
    </li>
  );
};

export default DropdownItem;
