import Dexie from 'dexie';
import { ITaxonomy } from '@cdam/shared';
import { CachedImage } from '../../interfaces';

class GateDB extends Dexie {
  public readonly taxonomy: Dexie.Table<ITaxonomy, string>;
  public readonly activeDigitalContent: Dexie.Table<CachedImage, string>;

  public constructor() {
    super('GateDB');

    this.version(2).stores({
      taxonomy: 'id, name, clientName',
      activeDigitalContent: 'metadataId, lastAccessedOn',
    });

    this.taxonomy = this.table('taxonomy');
    this.activeDigitalContent = this.table('activeDigitalContent');
  }
}

export default new GateDB();
