import React, { useState, useCallback, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IAssetsFilterItem } from '@cdam/shared';
import { filterAndSort } from 'stores/assets/data/AssetFilterOverrides';
import DropdownItem from '../../../../components/dropdown/DropdownItem';
import AssetsFilterMultiSelect from './AssetsFilterMultiSelect';
import AssetsFilterSingleSelect from './AssetsFilterSingleSelect';
import AssetsFilterArticleNumbers from './AssetsFilterArticleNumbers';
import AssetsFilterBoolean from './AssetsFilterBoolean';
import AssetsFilterChoices from './AssetsFilterChoices';
import AssetsFilterInput from './AssetsFilterInput';

import './AssetsFilterPopup.scss';

interface IAssetsFilterPopup {
  attributeName: string;
  group: string;
  list?: boolean;
  isLoading?: boolean | null;
  error?: Error | null;
  items?: Array<IAssetsFilterItem>;
  selectedItems?: Array<IAssetsFilterItem>;
  selectedValue?: string;
  selectedValues?: Array<string>;
  singleChoice?: boolean;
  type?: string;
  multipleChoices?: boolean;
  filterOverrides?: Array<string>;
  validate?: boolean;
  choicesMandatory?: boolean;
  onClearAllClick?(): void;
  onToggleSelectedItem?(item: IAssetsFilterItem): void;
  onToggleSingleSelectedItem?(item: IAssetsFilterItem): void;
  onChangeValue?(val: string, openAfter?: boolean): void;
  onMultipleRemoveItem?(val: string): void;
  searchMode?: boolean;
}

const AssetsFilterPopup: React.FC<IAssetsFilterPopup> = observer(
  ({
    attributeName,
    group,
    list,
    isLoading,
    error,
    items,
    selectedItems,
    selectedValue,
    selectedValues,
    singleChoice,
    type,
    multipleChoices,
    filterOverrides,
    validate,
    choicesMandatory,
    onToggleSingleSelectedItem,
    onToggleSelectedItem,
    onClearAllClick,
    onChangeValue,
    onMultipleRemoveItem,
    searchMode,
  }: IAssetsFilterPopup) => {
    const { t } = useTranslation();
    const [separatorIndex, setSeparatorIndex] = useState(-1);
    const [sortedItems, setSortedItems] = useState([] as Array<IAssetsFilterItem>);
    const [filteredItems, setFilteredItems] = useState([] as Array<IAssetsFilterItem>);

    const filterAndSortItems = useCallback(
      (search: string) => {
        const filterItems = items ?? [];
        const searchString = search.toLocaleLowerCase();

        return filterAndSort(attributeName, filterItems, selectedItems, searchString);
      },
      [attributeName, items, selectedItems],
    );

    const filterItems = useCallback(
      (st: string) => {
        const searchText = st.toLocaleLowerCase();

        return (items ?? [])
          .slice()
          .filter((item) => !searchText || (item.text || '').toLocaleLowerCase().includes(searchText))
          .sort((a, b) => {
            if (a.text === b.text) {
              return 0;
            }

            return a.text < b.text ? -1 : 1;
          });
      },
      [items],
    );

    const getInputType = (type: string): string => {
      switch (type) {
        case 'integer':
        case 'float':
          return 'number';
        case 'boolean':
          return 'checkbox';
        default:
          return 'text';
      }
    };

    const searchItemsByText = (value: string): void => {
      if (list !== false && !singleChoice) {
        const items = filterAndSortItems(value);

        setSortedItems(items);
        setSeparatorIndex(items.findIndex((item) => !selectedItems || !selectedItems.includes(item)) - 1);
      } else {
        const items = filterItems(value);

        setFilteredItems(items);
      }
    };

    useEffect(() => {
      setSortedItems(filterAndSortItems(''));
      setFilteredItems(filterItems(''));
      setSeparatorIndex(selectedItems ? selectedItems.length - 1 : -1);
    }, [items, selectedItems, filterAndSortItems, filterItems]);

    if (attributeName === 'articleNumber') {
      return (
        <div className="popup fit-width">
          <AssetsFilterArticleNumbers selectedValue={selectedValue} showTopBorder={true} onChangeValue={onChangeValue} />
        </div>
      );
    }

    return (
      <div className="popup">
        {type ? (
          <React.Fragment>
            {getInputType(type) === 'checkbox' ? (
              <AssetsFilterBoolean selectedValue={selectedValue} onChangeValue={onChangeValue} />
            ) : filterOverrides?.length ? (
              <ul className="list dropdown__options">
                {filterOverrides?.map((value, index) => (
                  <React.Fragment key={index}>
                    <DropdownItem value={value} onClick={() => onChangeValue?.(value)} />
                  </React.Fragment>
                ))}
              </ul>
            ) : (
              <React.Fragment>
                {multipleChoices ? (
                  <AssetsFilterChoices
                    type={getInputType(type)}
                    selectedValues={selectedValues}
                    removeItem={onMultipleRemoveItem}
                    mandatory={choicesMandatory}
                    onUpdate={onChangeValue}
                  />
                ) : (
                  <AssetsFilterInput
                    type={type}
                    value={selectedValue}
                    submitValue={onChangeValue}
                    validateValue={validate}
                    searchMode={searchMode}
                    attributeName={attributeName}
                    group={group}
                  />
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <input className="popupSearch" placeholder={t('common.search')} onChange={(e) => searchItemsByText(e.target.value)} />
            {list !== false && !singleChoice ? (
              <AssetsFilterMultiSelect
                isLoading={isLoading}
                error={error}
                selectedItems={selectedItems}
                type={type}
                sortedItems={sortedItems}
                separatorIndex={separatorIndex}
                onToggleSelectedItem={onToggleSelectedItem}
                onClearAllClick={onClearAllClick}
              />
            ) : (
              <AssetsFilterSingleSelect
                isLoading={isLoading}
                error={error}
                filteredItems={filteredItems}
                onToggleSingleSelectedItem={onToggleSingleSelectedItem}
              />
            )}
          </React.Fragment>
        )}
      </div>
    );
  },
);

export default AssetsFilterPopup;
