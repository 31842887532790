import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import AssetsDetails from 'containers/assets/details/AssetsDetails';
import EditTemplates from 'containers/templates/EditTemplates';
import UploadProductImages from 'containers/upload/UploadProductImages';
import UploadArchive from 'containers/upload/UploadArchive';
import UploadDownloadManagerStore from 'stores/uploadDownloadManager/UploadDownloadManagerStore';
import Profile from '../profile/Profile';
import ViewStore from '../../stores/view/ViewStore';
import MatrixSettings from '../search/components/MatrixSettings';
import Version from '../../components/coredam/Version';
import { Loader } from '../../components/loader/Loader';
import AppWithNavbar from './AppWithNavbar';
import AuthRedirect from '../../components/auth/AuthRedirect';

import './App.scss';
import AuthStore from '../../stores/auth/AuthStore';

const App: React.FC = observer(() => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  useEffect(() => {
    window.addEventListener('beforeunload', (event) => {
      if (UploadDownloadManagerStore.inProgress) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        event.returnValue = t('common.confirm_cancel_upload');
      }
    });
    setIsLoading(true);
    ViewStore.initStores();
  }, [t]);

  if (ViewStore.isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/details/:metadataId/:type?" component={AssetsDetails} exact />
        <Route path="/upload-product-images" component={UploadProductImages} exact />
        <Route path="/upload" component={UploadArchive} exact />
        <Route path="/profile" component={Profile} exact />
        <Route path="/matrix-settings/:brand/:eventId?" component={MatrixSettings} exact />
        <Route path="/edit-templates/:templateId?" component={EditTemplates} exact />
        <Route path="/" component={AppWithNavbar} />
      </Switch>
      <Version />
    </React.Fragment>
  );
});

export default App;
