/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import AssetsFilter from 'containers/assets/components/AssetsFilter';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import MatrixSettingsStore from '../../../../stores/matrix/MatrixSettingsStore';

import './MatrixSettingsUpload.scss';

interface IMatrixSettingsUploadProps {
  store: MatrixSettingsStore;
}

const MatrixSettingsUpload = ({ store }: IMatrixSettingsUploadProps): JSX.Element => {
  const toggleUploadFilter = (item: AssetFilter, filters: Array<AssetFilter>): boolean => {
    item.setMandatory(false);

    return store.toggleSelectedFilter(item, filters);
  };

  return (
    <div>
      <AssetsFilter
        className="MatrixSettingsUpload-filter-container"
        filterClassName="space-m-b-3 MatrixSettingsUpload-filter-stretch"
        filters={store.uploadingItemsTaxonomy}
        selectedFilters={store.editingItemUploadFilters}
        toggleSelectedFilter={toggleUploadFilter}
        clearAllFilters={store.clearAllUploadFilters}
        brand={store.event ? store.event.brand : undefined}
        invalidFilters={store.invalidEditingUploadFilters}
      />
    </div>
  );
};

export default MatrixSettingsUpload;
