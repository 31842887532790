import React from 'react';
import cn from 'classnames';
import Icon from 'components/icons/Icon';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import UploadDownloadManagerStore from 'stores/uploadDownloadManager/UploadDownloadManagerStore';
import { InProgressItemType } from 'stores/uploadDownloadManager/enums/InProgressItemType';
import UploadDownloadEmpty from './components/UDMEmpty';
import DownloadReadyItem from './components/UDMAvailableDownloadItem';
import UDMInProgressItem from './components/UDMInProgressItem';

import './UploadDownloadManager.scss';

interface IUploadDownloadManagerProps {
  className?: string;
}

const UploadDownloadManager = observer(({ className }: IUploadDownloadManagerProps): JSX.Element | null => {
  const { t } = useTranslation();
  const store = UploadDownloadManagerStore;

  if (!store.showManager) {
    return null;
  }

  return (
    <div className={cn('modal UploadDownloadManager', className)}>
      <div className="modal__header">
        <span className="modal__close">
          <button className="btn enabled btn-icon btn-default" onClick={async () => UploadDownloadManagerStore.showOrCloseManager(true)}>
            <Icon icon="icon-close" color="rgba(0,0,0,0.55)" />
          </button>
        </span>
      </div>
      <div className="modal__feature">
        {store.emptyManager && <UploadDownloadEmpty />}
        {store.uploadItems.length > 0 && (
          <React.Fragment>
            <p className="body--lg">{t('common.upload_in_progress')}</p>
            <hr className="UploadDownloadManager-horizontal-line" />
            {store.uploadItems.map((item, index) => (
              <UDMInProgressItem key={index} item={item} itemType={InProgressItemType.Upload} />
            ))}
          </React.Fragment>
        )}
        {store.downloadItems.length > 0 && (
          <React.Fragment>
            <p className="body--lg">{t('common.preparing_downloads')}</p>
            <hr className="UploadDownloadManager-horizontal-line" />
            {store.downloadItems.map((item, index) => (
              <UDMInProgressItem key={index} item={item} itemType={InProgressItemType.Download} />
            ))}
          </React.Fragment>
        )}
        {store.downloadReadyItems.length > 0 && (
          <React.Fragment>
            <p className="body--lg">{t('common.available_downloads')}</p>
            <hr className="UploadDownloadManager-horizontal-line" />
            {store.downloadReadyItems.map((item, index) => (
              <DownloadReadyItem key={index} item={item} />
            ))}
          </React.Fragment>
        )}
      </div>
    </div>
  );
});

export default UploadDownloadManager;
