/* eslint-disable complexity */
import React from 'react';
import cn from 'classnames';
import Icon from 'components/icons/Icon';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import { observer } from 'mobx-react-lite';
import { IInProgressItem } from 'stores/uploadDownloadManager/interfaces/IInProgressItem';
import { InProgressItemType } from 'stores/uploadDownloadManager/enums/InProgressItemType';

import './UDMItem.scss';

interface IUDMInProgressItemProps {
  className?: string;
  item: IInProgressItem;
  itemType: InProgressItemType;
}

const UDMInProgressItem = observer(
  ({ className, item, itemType }: IUDMInProgressItemProps): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div className={cn('UDMItem space-m-b-3', className)}>
        <div className="UDMItem-description">
          <Icon
            icon={itemType === InProgressItemType.Upload ? 'icon-upload' : 'icon-Open-in-Desi'}
            height={22}
            width={22}
            className="UDMItem-icon space-m-r-3"
          />
          <div className="UDMItem-description-text">
            <p className="body body--sm UDMItem-main-text">{decodeURIComponent(item.title)}</p>
            <span className="caption caption--sm">{!item.error && `${item.fileCount} files`}</span>
            {item.error && <span className="caption caption--sm UDMItem-error-message">{item.error.message}</span>}
            {item.error && !item.showCancel && (
              <Button
                buttonType={ButtonType.Secondary}
                size={ButtonSize.Small}
                className="UDMItem-try-again-btn"
                onClick={() => item.onTryAgainClick()}
              >
                {t('common.try_again')}
              </Button>
            )}
          </div>
        </div>
        {item.isLoading && <div className="loader loader--xs UDMItem-icon" />}
        {item.error && !item.showCancel && <Icon icon="icon-attention-big" className="UDMItem-icon" color="rgba(255, 0, 0)" height={25} width={25} />}
        {item.error && item.showCancel && (
          <Button buttonType={ButtonType.Default} size={ButtonSize.Default} className="btn-icon btn-rounded" onClick={() => item.remove()}>
            <Icon icon="icon-close" className="UDMItem-icon UDMItem-btn-icon" color="rgba(0,0,0,0.5)" height={25} width={25} />
          </Button>
        )}
        {!item.error && !item.isLoading && item.success && <Icon icon="icon-checkmark-released" color="#24bf62" height={25} width={25} />}
      </div>
    );
  },
);

export default UDMInProgressItem;
