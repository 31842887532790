import Excel from 'exceljs';
import { AssetData } from 'stores/assets/data/AssetData';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import TableListViewConfig from 'configs/TableListViewConfig';
import GateDB from '../db/indexdb';
import AssetUtils from './AssetUtils';

export default {
  async exportDataToFile(headers: Array<AssetFilter>, items: Array<AssetData>, fileName: string): Promise<void> {
    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Assets');

    worksheet.columns = this.setTableHeaders(headers);

    worksheet.pageSetup.verticalCentered = true;
    worksheet.pageSetup.showGridLines = true;

    const rows = this.fillTableRows(items, headers);

    rows.forEach((element) => {
      worksheet.addRow(element);
    });

    this.setTableStyle(worksheet);

    let index = 0;

    for (const item of items) {
      // eslint-disable-next-line no-await-in-loop
      const image = await GateDB.activeDigitalContent.get({ metadataId: item.asset.id });

      if (image?.blob) {
        // eslint-disable-next-line no-await-in-loop
        const base64 = await this.getImageFromCache(image.blob);

        const imageId2 = workbook.addImage({
          base64,
          extension: 'png',
        });

        worksheet.addImage(imageId2, {
          tl: { col: 0.5, row: index + 1 },
          ext: { width: 62, height: 62 },
        });
      }

      index++;
    }

    this.formatColumnWidth(worksheet);

    this.generateXlsxAndDownload(workbook, fileName);
  },
  formatColumnWidth(worksheet: Excel.Worksheet): void {
    const fromRow = 1;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) {
      return;
    }

    const maxColumnLengths: Array<number> = [];

    const PIXELS_PER_EXCEL_WIDTH_UNIT = 7.5;

    worksheet.eachRow((row, rowNum) => {
      if (rowNum < fromRow) {
        return;
      }

      row.eachCell((cell, num) => {
        if (typeof cell.value === 'string') {
          if (maxColumnLengths[num] === undefined) {
            maxColumnLengths[num] = 0;
          }

          const fontSize = cell.font?.size ? cell.font.size : 11;

          ctx.font = `${fontSize}pt Arial`;
          const metrics = ctx.measureText(cell.value);
          const cellWidth = metrics.width;

          maxColumnLengths[num] = Math.max(maxColumnLengths[num], cellWidth);
        }
      });
    });

    for (let i = 2; i <= worksheet.columnCount; i++) {
      const col = worksheet.getColumn(i);
      const width = maxColumnLengths[i];

      if (width) {
        // eslint-disable-next-line no-mixed-operators
        col.width = width / PIXELS_PER_EXCEL_WIDTH_UNIT + 1;
      }
    }
  },
  setTableStyle(worksheet: Excel.Worksheet): void {
    worksheet.eachRow((row, rowNumber) => {
      if (rowNumber === 1) {
        row.height = 20;
        row.font = { bold: true };

        row.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: {
            argb: 'C0C0C0',
          },
          bgColor: {
            argb: 'FF000000',
          },
        };
        row.alignment = { horizontal: 'center', vertical: 'middle' };
      } else {
        row.height = 50;
      }

      row.eachCell((cell, cellNum) => {
        if (cellNum === 1) {
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
        } else if (rowNumber !== 1) {
          cell.alignment = { vertical: 'middle' };
        }

        cell.border = {
          top: { style: 'thin', color: { argb: '000000' } },
          left: { style: 'thin', color: { argb: '000000' } },
          bottom: { style: 'thin', color: { argb: '000000' } },
          right: { style: 'thin', color: { argb: '000000' } },
        };
      });
    });
  },
  async getImageFromCache(blob: Blob): Promise<string> {
    return new Promise<string>((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(blob);

      reader.onload = () => {
        resolve(reader.result as string);
      };
    });
  },
  setTableHeaders(filters: Array<AssetFilter>): Array<Record<string, unknown>> {
    const fileHeaders: Array<Record<string, unknown>> = [];

    fileHeaders.push({ header: 'Placeholder', key: 'placeholder', width: 15 });

    filters.forEach((element) => {
      fileHeaders.push({ header: element.text, key: element.attribute.name, width: 15 });
    });

    return fileHeaders;
  },
  fillTableRows(items: Array<AssetData>, filters: Array<AssetFilter>): Array<Array<string>> {
    const values: Array<Array<string>> = [];

    items.forEach((item) => {
      const returnValues: Array<string> = [''];

      filters.forEach((element) => {
        const customFilters = TableListViewConfig.addMCTCustomColumnList;

        if (customFilters.includes(element.attribute.name)) {
          returnValues.push(AssetUtils.getCustomValue(item, element) ?? '');
        } else {
          returnValues.push(AssetUtils.getValue(item, element) ?? '');
        }
      });
      values.push(returnValues);
    });

    return values;
  },
  generateXlsxAndDownload(workbook: Excel.Workbook, fileName: string): void {
    workbook.xlsx
      .writeBuffer()
      .then((buffer) => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const objectUrl = URL.createObjectURL(blob);
        const anchor = document.createElement('a');

        anchor.href = objectUrl;
        anchor.download = `${fileName}.xlsx`;
        anchor.click();
        URL.revokeObjectURL(objectUrl);
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error(e);
      });
  },
};
