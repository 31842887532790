import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { PAGE_SIZE } from 'stores/assets/AssetsStore';
import { Loader } from '../../../components/loader/Loader';
import ErrorUtils from '../../../utils/ErrorUtils';
import './AssetsLoader.scss';

interface IAssetsLoader {
  isLoading: boolean;
  error: Error | null | undefined;
  itemsLength: number;
  emptyMessage?: string;
  small?: boolean;
  className?: string;
  allShowed?: boolean | null;
}

export const AssetsLoader = observer((props: IAssetsLoader) => {
  const { t } = useTranslation();

  if (props.isLoading) {
    return (
      <div className="AssetsLoader-content space-p-v-4">
        <Loader small={true} className={props.className ? props.className : 'AssetsLoader-loader'} />
        <div className="space-m-l-3 AssetsLoader-content-text">{t('list.loading').toUpperCase()}</div>
      </div>
    );
  }

  if (props.error) {
    return <div className="AssetsLoader-content AssetsLoader-content-error space-p-v-4">{ErrorUtils.getErrorMessage(props.error).toUpperCase()}</div>;
  }

  if (props.itemsLength === 0) {
    return <div className="AssetsLoader-content AssetsLoader-content-text space-p-v-4">{props.emptyMessage ?? t('list.empty').toUpperCase()}</div>;
  }

  if (props.itemsLength < PAGE_SIZE) {
    return null;
  }

  if (props.allShowed) {
    return (
      <div className="AssetsLoader-content space-p-v-4">
        <div className="AssetsLoader-content-icon" />
        <div className="space-m-l-3 AssetsLoader-content-text">{t('list.no_content').toUpperCase()}</div>
      </div>
    );
  }

  return null;
});
