import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import { useTranslation } from 'react-i18next';
import AuthStore from 'stores/auth/AuthStore';
import AuthRedirect from 'components/auth/AuthRedirect';
import Logout from 'containers/layout/Logout';
import DialogStore from 'stores/dialog/DialogStore';
import ForbiddenAccessPage from 'components/coredam/ForbiddenAccessPage';
import App from './containers/layout/App';
import { Loader } from './components/loader/Loader';

import 'containers/layout/App.scss';

const Root: React.FC = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    AuthStore.init().catch((e) => console.error(e));
  }, []);

  if (AuthStore.state !== 'done' && window.location.pathname !== '/redirect_url') {
    return <Loader />;
  }

  if (AuthStore.error) {
    return (
      <div className="App">
        <div className="App-container">
          <div>
            <Button className="space-m-r-2" buttonType={ButtonType.Secondary} size={ButtonSize.Default} onClick={() => window.location.reload()}>
              {t('common.try_again')}
            </Button>
            <Button
              buttonType={ButtonType.Primary}
              size={ButtonSize.Default}
              onClick={async () => {
                try {
                  await AuthStore.logout(false);
                } catch (e) {
                  const error = e as Error;

                  DialogStore.error(error);
                }
              }}
            >
              {t('common.logout')}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path="/redirect_url" component={AuthRedirect} exact />
        <Route path="/logout" component={Logout} exact />
        <Route
          path="/forbidden"
          component={() => <ForbiddenAccessPage classname="space-m-t-4 space-m-h-auto" message={t('error.forbidden_access_general')} />}
          exact
        />
        <Route path="/" component={App} />
      </Switch>
    </React.Fragment>
  );
});

export default Root;
