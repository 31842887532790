import React from 'react';
import cn from 'classnames';
import { ReactComponent as ImageIcon } from '../../../assets/icon/icon_image.svg';

interface IUploadTemplateItem {
  className?: string;
  text: string;
  selected: boolean;
  handleClick(): void;
}

const UploadTemplateItem = ({ className, text, selected, handleClick }: IUploadTemplateItem): JSX.Element => (
  // eslint-disable-next-line
    <a href="#"
    className={cn('TemplateItem box-shadow-2 space-m-l-1 space-m-r-4', className, { selected })}
    onClick={(e) => {
      e.preventDefault();
      handleClick();
    }}
  >
    <ImageIcon className="space-m-l-3 space-m-r-4" />
    {text}
  </a>
);

export default UploadTemplateItem;
