import { AttributeDisplayType } from '@cdam/shared';

type AttributeTypeMap<T> = { [displayType in AttributeDisplayType]: T };

const filterDisplayTypeConfig: AttributeTypeMap<Array<string>> = {
  date: [
    'createdOn',
    'deletedOn',
    'lastModifiedOn',
    'imageLaunchDate',
    'assetExpirationDate',
    'assetPublishDate',
    'availableFrom',
    'availableTo',
    'lastUpdated',
    'uploadDate',
    'imageExpiryDate',
    'visibleDate',
    'launchDate',
    'usageStartDate',
    'usageEndDate',
    'protectionUntilDate',
    'archiveDate',
  ],
  dateTime: ['updatedDate'],
};

export const customFilters: Array<string> = ['owners'];

export default filterDisplayTypeConfig;
