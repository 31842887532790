import React from 'react';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Dropdown from '../../../components/dropdown/Dropdown';
import Button, { ButtonSize, ButtonType } from '../../../components/input/Button';
import TextBoxControlled from '../../../components/input/TextBoxControlled';
import ClientHttpService, { UrlType } from '../../../services/ClientHttpService';
import { AssetFilter } from '../../../stores/assets/data/AssetFilter';

interface IUploadFilterArticleNumberProps {
  filter: AssetFilter;
}

export const UploadFilterArticleNumber = observer(({ filter }: IUploadFilterArticleNumberProps) => {
  const { t } = useTranslation();

  const store = useLocalObservable(() => ({
    value: '',
    error: null as Error | null,

    async searchArticle() {
      try {
        this.error = null;

        await ClientHttpService.fetch({ urlType: UrlType.Metadata, url: `/masterData/SPM_article/${this.value}`, method: 'GET' });

        filter.changeValue(this.value);
        this.value = '';
      } catch (e) {
        this.error = e as Error;
      }
    },

    changeValue(value: string) {
      this.value = value;
    },
  }));

  return (
    <Dropdown
      value={filter.value ? filter.value : t('common.select')}
      className={filter.value || !filter.isMandatory ? undefined : 'UploadAssets-white-background'}
      minFilterWidth="15rem"
      disableLabel
    >
      <ul className="list dropdown__options">
        <div className="space-m-2">
          <TextBoxControlled
            type="text"
            value={store.value}
            errorOutline={!!store.error}
            error={store.error ? t('error.article_not_found') : undefined}
            onChange={(e) => {
              store.changeValue(e.target.value);
            }}
          />
          <Button
            size={ButtonSize.Small}
            buttonType={ButtonType.Primary}
            className="UploadAssets-filter-update-button"
            onClick={async () => {
              await store.searchArticle();
            }}
          >
            {t('common.update')}
          </Button>
        </div>
      </ul>
    </Dropdown>
  );
});
