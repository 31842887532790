import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';

import './MatrixUnsavedChangesDialog.scss';

interface IConfirmDialogProps {
  onConfirm: React.MouseEventHandler<HTMLButtonElement>;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
  translationKey: string;
}

const MatrixUnsavedChangesDialog = observer(({ onCancel, onConfirm, translationKey }: IConfirmDialogProps) => {
  const { t } = useTranslation();

  return (
    <div className="MatrixUnsavedChangesDialog modal">
      <Icon icon="icon-attention-big" height={50} width={50} />
      <div className="MatrixUnsavedChangesDialog-text">{t(translationKey)}</div>
      <Button
        className="MatrixUnsavedChangesDialog-button space-m-r-2"
        buttonType={ButtonType.Secondary}
        size={ButtonSize.Default}
        onClick={onCancel}
      >
        {t('common.cancel')}
      </Button>
      <Button
        className="MatrixUnsavedChangesDialog-button space-m-l-2"
        buttonType={ButtonType.Secondary}
        size={ButtonSize.Default}
        onClick={onConfirm}
      >
        {t('common.save')}
      </Button>
    </div>
  );
});

export default MatrixUnsavedChangesDialog;
