/* eslint-disable max-len */
import React from 'react';

interface GateLogoProps {
  className?: string;
  height: string;
  width: string;
}

const GateLogo = ({ height, width, className = '' }: GateLogoProps): JSX.Element => (
  <svg height={height} width={width} viewBox="0 0 32 32" className={className}>
    <path
      d="M27.631 32H4.37A4.381 4.381 0 010 27.631V4.37A4.381 4.381 0 014.369 0H27.63A4.381 4.381 0 0132 4.369V27.63A4.381 4.381 0 0127.631 32z"
      fill="#3CB19C"
    />
    <path
      d="M20.371 21.48h-3.11v3.137h3.11V21.48zM19.482 14.307H18.15v1.345h1.333v-1.345zM15.038 10.72h-1.333v1.346h1.333V10.72zM15.482 21.031H14.15v1.345h1.333v-1.345zM3.483 4.444h3.555v24.208H3.483V4.444zM25.704 4.444h3.556v24.208h-3.556V4.444z"
      fill="#fff"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.372 17.893c0-7.274 5.229-13.449 12-13.449 6.77 0 11.999 6.175 11.999 13.45h-2.667c0-6.096-4.322-10.76-9.333-10.76s-9.333 4.664-9.333 10.76H4.372z"
      fill="#fff"
    />
    <path
      d="M24.823 28.607v-11.88c-.22-3.49-3.07-6.387-3.07-6.387v16.04l3.07 2.227zM7.94 28.607v-11.88c.22-3.49 3.07-6.387 3.07-6.387v16.04l-3.07 2.227zM14.816 14.307l2.145 3.716h-4.29l2.145-3.716z"
      fill="#fff"
    />
  </svg>
);

export default GateLogo;
