/* eslint-disable complexity */
import React from 'react';
import { IMetadataDigitalContent, AssetContentType } from '@cdam/shared';
import AssetUtils from 'utils/AssetUtils';
import ViewStore from '../../../stores/view/ViewStore';
import { AssetData } from '../../../stores/assets/data/AssetData';
import { getAssetCardMetadata, getCardDisplayValues } from '../../../utils/AssetDisplayUtils';

import './AssetCardBody.scss';

export interface AssetCardBodyProps {
  activeDigitalContent: IMetadataDigitalContent | undefined;
  contentType: AssetContentType;
  displayArticle?: boolean;
  hideDetails?: boolean;
  item: AssetData;
  openAssetDetails?(): void;
}

const MIN_LINE_LENGTH = 28;
const LINE_BREAKS = ['\u0020', '\u005F', '\u002D', '\u002C', '\u002E', '\u003A', '\u0028', '\u0029', '\u005B', '\u005D'];

function AssetCardBody({ item, contentType, hideDetails, displayArticle, activeDigitalContent, openAssetDetails }: AssetCardBodyProps): JSX.Element {
  const assetName = AssetUtils.getName(item, activeDigitalContent) ?? '';
  let title = '';
  let numberOfLines = 0;

  for (let i = 0; i < 6 * MIN_LINE_LENGTH && i < assetName.length; i++) {
    ++numberOfLines;
    let newIndex = i + MIN_LINE_LENGTH;

    for (let x = 0; x < 10; x++) {
      ++newIndex;

      if (LINE_BREAKS.includes(assetName[newIndex])) {
        break;
      }
    }

    const line = assetName.slice(i, newIndex);

    i = newIndex;

    title = `${title}\n${line}`;
  }

  if (contentType !== AssetContentType.PI) {
    const displayValues = getAssetCardMetadata(ViewStore.selectedUiAssetDomain, item, activeDigitalContent);

    return (
      <div
        className={`card__body AssetCardBody AssetCardBody-mct ${numberOfLines > 2 && 'AssetCardBody-mct__folded'}`}
        onClick={openAssetDetails && openAssetDetails}
      >
        <div className="card__title">
          <div className={`AssetCardBody-title-text ${numberOfLines > 2 && 'AssetCardBody-title-text__folded'}`}>{title}</div>
        </div>
        <div>
          <div className="AssetCardBody-mct-attribute-row">
            {displayValues.map((rowData, index) => (
              <div className="card__attribute AssetCardBody-detail-row" key={index}>
                <div>{rowData[0]}</div>
                <div className="card__attribute AssetCardBody-mct-attribute-row__justify-end">{rowData[1]}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  const displayValues = contentType && getCardDisplayValues({ item }, contentType, activeDigitalContent);

  return (
    <div className="card__body AssetCardBody" onClick={openAssetDetails && openAssetDetails}>
      <div className="card__title">
        <div className="card__title AssetCardBody-title-text">{displayArticle ? AssetUtils.getArticleNumber(item) : title}</div>
      </div>
      {!hideDetails && (
        <div className="card__attributes-container AssetsCard-attributes">
          {displayValues.map((item, index) => (
            <div key={index} className="card__attribute">
              {item.value}
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default AssetCardBody;
