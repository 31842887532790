/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Icon from 'components/icons/Icon';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import DialogStore from 'stores/dialog/DialogStore';
import TextBoxControlled from 'components/input/TextBoxControlled';
import UploadArchiveAssetsStore from 'stores/upload/UploadArchiveAssetsStore';
import Formatter from 'utils/Formatter';
import UploadArchiveFilterDropdown from '../../containers/upload/components/UploadArchiveFilterDropdown';

import './FilesTable.scss';

interface IArchiveFilesTable {
  className?: string;
  store: UploadArchiveAssetsStore;
}

const ArchiveFilesTable = observer(
  ({ className, store }: IArchiveFilesTable): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div className={cn('FilesTable FilesTable-archive-table-height space-m-b-4 space-m-t-6', className)}>
        <table className="table FilesTable-table-container">
          <thead className="table__head">
            <tr className="table__head-row">
              <th className="table__head-cell FilesTable-border-less" />
              <th className="table__head-cell">{t('common.name')}</th>
              {store.uploadAssets.length > 0 &&
                store.uploadAssets[0].presetUploadFilters.map((filter, index) => (
                  <th key={index} className="table__head-cell">
                    {filter.text}
                    {filter.isMandatory && <span className="FilesTable-red-text space-p-l-1">*</span>}
                  </th>
                ))}
              <th className="table__head-cell">{t('common.file_size')}</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {store.uploadAssets.map((file, index) => (
              <tr className="table__body-row" key={file.checksum}>
                <td className="table__body-cell FilesTable-border-less FilesTable-remove" onClick={() => store.removeFile(index)}>
                  <Icon icon="icon-close" height={22} width={22} />
                </td>
                <td className="table__body-cell FilesTable-textbox FilesTable-white-background">
                  <TextBoxControlled
                    value={file.name}
                    className="FilesTable-textbox-absolute"
                    height={2.85}
                    noMargin
                    onChange={(e) => {
                      store.changeFileName(file, e.target.value);
                    }}
                  />
                </td>
                {file.presetUploadFilters.map((filter, index) => (
                  <td key={index} className="table__body-cell FilesTable-upload-filter-cell">
                    <UploadArchiveFilterDropdown filter={filter} />
                  </td>
                ))}
                <td>
                  <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">
                    {Formatter.getFileSizeFromBytes(file.size, 1)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="space-m-t-4 space-m-l-3">
          <Button
            buttonType={ButtonType.Primary}
            size={ButtonSize.Default}
            icon="icon-upload"
            iconColor="#fff"
            className="FilesTable-upload-btn"
            disabled={store.canUpload}
            onClick={() => {
              store.beginUploadingFiles();
              DialogStore.removeLastDialog();
            }}
          >
            {t('common.upload')}
          </Button>
        </div>
      </div>
    );
  },
);

export default ArchiveFilesTable;
