import React from 'react';
import { observer } from 'mobx-react-lite';
import './Loader.scss';
import cn from 'classnames';

interface ILoaderProps {
  large?: boolean;
  small?: boolean;
  className?: string;
  width?: number;
}

export const Loader = observer(({ large, width, small, className }: ILoaderProps) => (
  <div className={cn('Loader', className)} style={{ width }}>
    <div className={cn('loader', { 'loader-lg': large, 'loader--sm': small })} />
  </div>
));
