/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/unbound-method */
import React, { useEffect, useRef, useState } from 'react';
import { appLocationEnum, AppVariantEnum } from '@cdam/shared';
import MinimalLayout from 'components/layout/MinimalLayout';
import NavigationService from 'services/NavigationService';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import TemplateStore from 'stores/templates/TemplateStore';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';
import TextBoxControlled from 'components/input/TextBoxControlled';
import AssetsFilter from 'containers/assets/components/AssetsFilter';
import CheckboxItem from 'components/checkbox/CheckboxItem';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import { ListLoader } from 'components/loader/ListLoader';
import DialogStore from 'stores/dialog/DialogStore';
import MatrixUnsavedChangesDialog from 'containers/search/dialogs/MatrixUnsavedChangesDialog';
import MatrixItemDeleteDialog from 'containers/search/dialogs/MatrixItemDeleteDialog';
import TemplateSearch from 'components/templatesearch/TemplateSearch';
import { ReactComponent as AddIcon } from '../../assets/icon/icon_plus.svg';
import ViewStore from '../../stores/view/ViewStore';
import MatrixSettingsOwners from '../search/dialogs/components/MatrixSettingsOwners';
import ForbiddenAccessPage from '../../components/coredam/ForbiddenAccessPage';
import { Loader } from '../../components/loader/Loader';
import TemplateItem from './components/TemplateItem';

import './EditTemplates.scss';

interface IEditTemplatesRouteProps {
  templateId: string;
}

const EditTemplates = observer(
  ({ match }: RouteComponentProps<IEditTemplatesRouteProps>): JSX.Element => {
    const { t } = useTranslation();
    const [store] = useState(() => new TemplateStore());
    const searchRef = useRef<HTMLInputElement | null>(null);

    const templateId = match.params.templateId;

    const onSearchPress = (e: KeyboardEvent): void => {
      if (e.key === 'F3' || ((e.ctrlKey || e.metaKey) && e.key === 'f')) {
        e.preventDefault();

        if (searchRef?.current && searchRef.current) {
          searchRef.current.focus();
        }
      } else if (e.key === 'Escape') {
        if (searchRef?.current && searchRef.current) {
          searchRef.current.blur();
        }
      }
    };

    useEffect(() => {
      store.load(templateId);

      window.addEventListener('keydown', onSearchPress);

      return () => {
        window.removeEventListener('keydown', onSearchPress);
      };
    }, [store, templateId]);

    const onCancelClick = (): void => {
      if (store.unsavedChanges()) {
        DialogStore.addDialog(
          <MatrixUnsavedChangesDialog
            translationKey="matrix.unsaved_changes"
            onCancel={() => {
              NavigationService.navigateTo('/');
              DialogStore.removeLastDialog();
            }}
            onConfirm={() => {
              store.saveTemplate();
              DialogStore.removeLastDialog();
            }}
          />,
        );
      } else {
        NavigationService.navigateTo('/');
      }
    };

    const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
      if (store.selectedTemplate) {
        store.updateSelectedTemplateName(e.currentTarget.value);
        store.validateTemplateName(store.selectedTemplate.name);
      }
    };

    if (!ViewStore.canCreateTemplates) {
      return <ForbiddenAccessPage classname="EditTemplates-error-page" />;
    }

    if (store.isLoading) {
      return <Loader />;
    }

    return (
      <MinimalLayout
        header={t('common.edit_creation_templates')}
        headerClassName="space-m-h-6 space-m-t-6 space-m-b-4"
        onCloseButtonClick={() => onCancelClick()}
      >
        <header className="space-m-h-6 space-m-t-5 space-m-b-3 EditTemplates-template-list-header">
          <h3 className="subtitle subtitle--lg">1. {t('common.select_create_template')}</h3>
          {/* eslint-disable-next-line react/jsx-handler-names */}
          <TemplateSearch searchRef={searchRef} onSearch={store.searchTemplates} />
        </header>
        <div className="EditTemplates-template-list space-m-l-6">
          <Link to="/edit-templates/new" className="btn btn-placeholder EditTemplates-add-template-btn space-m-r-5">
            <AddIcon className="space-m-r-4" />
            {t('common.new_template')}
          </Link>
          {store.templatesResults &&
            store.templatesResults.length > 0 &&
            store.templatesResults.map((templateInfo) => (
              <TemplateItem
                key={templateInfo.id}
                text={templateInfo.name}
                path={`/edit-templates/${templateInfo.id}`}
                selected={store.selectedTemplateId === templateInfo.id}
              />
            ))}
        </div>
        <>
          <ListLoader isLoading={store.isLoading} error={store.error} itemsLength={1} />
          {store.selectedTemplate && (
            <>
              <h3 className="subtitle subtitle--lg space-m-h-6 space-m-t-5 space-m-b-3">2. {t('common.edit_template')}</h3>
              <div className="space-m-h-6">
                <h4 className="subtitle subtitle--sm EditTemplates-section-heading">{t('common.template_name')}</h4>
                <div className="EditTemplates-template-name">
                  <div className="EditTemplates-template-name-input">
                    <TextBoxControlled value={store.selectedTemplate.name} onChange={handleTemplateNameChange} />
                    {store.selectedTemplate.name.length !== 0 && store.nameIsUnique && (
                      <svg className="space-m-l-2 yarn-svg-icon icon--xs">
                        <use xlinkHref="#icon-checkmark-released" />
                      </svg>
                    )}
                  </div>
                  {!store.nameIsUnique && <div className="EditTemplates-template-name-err">{t('common.name_not_unique')}</div>}
                </div>
                <h4 className="subtitle subtitle--sm EditTemplates-section-heading">{t('common.metadata')}</h4>
                <div className="EditTemplates-metadata-filters">
                  <AssetsFilter
                    filterClassName="space-m-r-3 space-m-t-4"
                    maxFilterWidth="15rem"
                    minFilterWidth="15rem"
                    filters={store.taxonomyMetadataFilters}
                    selectedFilters={store.metadataFilters}
                    clearAllFilters={store.clearAllMetadataFilters}
                    toggleSelectedFilter={store.toggleSelectedFilter}
                    choicesMandatory={true}
                    checkList
                    appLocation={appLocationEnum.EDIT_TEMPLATE}
                  />
                </div>
                {ViewStore.appVariant === AppVariantEnum.GateUI && (
                  <>
                    <div className="EditTemplates-extract-checkboxes space-m-t-4">
                      <label className="EditTemplates-checkbox-item body body--sm space-m-r-3">
                        <CheckboxItem
                          checked={store.selectedTemplate.extractArticleNumbers ?? false}
                          onClick={() => store.toggleExtractArticleNumbersCheckbox()}
                        />
                        {t('common.template_extract_article_number')}
                      </label>
                      <label className="EditTemplates-checkbox-item body body--sm">
                        <CheckboxItem
                          checked={store.selectedTemplate.extractProductViews ?? false}
                          onClick={() => store.toggleExtractProductViewsCheckbox()}
                        />
                        {t('common.template_extract_product_views')}
                      </label>
                    </div>
                  </>
                )}

                <>
                  <h3 className="subtitle subtitle--lg space-m-h-6 space-m-t-5 space-m-b-3">
                    3. {ViewStore.appVariant === AppVariantEnum.GateUI ? t('common.owners') : t('common.assign_users')}
                  </h3>
                  <MatrixSettingsOwners
                    addItem={store.addToGeneralItemOwners}
                    removeItem={store.removeGeneralItemOwners}
                    itemList={store.generalItemOwners}
                    allItems={store.allOwners}
                    label={ViewStore.appVariant === AppVariantEnum.ArchiveUI ? t('common.add_user_label') : undefined}
                  />
                </>

                <div className="space-m-t-6 space-m-b-3">
                  {store.selectedTemplateId !== 'new' && (
                    <Button
                      className="EditTemplates-delete-template-button space-m-r-3"
                      buttonType={ButtonType.Secondary}
                      size={ButtonSize.Default}
                      onClick={() => {
                        DialogStore.addDialog(
                          <MatrixItemDeleteDialog
                            translationKey="common.delete_template"
                            deleteBtnTranslationKey="common.delete"
                            onCancel={() => DialogStore.removeLastDialog()}
                            onConfirm={async () => {
                              await store.deleteTemplate();
                              DialogStore.removeLastDialog();
                            }}
                          />,
                        );
                      }}
                    >
                      {t('common.delete')}
                    </Button>
                  )}
                  <Button
                    buttonType={ButtonType.Primary}
                    size={ButtonSize.Default}
                    disabled={store.saveDisabled}
                    onClick={async () => {
                      if (store.saveDisabled) {
                        return;
                      }

                      await store.saveTemplate();
                    }}
                  >
                    {t('common.save')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </>
      </MinimalLayout>
    );
  },
);

export default EditTemplates;
