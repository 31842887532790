import React, { useEffect, useCallback, useState } from 'react';
import Icon from 'components/icons/Icon';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import DropdownCheckboxItem from 'components/dropdown/DropdownCheckboxItem';
import UtilityStore from 'stores/utility/UtilityStore';
import { AssetContentType, ContextAction } from '@cdam/shared';

interface IActionMenuModal {
  clientX: number;
  clientY: number;
  actionMenu: boolean;
  actionType: ContextAction;
  items: Array<AssetFilter>;
  itemsType: AssetContentType | null;
  onHide(): void;
  onToggleSingleSelectedItem(item: AssetFilter): void;
}

const ActionMenuModal = ({
  clientX,
  clientY,
  actionMenu,
  onHide,
  onToggleSingleSelectedItem,
  actionType,
  items,
  itemsType,
}: IActionMenuModal): JSX.Element => {
  const [filteredItems, setFilteredItems] = useState([] as Array<AssetFilter>);

  const closeOpenedFilter = useCallback(
    (e: Event): void => {
      const clickedElement = e.target as HTMLElement;

      if (clickedElement?.closest) {
        const closestFilterDropDown = clickedElement.closest('.TableListView-contextMenu');

        if (closestFilterDropDown) {
          return;
        }
      }

      if (actionMenu) {
        onHide();
      }
    },
    [actionMenu, onHide],
  );

  useEffect(() => {
    document.addEventListener('click', closeOpenedFilter);
    document.addEventListener('touch', closeOpenedFilter);

    let availableItems: Array<AssetFilter> = [];

    if (actionType === ContextAction.Replace) {
      const filters = UtilityStore.getColumnsByType(itemsType).map((e) => e.attribute.name);

      items.forEach((element) => {
        if (!filters.includes(element.attribute.name)) {
          availableItems.push(element);
        }
      });
    } else {
      availableItems = items;
    }

    setFilteredItems(availableItems);

    return () => {
      document.removeEventListener('click', closeOpenedFilter);
      document.removeEventListener('touch', closeOpenedFilter);
    };
  }, [actionType, closeOpenedFilter, items, itemsType]);

  const filterItems = useCallback(
    (st: string) => {
      const searchText = st.toLocaleLowerCase();

      return (items ?? [])
        .slice()
        .filter((item) => !searchText || (item.text || '').toLocaleLowerCase().includes(searchText))
        .sort((a, b) => {
          if (a.text === b.text) {
            return 0;
          }

          return a.text < b.text ? -1 : 1;
        });
    },
    [items],
  );

  const menuPosition = {
    top: `${clientY + 2}px`,
    left: `${clientX + 2}px`,
  };

  return (
    <div className="TableListView-contextMenu overflow-menu" style={menuPosition}>
      <div className="TableListView-contextMenu-header space-p-v-2 space-p-h-3">
        <strong>{actionType === ContextAction.Replace ? 'Replace with' : 'Insert new'}</strong>
        <Icon icon={'icon-close'} className={'TableListView-contextMenu-icon'} height={18} width={18} color={'#000'} onClick={onHide} />
      </div>
      <div>
        <input
          className="TableListView-contextMenu-search"
          placeholder="Search"
          onChange={(e) => {
            const items = filterItems(e.target.value);

            setFilteredItems(items);
          }}
        />
      </div>
      <ul className="overflow-menu__item-list TableListView-contextMenu-table">
        {actionType === ContextAction.Add
          ? filteredItems?.map((item, index) => (
              <li key={index} className="overflow-menu__item TableListView-contextMenu-item TableListView-actionMenu-item">
                <DropdownCheckboxItem
                  checked={UtilityStore.getColumnsByType(itemsType)
                    .map((e) => e.attribute.name)
                    .includes(item.attribute.name)}
                  value={item.text}
                  labelClassName="space-p-t-1 caption"
                  onClick={() => onToggleSingleSelectedItem(item)}
                />
              </li>
            ))
          : filteredItems?.map((item, index) => (
              <li key={index} className="overflow-menu__item TableListView-contextMenu-item" onClick={() => onToggleSingleSelectedItem(item)}>
                {item.text}
              </li>
            ))}
      </ul>
    </div>
  );
};

export default ActionMenuModal;
