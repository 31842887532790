import React from 'react';
import { observer } from 'mobx-react-lite';
import { SortDirection } from '@cdam/shared';

import './Sortable.scss';

interface ISortable {
  direction?: SortDirection;
  className?: string;
  onClick(direction: SortDirection): void;
  enabled?: boolean;
}

const Sortable = observer(
  ({ direction, onClick, enabled, className }: ISortable): JSX.Element => (
    <span className={`Sortable ${className} space-p-r-1 ${direction && 'Sortable-direction'} ${!enabled && 'Sortable-disabled'}`}>
      {direction ? (
        <svg
          className={`icon Sortable-${direction}`}
          height="14"
          width="14"
          viewBox="0 0 12 15"
          transform={direction === 'desc' ? 'rotate(180)' : ''}
          onClick={() => enabled && onClick(direction === 'asc' ? 'desc' : 'asc')}
        >
          <path fill="#000" d="M7.002 14.027V3.837l3.583 3.59L12 6.009 6 0 0 6.004l1.422 1.418 3.579-3.585v10.19h2z" />
        </svg>
      ) : (
        <svg className={'Sortable-neutral icon icon-fetch'} height="22" width="22" onClick={() => enabled && onClick('desc')}>
          <use xlinkHref={'#icon-fetch'} />
        </svg>
      )}
    </span>
  ),
);

export default Sortable;
