import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';

import './DownloadDialog.scss';

interface IDownloadDialogProps {
  text: string;
  onClose(): void;
  actionTo(): void;
}

export const DownloadDialog = observer(({ text, onClose, actionTo }: IDownloadDialogProps) => {
  const { t } = useTranslation();

  return (
    <div className="DownloadDialog modal">
      <div className="DownloadDialog-text">{text}</div>
      <Button
        className="space-m-l-3"
        buttonType={ButtonType.Primary}
        size={ButtonSize.Default}
        onClick={() => {
          onClose();
          actionTo();
        }}
      >
        {t('common.ok')}
      </Button>
      <Button className="space-m-l-3" buttonType={ButtonType.Secondary} size={ButtonSize.Default} onClick={onClose}>
        {t('common.cancel')}
      </Button>
    </div>
  );
});
