import React from 'react';
import Icon from '../icons/Icon';

import './ChoicesSelection.scss';

interface IAssetsFilterChoices {
  removeItem?(): void;
  item: string | undefined;
  backgroundColor?: string;
  color?: string;
  uppercase?: boolean;
  disableRemove?: boolean;
}

const ChoicesSelection = ({ removeItem, item, backgroundColor, uppercase, color, disableRemove }: IAssetsFilterChoices): JSX.Element => (
  <div className="ChoicesSelection" style={{ backgroundColor, color }}>
    <div className={`ChoicesSelection-item ${uppercase && 'ChoicesSelection-uppercase'}`}>{item}</div>
    {!disableRemove && (
      <div className="ChoicesSelection-item">
        <Icon icon="icon-close" color={color ? color : '#000'} height={18} width={18} onClick={removeItem && removeItem} />
      </div>
    )}
  </div>
);

export default ChoicesSelection;
