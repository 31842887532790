import React from 'react';
import { Brand } from '@cdam/shared';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Adidas } from '../../../assets/icon/adidas.svg';
import { ReactComponent as Reebok } from '../../../assets/icon/reebok.svg';

import './SearchBrandSelector.scss';

const SearchBrandSelector = (): JSX.Element => (
  <div className="SearchBrandSelector">
    <div className="SearchBrandSelector-container">
      <NavLink
        className="SearchBrandSelector-selector"
        isActive={(_, { pathname }) => pathname.includes('/adidas') || pathname === '/'}
        to="/"
        exact
        data-testid="search-view-brand-selector-adidas"
      >
        <Adidas />
      </NavLink>
      <div className="SearchBrandSelector-icon-seperator" />
      <NavLink className="SearchBrandSelector-selector" to={`/${Brand.Reebok}`} data-testid="search-view-brand-selector-reebok">
        <Reebok />
      </NavLink>
    </div>
  </div>
);

export default SearchBrandSelector;
