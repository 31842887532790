/* eslint-disable complexity */
import React from 'react';
import { observer } from 'mobx-react-lite';
import CoreDamImage from 'components/coredam/CoreDamImage';
import './DownloadAssetCard.scss';
import cn from 'classnames';
import { DownloadableAssetData } from 'stores/assets/AdvancedDownloadStore';
import { useTranslation } from 'react-i18next';
import { readFileExtension } from 'utils/FileFormatParser';
import AssetUtils from 'utils/AssetUtils';
import { showConfidential } from 'utils/AssetDisplayUtils';
import { ITransformationArguments } from '@cdam/shared';
import { AssetData } from 'stores/assets/data/AssetData';
import DownloadFormatSelectDropdown from './DownloadFormatSelectDropdown';

interface IAssetsCardProps {
  item: DownloadableAssetData;
  className?: string;
  width: number;
  height: number;
}

const DownloadAssetCard: React.FC<IAssetsCardProps> = observer(({ item, className, height, width }: IAssetsCardProps) => {
  const { t } = useTranslation();

  const {
    asset: { id: metadataId },
    activeDigitalContent,
  } = item;

  const assetData = new AssetData(item.asset);

  const showConfidentialWatermark = showConfidential(assetData);
  const expired = AssetUtils.getExpired(assetData);

  const getArguments = (): ITransformationArguments => {
    const args: ITransformationArguments = { width: 260, height: 260, format: 'jpg' };

    if (showConfidentialWatermark) {
      args.watermark = true;
      args.watermarkName = 'Confidential';
    } else if (expired === 'Yes') {
      args.watermark = true;
      args.watermarkName = 'Expired';
    }

    return args;
  };

  return (
    <div className={cn('DownloadAssetCard card card-status-default', className)}>
      <div className="card__inner-container">
        <div className="card__header">
          {activeDigitalContent && (
            <div className="DownloadAssetCard-action-container card__action-container bg-1.shape-rounded">
              <div className="card__actions">
                <DownloadFormatSelectDropdown asset={item} />
              </div>
            </div>
          )}
          <div className="card__image-container DownloadAssetCard-image" style={{ height, width /* stylelint-disable */ }}>
            <CoreDamImage
              className="card__image"
              etag={item.asset.etag}
              width={260}
              height={260}
              transformationArgs={getArguments()}
              metadataId={metadataId}
              fileName={activeDigitalContent?.fileName ?? ''}
              imageExtension={activeDigitalContent?.storageURL && readFileExtension(activeDigitalContent.storageURL)}
            />
            <span className="badge badge--white card__badge space-p-2">
              <div className="DownloadAssetCard-badge-square DownloadAssetCard-badge space-m-t-1 space-m-r-2" />
              <span className="DownloadAssetCard-badge-text">{t('common.asset')}</span>
            </span>
          </div>
        </div>
        <div className="card__body">
          <h4 className="card__title">{item.asset.attributeGroups?.['Asset Information']?.assetName || activeDigitalContent?.fileName}</h4>
        </div>
      </div>
    </div>
  );
});

export default DownloadAssetCard;
