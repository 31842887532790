import React from 'react';
import cn from 'classnames';
import { AssetType, AssetContentType } from '@cdam/shared';
import DetailsStore, { IDisplayItem } from 'stores/assets/DetailsStore';
import DetailsItem from './DetailsItem';

import './AssetsInfo.scss';

interface IAssetsInfo {
  className?: string;
  item: IDisplayItem;
  assetType: AssetType;
  detailsStore: DetailsStore;
  contentType: AssetContentType | null;
  onNavigateClick(): void;
}

const AssetsInfo = ({ className, item, assetType, contentType, onNavigateClick, detailsStore }: IAssetsInfo): JSX.Element => {
  let View;

  if (contentType === AssetContentType.PI) {
    if (assetType === AssetType.Article) {
      View = (
        <div>
          <DetailsItem item={item} assetType={AssetType.Article} detailsStore={detailsStore} />
        </div>
      );
    } else if (assetType === AssetType.Asset) {
      View = (
        <div>
          <DetailsItem item={item} assetType={AssetType.Asset} detailsStore={detailsStore} />
          <DetailsItem
            item={item}
            assetType={AssetType.Article}
            detailsStore={detailsStore}
            secondaryHeader={true}
            onNavigateClick={onNavigateClick}
          />
        </div>
      );
    }
  } else {
    View = <DetailsItem item={item} assetType={assetType} hideDropDown={true} detailsStore={detailsStore} />;
  }

  return <div className={cn('AssetsInfo space-m-t-4', className)}>{View}</div>;
};

export default AssetsInfo;
