import React, { useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import cn from 'classnames';
import MatrixSettingsStore from 'stores/matrix/MatrixSettingsStore';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import { observer } from 'mobx-react-lite';
import { ReactComponent as PDFIcon } from '../../assets/icon/placeholders/pdf.svg';

import './WhereToFindFileUploadZone.scss';

interface IWhereToFindFileUploadZone {
  className?: string;
  store: MatrixSettingsStore;
  whereToFindWhatUploaded: boolean;
}

const MAX_FILE_SIZE = 52428800;

const WhereToFindFileUploadZone = observer(
  ({ store, className, whereToFindWhatUploaded }: IWhereToFindFileUploadZone): JSX.Element => {
    const { t } = useTranslation();
    const [fileSizeError, setFileSizeError] = useState(false);

    const onDrop = useCallback(
      (acceptedFiles: Array<File>, rejectedFiles: Array<FileRejection>) => {
        setFileSizeError(false);

        if (rejectedFiles.length && rejectedFiles[0].file.size > MAX_FILE_SIZE) {
          setFileSizeError(true);

          return;
        }

        store.handleFileDrop(acceptedFiles[0]);
      },
      [store],
    );

    // eslint-disable-next-line @typescript-eslint/unbound-method
    const { acceptedFiles, getRootProps, getInputProps, isDragAccept, isDragReject } = useDropzone({
      onDrop,
      multiple: false,
      accept: 'application/pdf',
      maxSize: MAX_FILE_SIZE,
    });

    return (
      <section className={cn('WhereToFindFileUploadZone form-group', className)}>
        <label className="control__label">{t('common.where_to_find_what')}?</label>
        {whereToFindWhatUploaded && (
          <div className="WhereToFindFileUploadZone-file-exists-container space-m-v-2">
            <div className="WhereToFindFileUploadZone-file-exists-filename">
              <PDFIcon className="space-m-r-2" height={30} width={22} />
              <div className="caption caption--lg">{store.event ? store.event.whereToFindWhatFilename : 'Where-to-find-what.pdf'}</div>
            </div>
            <Icon
              icon="icon-delete"
              className="WhereToFindFileUploadZone-delete-button"
              height={22}
              width={22}
              // eslint-disable-next-line @typescript-eslint/return-await
              onClick={async () => await store.deleteWhereToFindWhatFile()}
            />
          </div>
        )}
        <div className="WhereToFindFileUploadZone-square-container">
          <div
            {...getRootProps({
              className: `WhereToFindFileUploadZone-dropzone ${isDragAccept ? 'WhereToFindFileUploadZone-dropzone-active' : undefined}`,
            })}
          >
            <input {...getInputProps()} />
            {acceptedFiles.length === 0 ? (
              <p className="body-xs space-m-0 text-center">{whereToFindWhatUploaded ? t('common.drag_drop_replace') : t('common.drag_drop_file')}</p>
            ) : (
              <div className="WhereToFindFileUploadZone-filename space-m-h-3">{acceptedFiles[0].name}</div>
            )}
          </div>
        </div>
        {isDragReject && <div className="caption caption--md space-m-v-3 space-m-l-3 text-danger">{t('common.one_pdf_file_upload')}.</div>}
        {fileSizeError && <div className="caption caption--md space-m-v-3 space-m-l-3 text-danger">{t('common.max_file_size')}.</div>}
        {store.invalidWhereToFindWhatFilename && (
          <>
            <div className="caption caption--md space-m-t-3 space-m-l-3 text-danger">{t('advancedDownload.invalid_file_name')}.</div>
            <div className="caption caption--md space-m-t-1 space-m-l-3 text-danger">
              {t('common.filename_must_follow_rules')}:
              <ul className="space-m-b-1">
                <li className="space-m-l-2">- {t('common.special-char-not_allowed')}</li>
                <li className="space-m-l-2">- {t('common.max-filename-char-length')}</li>
                <li className="space-m-l-2">- {t('common.os_words_forbidden')}</li>
                <li className="space-m-l-2">- {t('common.filename_end_char')}</li>
              </ul>
            </div>
          </>
        )}
      </section>
    );
  },
);

export default WhereToFindFileUploadZone;
