import { AssetDomainsNamesEnums } from '@cdam/shared';

export type TemplateFilterConfig = Partial<Record<AssetDomainsNamesEnums, IFilterCoTemplateFilterConfig>>;

interface IFilterCoTemplateFilterConfig {
  defaultFilters: Array<string>;
  allowedFilters: Array<string>;
}

export default {
  [AssetDomainsNamesEnums.DABOX_TAXONOMY_NAME]: {
    defaultFilters: ['category', 'containerName', 'generic', 'jobno', 'name', 'season', 'status', 'subBrand', 'visible', 'year'],
    allowedFilters: [
      'articleNo',
      'boxDynamicKeywords177',
      'boxDynamicKeywords185',
      'boxDynamicKeywords2623',
      'boxDynamicKeywords2625',
      'boxDynamicKeywords2627',
      'boxDynamicKeywords388',
      'boxDynamicKeywords416',
      'boxDynamicKeywords5',
      'boxDynamicKeywords59',
      'boxDynamicKeywords696',
      'boxDynamicKeywords698',
      'boxDynamicKeywords70',
      'boxDynamicKeywords746',
      'campaignId',
      'campaignPortalId',
      'campaignStatus',
      'category',
      'communicationChannel',
      'containerName',
      'contentGroupProtected',
      'DABOX_campaign',
      'deliverable',
      'Kalido_MDT_BRAND',
      'Kalido_MDT_BRAND_ASSET',
      'Kalido_MDT_BRAND_ASSET_TYPE',
      'Kalido_MDT_GENDER',
      'Kalido_MDT_SPORTS_CAT',
      'Kalido_MDT_TECH_CONCEPT',
      'generic',
      'jobno',
      'launchDate',
      'name',
      'protectionUntilDate',
      'season',
      'status',
      'subBrand',
      'usageEndDate',
      'usageStartDate',
      'usageTypes',
      'visible',
      'visibleDate',
      'visibleServiceUser',
      'year',
    ],
  },
  [AssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME]: {
    defaultFilters: ['batchName', 'brand_pm', 'subBrand_pm', 'category_pm', 'season_pm', 'year_pm', 'agency', 'status_rd'],
    allowedFilters: [
      'DABOX_campaign_pm',
      'agency',
      'articleNumber_pm',
      'batchName',
      'brand_pm',
      'campaignName_pm',
      'category_pm',
      'communicationChannel_pm',
      'gender_pm',
      'jobno_pm',
      'keywords',
      'mediaType',
      'productTechnology_pm',
      'season_pm',
      'status_rd',
      'subBrand_pm',
      'usageEndDate',
      'usageStartDate',
      'viewOnly',
      'year_pm',
    ],
  },
} as TemplateFilterConfig;
