export default {
  // eslint-disable-next-line complexity
  getFileType(fileExtensions: string): string {
    switch (fileExtensions) {
      case 'aac':
        return 'audio/aac';
      case 'avi':
        return 'video/x-msvideo';
      case 'bmp':
        return 'image/bmp';
      case 'csv':
        return 'text/csv';
      case 'doc':
        return 'application/msword';
      case 'docx':
        return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      case 'gif':
        return 'image/gif';
      case 'ico':
        return 'image/vnd.microsoft.icon';
      case 'jpeg':
      case 'jpg':
        return 'image/jpg';
      case 'json':
        return 'application/json';
      case 'mp3':
        return 'audio/mpeg';
      case 'mpeg':
        return 'video/mpeg';
      case 'odp':
        return 'application/vnd.oasis.opendocument.presentation';
      case 'ods':
        return 'application/vnd.oasis.opendocument.spreadsheet';
      case 'odt':
        return 'application/vnd.oasis.opendocument.text';
      case 'oga':
        return 'audio/ogg';
      case 'ogv':
        return 'video/ogg';
      case 'png':
        return 'image/png';
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'rar':
        return 'application/x-rar-compressed';
      case 'rtf':
        return 'application/rtf';
      case 'svg':
        return 'image/svg+xml';
      case 'tar':
        return 'application/x-tar';
      case 'tif':
      case 'tiff':
        return 'image/tiff';
      case 'txt':
        return 'text/plain';
      case 'wav':
        return 'audio/wav';
      case 'weba':
        return 'audio/webm';
      case 'webm':
        return 'video/webm';
      case 'webp':
        return 'image/webm';
      case 'xls':
        return 'application/vnd.ms-excel';
      case 'xlsx':
        return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      case 'zip':
        return 'application/zip';
      case '3gp':
        return 'video/3gpp';
      case '3g2':
        return 'video/3gp2';
      case '7z':
        return 'application/x-7z-compressed';
      default:
        return 'application/octet-stream';
    }
  },
  composeFileExtensionFromBlob(blob: Blob): string {
    const blobTypeParts = blob.type.split('/');

    return blobTypeParts[blobTypeParts.length - 1];
  },
  getDateTimeAsString(): string {
    const date = new Date();

    const currentDateAsString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date
      .getMinutes()
      .toString()
      .padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

    return currentDateAsString;
  },
};
