import React, { useEffect } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import DatePicker from 'react-datepicker';
import { observer, useLocalObservable } from 'mobx-react-lite';
import { observable, runInAction } from 'mobx';

import 'react-datepicker/dist/react-datepicker.css';
import './DateTimePicker.scss';
import '../dropdown/Dropdown.scss';

interface IDatePickerProps {
  label?: string;
  value?: string;
  onChangeValue?(val: string): void;
  open?: boolean;
  disabled?: boolean;
  className?: string;
  closeWhenItemSelect?: boolean;
  maxFilterWidth?: string;
  minFilterWidth?: string;
  disableLabel?: boolean;
  error?: boolean;
  displayGrayedOut?: boolean;
  displayValue?: string;
  selectRange?: boolean;
}

export const DATE_RANGE_PREFIX = 'Range:';

export const DATE_RANGE_SEPARATOR = ',';
const DATE_FORMAT = 'yyyy-MM-dd';

const DateTimePicker = observer(
  ({
    label,
    value,
    onChangeValue,
    open,
    disabled,
    className,
    closeWhenItemSelect,
    maxFilterWidth,
    minFilterWidth,
    disableLabel,
    error,
    displayGrayedOut,
    displayValue,
    selectRange,
  }: IDatePickerProps): JSX.Element => {
    const store = useLocalObservable(
      () => ({
        onChangeValue,

        date: null as Date | null,
        endDate: null as Date | null,

        onDateChange(date: Date | null): void {
          this.date = date;

          if (!date) {
            this.onChangeValue?.('');

            return;
          }

          this.onChangeValue?.(format(date, DATE_FORMAT));
        },

        onRangeDateChange(date: [Date, Date] | null): void {
          if (!date) {
            this.onChangeValue?.('');

            return;
          }

          const [start, end] = date;

          this.date = start;
          this.endDate = end;

          if (start && end) {
            this.onChangeValue?.(`${DATE_RANGE_PREFIX}${format(start, DATE_FORMAT)}${DATE_RANGE_SEPARATOR}${format(end, DATE_FORMAT)}`);
          }
        },
      }),
      { onChangeValue: observable.ref },
    );

    useEffect(() => {
      runInAction(() => {
        store.date = value ? new Date(value) : null;
        store.onChangeValue = onChangeValue;
      });
    }, [onChangeValue, store, value]);

    return (
      <div className={cn('Dropdown', className)} style={{ maxWidth: maxFilterWidth, minWidth: minFilterWidth /* stylelint-disable */ }}>
        <div className="dropdown">
          <div
            className={cn(`DatePicker ${error ? 'DatePicker_err' : ''} ${displayGrayedOut ? 'DatePicker-grayed-out' : ''}`, className)}
            style={{ maxWidth: maxFilterWidth, minWidth: minFilterWidth }}
          >
            {!disableLabel && <div className="dropdown__label">{label}</div>}
            {selectRange ? (
              <DatePicker
                selected={disabled ? null : store.date}
                startDate={store.date}
                endDate={store.endDate}
                placeholderText={disabled ? displayValue : undefined}
                disabled={disabled}
                shouldCloseOnSelect={false}
                open={open}
                dateFormat={DATE_FORMAT}
                isClearable={!disabled}
                disabledKeyboardNavigation
                showPopperArrow={false}
                customInput={
                  <input className="react-datepicker__input-container" readOnly style={{ maxWidth: maxFilterWidth, minWidth: minFilterWidth }} />
                }
                selectsRange={selectRange}
                onChange={(date: [Date, Date] | null) => store.onRangeDateChange(date)}
              />
            ) : (
              <DatePicker
                selected={disabled ? null : store.date}
                placeholderText={disabled ? displayValue : undefined}
                disabled={disabled}
                shouldCloseOnSelect={closeWhenItemSelect}
                open={open}
                dateFormat={DATE_FORMAT}
                isClearable={!disabled}
                disabledKeyboardNavigation
                showPopperArrow={false}
                customInput={
                  <input className="react-datepicker__input-container" readOnly style={{ maxWidth: maxFilterWidth, minWidth: minFilterWidth }} />
                }
                onChange={(date: Date | null) => store.onDateChange(date)}
              />
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default DateTimePicker;
