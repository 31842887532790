import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { ITransformationArguments } from '@cdam/shared';
import DownloadUtils from 'utils/DownloadUtils';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import { getPlaceholderImage, getPlaceholderWidth, getPlaceholderDetailsWidth } from 'utils/AssetCardPlaceholder';
import { getFileFormatTypeFromExtension, readFileExtension } from 'utils/FileFormatParser';
import { AssetData } from 'stores/assets/data/AssetData';
import { Loader } from 'components/loader/Loader';
import Icon from 'components/icons/Icon';
import PreviewNotAvailable from '../../../../components/coredam/PreviewNotAvailable';

import './PreviewImage.scss';

interface IPreviewImage {
  failedLoad?: boolean;
  imageBlob?: string;
  metadataId?: string;
  fileName?: string;
  width?: number;
  height?: number;
  transformationArgs?: ITransformationArguments;
  className?: string;
  imageExtension?: string | undefined;
  item?: AssetData;
  openFullPreview?(url: string): void;
}

const PreviewImage: React.FC<IPreviewImage> = observer(
  // eslint-disable-next-line complexity
  ({
    metadataId,
    className,
    width,
    height,
    fileName,
    transformationArgs,
    imageExtension,
    item,
    openFullPreview,
    imageBlob,
    failedLoad,
  }: IPreviewImage) => {
    const [failedToLoad, setFailedToLoad] = useState(false);
    const [downloadableUrl, setDownloadableUrl] = useState('');
    const [loading, setLoading] = useState(true);
    const [downloadLink, setDownloadLink] = useState('');
    const currentAbortController: React.MutableRefObject<null | AbortController> = useRef(null);

    useEffect(() => {
      if (failedLoad) {
        setFailedToLoad(true);
        setLoading(false);

        return;
      }

      setLoading(true);
      const formatType = imageExtension && getFileFormatTypeFromExtension(imageExtension);

      if ((!imageBlob && !formatType) || formatType === 'video' || formatType === 'audio' || formatType === 'Unknown') {
        setLoading(false);
        setFailedToLoad(true);

        return;
      }

      const getBlob = async (): Promise<void> => {
        try {
          const formatUrlParams = DownloadUtils.transformationArgsToQuery(transformationArgs);
          const url = `/metadata/${metadataId}/activeDigitalContent${formatUrlParams}`;
          const urlType = UrlType.Metadata;

          setDownloadLink(url);

          currentAbortController.current = new AbortController();
          const blob = await ClientHttpService.fetchBlob({ urlType, url, method: 'GET', signal: currentAbortController.current.signal });

          const objectUrl = URL.createObjectURL(blob);

          setFailedToLoad(false);
          setLoading(false);
          setDownloadableUrl(objectUrl);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error('PreviewImage', e);
          setFailedToLoad(true);
          setLoading(false);
        }
      };

      setDownloadableUrl('');

      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }

      if (imageBlob) {
        setDownloadLink(`/metadata/${metadataId}/activeDigitalContent${DownloadUtils.transformationArgsToQuery(transformationArgs)}`);
        setDownloadableUrl(imageBlob);
        setLoading(false);
      } else {
        getBlob();
      }

      // eslint-disable-next-line consistent-return
      return () => {
        if (currentAbortController.current) {
          currentAbortController.current.abort();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metadataId, imageBlob, item?.asset.etag]);

    const url = failedToLoad ? getPlaceholderImage((imageExtension && readFileExtension(imageExtension)) || '', true) : downloadableUrl;

    const imagewidth = failedToLoad
      ? (imageExtension && width && width / getPlaceholderWidth(imageExtension)) ||
        (imageExtension && getPlaceholderDetailsWidth(imageExtension)) ||
        (width && width / getPlaceholderWidth(''))
      : width;

    return (
      <React.Fragment>
        {loading ? (
          <Loader small={!!(width && width < 100)} />
        ) : (
          <div className={`PreviewImage ${imageBlob && 'PreviewImage-multiple'}`}>
            {!failedToLoad ? (
              <img
                className={className}
                src={url}
                alt=""
                style={{ maxWidth: imagewidth, maxHeight: height /* stylelint-disable */ }}
                onError={() => {
                  setFailedToLoad(true);
                }}
              />
            ) : (
              <PreviewNotAvailable
                height={height ? height : 500}
                width={width ? width : 500}
                backgroundColor={'#ededed'}
                classname={'PreviewImage-preview-not-available'}
              />
            )}
            {!failedToLoad && (
              <a
                className="PreviewImage-download-button btn btn-icon btn-sm btn-default btn-rounded"
                href={downloadLink}
                onClick={(e) => {
                  DownloadUtils.handleItemDownload(e, downloadLink, fileName ?? DownloadUtils.getFileNameWithExtension(item?.activeDigitalContent));
                }}
              >
                <Icon icon="icon-download" color="#808080" height={22} width={22} />
              </a>
            )}
            {!failedToLoad && openFullPreview && (
              <div className="PreviewImage-fullscreen" onClick={() => openFullPreview(url)}>
                <Icon icon="icon-enter-fullscreen" color="#737373" height={25} width={25} />
              </div>
            )}
          </div>
        )}
      </React.Fragment>
    );
  },
);

export default PreviewImage;
