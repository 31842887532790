import React from 'react';
import { observer } from 'mobx-react-lite';
import { AssetReebokCell } from 'stores/assets/data/AssetReebokCell';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icons/Icon';
import { MatrixSearch } from '../../../components/icons/MatrixSearch';
import MatrixStore from '../../../stores/assets/MatrixStore';
import ProfileStore from '../../../stores/profile/ProfileStore';

import './ReebokRightTableCell.scss';

interface ICellProps {
  cell: AssetReebokCell;
}

const CellCounterItem = ({ cell }: ICellProps): JSX.Element => (
  <div
    className="ReebokRightTableCell-highlighted-cell"
    onClick={() => {
      cell.onClick();
    }}
  >
    <MatrixSearch />
    <span>{cell.count}</span>
  </div>
);

const SearchMatrixModal = ({ cell }: ICellProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="SearchMatrixModal">
      <div
        className="SearchMatrixModal-browse"
        onClick={() => {
          cell.onClick();
        }}
      >
        <MatrixSearch />
        {t('common.browse')}
      </div>
      <div
        className="SearchMatrixModal-upload"
        onClick={() => {
          cell.openUploadDialog();
        }}
      >
        <Icon icon="icon-upload" height={20} width={20} />
        {t('common.upload')}
      </div>
    </div>
  );
};

const HighlightedCell = ({ cell, hidden }: { cell: AssetReebokCell; hidden: boolean }): JSX.Element => {
  let uploadGroup = false;

  if (cell.cellData?.upload) {
    uploadGroup = cell.cellData.upload.some((u) => ProfileStore.groups.includes(u));
  }

  return (
    <div className={`ReebokRightTableCell-item highlight-item ${hidden ? 'ReebokRightTableCell-hidden' : ''}`}>
      {uploadGroup ? <SearchMatrixModal cell={cell} /> : <CellCounterItem cell={cell} />}
    </div>
  );
};

interface IReebokRightTableCellProps {
  itemIndex: number;
  store: MatrixStore;
  cell: AssetReebokCell;
  darkBackground: boolean;
}

const ReebokRightTableCell = observer(
  ({ itemIndex, darkBackground, store, cell }: IReebokRightTableCellProps): JSX.Element => {
    if (!cell.item) {
      throw new Error();
    }

    const hasFilter = cell.item.filters && cell.item.filters.length > 0;

    const handleMouseEnter = (): void => {
      store.selectReebokCell(hasFilter ? itemIndex : -1);
      store.selectCell(-1, -1, false);
    };

    const cellIsSelected = cell.highlightItem;

    return (
      <td
        className={`ReebokRightTableCell ${cellIsSelected ? 'highlight-item' : ''} ${darkBackground ? 'dark-cell' : ''}`}
        rowSpan={1000}
        onMouseEnter={handleMouseEnter}
      >
        <div className={`ReebokRightTableCell-relative-container ${cellIsSelected ? 'outline-cell' : ''}`}>
          {hasFilter && <span className={`${cellIsSelected ? 'ReebokRightTableCell-hidden' : ''}`}>{cell.count}</span>}
          <HighlightedCell cell={cell} hidden={!cellIsSelected} />
        </div>
      </td>
    );
  },
);

export default ReebokRightTableCell;
