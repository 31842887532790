import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Loader } from '../loader/Loader';
import NavigationService from '../../services/NavigationService';
import AuthStore from '../../stores/auth/AuthStore';

const AuthRedirect = observer(() => {
  const { t } = useTranslation();

  useEffect(() => {
    AuthStore.handleWindowCallback().catch((e) => console.error(e));
  }, []);

  return (
    <div>
      {AuthStore.state === 'inProgress' && <Loader />}
      {AuthStore.error && <button onClick={() => NavigationService.navigateTo('/')}>{t('auth.back_to_login_page')}</button>}
    </div>
  );
});

export default AuthRedirect;
