import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import decode from 'jwt-decode';
import { IProfile, IProfileTemplates, IProfileUploadInfo, ITokenPayload, PermissionsEnums, UIAssetDomainsNamesEnums } from '@cdam/shared';
import { ITableHeader, ITableItem } from '../../components/table/Table';
import ClientHttpService, { UrlType } from '../../services/ClientHttpService';
import DialogStore from '../dialog/DialogStore';
import AuthStore from '../auth/AuthStore';

class ProfileStore {
  public readonly uploadTableHeaders: Array<ITableHeader> = [{ text: 'Asset name' }, { text: 'Date' }, { text: 'Link' }, { text: 'Status' }];

  @observable private profile: IProfile | null = null;

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get username(): string | undefined {
    return this.profile?.username;
  }

  @computed
  public get displayName(): string {
    if (!this.profile) {
      return '';
    }

    return decodeURIComponent(this.profile.username).split('\\')[2];
  }

  @computed
  public get groups(): Array<string> {
    return this.profile?.groups ?? [];
  }

  @computed
  public get templates(): IProfileTemplates {
    if (this.profile?.templates) {
      return this.profile.templates;
    }

    return {} as IProfileTemplates;
  }

  @computed
  public get uploadTableItems(): Array<ITableItem> {
    return (
      this.profile?.uploads?.map((upload) => ({
        rowData: [upload.assetName, upload.uploadDate, upload.link, upload.status],
      })) ?? []
    );
  }

  @computed
  public get usersUiAssetDomains(): Array<UIAssetDomainsNamesEnums> {
    return this.profile?.permissions ? (Object.keys(this.profile.permissions) as Array<UIAssetDomainsNamesEnums>) : [];
  }

  public getPropertyFromToken(property: string): string | number | undefined {
    const token = this.decodedToken();

    return token?.[property] ?? undefined;
  }

  public getInitials(): string | undefined {
    const email = this.getPropertyFromToken('unique_name') as string | undefined;

    if (!email) {
      return '';
    }

    const [firstName, lastName] = email.split('@')[0].split('.');

    return firstName && lastName ? `${firstName[0].toLocaleUpperCase() ?? ''}${lastName[0].toLocaleUpperCase() ?? ''}` : undefined;
  }

  @action
  public async init(): Promise<void> {
    try {
      const profile = await ClientHttpService.fetch<IProfile | null>({
        method: 'GET',
        urlType: UrlType.Backend,
        url: '/profile',
      });

      runInAction(() => {
        this.profile = profile;
      });
    } catch (e) {
      DialogStore.error(e);
    }
  }

  @action
  public async updateProfile(): Promise<void> {
    if (!this.profile) {
      return;
    }

    try {
      const profile = await ClientHttpService.fetch<IProfile | null>({
        method: 'POST',
        urlType: UrlType.Backend,
        url: '/profile',
        body: this.profile,
      });

      runInAction(() => {
        this.profile = profile;
      });
    } catch (e) {
      DialogStore.error(e);
    }
  }

  public hasPermission(uiAssetDomain: UIAssetDomainsNamesEnums, permission: PermissionsEnums): boolean {
    const domainPermissions = this.profile?.permissions?.[uiAssetDomain];

    if (!domainPermissions) {
      return false;
    }

    switch (permission) {
      case PermissionsEnums.READ:
        return domainPermissions.length !== 0;
      case PermissionsEnums.ADMIN:
        return domainPermissions.some((p) => p === permission);
      default:
        return domainPermissions.some((p) => p === permission || p === PermissionsEnums.ADMIN);
    }
  }

  public isInGroup(group: string): boolean {
    if (!this.profile?.groups) {
      return false;
    }

    return this.profile.groups.includes(group);
  }

  public isInAnyOfGroups(group: Array<string>): boolean {
    const { groups: usersGroups } = this.profile ?? {};

    if (!usersGroups) {
      return false;
    }

    return group.some((g) => usersGroups.includes(g));
  }

  public async updateUploadItem(uploadItem: IProfileUploadInfo): Promise<void> {
    if (!this.profile) {
      return;
    }

    runInAction(() => {
      if (this.profile) {
        this.profile.uploads = [uploadItem, ...(this.profile.uploads ?? [])].slice(0, 49);
      }
    });
  }

  private decodedToken(): ITokenPayload | null {
    return AuthStore.token ? decode<ITokenPayload>(AuthStore.token) : null;
  }
}

export default new ProfileStore();
