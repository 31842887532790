import React from 'react';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { IEvent, Brand, IMatrixAxis, IEventInfoArray, IEventItem } from '@cdam/shared';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import DownloadUtils from 'utils/DownloadUtils';
import DialogStore from 'stores/dialog/DialogStore';
import DownloadAssetDialog from 'components/coredam/DownloadAssetDialog';
import { AssetCell } from './data/AssetCell';
import { AssetReebokCell } from './data/AssetReebokCell';

export default class MatrixStore {
  @observable public selectedColumnIndex = -1;
  @observable public selectedRowIndex = -1;
  @observable public selectedReebokCellIndex = -1;
  @observable public isGroupItemSelected = false;
  @observable public groupHighlightEndIndex = -1;
  @observable public groupHighlightStartIndex = -1;
  @observable public cells: Array<AssetCell> = [];
  @observable public reebokCells: Array<AssetReebokCell> = [];

  @observable public isLoading = false;
  @observable public error: Error | null = null;

  @observable public events: IEventInfoArray | null = null;
  @observable public selectedEvent: IEvent | null = null;
  @observable public selectedEventId: string | undefined = undefined;
  @observable public brand: Brand | null = null;

  public constructor() {
    makeObservable(this);
  }

  @action
  public async load(brand: Brand, eventId: string | undefined, metadataId?: string): Promise<void> {
    this.isLoading = true;
    this.error = null;

    if (this.brand !== brand) {
      runInAction(() => {
        this.events = null;
        this.brand = brand;
      });
    }

    this.selectedEventId = eventId;
    this.selectedEvent = null;

    if (metadataId) {
      this.triggerDownloadDialog(metadataId);
    }

    try {
      if (!this.events) {
        let eventList: IEventInfoArray = {
          drafts: [],
          expired: [],
          released: [],
        };

        try {
          eventList = await ClientHttpService.fetch<IEventInfoArray>({ urlType: UrlType.Backend, url: `/matrix/${brand}`, method: 'GET' });
        } catch (e) {
          this.error = e as Error;
        }

        runInAction(() => {
          this.events = eventList;
        });
      }

      runInAction(() => {
        if (!this.selectedEventId && this.events && this.events.released.length) {
          const today = new Date();

          for (const event of this.events.released) {
            if (event.releaseDate) {
              if (!(new Date(event.releaseDate) > today)) {
                this.selectedEventId = event.id;
                break;
              }
            }
          }
        }
      });

      let event: IEvent | null = null;

      if (this.selectedEventId) {
        event = await ClientHttpService.fetch<IEvent>({ urlType: UrlType.Backend, url: `/matrix/${brand}/${this.selectedEventId}`, method: 'GET' });
      }

      runInAction(() => {
        this.selectEvent(event);
        this.isLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public async downloadWhereToFindWhatPdf(): Promise<void> {
    if (!this.selectedEvent) {
      return;
    }

    await DialogStore.execFullScreen(
      DownloadUtils.download(
        `/matrix/where-to-find-what/${this.selectedEvent.id}`,
        this.selectedEvent.whereToFindWhatFilename || 'Where-to-find-what.pdf',
        UrlType.Backend,
      ),
    );
  }

  @action
  public selectCell(rowIndex: number, columnIndex: number, isGroupItem: boolean): void {
    this.selectedRowIndex = rowIndex;
    this.selectedColumnIndex = columnIndex;
    this.isGroupItemSelected = isGroupItem;

    if (isGroupItem) {
      this.groupHighlightStartIndex = rowIndex;
      this.groupHighlightEndIndex = this.getHighlightGroupEndIndex(rowIndex);
    }
  }

  @action
  public selectReebokCell(itemIndex: number): void {
    this.selectedReebokCellIndex = itemIndex;
  }

  @action
  public selectEvent(event: IEvent | null): void {
    this.selectedEvent = event;

    this.generateMatrix();
  }

  @action
  private generateMatrix(): void {
    this.cells = [];
    this.reebokCells = [];

    if (!this.selectedEvent) {
      return;
    }

    const { id, matrix, reebokFilters, axisCounters } = this.selectedEvent;

    matrix.yAxis.forEach((yItem, rowIndex) => {
      matrix.xAxis.forEach((xItem, columnIndex) => {
        const cellData = this.getCellData(xItem, yItem, axisCounters);

        const count = this.getCount(cellData);
        const matrixAssetId = `${id}:${xItem.id}:${yItem.id}`;

        this.cells.push(new AssetCell(this, rowIndex, columnIndex, cellData, count, matrixAssetId));
      });
    });

    reebokFilters.forEach((rf, index) => {
      const cellData = this.getCellData(rf, null, axisCounters);
      const count = this.getCount(cellData);

      this.reebokCells.push(new AssetReebokCell(this, index, count, cellData));
    });
  }

  private getCellData(xAxis: IMatrixAxis, yAxis: IMatrixAxis | null, axisCounters: Array<IEventItem> | undefined): IEventItem | undefined {
    return axisCounters?.find((c) => c.xAxisId === xAxis.id && c.yAxisId === (yAxis ? yAxis.id : ''));
  }

  private getCount(counter: IEventItem | undefined): string {
    if (counter) {
      if (counter.isError) {
        return 'err';
      }

      if (counter.count === -1) {
        return '0';
      }

      if (counter.count > 999) {
        return '+999';
      }

      return counter.count.toString();
    }

    return 'n/a';
  }

  private getHighlightGroupEndIndex(groupItemRowIndex: number): number {
    const numberOfItemsInRow = this.selectedEvent?.matrix.xAxis.length ?? 1;
    const rowCount = Math.round(this.cells.length / numberOfItemsInRow);

    let startCounting = false;
    let index = -1;
    let cell;

    for (let i = 0; i < rowCount; i++) {
      index++;

      cell = this.cells[i * numberOfItemsInRow];

      if (index === groupItemRowIndex) {
        startCounting = true;
      }

      if (startCounting && (cell.isGroupItem || cell.isStandaloneItem) && index !== groupItemRowIndex) {
        break;
      }
    }

    if (index === rowCount - 1 && !(cell?.isStandaloneItem || cell?.isGroupItem)) {
      return index;
    }

    return index - 1;
  }

  private triggerDownloadDialog(metadataId: string): void {
    DialogStore.addDialog(<DownloadAssetDialog metadataId={metadataId} />);
  }
}
