import React from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { IFilterDropdownProps } from '@cdam/shared';
import { ListLoader } from '../../../../components/loader/ListLoader';
import DropdownCheckboxItem from '../../../../components/dropdown/DropdownCheckboxItem';

import './AssetsFilterPopupMultiSelect.scss';

const AssetsFilterMultiSelect = observer(
  ({
    isLoading,
    error,
    selectedItems,
    onToggleSelectedItem,
    type,
    onClearAllClick,
    sortedItems,
    separatorIndex,
  }: IFilterDropdownProps): JSX.Element => {
    const { t } = useTranslation();

    return (
      <React.Fragment>
        <div className="popupContent">
          {isLoading !== null && isLoading !== undefined && <ListLoader isLoading={isLoading} itemsLength={sortedItems?.length ?? 0} error={error} />}
          {sortedItems?.map((item, index) => (
            <React.Fragment key={index}>
              <DropdownCheckboxItem
                className={cn({ 'cb-selected': selectedItems?.includes(item) })}
                labelClassName="AssetsFilterDropDown-checkbox-label"
                checked={selectedItems?.includes(item)}
                value={item.text}
                isDisabled={(item.canBeRemoved !== undefined && !item.canBeRemoved) ?? undefined}
                onClick={() => {
                  if (item.canBeRemoved !== undefined && !item.canBeRemoved) {
                    return;
                  }

                  onToggleSelectedItem?.(item);
                }}
              />
              {index === separatorIndex && <div className="popupSeparator" />}
            </React.Fragment>
          ))}
        </div>
        {!type && onClearAllClick && (
          <button className="popupClear" type="button" onClick={onClearAllClick}>
            {t('common.clear_all')}
          </button>
        )}
      </React.Fragment>
    );
  },
);

export default AssetsFilterMultiSelect;
