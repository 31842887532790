import { observable, action, makeObservable } from 'mobx';
import { AssetContentType, IReferenceData } from '@cdam/shared';
import { IDisplayColumns } from 'stores/assets/AssetsStore';
import { AssetFilter } from 'stores/assets/data/AssetFilter';

export interface ICachedReferenceData {
  referenceDataList: Array<IReferenceData>;
  createdAt: Date;
}

class UtilityStore {
  @observable public listViewCols: Array<IDisplayColumns> = [];
  private assetDetailsNavigation: Array<string> | null = null;
  private readonly referenceData: Map<string, ICachedReferenceData> = new Map<string, ICachedReferenceData>();

  public constructor() {
    makeObservable(this);
  }

  // NAVIGATION CACHE LOGIC
  public storeAssetDetailsNavigation(navigationIds: Array<string> | null): void {
    if (!navigationIds) {
      return;
    }

    this.assetDetailsNavigation = navigationIds;
  }

  public getNavigationIds(): Array<string> | null {
    return this.assetDetailsNavigation;
  }

  public clearNaivgationCache(): void {
    this.assetDetailsNavigation = [];
  }

  // UserColumnsLogic

  @action
  public setColumnList(items: Array<IDisplayColumns>): void {
    this.listViewCols = items;
  }

  public getColumnsByType(items: AssetContentType | null): Array<AssetFilter> {
    if (!items) {
      return [];
    }

    const index = this.listViewCols.findIndex((e) => e.assetGroup === items);

    if (index === -1) {
      return [];
    }

    return this.listViewCols[index].assetColumns;
  }

  // Reference data
  public storeReferenceData(referenceDataString: string, referenceDataList: Array<IReferenceData>): void {
    this.referenceData.set(referenceDataString, { referenceDataList, createdAt: new Date() });
  }

  public getReferenceData(referenceDataString: string): ICachedReferenceData | undefined {
    return this.referenceData.get(referenceDataString);
  }
}

// singleton
export default new UtilityStore();
