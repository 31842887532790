import React from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';

import './Table.scss';

export interface ITableItem {
  rowData: Array<string | number | boolean | undefined>;
}

export interface ITableHeader {
  text: string;
  mandatory?: boolean;
}

interface ITableProps<T extends ITableItem> {
  className?: string;
  headers: Array<ITableHeader>;
  items: Array<T>;
}

const Table = observer(
  <T extends ITableItem>({ className, headers, items }: ITableProps<T>): JSX.Element => (
    <div className={cn('Table space-m-b-4 space-m-t-6', className)}>
      <table className="table Table Table-table-container">
        <thead className="table__head">
          <tr className="table__head-row">
            {headers.map((header, index) => (
              <th key={`${header.text}-${index}`} className="table__head-cell">
                {`${header.text}${header.mandatory ? '*' : ''}`}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="table__body">
          {items.map((item, index) => (
            <tr className="table__body-row" key={index}>
              {item.rowData.map((data, index) => (
                <td key={index} className="table__body-cell">
                  {data ?? ''}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ),
);

export default Table;
