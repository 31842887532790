import React, { useEffect, useState } from 'react';
import MinimalLayout from 'components/layout/MinimalLayout';
import { useTranslation } from 'react-i18next';
import NavigationService from 'services/NavigationService';
import { observer } from 'mobx-react-lite';
import UploadProductImagesStore from 'stores/upload/UploadProductImagesStore';
import { ListLoader } from 'components/loader/ListLoader';
import ProductImageUploadZone from 'components/fileUpload/ProductImageUploadZone';
import ProductImagesFilesTable from 'components/fileUpload/ProductImagesFilesTable';
import { Link } from 'react-router-dom';
import ViewStore from '../../stores/view/ViewStore';
import UploadTemplateItem from './components/UploadTemplateItem';

import './UploadAssets.scss';

const UploadProductImages = observer(
  (): JSX.Element => {
    const { t } = useTranslation();
    const [store] = useState(() => new UploadProductImagesStore());

    useEffect(() => {
      store.load();
    }, [store]);

    return (
      <MinimalLayout
        header={t('common.upload')}
        headerClassName="space-m-h-6 space-m-t-6 space-m-b-4"
        onCloseButtonClick={() => {
          NavigationService.navigateTo('/');
        }}
      >
        <div>
          {store.templates && store.templates.length > 0 ? (
            <React.Fragment>
              <h3 className="subtitle subtitle--lg space-m-h-6 space-m-t-5 space-m-b-3">1. {t('common.select_template')}</h3>
              <div className="EditTemplates-template-list space-m-l-6">
                {store.templates?.map((templateInfo) => (
                  <UploadTemplateItem
                    key={templateInfo.id}
                    text={templateInfo.name}
                    selected={store.selectedTemplate ? store.selectedTemplate.id === templateInfo.id : false}
                    // eslint-disable-next-line @typescript-eslint/return-await
                    handleClick={async () => await store.selectTemplate(templateInfo.id)}
                  />
                ))}
              </div>
            </React.Fragment>
          ) : (
            <div className="EditTemplates-template-list space-m-l-6">
              {t('common.no_templates_found')}&nbsp;
              {ViewStore.canCreateTemplates && (
                <React.Fragment>
                  {t('common.please_create_a_template')}&nbsp;<Link to="/edit-templates">{t('common.here')}.</Link>
                </React.Fragment>
              )}
            </div>
          )}
        </div>
        <ListLoader isLoading={store.isLoading} error={store.error} itemsLength={1} />
        <div>
          {store.selectedTemplate && (
            <React.Fragment>
              <div className="space-m-h-6 space-m-v-5">
                <div className="caption caption--md space-m-b-2 UploadAssets-filename-convention-label">{t('common.name_guideline')}</div>
                <div className="caption caption--lg UploadAssets-filename-convention">{'Article number_Product view code_xxxx.fileformat'}</div>
              </div>
              <div>
                <h3 className="subtitle subtitle--lg space-m-h-6 space-m-t-5 space-m-b-3">2. {t('common.drag_drop_browse_choose')}</h3>
              </div>
              <div className="UploadAssets-square-content space-m-h-6 space-m-v-2">
                <div className="col-sm-7">
                  <ProductImageUploadZone store={store} />
                  {store.uploadAssets.length > 0 && <ProductImagesFilesTable store={store} />}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </MinimalLayout>
    );
  },
);

export default UploadProductImages;
