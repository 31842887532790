/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Icon from 'components/icons/Icon';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import DialogStore from 'stores/dialog/DialogStore';
import UploadFilterDropdown from 'containers/upload/components/UploadFilterDropdown';
import TextBoxControlled from 'components/input/TextBoxControlled';
import { UploadStore } from '../../stores/upload/UploadStore';

import './FilesTable.scss';

interface IFileUploadDropZone {
  className?: string;
  store: UploadStore;
  filenameConvention: string;
}

const FilesTable = observer(
  ({ className, filenameConvention, store }: IFileUploadDropZone): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div className={cn('FilesTable space-m-b-4 space-m-t-6', className)}>
        <table className="table FilesTable-table-container">
          <thead className="table__head">
            <tr className="table__head-row">
              <th className="table__head-cell FilesTable-border-less" />
              <th className="table__head-cell">{t('common.name')}</th>
              {store.uploadAssets.length > 0 &&
                store.uploadAssets[0].displayedUploadFilters.map((filter, index) => (
                  <th key={index} className="table__head-cell">
                    {filter.text}
                    {filter.isMandatory && <span className="FilesTable-red-text space-p-l-1">*</span>}
                  </th>
                ))}
            </tr>
          </thead>
          <tbody className="table__body">
            {store.uploadAssets.map((file, index) => (
              <tr className="table__body-row" key={index}>
                <td
                  className="table__body-cell FilesTable-border-less FilesTable-remove"
                  onMouseOver={() => store.changeChecksumLoaderHover(file, true)}
                  onMouseLeave={() => store.changeChecksumLoaderHover(file, false)}
                  onClick={() => store.removeFile(index)}
                >
                  {file.checksumCalculationInProgress && !file.checksumLoaderMouseHovered ? (
                    <div className="FilesTable-checksum-loader">
                      <div className="loader loader--sm" />
                    </div>
                  ) : (
                    <div className="FilesTable-checksum-loader">
                      <Icon icon="icon-close" height={24} width={24} />
                    </div>
                  )}
                </td>
                {file.validFilename ? (
                  <td className="table__body-cell">
                    <div className="FilesTable-truncate">{file.name}</div>
                  </td>
                ) : (
                  <td className="table__body-cell FilesTable-textbox FilesTable-white-background">
                    <TextBoxControlled
                      value={file.name}
                      className="FilesTable-textbox-absolute"
                      height={2.85}
                      noMargin
                      errorOutline
                      onChange={(e) => {
                        store.changeFileName(file, e.target.value);
                      }}
                      onBlur={() => {
                        store.validateFilenames();
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          store.validateFilenames();
                        }
                      }}
                    />
                  </td>
                )}
                {file.displayedUploadFilters.map((filter, index) => (
                  <td key={index} className="table__body-cell FilesTable-upload-filter-cell">
                    {/* eslint-disable-next-line react/jsx-handler-names */}
                    <UploadFilterDropdown filter={filter} onItemClick={filter.setSingleSelectedValue} />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="space-m-l-6">
          <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.upload_files_caption')}</div>
          {store.filenameError && (
            <>
              <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.upload_filename_validation_error')}.</div>
              <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">
                {t('common.asset_must_follow_rules')}:
                <ul className="space-m-b-1">
                  <li className="space-m-l-2">
                    - {t('common.filename_guideline')} ({filenameConvention})
                  </li>
                  <li className="space-m-l-2">- {t('common.special-char-not_allowed')}</li>
                  <li className="space-m-l-2">- {t('common.max-filename-char-length')}</li>
                  <li className="space-m-l-2">- {t('common.os_words_forbidden')}</li>
                  <li className="space-m-l-2">- {t('common.filename_end_char')}</li>
                </ul>
              </div>
            </>
          )}
          <div className="space-m-t-4 space-m-l-3">
            {!store.isLoading && (
              <Button
                buttonType={ButtonType.Primary}
                size={ButtonSize.Default}
                disabled={!store.allMandatoryFiltersSelected || store.filenameError || !store.allAssetsChecksumCalculated}
                icon="icon-upload"
                iconColor="#fff"
                className="FilesTable-upload-btn"
                onClick={() => {
                  if (!store.allMandatoryFiltersSelected || store.filenameError || !store.allAssetsChecksumCalculated) {
                    return;
                  }

                  DialogStore.removeLastDialog();
                  store.beginUploadingFiles();
                }}
              >
                {t('common.upload')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  },
);

export default FilesTable;
