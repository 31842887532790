import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { IEventInfo, Brand } from '@cdam/shared';
import { Link } from 'react-router-dom';
import { useWindowWidth } from 'hooks/useWindowsWidth';
import Icon from 'components/icons/Icon';

import './SearchEventSelector.scss';

interface ISearchEventSelectorProps {
  brand: Brand;
  events: Array<IEventInfo>;
  selectedEventId: string | undefined;
}

function getNumberOfItemsPerPage(width: number): number {
  if (width >= 1024) {
    return 6;
  } else if (width < 1024 && width >= 786) {
    return 4;
  } else if (width < 786 && width >= 480) {
    return 3;
  }

  return 2;
}

const SearchEventSelector: React.FC<ISearchEventSelectorProps> = observer(({ brand, events, selectedEventId }) => {
  const width = useWindowWidth();
  const today = new Date();

  const numberPerPages = getNumberOfItemsPerPage(width);

  const [currentPage, setCurrentPage] = useState(1);

  const numberOfPages = Math.ceil(events.length / numberPerPages);
  const begin = (currentPage - 1) * numberPerPages;
  const end = begin + numberPerPages;

  const paginatedEvents = events.slice(begin, end);

  return (
    <div className="SearchEventSelector space-m-b-6">
      {events.length > numberPerPages && (
        <Icon
          icon="icon-arrow-breadcrumb-left"
          className={`SearchEventSelector-left-arrow-button ${currentPage > 1 ? 'visible' : ''}`}
          height={20}
          width={20}
          data-testid="search-view-matrix-selector-left"
          onClick={() => {
            setCurrentPage((prevState) => prevState - 1);
          }}
        />
      )}
      <ul className="tabbar">
        {paginatedEvents
          .filter((event) => event.releaseDate && new Date(event.releaseDate) < today)
          .map((event: IEventInfo) => (
            <li
              key={event.id}
              className={`tabbar__item ${selectedEventId === event.id ? 'active' : ''}`}
              data-testid="search-view-matrix-selector-matrix"
            >
              <Link className="tabbar__item__link SearchEventSelector-item" to={`/${brand}/${event.id}`} key={event.id}>
                <p className={`SearchEventSelector-item-text ${selectedEventId === event.id ? 'active' : ''}`}>
                  {event.name} {event.season}
                </p>
              </Link>
            </li>
          ))}
      </ul>
      {events.length > numberPerPages && (
        <Icon
          icon="icon-arrow-breadcrumb-right"
          className={`SearchEventSelector-right-arrow-button ${currentPage < numberOfPages ? 'visible' : ''}`}
          height={22}
          width={22}
          data-testid="search-view-matrix-selector-right"
          onClick={() => {
            setCurrentPage((prevState) => prevState + 1);
          }}
        />
      )}
    </div>
  );
});

export default SearchEventSelector;
