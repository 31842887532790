import { HandledError, ErrorMessage, ILogSeverity, LogLevelEnum, EventTypeEnum, HttpStatusCode } from '@cdam/shared';
import AuthStore from '../stores/auth/AuthStore';
import DialogStore from '../stores/dialog/DialogStore';
import ViewStore from '../stores/view/ViewStore';
import LoggingService from './LoggingService';

export enum UrlType {
  Backend,
  Metadata,
  MetadataAkamai,
  Repository,
  Raw,
}

export interface IClientHttpServiceOptions {
  urlType: UrlType;
  url: string;
  assetDomainListHeader?: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'HEAD';
  disableAuth?: boolean;
  headers?: Headers;
  signal?: AbortSignal;
  body?: unknown | string;
}

class ClientHttpService {
  private _isLogoutDialogShown = false;

  public get isLogoutDialogShown(): boolean {
    return this._isLogoutDialogShown;
  }

  public async fetch<T>(options: IClientHttpServiceOptions): Promise<T> {
    const response = await this.execFetch(options);

    if (!response.ok) {
      const error = await this.getError(options, response);

      throw error;
    }

    const contentType = response.headers.get('content-type');
    let json = null;

    if (
      !['HEAD', 'OPTIONS'].includes(options.method) &&
      contentType &&
      (contentType.startsWith('application/json') || contentType.startsWith('application/hal+json'))
    ) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        json = await response.json();
      } catch (err) {
        const error = new HandledError(
          ErrorMessage.ApiError,
          response.status,
          err,
          (options as unknown) as Record<string, unknown>,
          { status_code: response.status },
          3,
        );

        LoggingService.log(
          {
            message: 'Could not await json from response with content-type "application/json"',
            level: LogLevelEnum.WARN,
            event: EventTypeEnum.APP_ERROR,
          },
          error,
        );

        throw err;
      }
    }

    return json as T;
  }

  public async fetchBlob(options: IClientHttpServiceOptions): Promise<Blob> {
    const response = await this.execFetch(options);

    if (!response.ok) {
      throw await this.getError(options, response);
    }

    try {
      return await response.blob();
    } catch (error) {
      throw new HandledError(ErrorMessage.NetworkError, undefined, error, (options as unknown) as Record<string, unknown>, undefined, 0);
    }
  }

  public getAbsoluteUrl(urlType: UrlType, url: string): string {
    let prefix;

    switch (urlType) {
      case UrlType.Metadata:
        prefix = ViewStore.metadataUrl;
        break;
      case UrlType.MetadataAkamai:
        prefix = ViewStore.metadataAkamaiUrl;
        break;
      case UrlType.Repository:
        prefix = ViewStore.repositoryUrl;
        break;
      case UrlType.Raw:
        prefix = '';
        break;
      case UrlType.Backend:
      default:
        prefix = '/api/v1';
    }

    return `${prefix}${url}`;
  }

  // eslint-disable-next-line complexity
  private async execFetch(options: IClientHttpServiceOptions): Promise<Response> {
    const init: RequestInit = {
      method: options.method || 'GET',
      headers: options.headers ?? new Headers(),
      signal: options.signal,
    };

    const headers = init.headers as Headers;

    // Disable caching because of IE11
    headers.append('Cache-Control', 'no-cache');
    headers.append('Pragma', 'no-cache');
    headers.append('Expires', '-1');

    // ADDED REPOSITORY
    if (options.urlType === UrlType.Metadata) {
      headers.append('assetDomainList', ViewStore.metadataAssetDomainList);
    }

    if (!options.disableAuth && !headers.has('Authorization') && AuthStore.token) {
      headers.append('Authorization', `Bearer ${AuthStore.token}`);
    }

    if (options.body) {
      if (typeof options.body === 'string') {
        headers.append('Content-Type', 'application/x-www-form-urlencoded');
        init.body = options.body;
      } else if (options.body instanceof FormData) {
        init.body = options.body;
      } else {
        headers.append('Content-Type', 'application/json');
        init.body = JSON.stringify(options.body);
      }
    }

    try {
      return await fetch(this.getAbsoluteUrl(options.urlType, options.url), init);
    } catch (error) {
      throw new HandledError(ErrorMessage.NetworkError, undefined, error, (options as unknown) as Record<string, unknown>, undefined, 0);
    }
  }

  // eslint-disable-next-line complexity
  private async getError(options: IClientHttpServiceOptions, response: Response): Promise<HandledError> {
    let body: unknown | undefined;

    const contentType = response.headers.get('content-type');

    if (contentType?.startsWith('application/json') || contentType?.startsWith('application/hal+json')) {
      try {
        body = await response.json();
      } catch (e) {}
    } else if (contentType?.startsWith('text/html')) {
      try {
        body = await response.text();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
    }

    const authErrorHeaders = response.headers.get('WWW-Authenticate');

    let message = ErrorMessage.UnhandledError;
    let severity: ILogSeverity = 7;

    if (Object.keys(response).length === 0 && !response.status) {
      message = ErrorMessage.NetworkError;
    } else if (response.status === HttpStatusCode.Unauthorized || authErrorHeaders) {
      this.logout();
      message = ErrorMessage.Unauthorized;
    } else if (response.status === HttpStatusCode.Forbidden) {
      message = ErrorMessage.Forbidden;
    } else if (response.status === HttpStatusCode.NotFound) {
      message = ErrorMessage.NotFound;
      severity = 5;
    } else if (response.status === HttpStatusCode.UnprocessableEntity) {
      message = ErrorMessage.UnprocessableEntry;
      severity = 2;
    } else if (response.status === HttpStatusCode.InternalServerError) {
      severity = 1;
      message = options.urlType === UrlType.Backend ? ErrorMessage.BackendError : ErrorMessage.ApiError;
    } else if (response.status === HttpStatusCode.BadGateway) {
      severity = 2;
      message = options.urlType === UrlType.Backend ? ErrorMessage.BackendError : ErrorMessage.BadGateway;
    } else if (response.status === HttpStatusCode.ServiceUnavailable) {
      severity = 1;
      message = options.urlType === UrlType.Backend ? ErrorMessage.BackendError : ErrorMessage.ServiceUnavailable;
    } else if (response.status === HttpStatusCode.GatewayTimeout) {
      message = options.urlType === UrlType.Backend ? ErrorMessage.BackendError : ErrorMessage.ServerTimeout;
      severity = 3;
    }

    try {
      return new HandledError(
        message,
        response.status,
        body,
        (options as unknown) as Record<string, unknown>,
        { status_code: response.status, body },
        severity,
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      throw e;
    }
  }

  private logout(): void {
    if (this._isLogoutDialogShown) {
      return;
    }

    this._isLogoutDialogShown = true;

    DialogStore.alert('Your sessions has expired. The page will be reloaded.', () => {
      AuthStore.logout(true);
      this._isLogoutDialogShown = false;
    });
  }
}

// singletone
export default new ClientHttpService();
