/* eslint-disable no-unmodified-loop-condition */
import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { AssetDomainsNamesEnums, DisplayAssetType, ITransformationArguments } from '@cdam/shared';
import { AssetData } from 'stores/assets/data/AssetData';
import { Loader } from 'components/loader/Loader';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import DownloadUtils from 'utils/DownloadUtils';
import { getDisplayType, dimensions, showConfidential } from 'utils/AssetDisplayUtils';
import { readFileExtension } from 'utils/FileFormatParser';
import DialogStore from 'stores/dialog/DialogStore';
import AssetUtils from 'utils/AssetUtils';
import ViewStore from '../../../../stores/view/ViewStore';
import AssetsDetailsFullScreen from './AssetsDetailsFullScreen';
import PreviewImage from './PreviewImage';

import './AssetsMultiplePreview.scss';

interface IAssetsMultiplePreview {
  item: AssetData | null;
}

const AssetsMultiplePreview: React.FC<IAssetsMultiplePreview> = observer(
  ({ item }: IAssetsMultiplePreview): JSX.Element => {
    const [failedToLoad, setFailedToLoad] = useState(false);
    const [loading, setLoading] = useState(true);
    const currentAbortController: React.MutableRefObject<null | AbortController> = useRef(null);
    const [images, setImage] = useState<Array<string>>([]);
    const previewFormat = 'jpg';

    const fileExtension = item?.activeDigitalContent?.storageURL && readFileExtension(item.activeDigitalContent.storageURL);

    const displayType = (fileExtension && getDisplayType(fileExtension)) || '';

    const showConfidentialWatermark = item && showConfidential(item);
    const expired = item && AssetUtils.getExpired(item);

    const layoutSelect = (): string => {
      if (displayType === DisplayAssetType.Grid) {
        return 'Item';
      } else if (displayType === DisplayAssetType.ScrollList) {
        return 'Slide';
      } else if (displayType === DisplayAssetType.InlineList) {
        return 'Page';
      }

      return '';
    };

    const layoutType = layoutSelect();

    const closeFullscreen = (): void => {
      DialogStore.removeLastDialog();
    };

    const openFullScreen = (url: string): void => {
      DialogStore.addDialog(<AssetsDetailsFullScreen url={url} onClose={() => closeFullscreen()} />);
    };

    // isZipTurntable - if assetCategory is `ZIP - Turntable`, then append `zipasais=true` to the query
    const getTransformationData = (isZipTurntable: boolean): ITransformationArguments => {
      const arg: ITransformationArguments = {
        format: previewFormat,
        frameIndex: 1,
        operationType: 'Sync',
        height: dimensions(layoutType).height * 2,
        width: dimensions(layoutType).width * 2,
      };

      if (isZipTurntable) {
        arg.zipasis = true;
      }

      return arg;
    };

    useEffect(() => {
      const getBlob = async (item: AssetData | null): Promise<void> => {
        if (failedToLoad) {
          return;
        }

        const urls: Array<string> = [];

        try {
          const isZip =
            ViewStore.selectedAssetDomain === AssetDomainsNamesEnums.MARVIN_TAXONOMY_NAME &&
            !!item &&
            item?.hasAttribute('Asset Information', 'assetCategory', ViewStore.zipTurntableId);
          const transformationArguments = getTransformationData(isZip);

          do {
            const formatUrlParams = DownloadUtils.transformationArgsToQuery(transformationArguments);
            const metadatId = item?.asset?.id;

            if (!metadatId) {
              setFailedToLoad(true);

              return;
            }

            const url = `/metadata/${metadatId}/activeDigitalContent${formatUrlParams}`;
            const urlType = UrlType.Metadata;

            currentAbortController.current = new AbortController();
            // eslint-disable-next-line no-await-in-loop
            const blob = await ClientHttpService.fetchBlob({ urlType, url, method: 'GET', signal: currentAbortController.current.signal });
            const objectUrl = URL.createObjectURL(blob);

            transformationArguments.frameIndex && transformationArguments.frameIndex++;

            urls.push(objectUrl);

            if (currentAbortController.current) {
              currentAbortController.current.abort();
            }

            // eslint-disable-next-line no-unmodified-loop-condition
            setImage([...images, ...urls]);
          } while (!failedToLoad);
        } catch (e) {
          setFailedToLoad(true);
          setLoading(false);
        }
      };

      (async () => {
        getBlob(item);
      })();

      // eslint-disable-next-line consistent-return
      return () => {
        if (currentAbortController.current) {
          currentAbortController.current.abort();
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const getArguments = (frameIndex: number): ITransformationArguments => {
      const args: ITransformationArguments = { frameIndex, format: previewFormat };

      if (showConfidentialWatermark) {
        args.watermark = true;
        args.watermarkName = 'Confidential';
      } else if (expired === 'Yes') {
        args.watermark = true;
        args.watermarkName = 'Expired';
      }

      return args;
    };

    return (
      <div className="AssetsMultiplePreview">
        <div className={`AssetsMultiplePreview-${layoutSelect()}`}>
          {images.length !== 0 &&
            images.map(
              (element, index) =>
                element !== '' && (
                  <div key={index} className={`AssetsMultiplePreview-${layoutType}-item space-p-2`}>
                    <div className={`AssetsMultiplePreview-${layoutType}-content`}>
                      <PreviewImage
                        imageBlob={element}
                        fileName={`${item?.activeDigitalContent?.fileName ?? 'content'}-page-${index}.${previewFormat}`}
                        imageExtension={previewFormat}
                        metadataId={item?.asset?.id ?? undefined}
                        openFullPreview={openFullScreen}
                        transformationArgs={getArguments(index)}
                        height={dimensions(layoutType).height}
                        width={dimensions(layoutType).width}
                        className={`AssetsMultiplePreview-${layoutType}-img`}
                      />
                      <div>
                        {displayType} {index} of {images.length - 1}
                      </div>
                    </div>
                  </div>
                ),
            )}
          {loading && (
            <div className={`AssetsMultiplePreview-${layoutType}-item space-p-2`}>
              <div className={`AssetsMultiplePreview-${layoutType}-content`}>
                <div
                  className="AssetsMultiplePreview-placeholder"
                  style={{ height: dimensions(layoutType).height, width: dimensions(layoutType).width }}
                >
                  <Loader />
                </div>
              </div>
            </div>
          )}
          {failedToLoad && images.length === 1 && (
            <PreviewImage
              failedLoad={true}
              height={dimensions(layoutType).height}
              width={dimensions(layoutType).width}
              className={`AssetsMultiplePreview-${layoutType}-img`}
            />
          )}
        </div>
      </div>
    );
  },
);

export default AssetsMultiplePreview;
