/* eslint-disable @typescript-eslint/unbound-method */
import React, { useEffect, useState } from 'react';
import DialogStore from 'stores/dialog/DialogStore';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Icon from 'components/icons/Icon';
import { IMatrixAxis, MatrixItemType } from '@cdam/shared';
import Button, { ButtonType, ButtonSize } from '../../../components/input/Button';
import MatrixSettingsStore, { Axis, MatrixSettingsMode, MatrixEditType } from '../../../stores/matrix/MatrixSettingsStore';
import MatrixSettingsGroup from './components/MatrixSettingsGroup';
import MatrixSettingsDimension from './components/MatrixSettingsDimension';
import MatrixItemDeleteDialog from './MatrixItemDeleteDialog';
import MatrixSettingsPermission from './components/MatrixSettingsPermission';
import MatrixSettingsUpload from './components/MatrixSettingsUpload';
import MatrixSettingsOwners from './components/MatrixSettingsOwners';

import './MatrixSettingsDialog.scss';

interface IConfirmDialogProps {
  axis: Axis;
  store: MatrixSettingsStore;
  item?: IMatrixAxis;
  onSave: React.MouseEventHandler<HTMLElement>;
  onCancel: React.MouseEventHandler<HTMLElement>;
}

enum TabItem {
  Edit,
  Permission,
  Upload,
  Owners,
}

// eslint-disable-next-line complexity
const MatrixSettingsDialog = observer(({ axis, store, item, onCancel, onSave }: IConfirmDialogProps) => {
  const { t } = useTranslation();

  const [mode] = useState(() => {
    if (item) {
      return MatrixSettingsMode.Edit;
    }

    return MatrixSettingsMode.Create;
  });

  const [tabItem, setTabItem] = useState(TabItem.Edit);

  useEffect(() => {
    if (mode === MatrixSettingsMode.Edit) {
      store.loadCurrentEditingItem(axis, item);
    } else {
      store.loadCurrentEditingItem(axis);
    }
  }, [axis, item, mode, store]);

  function onSaveClickHandler(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (mode === MatrixSettingsMode.Edit) {
      store.AddOrUpdateCurrentEditingItem();
    } else {
      store.AddOrUpdateCurrentEditingItem(axis);
    }

    if (store.editingItemNameError !== '') {
      return;
    }

    onSave(e);
  }

  function onDeleteClickHandler(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    if (store.currentItem && store.currentItem.type === MatrixItemType.Group) {
      DialogStore.addDialog(
        <MatrixItemDeleteDialog
          translationKey="common.delete_group_field"
          deleteBtnTranslationKey="common.delete_all"
          onCancel={() => DialogStore.removeLastDialog()}
          onConfirm={() => {
            store.deleteGroupItem();
            DialogStore.removeLastDialog();
            onCancel(e);
          }}
        />,
      );

      return;
    }

    DialogStore.addDialog(
      <MatrixItemDeleteDialog
        translationKey="common.delete_dimension"
        deleteBtnTranslationKey="common.delete"
        onCancel={() => DialogStore.removeLastDialog()}
        onConfirm={() => {
          store.deleteCurrentEditingItem();
          DialogStore.removeLastDialog();
          onCancel(e);
        }}
      />,
    );
  }

  function getHeaderText(): string {
    if (mode === MatrixSettingsMode.Edit) {
      if (item && item.type === MatrixItemType.Group) {
        return t('common.edit_group');
      }

      return t('common.edit_dimension');
    }

    if (axis === Axis.xAxis) {
      return t('common.add_dimension');
    }

    return t('common.add');
  }

  return (
    <div className="modal MatrixSettingsDialog">
      <div className="modal__header">
        <span className="modal__close" onClick={onCancel}>
          <button className="btn enabled btn-icon btn-default">
            <Icon icon="icon-close" />
          </button>
        </span>
      </div>
      <div className="modal__feature">
        <div className="MatrixSettingsDialog-mode space-m-b-4">{getHeaderText()}</div>
        <ul className="tabbar space-m-b-3">
          <li className={`tabbar__item ${tabItem === TabItem.Edit ? 'active' : ''}`} onClick={() => setTabItem(TabItem.Edit)}>
            <p className="tabbar__item__link">{t('common.edit')}</p>
          </li>
          <li className={`tabbar__item ${tabItem === TabItem.Permission ? 'active' : ''}`} onClick={() => setTabItem(TabItem.Permission)}>
            <p className="tabbar__item__link">{t('common.permission')}</p>
          </li>
          {store.editType === MatrixEditType.Dimension && (
            <li className={`tabbar__item ${tabItem === TabItem.Upload ? 'active' : ''}`} onClick={() => setTabItem(TabItem.Upload)}>
              <p className="tabbar__item__link">{t('common.upload')}</p>
            </li>
          )}
          {store.editType === MatrixEditType.Dimension && (
            <li className={`tabbar__item ${tabItem === TabItem.Owners ? 'active' : ''}`} onClick={() => setTabItem(TabItem.Owners)}>
              <p className="tabbar__item__link">{t('common.owners')}</p>
            </li>
          )}
        </ul>
        {tabItem === TabItem.Edit && (
          <div className="MatrixSettingsDialog-container-items MatrixSettingsDialog-container-items__tab MatrixSettingsDialog-scroll">
            {store.editType === MatrixEditType.Group && <MatrixSettingsGroup store={store} />}
            {store.editType === MatrixEditType.Dimension && <MatrixSettingsDimension store={store} axis={axis} />}
          </div>
        )}
        {tabItem === TabItem.Permission && (
          <div className="MatrixSettingsDialog-container-items MatrixSettingsDialog-container-items__tab">
            <MatrixSettingsPermission store={store} />
          </div>
        )}
        {tabItem === TabItem.Upload && (
          <div className="MatrixSettingsDialog-container-items MatrixSettingsDialog-container-items__tab MatrixSettingsDialog-scroll">
            <MatrixSettingsUpload store={store} />
          </div>
        )}
        {tabItem === TabItem.Owners && (
          <div className="MatrixSettingsDialog-container-items MatrixSettingsDialog-container-items__tab">
            <MatrixSettingsOwners
              addItem={store.addToEditingItemOwners}
              removeItem={store.removeEditingItemOwners}
              itemList={store.editingItemOwners}
              getAllItems={store.getAllMatrixSettingsOwners}
            />
          </div>
        )}
      </div>
      <div className="modal__footer MatrixSettingsDialog-footer">
        {mode === MatrixSettingsMode.Edit && (
          <Button
            className="MatrixSettingsDialog-delete-button"
            buttonType={ButtonType.Secondary}
            size={ButtonSize.Default}
            onClick={onDeleteClickHandler}
          >
            {t('common.delete')}
          </Button>
        )}
        <Button
          className="MatrixSettingsDialog-save-button"
          buttonType={ButtonType.Primary}
          size={ButtonSize.Default}
          disabled={!!(store.invalidEditingItemFilters.length || store.invalidEditingUploadFilters.length)}
          title={
            store.invalidEditingItemFilters.length || store.invalidEditingUploadFilters.length ? t('common.duplicate_filter_error') : t('common.save')
          }
          onClick={(e) => {
            if (store.invalidEditingItemFilters.length || store.invalidEditingUploadFilters.length) {
              return;
            }

            onSaveClickHandler(e);
          }}
        >
          {t('common.save')}
        </Button>
      </div>
    </div>
  );
});

export default MatrixSettingsDialog;
