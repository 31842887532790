import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { fileNameIsInvalid } from '@cdam/shared';
import FileUtils from 'utils/FileUtils';
import Button, { ButtonSize, ButtonType } from '../../../components/input/Button';

import './ExportDialog.scss';

interface IExportDialog {
  onConfirm(fileName: string): void;
  onCancel: React.MouseEventHandler<HTMLButtonElement>;
}

export const ExportDialog = observer(({ onCancel, onConfirm }: IExportDialog) => {
  const { t } = useTranslation();
  const currentDateAsString = FileUtils.getDateTimeAsString();

  const [fileName, setFileName] = useState<{ value: string; error: boolean }>({ value: currentDateAsString, error: false });

  const handleChange = (value: string): void => {
    const error = fileNameIsInvalid(value);

    setFileName({ value, error });
  };

  return (
    <div className="ExportDialog modal">
      <div className="ExportDialog-title">{t('common.export_file_name').toUpperCase()}</div>
      <div className="space-m-h-2 space-m-v-4">
        <div className="dropdown__label ExportDialog-label">{t('common.enter_file_name')}</div>
        <div className="ExportDialog-input">
          <input
            className="input ie-font-fix"
            type="text"
            placeholder={currentDateAsString}
            value={fileName.value}
            onChange={(e) => {
              handleChange(e.target.value);
            }}
          />
          <div className="space-m-l-2">.xlsx</div>
        </div>
        <div className={`dropdown__label ExportDialog-error ${fileName.error && 'ExportDialog-error-visible'}`}>
          {t('advancedDownload.invalid_file_name')}
        </div>
      </div>
      <Button
        className="ExportDialog-button ExportDialog-button-cancel space-m-r-2"
        buttonType={ButtonType.Secondary}
        size={ButtonSize.Default}
        onClick={onCancel}
      >
        {t('common.cancel')}
      </Button>
      <Button
        className="ExportDialog-button space-m-l-2"
        buttonType={ButtonType.Primary}
        size={ButtonSize.Default}
        disabled={fileName.error}
        onClick={() => !fileName.error && onConfirm(fileName.value)}
      >
        {t('common.download')}
      </Button>
    </div>
  );
});
