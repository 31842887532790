import { ErrorMessage, EventTypeEnum, HandledError, IMetadata, IMetadataDigitalContent, LogLevelEnum } from '@cdam/shared';
import ClientHttpService, { IClientHttpServiceOptions, UrlType } from '../services/ClientHttpService';
import LoggingService from '../services/LoggingService';
import { AssetData } from '../stores/assets/data/AssetData';
import ViewStore from '../stores/view/ViewStore';
import AssetUtils from './AssetUtils';

export interface IMetadataDeleteResult {
  message: string;
  asset: AssetData;
}

// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export default class MetadataUtils {
  public static async bulkDeleteAssets(assets: Array<AssetData>): Promise<Array<IMetadataDeleteResult>> {
    const failedDeletions: Array<IMetadataDeleteResult> = [];

    for (const asset of assets) {
      try {
        // eslint-disable-next-line no-await-in-loop
        await this.deleteAsset(asset.asset.id);
      } catch (error) {
        failedDeletions.push({ message: `${AssetUtils.getAssetName(asset)} (reason: ${(error as Error).message})`, asset });
      }
    }

    return failedDeletions;
  }

  public static async deleteAsset(assetId: string): Promise<void> {
    const digitalContentList = await this.getMetadataContent(assetId);

    await this.deleteMetadata(assetId);

    try {
      await Promise.all(
        digitalContentList.map(async (digitalContent) => {
          const { storageURL } = digitalContent;

          const url = storageURL.startsWith('http') ? storageURL : `${ViewStore.repositoryUrl}${storageURL}`;
          const options: IClientHttpServiceOptions = {
            url,
            urlType: UrlType.Raw,
            method: 'DELETE',
          };

          try {
            await ClientHttpService.fetch(options);
          } catch (e) {
            const error = e as HandledError;

            error.message = `Failed to delete digital asset for metadata id ${digitalContent.storageId}`;

            LoggingService.log({ message: ErrorMessage.ApiError, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_DELETE }, error);

            throw error;
          }
        }),
      );
    } catch (e) {
      const error = e as HandledError;

      error.message = `Failed to delete digital assets for metadata id ${assetId}`;

      LoggingService.log({ message: ErrorMessage.ApiError, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_DELETE }, error);

      throw error;
    }
  }

  private static async deleteMetadata(assetId: string): Promise<void> {
    try {
      await ClientHttpService.fetch({
        url: `/metadata/${assetId || ''}`,
        method: 'DELETE',
        urlType: UrlType.Metadata,
      });
    } catch (e) {
      const error = e as HandledError;

      error.message = `Failed to delete metadata item with id ${assetId}`;

      LoggingService.log({ message: ErrorMessage.ApiError, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_DELETE }, error);

      throw error;
    }
  }

  private static async getMetadataContent(assetId: string): Promise<Array<IMetadataDigitalContent>> {
    try {
      const { digitalContentList } = await ClientHttpService.fetch<IMetadata>({
        url: `/metadata/${assetId}`,
        urlType: UrlType.Metadata,
        method: 'GET',
      });

      return digitalContentList;
    } catch (e) {
      const error = e as HandledError;

      error.message = `Failed to retrieve metadata for item with id ${assetId}`;

      LoggingService.log({ message: ErrorMessage.ApiError, level: LogLevelEnum.ERROR, event: EventTypeEnum.METADATA_DELETE }, error);

      throw error;
    }
  }
}
