import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { UIAssetDomainsNamesEnums } from '@cdam/shared';
import ViewStore from '../../../stores/view/ViewStore';
import { ReactComponent as MarketingContentIcon } from '../../../assets/icon/marketing-content.svg';
import SearchStore from '../../../stores/assets/SearchStore';
import { Loader } from '../../../components/loader/Loader';

import './SearchFilter.scss';

interface ISearchFilterProps {
  store: SearchStore;
}

const ArchiveSearchFilter = observer(
  ({ store }: ISearchFilterProps): JSX.Element => {
    const { t } = useTranslation();

    const handleClick = async (uiAssetDomain: UIAssetDomainsNamesEnums): Promise<void> => {
      await ViewStore.setSelectedUiAssetDomain(uiAssetDomain);
      store.browseByContentType(undefined);
    };

    if (ViewStore.isLoading) {
      return <Loader large />;
    }

    return (
      <div className="SearchFilter" data-testid="search-view-browse-by-content">
        <h4 className="SearchFilter-heading space-m-t-6">{t('archive.browse_by_domain')}</h4>
        <div className="SearchFilter-conatiner space-m-t-6">
          {ViewStore.usersUiDomainsForSelectedAppVariant.map((uiAssetDomain) => (
            <div key={uiAssetDomain} className="SearchFilter-item box-shadow-2 space-m-r-4">
              <div className="SearchFilter-icon">
                <MarketingContentIcon data-testid="search-view-dabox" onClick={async () => handleClick(uiAssetDomain)} />
              </div>
              <p className="SearchFilter-description">{ViewStore.getUiAssetDomainName(uiAssetDomain)}</p>
            </div>
          ))}
        </div>
      </div>
    );
  },
);

export default ArchiveSearchFilter;
