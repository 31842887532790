import { action, observable, runInAction, makeObservable } from 'mobx';
import { IMetadataSearch, IMetadataSearchRoot, IMetadataSearchValue } from '@cdam/shared';
import ClientHttpService, { UrlType } from '../../services/ClientHttpService';

export default class ArticleNumbersSearchStore {
  @observable public error: Error | null = null;
  @observable public isLoading = false;
  @observable public validArticleNumbers: Array<string> = [];
  @observable public invalidArticleNumbers: Array<string> = [];

  private abortController: AbortController | null = null;
  private loadingIndex = 0;

  public constructor() {
    makeObservable(this);
  }

  @action
  public async validateArticleNumbers(searchText: string): Promise<void> {
    if (!searchText) {
      this.error = null;
      this.isLoading = false;
      this.validArticleNumbers = [];
      this.invalidArticleNumbers = [];

      return;
    }

    const currentLoadingIndex = ++this.loadingIndex;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.error = null;
    this.isLoading = true;
    this.validArticleNumbers = [];
    this.invalidArticleNumbers = [];

    const searchArticleNumbers: Array<string> = searchText.split(/[\s,]+/).filter((value) => value.length > 0);
    const existingArticleNumbers: Array<string> = [];
    const nonExistingArticleNumbers: Array<string> = [];

    if (searchArticleNumbers) {
      try {
        this.abortController = new AbortController();

        if (currentLoadingIndex !== this.loadingIndex) {
          return;
        }

        const searchResult = await this.searchArticles(searchArticleNumbers);
        const searchedArticleNumbers = this.getSearchedValueList(searchResult);

        for (const searchedArticleNumber of searchedArticleNumbers) {
          if (searchedArticleNumber.hits && searchedArticleNumber.hits > 0) {
            existingArticleNumbers.push(searchedArticleNumber.value);
          } else {
            nonExistingArticleNumbers.push(searchedArticleNumber.value);
          }
        }

        runInAction(() => {
          this.isLoading = false;
          this.validArticleNumbers = existingArticleNumbers;
          this.invalidArticleNumbers = nonExistingArticleNumbers;
        });
      } catch (e) {
        if (currentLoadingIndex !== this.loadingIndex) {
          return;
        }

        runInAction(() => {
          this.error = new Error('Failed to validate Article Number(s)');
          this.isLoading = false;
          this.validArticleNumbers = [];
          this.invalidArticleNumbers = [];
        });
      }
    }
  }

  private async searchArticles(articleNumbers: Array<string>): Promise<IMetadataSearchRoot> {
    const searchBody: IMetadataSearch = {
      searchFacet: {
        searchAttributeList: [
          {
            group: 'Asset Information',
            name: 'articleNumber',
            valueList: articleNumbers.map((value) => ({
              value,
            })),
          },
        ],
      },
    };

    return ClientHttpService.fetch<IMetadataSearchRoot>({
      urlType: UrlType.Metadata,
      url: '/metadata/search',
      method: 'POST',
      body: searchBody,
      signal: this.abortController?.signal,
    });
  }

  private getSearchedValueList(searchResult: IMetadataSearchRoot): Array<IMetadataSearchValue> {
    if (searchResult._embedded.metadataSearchResourceList) {
      const searchAttributeList = searchResult._embedded.metadataSearchResourceList[0].searchFacet.searchAttributeList.filter(
        (attr) => attr.name === 'articleNumber',
      );

      if (searchAttributeList?.[0].valueList) {
        const valueList = searchAttributeList[0].valueList;

        if (valueList?.length && valueList.length >= 3) {
          valueList.pop();
        }

        return valueList;
      }
    }

    return [];
  }
}
