import React, { useState, useEffect, useRef } from 'react';
import { Loader } from 'components/loader/Loader';
import Icon from 'components/icons/Icon';
import ClientHttpService, { UrlType, IClientHttpServiceOptions } from 'services/ClientHttpService';
import { IMetadata, HandledError, LogLevelEnum, EventTypeEnum } from '@cdam/shared';
import { AssetData } from 'stores/assets/data/AssetData';
import DialogStore from 'stores/dialog/DialogStore';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import DownloadUtils from 'utils/DownloadUtils';
import NavigationService from 'services/NavigationService';
import { showConfidential } from 'utils/AssetDisplayUtils';
import AssetUtils from 'utils/AssetUtils';
import LoggingService from '../../services/LoggingService';

import './DownloadAssetDialog.scss';

interface IDownloadAssetDialog {
  metadataId?: string;
}

const DownloadAssetDialog = ({ metadataId }: IDownloadAssetDialog): JSX.Element => {
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(true);
  const [failedToLoad, setFailedToLoad] = useState(false);
  const [assetName, setAssetName] = useState('');
  const [errorValue, setErrorValue] = useState('');
  const currentAbortController: React.MutableRefObject<null | AbortController> = useRef(null);

  let downloadUrl = `/metadata/${metadataId}/activeDigitalContent`;

  const setError = (e: number | undefined): void => {
    switch (e) {
      case 403:
        setErrorValue('error.permissions_denied');
        break;
      case 404:
        setErrorValue('error.asset_not_found');
        break;
      default:
        setErrorValue('error.default');
    }
  };

  useEffect(() => {
    const getItem = async (): Promise<void> => {
      setFailedToLoad(false);
      setLoading(true);

      try {
        const url = `/metadata/${metadataId}`;
        const urlType = UrlType.Metadata;

        currentAbortController.current = new AbortController();
        const options: IClientHttpServiceOptions = { urlType, url, method: 'GET', signal: currentAbortController.current.signal };

        const response = await ClientHttpService.fetch<IMetadata>(options);

        const item = new AssetData(response);
        const digitalContent = item.activeDigitalContent;
        const assetName = AssetUtils.getAssetName(item);

        if (!assetName || !digitalContent) {
          const error = new HandledError('Asset image not found', undefined, (options as unknown) as Record<string, unknown>);

          LoggingService.log({ message: 'Digital asset was not found', level: LogLevelEnum.WARN, event: EventTypeEnum.METADATA_GET }, error);

          throw error;
        }

        setAssetName(assetName);
        const showConfidentialWatermark = showConfidential(item);

        if (showConfidentialWatermark) {
          const formatUrlParams = DownloadUtils.transformationArgsToQuery({ watermark: true, watermarkName: 'Confidential' });

          // eslint-disable-next-line react-hooks/exhaustive-deps
          downloadUrl = `${downloadUrl}${formatUrlParams}`;
        }

        setLoading(false);
      } catch (e) {
        const error = e as HandledError;

        setError(error.code);
        setFailedToLoad(true);
        setLoading(false);
      }
    };

    getItem();

    // eslint-disable-next-line consistent-return
    return () => {
      if (currentAbortController.current) {
        currentAbortController.current.abort();
      }
    };
  }, [metadataId]);

  return (
    <div className="DownloadAssetDialog">
      <div className="DownloadAssetDialog-container space-p-h-4">
        <div className="DownloadAssetDialog-close space-p-1">
          <Icon
            icon="icon-close"
            color={'#000'}
            height={25}
            width={25}
            onClick={() => {
              NavigationService.goBack();
              DialogStore.removeLastDialog();
            }}
          />
        </div>
        {isLoading ? (
          <Loader />
        ) : !failedToLoad ? (
          <React.Fragment>
            <div className="space-m-b-3">
              <strong>{t('advancedDownload.download_ready').toLocaleUpperCase()}</strong>
            </div>
            <div className="DownloadAssetDialog-asset space-m-b-4">{`${t('common.file_name')} ${assetName}`}</div>
            <Button
              buttonType={ButtonType.Secondary}
              size={ButtonSize.Default}
              onClick={async () => DownloadUtils.handleItemDownload(null, downloadUrl, assetName)}
            >
              {t('common.download')}
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className="space-m-b-3">
              <strong>{t('error.download_failed').toLocaleUpperCase()}</strong>
            </div>
            <div className="space-m-b-4 DownloadAssetDialog-error">{t(errorValue)}</div>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default DownloadAssetDialog;
