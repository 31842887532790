import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';

import './ValidationDialog.scss';

interface IValidationDialog {
  icon: string;
  onConfirm?(): void;
  onCancel?(): void;
  duplicateFiles: Array<string>;
  tableLabel: string;
  titleTranslationKey: string;
  descriptionTranslationKey: string;
  actionTranslationKey: string;
}

const ValidationDialog = observer(
  ({ onCancel, onConfirm, actionTranslationKey, descriptionTranslationKey, duplicateFiles, tableLabel, titleTranslationKey }: IValidationDialog) => {
    const { t } = useTranslation();

    return (
      <div className="VerifyDuplicateDialog modal">
        <div className="space-m-b-4">
          <strong>{t(titleTranslationKey).toUpperCase()}</strong>
        </div>
        <div className="VerifyDuplicateDialog-text space-m-b-4">{t(descriptionTranslationKey)}</div>
        <div className="form-group disabled VerifyDuplicateDialog-duplicate-table">
          <label htmlFor="disabledMultiline" className="control__label VerifyDuplicateDialog-label">
            {t(tableLabel)}
          </label>
          <div className="form-control__container multiline">
            <textarea
              name="disabledMultiline"
              readOnly={true}
              id="disabledMultiline"
              className="form-control form-control-multiline VerifyDuplicateDialog-duplicate-area"
            >
              {duplicateFiles.join(', \n')}
            </textarea>
          </div>
        </div>
        <Button className="VerifyDuplicateDialog-button space-m-r-2" buttonType={ButtonType.Secondary} size={ButtonSize.Default} onClick={onCancel}>
          {t('common.cancel')}
        </Button>
        <Button
          className="VerifyDuplicateDialog-upload-button space-m-l-2"
          buttonType={ButtonType.Primary}
          size={ButtonSize.Default}
          onClick={onConfirm}
        >
          {t(actionTranslationKey)}
        </Button>
      </div>
    );
  },
);

export default ValidationDialog;
