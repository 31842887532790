import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from 'components/icons/Icon';
import MatrixSettingsStore from 'stores/matrix/MatrixSettingsStore';
import { useTranslation } from 'react-i18next';
import CheckboxItem from 'components/checkbox/CheckboxItem';
import TypeaheadSearchStore, { ITypeaheadSearchItem } from 'components/typeaheadSearch/store/TypeaheadSearchStore';
import { IGroup } from '@cdam/shared';
import TypeaheadSearch from 'components/typeaheadSearch/TypeaheadSearch';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';

import './MatrixSettingsPermission.scss';

interface IMatrixSettingsPermissionProps {
  store: MatrixSettingsStore;
}

type IGroupSearchable = ITypeaheadSearchItem & IGroup;

// NOTE: Will not work unless groups can be retrived
const MatrixSettingsPermission = observer(
  ({ store }: IMatrixSettingsPermissionProps): JSX.Element => {
    const { t } = useTranslation();

    const [searchStore] = useState(
      // eslint-disable-next-line @typescript-eslint/unbound-method
      () => new TypeaheadSearchStore<IGroupSearchable>(store.addPermissionToCurrentItem, store.deletePermissionOfCurrentItem),
    );

    const getAllItems = useCallback(async () => {
      const groups = await ClientHttpService.fetch<Array<IGroup>>({ urlType: UrlType.Backend, url: '/users/groups', method: 'GET' });

      return groups.reduce<Array<IGroupSearchable>>((acc, item) => {
        acc.push({ searchableText: item.groupName, ...item });

        return acc;
      }, []);
    }, []);

    return (
      <div className="MatrixSettingsPermission">
        <TypeaheadSearch<IGroupSearchable> store={searchStore} inputLabel={t('common.add_user_group')} getAllItems={getAllItems} />
        <div className="space-m-t-4">
          <div className="MatrixSettingsPermission-captions-container space-m-b-2">
            <div className="MatrixSettingsPermission-captions-left caption caption--lg">{t('common.groups')}</div>
            <div className="MatrixSettingsPermission-captions-right">
              <div className="caption caption--lg space-m-r-4">{t('common.download')}</div>
              <div className="caption caption--lg">{t('common.upload')}</div>
            </div>
          </div>
          <div className="MatrixSettingsPermission-groups-container">
            {store.editingItemPermissionGroups.map((group, index) => (
              <div key={index} className="MatrixSettingsPermission-group-item space-p-2">
                <div className="MatrixSettingsPermission-group-item-name-container">
                  <span className="MatrixSettingsPermission-group-item-name caption">{group.groupName}</span>
                </div>
                <div className="MatrixSettingsPermission-group-item-settings">
                  <CheckboxItem
                    checked={group.download}
                    onClick={() => {
                      store.changeGroupDownloadPermission(group);
                    }}
                  />
                  <CheckboxItem
                    checked={group.upload}
                    onClick={() => {
                      store.changeGroupUploadPermission(group);
                    }}
                  />
                  <Icon
                    icon="icon-delete"
                    className="MatrixSettingsPermission-delete-icon"
                    height={22}
                    width={22}
                    onClick={() => store.deletePermissionOfCurrentItem(index)}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  },
);

export default MatrixSettingsPermission;
