/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { AssetType, AssetContentType } from '@cdam/shared';
import Icon from 'components/icons/Icon';
import AssetUtils from 'utils/AssetUtils';
import DetailsStore from 'stores/assets/DetailsStore';
import { RouteComponentProps } from 'react-router';
import NavigationService from 'services/NavigationService';
import { Loader } from 'components/loader/Loader';
import { getAssetContentType } from 'utils/AssetDisplayUtils';
import PageErrors from 'components/coredam/PageErrors';
import { useTranslation } from 'react-i18next';
import UtilityStore from 'stores/utility/UtilityStore';
import AssetsCard from '../components/AssetsCard';
import AsideDetails from './components/AsideDetails';
import AssetPreview from './components/AssetPreview';

import './AssetsDetails.scss';

interface IAssetsDetails {
  metadataId?: string;
  type?: string;
}

// eslint-disable-next-line complexity
const AssetsDetails = observer(
  ({ match }: RouteComponentProps<IAssetsDetails>): JSX.Element => {
    const { t } = useTranslation();
    const { metadataId, type } = match.params;

    const [detailsStore] = useState(() => new DetailsStore());

    useEffect(() => {
      detailsStore.init(metadataId, (type && type === 'article' && AssetType.Article) || AssetType.Asset);
    }, [detailsStore, metadataId, type]);

    const digitalContent = detailsStore.displayItem?.item.activeDigitalContent ?? undefined;
    const contentType = detailsStore.displayItem ? getAssetContentType(detailsStore.displayItem.item) : AssetContentType.Unknown;

    return (
      <div className="AssetsDetails">
        {detailsStore.isLoading ? (
          <Loader />
        ) : !detailsStore.failedToLoad && detailsStore.assetType ? (
          <React.Fragment>
            <div
              className="AssetsDetails-top-bar space-p-h-4"
              style={{ backgroundColor: detailsStore.getHeadercolor(contentType) } /* stylelint-disable */}
            >
              <div className="AssetsDetails-title">
                <Icon
                  icon={'icon-arrow-breadcrumb-left'}
                  className={`AssetsDetails-navigate ${
                    detailsStore.displayItem &&
                    detailsStore.navigationItems &&
                    detailsStore.navigationItems.indexOf(detailsStore.metadataId) !== 0 &&
                    'AssetsDetails-navigate-show'
                  }`}
                  color="#000"
                  height={22}
                  width={22}
                  onClick={() => detailsStore.toggleDetailsItem('prev')}
                />
                <strong className="space-m-h-2">
                  {detailsStore.displayItem && detailsStore.assetType === AssetType.Article
                    ? AssetUtils.getArticleNumber(detailsStore.displayItem.item)
                    : detailsStore.getAssetName().toUpperCase()}
                </strong>
                <Icon
                  icon={'icon-arrow-breadcrumb-right'}
                  className={`AssetsDetails-navigate ${
                    detailsStore.displayItem &&
                    detailsStore.navigationItems &&
                    detailsStore.navigationItems.indexOf(detailsStore.metadataId) + 1 !== detailsStore.navigationItems.length &&
                    'AssetsDetails-navigate-show'
                  }`}
                  color="#000"
                  height={22}
                  width={22}
                  onClick={() => detailsStore.toggleDetailsItem('forward')}
                />
              </div>
              <div
                className="AssetsDetails-close"
                onClick={() => {
                  UtilityStore.clearNaivgationCache();
                  NavigationService.goBack();
                }}
              >
                <Icon icon={'icon-close'} color="#000" height={30} width={30} />
              </div>
            </div>
            <section className="AssetsDetails-main-section">
              <div className="AssetsDetails-content space-p-4">
                {detailsStore.displayItem && <AssetPreview store={detailsStore} />}
                {detailsStore.assetType === AssetType.Article && detailsStore.displayItem && detailsStore.displayItem.articleItems && (
                  <div className="space-m-t-4">
                    <div className="space-m-b-4">{t('details.article_assets')}</div>
                    <div className="AssetsDetails-related-assets">
                      {detailsStore.displayItem.articleItems.map((item) => (
                        <AssetsCard
                          key={item.asset.id}
                          hideDetails={true}
                          item={item}
                          openAssetDetails={() => detailsStore.openAssetDetailsPage(item)}
                        />
                      ))}
                    </div>
                  </div>
                )}
                {detailsStore.assetType === AssetType.Article && detailsStore.pairedArticles.length !== 0 && (
                  <div className="AssetsDetails-paired space-m-t-4 space-p-t-4">
                    <div className="space-m-b-4">{t('details.related')}</div>
                    <div className="AssetsDetails-related-assets">
                      {detailsStore.pairedArticles.map((item) => (
                        <AssetsCard
                          key={item.asset.id}
                          hideDetails={true}
                          item={item}
                          displayArticle={true}
                          openAssetDetails={() => detailsStore.openAssetDetailsPage(item, AssetType.Article)}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {detailsStore.displayItem && (
                <AsideDetails
                  displayItem={detailsStore.displayItem}
                  digitalContent={digitalContent}
                  contentType={contentType}
                  assetType={detailsStore.assetType}
                  detailsStore={detailsStore}
                />
              )}
            </section>
          </React.Fragment>
        ) : (
          <PageErrors classname={'AssetsDetails-error'} />
        )}
      </div>
    );
  },
);

export default AssetsDetails;
