import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { ListLoader } from '../../../../components/loader/ListLoader';
import ArticleNumbersSearchStore from '../../../../stores/assets/ArticleNumbersSearchStore';

import './AssetsFilterArticleNumbers.scss';

interface IAssetsFilterArticleNumbersProps {
  selectedValue?: string;
  onChangeValue?(val: string, openAfter?: boolean): void;
  onSearchComplete?(validValues: Array<string>, invalidValues: Array<string>): void;
  showTopBorder?: boolean;
  width?: number;
}

const AssetsFilterArticleNumbers = observer(
  ({ selectedValue, onChangeValue, onSearchComplete, showTopBorder, width }: IAssetsFilterArticleNumbersProps): JSX.Element => {
    const { t } = useTranslation();
    const [store] = useState(() => new ArticleNumbersSearchStore());
    const [searchValue, setSearchValue] = useState(selectedValue ?? '');

    useEffect(() => {
      if (selectedValue) {
        (async () => {
          await validateValues(selectedValue);
        })();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function validateValues(value: string): Promise<void> {
      await store.validateArticleNumbers(value);

      if (onSearchComplete) {
        onSearchComplete(store.validArticleNumbers, store.invalidArticleNumbers);
      }
    }

    async function handleChange(value: string): Promise<void> {
      setSearchValue(value);

      if (onChangeValue) {
        onChangeValue(value, true);
      }

      await validateValues(value);
    }

    return (
      <div className="AssetsFilterArticleNumbers" style={{ width: width ?? 465 }}>
        <textarea
          className={`AssetsFilterArticleNumbers-textarea ${showTopBorder ? 'AssetsFilterArticleNumbers-top-border' : ''}`}
          placeholder={t('filters.article_number.search_placeholder')}
          value={searchValue}
          onChange={(e) => {
            (async () => {
              await handleChange(e.target.value);
            })();
          }}
          onFocus={(e) => e.target.select()}
        />
        {(store.isLoading || store.validArticleNumbers.length > 0 || store.invalidArticleNumbers.length > 0) && (
          <div className="AssetsFilterArticleNumbers-info">
            {store.isLoading || store.error ? (
              <ListLoader isLoading={store.isLoading} error={store.error} small={true} itemsLength={1} />
            ) : (
              <React.Fragment>
                {store.validArticleNumbers.length > 0 && (
                  <div className="AssetsFilterArticleNumbers-info-valid">
                    {`${store.validArticleNumbers.length} `}
                    {t('filters.article_number.inserted')}
                  </div>
                )}
                {store.invalidArticleNumbers.length > 0 && (
                  <div className="AssetsFilterArticleNumbers-info-invalid">
                    {t('filters.article_number.following')}
                    {` ${store.invalidArticleNumbers.length} `}
                    {t('filters.article_number.were_not_valid')}
                    {` ${store.invalidArticleNumbers.join(', ')}`}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  },
);

export default AssetsFilterArticleNumbers;
