import React from 'react';
import { useTranslation } from 'react-i18next';

interface IForbiddenAccessPageProps {
  classname?: string;
  message?: string;
}

const ForbiddenAccessPage = ({ classname, message }: IForbiddenAccessPageProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={classname}>
      <h2>{t('error.forbidden_access')}</h2>
      <p>{message ?? t('error.forbidden_access_desc')}</p>
    </div>
  );
};

export default ForbiddenAccessPage;
