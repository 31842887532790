/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import Icon from 'components/icons/Icon';
import Button, { ButtonType, ButtonSize } from 'components/input/Button';
import DialogStore from 'stores/dialog/DialogStore';
import TextBoxControlled from 'components/input/TextBoxControlled';
import UploadProductImagesStore from 'stores/upload/UploadProductImagesStore';
import { Loader } from 'components/loader/Loader';
import UploadPIFilterDropdown from 'containers/upload/components/UploadPIFilterDropdown';
import Formatter from 'utils/Formatter';

import './FilesTable.scss';

interface IProductImagesFilesTable {
  className?: string;
  store: UploadProductImagesStore;
}

const ProductImagesFilesTable = observer(
  // eslint-disable-next-line complexity
  ({ className, store }: IProductImagesFilesTable): JSX.Element => {
    const { t } = useTranslation();

    return (
      <div className={cn('FilesTable space-m-b-4 space-m-t-6', className)}>
        <table className="table FilesTable-table-container">
          <thead className="table__head">
            <tr className="table__head-row">
              <th className="table__head-cell FilesTable-border-less" />
              <th className="table__head-cell">{t('common.name')}</th>
              {store.uploadAssets.length > 0 &&
                store.uploadAssets[0].presetUploadFilters.map((filter, index) => (
                  <th key={index} className="table__head-cell">
                    {filter.text}
                    {filter.isMandatory && <span className="FilesTable-red-text space-p-l-1">*</span>}
                  </th>
                ))}
              <th className="table__head-cell">{t('common.file_size')}</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {store.uploadAssets.map((file, index) => (
              <tr className="table__body-row" key={file.checksum}>
                <td className="table__body-cell FilesTable-border-less FilesTable-remove" onClick={() => store.removeFile(index)}>
                  <Icon icon="icon-close" height={22} width={22} />
                </td>
                {!file.validFilename ||
                (file.articleNumberMissing && store.selectedTemplate?.extractArticleNumbers) ||
                (file.productViewMissing && store.selectedTemplate?.extractProductViews) ||
                store.verifyDuplicates.files.includes(file) ? (
                  <td className="table__body-cell FilesTable-textbox FilesTable-white-background">
                    <TextBoxControlled
                      value={file.name}
                      className="FilesTable-textbox-absolute"
                      height={2.85}
                      noMargin
                      errorOutline
                      onChange={(e) => {
                        store.changeFileName(file, e.target.value);
                      }}
                      onBlur={async () => {
                        store.validateFilenames();

                        if (store.selectedTemplate?.extractArticleNumbers) {
                          await store.validateExtractedArticleNumber(file);
                        }

                        if (store.selectedTemplate?.extractProductViews) {
                          store.validateExtractedProductView(file);
                        }
                      }}
                      onKeyDown={async (e) => {
                        if (e.key === 'Enter') {
                          store.validateFilenames();

                          if (store.selectedTemplate?.extractArticleNumbers) {
                            await store.validateExtractedArticleNumber(file);
                          }

                          if (store.selectedTemplate?.extractProductViews) {
                            store.validateExtractedProductView(file);
                          }
                        }
                      }}
                    />
                  </td>
                ) : (
                  <td className="table__body-cell">
                    <div className="FilesTable-truncate">{file.name}</div>
                  </td>
                )}
                {file.presetUploadFilters.map((filter, index) => (
                  <td key={index} className="table__body-cell FilesTable-upload-filter-cell">
                    <UploadPIFilterDropdown
                      filter={filter}
                      extractArticleNumbers={store.selectedTemplate?.extractArticleNumbers}
                      articleNumberMissing={file.articleNumberMissing && !store.selectedTemplate?.extractArticleNumbers}
                      onSubmit={() => store.validateArticleNumber(file)}
                    />
                  </td>
                ))}
                <td>
                  <div className="table__head-cell subtitle subtitle--md UploadAssets-preselected-cell">
                    {Formatter.getFileSizeFromBytes(file.size, 1)}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="space-m-l-6">
          {store.filenameError && (
            <>
              <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.upload_filename_validation_error')}.</div>
              <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">
                {t('common.asset_must_follow_rules')}:
                <ul className="space-m-b-1">
                  <li className="space-m-l-2">
                    - {t('common.filename_guideline')} ({'Article number_Product view code_xxxx.fileformat'})
                  </li>
                  <li className="space-m-l-2">- {t('common.special-char-not_allowed')}</li>
                  <li className="space-m-l-2">- {t('common.max-filename-char-length')}</li>
                  <li className="space-m-l-2">- {t('common.os_words_forbidden')}</li>
                  <li className="space-m-l-2">- {t('common.filename_end_char')}</li>
                </ul>
              </div>
            </>
          )}
          {store.verifyDuplicates.show && (
            <div className="FilesTable-duplicate space-m-l-4 space-m-t-2">
              {store.verifyDuplicates.isVerifying && <Loader small={true} className="FilesTable-loader" />}
              {store.verifyDuplicates.isVerifying ??
                (!store.verifyDuplicates.successful && (
                  <div className={`space-m-l-2 ${!store.verifyDuplicates.successful && 'FilesTable-error-text'}`}>
                    {t(store.verifyDuplicates.isVerifying ? 'upload.verifying' : 'error.default_again')}
                  </div>
                ))}
            </div>
          )}
          {store.articleNumberError && store.selectedTemplate?.extractArticleNumbers && (
            <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.pi_extracted_article_number_error')}</div>
          )}
          {store.articleNumberError && !store.selectedTemplate?.extractArticleNumbers && (
            <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.pi_article_number_error')}</div>
          )}
          {store.productViewError && (
            <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.pi_product_view_error')}</div>
          )}
          {store.duplicateFilenames && (
            <div className="caption caption--md space-m-v-3 space-m-l-3 FilesTable-red-text">{t('common.duplicate_filenames')}</div>
          )}
          <div className="space-m-t-4 space-m-l-3">
            <Button
              buttonType={ButtonType.Primary}
              size={ButtonSize.Default}
              disabled={
                !store.allFiltersSelected ||
                store.filenameError ||
                store.articleNumberError ||
                store.productViewError ||
                store.duplicateFilenames ||
                store.verifyDuplicates.isVerifying
              }
              icon="icon-upload"
              iconColor="#fff"
              className="FilesTable-upload-btn"
              onClick={() => {
                if (
                  !store.allFiltersSelected ||
                  store.filenameError ||
                  store.articleNumberError ||
                  store.productViewError ||
                  store.duplicateFilenames ||
                  store.verifyDuplicates.isVerifying
                ) {
                  return;
                }

                store.beginUploadingFiles();
                DialogStore.removeLastDialog();
              }}
            >
              {t(store.verifyDuplicates.show && !store.verifyDuplicates.successful ? 'common.try_again' : 'common.upload')}
            </Button>
          </div>
        </div>
      </div>
    );
  },
);

export default ProductImagesFilesTable;
