/* eslint-disable complexity */
import pdfPlaceholderImage from 'assets/img/placeholders/pdf_placeholder.png';
import zipPlaceholderImage from 'assets/img/placeholders/zip_placeholder.png';
import zzipPlaceholderImage from 'assets/img/placeholders/7zip_placeholder.png';
import ttifPlaceholderImage from 'assets/img/placeholders/ttif_placeholder.png';
import otherPlaceholderImage from 'assets/img/placeholders/other_placeholder.png';
import docPlaceholderImage from 'assets/img/placeholders/doc_placeholder.png';
import docxPlaceholderImage from 'assets/img/placeholders/docx_placeholder.png';
import jpgPlaceholderImage from 'assets/img/placeholders/jpg_placeholder.png';
import mp4PlaceholderImage from 'assets/img/placeholders/mp4_placeholder.png';
import pptPlaceholderImage from 'assets/img/placeholders/ppt_placeholder.png';
import pptxlaceholderImage from 'assets/img/placeholders/pptx_placeholder.png';
import xlslaceholderImage from 'assets/img/placeholders/xls_placeholder.png';
import xlsxlaceholderImage from 'assets/img/placeholders/xlsx_placeholder.png';
import modellaceholderImage from 'assets/img/placeholders/model_placeholder.png';
import articlelaceholderImage from 'assets/img/placeholders/article_placeholder.png';
import brokenplaceholderImage from 'assets/img/placeholders/broken_placeholder.png';
import { AssetType, AssetContentType } from '@cdam/shared';

export interface IPreviewItemDimensions {
  height: number;
  width: number;
}

export const getPlaceholderWidth = (extension: string): number => {
  const validExtension = ['pptx', 'ppt', 'docx', 'doc', 'mp4', 'pdf', 'jpg', 'tif', 'xls', 'xlsx'];

  if (extension === 'zip' || extension === '7zip') {
    return 2.75;
  }
  if (validExtension.includes(extension)) {
    return 3.1;
  }

  return 3.6;
};

export const getPlaceholderDetailsWidth = (extension: string): number => {
  const validExtension = ['pptx', 'ppt', 'docx', 'doc', 'mp4', 'pdf', 'jpg', 'tif', 'xls', 'xlsx'];

  if (extension === 'zip' || extension === '7zip') {
    return 90;
  }
  if (validExtension.includes(extension)) {
    return 80;
  }

  return 72;
};

export const getPlaceholderImage = (extension: string, preview?: boolean): string => {
  if (preview) {
    return brokenplaceholderImage as string;
  } else if (!extension) {
    return otherPlaceholderImage as string;
  } else if (extension === 'Model') {
    return modellaceholderImage as string;
  } else if (extension === 'Article') {
    return articlelaceholderImage as string;
  }

  switch (extension?.toLowerCase()) {
    case 'pptx':
      return pptxlaceholderImage as string;
    case 'ppt':
      return pptPlaceholderImage as string;
    case 'docx':
      return docxPlaceholderImage as string;
    case 'mp4':
      return mp4PlaceholderImage as string;
    case 'doc':
      return docPlaceholderImage as string;
    case 'pdf':
      return pdfPlaceholderImage as string;
    case 'jpg':
      return jpgPlaceholderImage as string;
    case 'zip':
      return zipPlaceholderImage as string;
    case '7zip':
      return zzipPlaceholderImage as string;
    case 'tif':
      return ttifPlaceholderImage as string;
    case 'xls':
      return xlslaceholderImage as string;
    case 'xlsx':
      return xlsxlaceholderImage as string;
    default:
      return otherPlaceholderImage as string;
  }
};

export const getExtensionsColor = (extension: string): string => {
  if (!extension) {
    return '';
  }

  switch (extension?.toLowerCase()) {
    case 'pptx':
      return '#e6512e';
    case 'jpg':
      return '#e6512e';
    case 'tif':
      return '#e6512e';
    case 'zip':
      return '#f8a214';
    case 'pdf':
      return '#d12c2c';
    case 'docx':
      return '#2f5fa8';
    case 'doc':
      return '#2f5fa8';
    case 'swf':
      return '#2fa89a';
    case 'mp4':
      return '#2fa89a';
    default:
      return '#e6512e';
  }
};

export const getTypeNumber = (selectedType: Array<string>): number => {
  if (selectedType.includes('model') && selectedType.includes('article') && selectedType.includes('asset')) {
    return 1;
  } else if (selectedType.includes('model') && selectedType.includes('article')) {
    return 2;
  } else if (selectedType.includes('model') && selectedType.includes('asset')) {
    return 3;
  } else if (selectedType.includes('article') && selectedType.includes('asset')) {
    return 4;
  } else if (selectedType.includes('model')) {
    return 5;
  } else if (selectedType.includes('article')) {
    return 6;
  } else if (selectedType.includes('asset')) {
    return 7;
  }

  return -1;
};

export const getTypeValues = (type: AssetType | null, contentType?: AssetContentType | null): Array<string> => {
  if (contentType === AssetContentType.PI) {
    if (type && type === AssetType.Asset) {
      return [
        'Asset Name',
        'Article Number',
        'Asset Category',
        'Product View',
        'Status',
        'Usage',
        'Vendor',
        'Image Style',
        'Original File Size',
        'Confidential',
        'Image Launch Date',
      ];
    } else if (type && type === AssetType.Article) {
      return ['Article Number', 'Business Unit', 'Product Manager'];
    }
  } else if (contentType === AssetContentType.MCT) {
    return ['Asset Name', 'Asset Category', 'Business Unit', 'Marketing Season', 'Original File Size', 'Last Modified', 'Check-in user'];
  }

  return ['Asset Name', 'Asset Group', 'Marketing Season', 'Original File Size', 'Last Modified On', 'Owner', 'Created On'];
};
