import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { ErrorMessage, valueHasInvalidCharacters, HandledError, IMetadata, IMetadataSearchRoot } from '@cdam/shared';
import i18n from '../../i18n';
import { IAssetsFilterInputProps } from '../../containers/assets/components/Filters/AssetsFilterInput';
import ClientHttpService, { UrlType } from '../../services/ClientHttpService';

export class AssetFilterInputStore {
  @observable public value: string | number = '';
  @observable public suggestions: Array<string> = [];

  @observable public isLoading = true;
  @observable public error: Error | HandledError | null = null;
  @observable public errorMessage = '';

  public readonly numberStep: number | undefined;
  public readonly numberMin: number | undefined;
  public readonly numberMax: number | undefined;
  public readonly maxLength: number | undefined;
  public readonly validateValue: boolean | undefined;
  public readonly searchMode: boolean | undefined;

  public readonly displayResultsLimit = 5;

  private abortController: AbortController | null = null;
  private interval: NodeJS.Timeout | number | null = null;
  private loadingIndex = 0;

  private readonly attributeName: string;
  private readonly dataType: string;
  private readonly groupName: string;

  private readonly searchLimit = 100;
  private readonly debounceDelay = 300;

  @computed
  public get type(): 'text' | 'number' {
    switch (this.dataType) {
      case 'integer':
      case 'float':
        return 'number';
      case 'string':
      default:
        return 'text';
    }
  }

  @computed
  public get valueAsString(): string {
    return typeof this.value === 'string' ? this.value : this.value.toString();
  }

  public constructor(data: IAssetsFilterInputProps) {
    makeObservable(this);
    const { attributeName, group, maxLength, numberMax, numberMin, numberStep, searchMode, type, validateValue, value } = data;

    runInAction(() => {
      this.value = value ?? '';
    });
    this.numberStep = numberStep;
    this.numberMin = numberMin;
    this.numberMax = numberMax;
    this.maxLength = maxLength;
    this.validateValue = validateValue;
    this.searchMode = searchMode;
    this.dataType = type ?? 'string';
    this.attributeName = attributeName;
    this.groupName = group;
  }

  @action
  public init(): void {
    this.search();
  }

  @action
  public setValue(value: string | number): void {
    this.errorMessage = '';
    this.value = value;
    this.search();
  }

  public disposeInterval(): void {
    clearInterval(this.interval as number);
  }

  public search(): void {
    if (this.type === 'number') {
      return;
    }
    if (this.interval) {
      this.disposeInterval();
    }

    this.interval = setTimeout(() => {
      this.execSearch();
    }, this.debounceDelay);
  }

  @action
  public hasError(value: string): boolean {
    this.errorMessage = '';
    let error = false;

    if (this.validateValue) {
      if (value.length === 0) {
        this.errorMessage = i18n.t('common.please_enter_value');
        error = true;
      } else if (valueHasInvalidCharacters(value)) {
        this.errorMessage = i18n.t('common.invalid_characters');
        error = true;
      }
    }

    return error;
  }

  @action
  private async execSearch(): Promise<void> {
    const currentLoadingIndex = ++this.loadingIndex;

    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }

    this.error = null;
    this.isLoading = true;
    this.suggestions = [];

    if (currentLoadingIndex !== this.loadingIndex) {
      return;
    }

    try {
      this.abortController = new AbortController();

      const data = await ClientHttpService.fetch<IMetadataSearchRoot>({
        urlType: UrlType.Metadata,
        url: `/metadata/search?size=${this.searchLimit}`,
        method: 'POST',
        body: {
          searchText: this.valueAsString.length !== 0 ? `*${this.valueAsString}*` : '*',
          textSearchTargetList: [
            {
              attributeGroup: this.groupName,
              attributeName: this.attributeName,
            },
          ],
        },
        signal: this.abortController.signal,
      });

      runInAction(() => {
        this.error = null;

        if (data._embedded.metadataSearchResourceList[0].metadataList) {
          this.suggestions = this.getUniqueValues(data._embedded.metadataSearchResourceList[0].metadataList).slice(0, this.displayResultsLimit);
        }
      });
    } catch (e) {
      const error = e as HandledError;

      if (error.message !== ErrorMessage.NetworkError) {
        runInAction(() => {
          this.error = e as Error;
        });
      }
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  private getUniqueValues(data: Array<IMetadata>): Array<string> {
    return data
      .reduce<Array<string>>((acc, metadata) => {
        const group = metadata.attributeGroupList.find((group) => group.name === this.groupName);

        if (group) {
          const attribute = group.attributeList.find((attr) => attr.name === this.attributeName);

          if (attribute) {
            if (attribute.value && !acc.includes(attribute.value)) {
              acc.push(attribute.value);
            }
          }
        }

        return acc;
      }, [])
      .sort((a, b) => (a > b ? 1 : -1));
  }
}
