import { action, makeObservable } from 'mobx';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import './Draggable.scss';

interface IDraggableProps {
  tag: string;
  item: unknown;
  items: Array<unknown>;
  itemIndex?: number;
  droppableHeight?: number;
  columnsView?: boolean;
  subtractHeightOnDrag?: boolean;
  allowTouchDrag?: boolean;
  onDragStart?(item: unknown, sourceItems: Array<unknown>): void;
  onDragOver?(sourceItems: Array<unknown>, currentIndex: number, newIndex: number): void;
  onDragEnd?(item: unknown, sourceItems: Array<unknown>, destinationItems: Array<unknown> | undefined): void;
  canDrop?(tag: string, item: unknown): boolean;
}

export class Draggable extends React.Component<IDraggableProps> {
  public static selectedItem: unknown | undefined;
  public static selectedItemTag: string | undefined;
  public static destinationItems: Array<unknown> | undefined;

  private draggableItem: HTMLElement | undefined;
  private clonePos: { pageX: number; pageY: number } | undefined;

  public constructor(props: IDraggableProps) {
    super(props);
    makeObservable(this);
  }

  public componentDidMount(): void {
    this.updateDraggableItem();
  }

  public componentDidUpdate(): void {
    this.updateDraggableItem();
  }

  public render(): JSX.Element {
    return (
      <span
        className={`${this.props.itemIndex !== 0 && 'draggable-handle'} ${this.props.columnsView && 'draggable-handle-columns'}`}
        onMouseDown={(e) => this.onMouseDown(e)}
        onTouchStart={(e) => this.onMouseDown(e)}
      >
        {this.props.children}
      </span>
    );
  }

  private updateDraggableItem(): void {
    // TODO: remove findDomeNode
    // eslint-disable-next-line react/no-find-dom-node
    const element = ReactDOM.findDOMNode(this) as Element;
    const draggableItem = this.findDraggableItem(element);

    if (draggableItem !== this.draggableItem) {
      this.draggableItem = draggableItem;

      if (this.draggableItem) {
        this.draggableItem.addEventListener('mouseenter', () => this.onMouseEnter());
        this.draggableItem.addEventListener('touchmove', () => this.onMouseEnter());
      }
    }
  }

  // eslint-disable-next-line complexity
  private onMouseDown(e: React.MouseEvent | React.TouchEvent): void {
    if (!this.draggableItem || (e as React.MouseEvent).nativeEvent.button === 3 || (e.type === 'touchstart' && this.props.allowTouchDrag === false)) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    Draggable.selectedItem = this.props.item;
    Draggable.selectedItemTag = this.props.tag;
    Draggable.destinationItems = this.props.items;

    let clone = this.draggableItem.cloneNode(true) as HTMLElement;
    let { className } = this.draggableItem;
    const width = this.draggableItem.offsetWidth;
    const height = this.draggableItem.offsetHeight;

    if (this.draggableItem.tagName === 'TR') {
      const table = this.findTable(this.draggableItem);

      if (table) {
        const tmp = document.createElement('table');

        tmp.appendChild(clone);
        clone = tmp;
        className = table.className;
      }
    }

    if (!this.props.columnsView) {
      clone.className = `${className} draggable-clone`;
      clone.style.width = `${width}px`;
      clone.style.height = `${height}px`;
    } else {
      clone.className = `${className} column-draggable-clone`;
    }

    const pageX = (e as React.TouchEvent).touches ? (e as React.TouchEvent).touches[0].pageX : (e as React.MouseEvent).pageX || 0;
    const pageY = (e as React.TouchEvent).touches ? (e as React.TouchEvent).touches[0].pageY : (e as React.MouseEvent).pageY || 0;

    document.body.appendChild(clone);
    this.updateClonePosition(clone, { pageX, pageY });

    const windowScroll = (): void => {
      if (this.clonePos) {
        this.updateClonePosition(clone, this.clonePos);
      }
    };

    const mouseMoveFn = (ev: MouseEvent | TouchEvent): void => {
      const pageX = (ev as TouchEvent).touches ? (ev as TouchEvent).touches[0].pageX : (ev as MouseEvent).pageX || 0;
      const pageY = (ev as TouchEvent).touches ? (ev as TouchEvent).touches[0].pageY : (ev as MouseEvent).pageY || 0;

      this.updateClonePosition(clone, { pageX, pageY });
    };

    const mouseUpFn = (): void => {
      document.body.removeChild(clone);

      window.removeEventListener('scroll', windowScroll);
      window.removeEventListener('mousemove', mouseMoveFn);
      window.removeEventListener('touchmove', mouseMoveFn);
      window.removeEventListener('mouseup', mouseUpFn);
      window.removeEventListener('touchend', mouseUpFn);

      if (this.props.onDragEnd) {
        this.props.onDragEnd(this.props.item, this.props.items, Draggable.destinationItems);
      }

      Draggable.selectedItem = undefined;
      Draggable.selectedItemTag = undefined;
      Draggable.destinationItems = undefined;
    };

    window.addEventListener('scroll', windowScroll);
    window.addEventListener('mousemove', mouseMoveFn);
    window.addEventListener('touchmove', mouseMoveFn);
    window.addEventListener('mouseup', mouseUpFn);
    window.addEventListener('touchend', mouseUpFn);

    if (this.props.onDragStart) {
      this.props.onDragStart(this.props.item, this.props.items);
    }
  }

  private findDraggableItem(element: Node): HTMLElement | undefined {
    const el = element as HTMLElement;

    if (el?.className?.includes('draggable-item')) {
      return el;
    } else if (element?.parentNode) {
      return this.findDraggableItem(element.parentNode);
    }

    return undefined;
  }

  private findTable(element: Node): HTMLTableElement | undefined {
    const el = element as HTMLTableElement;

    if (el && el.tagName === 'TABLE') {
      return el;
    } else if (element?.parentNode) {
      return this.findTable(element.parentNode);
    }

    return undefined;
  }

  private updateClonePosition(clone: HTMLElement, pos: { pageX: number; pageY: number }): void {
    this.clonePos = pos;
    clone.style.left = `${pos.pageX - 7 - window.pageXOffset}px`;

    let top = pos.pageY - 7 - window.pageYOffset;

    if (this.props.subtractHeightOnDrag) {
      top -= clone.offsetHeight;
    }

    clone.style.top = `${top}px`;
  }

  @action
  private onMouseEnter(): void {
    if (!Draggable.selectedItem) {
      return;
    }

    if (Draggable.selectedItem === this.props.item && !this.props.onDragOver) {
      return;
    }

    if (this.props.canDrop) {
      if (!this.props.canDrop(Draggable.selectedItemTag as string, Draggable.selectedItem)) {
        return;
      }
    }

    const newIndex = this.props.items.indexOf(this.props.item);

    if (newIndex === -1) {
      return;
    }

    const currentIndex = this.props.items.indexOf(Draggable.selectedItem);

    if (currentIndex === newIndex && !this.props.onDragOver) {
      return;
    }

    if (currentIndex !== -1) {
      this.props.items.splice(currentIndex, 1);
    }

    this.props.items.splice(newIndex, 0, Draggable.selectedItem);
    Draggable.destinationItems = this.props.items;

    if (this.props.onDragOver) {
      this.props.onDragOver(Draggable.destinationItems, currentIndex, newIndex);
    }
  }
}
