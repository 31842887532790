/* eslint-disable @typescript-eslint/unbound-method */
import React, { useEffect, useRef, useState } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import { AppVariantEnum, IMetadataSearch } from '@cdam/shared';
import Icon from 'components/icons/Icon';
import AssetsStore from '../../stores/assets/AssetsStore';
import ViewStore from '../../stores/view/ViewStore';
import NavigationService from '../../services/NavigationService';
import AssetsCardView from './AssetsCardView';
import AssetsListView from './AssetsListView';
import AssetsFilter from './components/AssetsFilter';
import AssetsSearch from './components/AssetsSearch';
import { AssetsLoader } from './components/AssetsLoader';

import './AssetsView.scss';

const AssetsView: React.FC<RouteComponentProps> = observer(({ match }) => {
  const { path } = match;

  const [store] = useState(() => new AssetsStore());
  const containerRef = useRef(null);

  const checkEndOfListView = (e: HTMLElement): boolean => {
    if (e.scrollTop + (e.offsetHeight + 1) >= e.scrollHeight) {
      return true;
    }

    return false;
  };

  const handleScroll = (): void => {
    const element: HTMLElement | null = containerRef.current;

    if (!element) {
      return;
    }

    const isEnd = checkEndOfListView(element);

    if (!isEnd) {
      return;
    }

    !store.isLoading &&
      store.paginationData &&
      store.paginationData.number < store.paginationData.totalPages - 1 &&
      store.loadMore(store.paginationData);
  };

  const searchHasEmptyValues = (searchBody: IMetadataSearch): boolean => {
    if (searchBody.searchFacet) {
      for (const attr of searchBody.searchFacet.searchAttributeList) {
        if (attr.valueList) {
          for (const value of attr.valueList) {
            if (value.value === '') {
              return true;
            }
          }
        }
      }
    }

    return false;
  };

  useEffect(() => {
    ViewStore.toggleDisplayDomain();

    return () => {
      ViewStore.toggleDisplayDomain();
    };
  }, [store]);

  useEffect(() => {
    autorun(() => {
      const searchBody = store.getMetadataSearchBody();

      if (NavigationService.history.location.pathname === '/assets' || NavigationService.history.location.pathname === '/assets/cards') {
        // replace url only if filters are loaded
        if (store.filters) {
          const searchParams = new URLSearchParams(NavigationService.search);

          if (searchBody?.searchFacet?.searchAttributeList?.length || searchBody.searchText || searchBody.orderBy) {
            searchParams.set('q', JSON.stringify(searchBody));
          } else {
            searchParams.delete('q');
          }

          const searchParamsStr = searchParams.toString();

          NavigationService.replace(`${NavigationService.history.location.pathname}${searchParamsStr ? `?${searchParamsStr}` : ''}`);
        }

        if (!searchHasEmptyValues(searchBody)) {
          store.load(searchBody, false);
        }
      }
    });
  }, [store, store.orderBy]);

  return (
    <div className="yarn-theme--light AssetsView-assets-view-container">
      {ViewStore.appVariant === AppVariantEnum.ArchiveUI && <div className="AssetsView-assets-view-filters bg-2 space-p-h-2 space-p-b-4" />}
      <div className="AssetsView-assets-view-filters bg-2 space-p-h-2 space-p-b-4">
        <div
          className={`${
            store.selectedFilters.length === 0 ? 'AssetsView-assets-view-filter-icon-flex' : 'AssetsView-assets-view-filter-icon-relative'
          } space-p-h-3`}
        >
          <Icon icon="icon-filter" height={22} width={22} />
        </div>
        <AssetsFilter
          filterClassName="space-m-r-3 space-m-t-4"
          filters={store.filters}
          selectedFilters={store.selectedFilters}
          clearAllFilters={store.clearAllFilters}
          toggleSelectedFilter={store.toggleSelectedFilter}
          maxFilterWidth="15rem"
          minFilterWidth="9.375rem"
          brand={undefined}
          validateFilters={() => store.validateFilters()}
          searchMode={true}
        />
      </div>

      <AssetsSearch store={store} />
      <div className="AssetsView-assets-view-content">
        <div className="AssetsView-assets-view-scroll-container" ref={containerRef} id="scroller" onScroll={handleScroll}>
          <Switch>
            <Route path={`${path}/cards`} render={(props) => <AssetsCardView {...props} assetsStore={store} />} />
            <Route path={`${path}`} render={(props) => <AssetsListView {...props} store={store} />} />
          </Switch>
          <AssetsLoader
            isLoading={store.isLoading}
            error={store.error}
            allShowed={!store.isLoading && store.paginationData && !(store.paginationData.number < store.paginationData.totalPages - 1)}
            itemsLength={store.displayItems?.items.length ?? 0}
          />
        </div>
      </div>
    </div>
  );
});

export default AssetsView;
