import React from 'react';
import { observer } from 'mobx-react-lite';
import { AssetData } from 'stores/assets/data/AssetData';
import AdvancedDownloadStore from 'stores/assets/AdvancedDownloadStore';
import DownloadAssetCard from './DownloadAssetCard';
import './AdvancedDownloadCardView.scss';

interface IAssetsCardViewProps {
  selectedAssets: Array<AssetData>;
  advancedDownloadStore: AdvancedDownloadStore;
}

function getEmptyItems(count: number): React.ReactNode {
  const items = [];

  for (let i = 0; i < count; i++) {
    items.push(<div key={i} className="AdvancedDownloadCardView-assets-card-view-empty" />);
  }

  return items;
}

const AdvancedDownloadCardView: React.FC<IAssetsCardViewProps> = observer(({ selectedAssets, advancedDownloadStore }: IAssetsCardViewProps) => (
  <>
    {selectedAssets && !!selectedAssets.length && (
      <div className="AdvancedDownloadCardView-cardlist space-m-t-4">
        {advancedDownloadStore.filteredItems.map((item) => (
          <DownloadAssetCard className="AdvancedDownloadCardView-card" key={item.asset.id} item={item} height={260} width={260} />
        ))}
        {getEmptyItems(20)}
      </div>
    )}
  </>
));

export default AdvancedDownloadCardView;
