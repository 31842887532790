import {
  IMetadataSearchValue,
  IReferenceData,
  ITaxonomyAttribute,
  SortDirection,
  IAssetsFilterItem,
  IReferenceDataRoot,
  IMetadataAttributeData,
  IMetadataAttribute,
} from '@cdam/shared';
import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { customFilters } from 'configs/FilterDisplayTypeConfig';
import TaxonomyStore from '../../taxonomy/TaxonomyStore';
import ClientHttpService, { UrlType } from '../../../services/ClientHttpService';
import ViewStore from '../../view/ViewStore';
import UtilityStore from '../../utility/UtilityStore';
import { ReferenceDataItem } from './ReferenceDataItem';

export class AssetFilter implements IAssetsFilterItem {
  @observable public referenceItemsLoading: boolean | null = null;
  @observable public referenceItemsError: Error | null = null;
  @observable public selectedReferenceItems: Array<ReferenceDataItem> = [];
  @observable public value = '';
  @observable public values: Array<string> = [];
  @observable public isMandatory: boolean | undefined = undefined;
  @observable public canToggleMandatory = true;
  @observable public canBeRemoved = true;
  @observable public isDisabled = false;
  @observable public isPreset = false;
  @observable public selectedSingleValue: ReferenceDataItem | null = null; // this property is used only for upload filters on upload page
  @observable public sort: SortDirection | undefined = undefined;
  @observable public asList = false;
  @observable public displayValue: string | undefined = undefined;

  public allowedReferenceItemsIds: Array<string> = [];
  public readonly text: string;
  public readonly groupName: string;
  public readonly attribute: ITaxonomyAttribute;

  @observable private _referenceItems: Array<ReferenceDataItem> | null = null;

  private readonly cacheValid = 3000;
  private readonly isFilterCustom: boolean = false;

  @computed
  public get valueOrValues(): string | ReferenceDataItem | Array<string> | Array<ReferenceDataItem> | null {
    if (this.value) {
      return this.value;
    }
    if (this.values.length !== 0) {
      return this.values;
    }
    if (this.selectedReferenceItems.length !== 0) {
      return this.selectedReferenceItems;
    }

    if (this.selectedSingleValue) {
      return this.selectedSingleValue;
    }

    return null;
  }

  @computed
  public get selectedAsValues(): Array<string | ReferenceDataItem> | null {
    if (!this.valueOrValues) {
      return null;
    }

    return Array.isArray(this.valueOrValues) ? this.valueOrValues : [this.valueOrValues];
  }

  @computed
  public get hasNoValues(): boolean {
    return this.valueOrValues === null;
  }

  @computed
  public get referenceItems(): Array<ReferenceDataItem> | null {
    if (!this.attribute.referenceType && !this.isFilterCustom) {
      return null;
    }

    let referenceData = this._referenceItems;

    if (this.attribute.referenceType && !referenceData) {
      (async () => {
        referenceData = await this.loadReferenceData();
      })();
    }

    if (referenceData) {
      if (this.allowedReferenceItemsIds.length === 0) {
        if (this.attribute.name === 'status_rd' && !ViewStore.canAddHiddenRefData) {
          return referenceData.filter((item) => item.text !== 'hidden');
        }

        return referenceData;
      }

      return referenceData.filter((refItem) => this.allowedReferenceItemsIds?.includes(refItem.referenceData.id));
    }

    return null;
  }

  public set referenceItems(newReferenceData: Array<ReferenceDataItem> | null) {
    this._referenceItems = newReferenceData ? [...(this._referenceItems ?? []), ...newReferenceData] : null;
  }

  @computed
  public get metadataAttributeData(): IMetadataAttributeData | undefined {
    let data: IMetadataAttributeData = {};

    if (this.attribute.list === 'true') {
      const values =
        this.selectedReferenceItems.length !== 0 ? this.selectedReferenceItems.map((refItems) => refItems.referenceData.id) : this.values;

      if (values && values.length !== 0) {
        data = { values };
      }
    } else if (this.attribute.list === 'false') {
      const value = this.selectedReferenceItems.length !== 0 ? this.selectedReferenceItems[0].referenceData.id : this.value;

      if (value && value.length !== 0) {
        data = { value };
      }
    }

    if (Object.keys(data).length === 0) {
      return undefined;
    }

    return data;
  }

  @computed
  public get metadataAttribute(): IMetadataAttribute | undefined {
    return this.metadataAttributeData && { name: this.attribute.name, ...this.metadataAttributeData };
  }

  public constructor(groupName: string, attribute: ITaxonomyAttribute, referenceDataIds?: Array<string>) {
    makeObservable(this);
    this.groupName = groupName;
    this.attribute = attribute;

    runInAction(() => {
      this.asList = attribute.list === 'true';
    });

    this.isFilterCustom = customFilters.includes(attribute.name);

    let text = (attribute.name || '')
      .replace(/_pm/i, '')
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/_/g, ' ');

    if (text.length) {
      text = `${text[0].toUpperCase()}${text.substring(1)}`;
    }

    this.text = text;

    if (this.attribute.name === 'lastModifiedOn') {
      this.setSort('desc');
    }

    if (referenceDataIds) {
      this.appendReferenceItems(referenceDataIds);
    } else if (attribute.referenceDataList) {
      runInAction(() => {
        this._referenceItems = (attribute.referenceDataList as Array<IReferenceData>).map((referenceData) => new ReferenceDataItem(referenceData));
      });
    }
  }

  @action
  public setAllowedReferenceItemsIds(ids: Array<string>): void {
    this.allowedReferenceItemsIds = ids;
  }

  @action
  public setDisplayValue(value: string): void {
    this.displayValue = value;
  }

  @action
  public appendReferenceItems(newReferenceData: Array<string>): void {
    const referenceDataItems = newReferenceData.reduce((acc: Array<ReferenceDataItem>, referenceDataId: string) => {
      const referenceData = TaxonomyStore.getTaxonomyReferenceData(this.groupName, this.attribute.name, referenceDataId);

      return referenceData ? [...acc, new ReferenceDataItem(referenceData)] : acc;
    }, []);

    if (referenceDataItems.length !== 0) {
      this._referenceItems = [...(this._referenceItems ?? []), ...referenceDataItems];
    }
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public toggleMandatory(): void {
    this.isMandatory = !this.isMandatory;
  }

  // eslint-disable-next-line @typescript-eslint/unbound-method
  @action.bound
  public setSingleSelectedValue(value: ReferenceDataItem | string): void {
    if (typeof value === 'string') {
      this.value = value;
    } else {
      this.selectedSingleValue = value;
    }
  }

  @action
  public setCanBeRemoved(value: boolean): void {
    this.canBeRemoved = value;
  }

  @action
  public setIsPreset(value: boolean): void {
    this.isPreset = value;
  }

  @action
  public setMandatory(value: boolean): void {
    this.isMandatory = value;
  }

  @action
  public setCanToggleMandatory(value: boolean): void {
    this.canToggleMandatory = value;
  }

  @action
  public setDisabled(value: boolean): void {
    this.isDisabled = value;
  }

  @action
  public setSort(direction: SortDirection): void {
    this.sort = direction;
  }

  @action
  public toggleSelectedReference(item: ReferenceDataItem, appendOnly?: boolean): void {
    const existingIndex = this.selectedReferenceItems.indexOf(item);

    if (this.isFilterCustom) {
      this.changeValue(item.referenceData.id);
    }

    if (existingIndex === -1) {
      this.selectedReferenceItems.push(item);
    } else if (!appendOnly) {
      this.selectedReferenceItems.splice(existingIndex, 1);
    }
  }

  @action
  public toggleSingleSelectedReference(item: ReferenceDataItem): void {
    this.selectedReferenceItems = [item];
  }

  @action
  public changeValue(val: string, fillValue?: boolean): void {
    if (this.attribute.referenceType) {
      if (this.referenceItems) {
        const item = this.referenceItems.find((item) => item.referenceData.id === val);

        if (item) {
          const existingIndex = this.selectedReferenceItems.indexOf(item);

          if (existingIndex === -1) {
            this.selectedReferenceItems.push(item);
          }
        }
      }
    } else if (this.attribute.list === 'true') {
      if (fillValue) {
        const fillValues = val.split(',');

        this.values = fillValues;

        return;
      }

      if (!this.values.includes(val)) {
        this.values.push(val);
      } else {
        this.values.splice(this.values.indexOf(val), 1);
      }
    } else {
      this.value = val;
    }
  }

  @action
  public async forceAppendReferenceValue(val: string, appendOnly?: boolean): Promise<void> {
    const refValue = this.referenceItems?.find((f) => f.referenceData.id === val);

    if (refValue) {
      this.toggleSelectedReference(refValue, appendOnly);
    }
  }

  @action
  public removeMultipleValue(val: string): void {
    if (this.values.includes(val)) {
      const index = this.values.indexOf(val);

      this.values.splice(index, 1);
    }
  }

  @action
  public clearAllSelectedValues(): void {
    this.selectedReferenceItems = [];
    this.value = '';
    this.values = [];
  }

  public getMetadataSearchValueList(): Array<IMetadataSearchValue> {
    if (this.attribute.referenceType) {
      if (this.attribute.list === 'false' && this.selectedSingleValue) {
        return [
          {
            value: this.selectedSingleValue.referenceData.id,
          },
        ];
      }

      return this.selectedReferenceItems.map((item) => ({
        value: item.referenceData.id,
      }));
    }

    if (this.allowedReferenceItemsIds.length) {
      return this.allowedReferenceItemsIds.map((id) => ({ value: id }));
    }

    if (this.attribute.list === 'true') {
      return this.values.map((item) => ({
        value: item,
      }));
    }

    if (this.attribute.name === 'articleNumber') {
      return this.value.split(/[\s,]+/).map((item) => ({
        value: item,
      }));
    }

    if (this.value) {
      return [
        {
          value: this.value,
        },
      ];
    }

    return [];
  }

  @action
  private async loadReferenceData(): Promise<Array<ReferenceDataItem> | null> {
    if (this.referenceItemsLoading) {
      return null;
    }

    this.referenceItemsLoading = true;
    this.referenceItemsError = null;

    let referenceItems: Array<ReferenceDataItem> | null = null;

    try {
      const referenceDataList = await this.getReferenceData();

      referenceItems = referenceDataList.map((referenceData) => new ReferenceDataItem(referenceData));

      runInAction(() => {
        this._referenceItems = referenceItems;
        this.referenceItemsLoading = false;
      });
    } catch (e) {
      runInAction(() => {
        this.referenceItemsError = e as Error;
        this.referenceItemsLoading = false;
      });
    }

    return referenceItems;
  }

  private async getReferenceData(): Promise<Array<IReferenceData>> {
    let referenceDataList: Array<IReferenceData> = [];

    const cachedReferenceData = UtilityStore.getReferenceData(this.attribute.referenceType as string);
    const cacheIsValid = cachedReferenceData ? new Date().getTime() - cachedReferenceData.createdAt.getTime() > this.cacheValid : false;

    if (cachedReferenceData && cacheIsValid) {
      referenceDataList = cachedReferenceData.referenceDataList;
    } else {
      const expand = ViewStore.shouldGetDeprecatedReferenceData(this.attribute.referenceType ?? '');

      const referenceData = await ClientHttpService.fetch<IReferenceDataRoot>({
        urlType: UrlType.Metadata,
        url: `/referenceData/${encodeURIComponent(this.attribute.referenceType as string)}?size=10000&showDeprecated=${expand}`,
        method: 'GET',
      });

      referenceDataList = referenceData.referenceDataList;

      UtilityStore.storeReferenceData(this.attribute.referenceType as string, referenceDataList);
    }

    return referenceDataList;
  }

  // @action
  // private async getCustomReferenceItems(): Promise<void> {
  //   const referenceDataItems = TaxonomyStore.getCustomReferenceData(this.attribute.name);

  //   if (referenceDataItems.length !== 0) {
  //     this._referenceItems = [...(this._referenceItems ?? []), ...referenceDataItems];
  //   }
  // }
}
