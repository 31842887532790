import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChoicesSelection from 'components/dropdown/ChoicesSelection';

import './AssetsFilterChoices.scss';

interface IAssetsFilterChoices {
  onUpdate?(value: string, openAfter: boolean): void;
  removeItem?(value: string): void;
  type: string;
  selectedValues?: Array<string>;
  mandatory?: boolean;
}

const AssetsFilterChoices = ({ onUpdate, type, selectedValues, removeItem, mandatory }: IAssetsFilterChoices): JSX.Element => {
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState('');

  const addItem = (e: React.KeyboardEvent<HTMLInputElement>): void => {
    if (e.key === 'Enter') {
      if (currentValue !== '' && onUpdate) {
        onUpdate(currentValue, true);
        setCurrentValue('');
      }
    }
  };

  return (
    <React.Fragment>
      <div className="AssetsFilterChoices">
        <div className="AssetsFilterChoices-content">
          {selectedValues?.map((item, index) => (
            <React.Fragment key={index}>
              {item !== '' && (
                <ChoicesSelection
                  item={item}
                  removeItem={() => removeItem?.(item)}
                  disableRemove={mandatory && selectedValues.length === 0 && true}
                />
              )}
            </React.Fragment>
          ))}
          <input
            className="AssetsFilterChoices-input"
            type={type}
            placeholder="Enter value"
            value={currentValue}
            onKeyDown={(e) => addItem(e)}
            onChange={(e) => {
              setCurrentValue(e.target.value);
            }}
          />
        </div>
        {currentValue !== '' && (
          <div className="AssetsFilterChoices-choice-text">
            {t('common.add_value')} <strong>{`"${currentValue}"`}</strong>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default AssetsFilterChoices;
