import { Name as GroupName, appLocationEnum, assetGroupsEnum } from '@cdam/shared';

export interface IFilterRuleItemBase {
  filterName: string;
  filterGroup: GroupName;
}

export interface IDisplayRuleItem extends IFilterRuleItemBase {
  allowedValuesMap?: Array<[string, Array<string>]>;
}

export interface IRequiredFilterItem extends IFilterRuleItemBase {
  filterValues?: Array<string>;
}

export interface IFilterRule {
  displayUnmodified?: boolean;
  isDisabled?: boolean;
  isMandatory?: boolean;
  preselected?: {
    isPreselected: boolean;
    preselectedValues?: Array<string>;
  };

  // showWhenFilterIsSelected?: Array<IRequiredFilterItem>;
  displayRules?: Array<IDisplayRuleItem>;
}

export interface IRuleGroups {
  displayUnmodified?: boolean | undefined;
  isDisabled?: boolean;
  rules?: {
    general?: IFilterRule | undefined;
    [assetGroup: string]: IFilterRule | undefined;
  };
}

export interface IFilterConfigItem {
  name: string;
  groupName: GroupName;
  displayUnmodified?: boolean;
  appLocation?: {
    [appLocation: string]: IRuleGroups;
  };
}

export type IFilterConfig = Array<IFilterConfigItem>;

const FilterConfig: IFilterConfig = [
  {
    name: 'agencyModelName',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'agencyName',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'articleNumber',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            isMandatory: true,
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'assetCategory',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
            isMandatory: true,
          },
        },
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.General,
                filterName: 'assetGroup',
                allowedValuesMap: [
                  ['7', ['13', '20', '80', '81', '82', '14', '49', '50', '68', '67', '85', '96', '57', '54', '16', '101', '100', '103']],
                ],
              },
            ],
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'assetGroup',
    groupName: GroupName.General,
    appLocation: {
      [appLocationEnum.EDIT_MATRIX]: {
        isDisabled: true,
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['1', '12', '2', '3', '34', '4']],
                  ['26', ['1', '12', '2', '3', '34', '4']],
                ],
              },
            ],
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['1', '12', '2', '3', '34', '4']],
                  ['26', ['1', '12', '2', '3', '34', '4']],
                ],
              },
            ],
          },
        },
      },
    },
  },
  {
    name: 'assetStage',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    // TODO: expand to handle special settings for brand on matrix (disabled: true etc.)
    name: 'brand',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    // Not in MCT upload https://tools.adidas-group.com/jira/browse/CDAM-3147
    name: 'businessUnit',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'confidential',
    groupName: GroupName.Miscellaneous,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    groupName: GroupName.AssetInformation,
    name: 'creationCenter',
    appLocation: {
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    groupName: GroupName.AssetInformation,
    name: 'distribution',
    appLocation: {
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'eanCode',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'functions',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['3', '5', '8', '7', '10', '12', '14', '15', '16']],
                  ['26', ['4', '11', '12', '9', '10']],
                ],
              },
            ],
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['3', '5', '8', '7', '10', '12', '14', '15', '16']],
                  ['26', ['4', '8', '9', '10']],
                ],
              },
            ],
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'imageStyle',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            isMandatory: true,
          },
        },
      },
    },
  },
  {
    name: 'keyCategory',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  [
                    '11',
                    [
                      '00023',
                      '00088',
                      '00079',
                      '00003',
                      '00112',
                      '00083',
                      '00085',
                      '00097',
                      '00056',
                      '00108',
                      '00066',
                      '00116',
                      '00128',
                      '00010',
                      '00009',
                      '00143',
                      '00142',
                      '00006',
                      '00118',
                      '00002',
                      '00094',
                      '00092',
                      '00093',
                      '00004',
                      '00086',
                      '00059',
                      '00110',
                      '00129',
                      '00132',
                      '00082',
                      '00119',
                      '00087',
                      '00091',
                      '00084',
                      '00010',
                      '00007',
                      '00005',
                      '00024',
                      '00123',
                      '00125',
                    ],
                  ],
                  ['26', ['00042', '00106', '00029', '00101', '00041', '00105', '00027', '00099', '00062', '00114', '00080']],
                ],
              },
              {
                filterName: 'keyCategoryCluster',
                filterGroup: GroupName.AssetInformation,
                allowedValuesMap: [
                  ['1', ['00088', '00079', '00092', '00093', '00004', '00087', '00091']],
                  ['2', ['00002', '00094', '00086']],
                  ['3', ['00097']],
                  ['4', ['00003', '00112', '00113']],
                  ['5', ['00006', '00118']],
                  ['9', ['00023', '00009', '00143', '00142', '00024']],
                  ['15', ['00027', '00099', '00080']],
                  ['16', ['00029', '00101']],
                  ['20', ['00041', '00105']],
                  ['22', ['00042', '00106']],
                  ['29', ['00056', '00108']],
                  ['37', ['00066', '00116', '00059', '00110']],
                  ['40', ['00062', '00114']],
                  ['60', ['00082', '00119']],
                  ['61', ['00083', '00085', '00010', '00084']],
                  ['62', ['00123', '00125']],
                  ['63', ['00128', '00129', '00132']],
                ],
              },
            ],
          },
        },
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  [
                    '11',
                    [
                      '00023',
                      '00088',
                      '00079',
                      '00003',
                      '00112',
                      '00083',
                      '00085',
                      '00097',
                      '00056',
                      '00108',
                      '00066',
                      '00116',
                      '00128',
                      '00010',
                      '00009',
                      '00143',
                      '00142',
                      '00006',
                      '00118',
                      '00002',
                      '00094',
                      '00092',
                      '00093',
                      '00004',
                      '00086',
                      '00059',
                      '00110',
                      '00129',
                      '00132',
                      '00082',
                      '00119',
                      '00087',
                      '00091',
                      '00084',
                      '00010',
                      '00007',
                      '00005',
                      '00024',
                      '00123',
                      '00125',
                    ],
                  ],
                  ['26', ['00042', '00106', '00029', '00101', '00041', '00105', '00027', '00099', '00062', '00114', '00080']],
                ],
              },
              {
                filterName: 'keyCategoryCluster',
                filterGroup: GroupName.AssetInformation,
                allowedValuesMap: [
                  ['1', ['00088', '00079', '00092', '00093', '00004', '00087', '00091']],
                  ['2', ['00002', '00094', '00086']],
                  ['3', ['00097']],
                  ['4', ['00003', '00112', '00113']],
                  ['5', ['00006', '00118']],
                  ['9', ['00023', '00009', '00143', '00142', '00024']],
                  ['15', ['00027', '00099', '00080']],
                  ['16', ['00029', '00101']],
                  ['20', ['00041', '00105']],
                  ['22', ['00042', '00106']],
                  ['29', ['00056', '00108']],
                  ['37', ['00066', '00116', '00059', '00110']],
                  ['40', ['00062', '00114']],
                  ['60', ['00082', '00119']],
                  ['61', ['00083', '00085', '00010', '00084']],
                  ['62', ['00123', '00125']],
                  ['63', ['00128', '00129', '00132']],
                ],
              },
            ],
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'keyCategoryCluster',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'market',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'marketingSeason',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'milestones',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['1', '17', '18']],
                  ['26', ['18', '17']],
                ],
              },
            ],
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'modelChest',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'modelHeight',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'modelWaist',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'quarter',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'pairedArticles',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'policy',
    groupName: GroupName.General,
    appLocation: {
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'productSize',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'productView',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            isMandatory: true,
          },
        },
      },
    },
  },
  {
    name: 'refCode',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            displayUnmodified: true,
          },
        },
      },
    },
  },
  {
    name: 'sportsCategory',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayUnmodified: true,
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
          },
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
    },
  },
  {
    name: 'subBrand',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.UPLOAD_MCT]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['31', '32']],
                  ['26', ['9', '30']],
                ],
              },
            ],
          },
        },
      },
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['31', '32']],
                  ['26', ['9', '30']],
                ],
              },
            ],
          },
        },
      },
      [appLocationEnum.EDIT_MATRIX]: {
        rules: {
          [assetGroupsEnum.MARKETING_CONTENT]: {
            preselected: {
              isPreselected: true,
            },
            displayRules: [
              {
                filterGroup: GroupName.AssetInformation,
                filterName: 'brand',
                allowedValuesMap: [
                  ['11', ['31', '32']],
                  ['26', ['9', '30', '16', '17', '20']],
                ],
              },
            ],
          },
        },
      },
    },
  },
  {
    name: 'status',
    groupName: GroupName.AssetStatus,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            isMandatory: true,
          },
        },
      },
    },
  },
  {
    name: 'usage',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            isMandatory: true,
          },
        },
      },
    },
  },
  {
    name: 'vendor',
    groupName: GroupName.AssetInformation,
    appLocation: {
      [appLocationEnum.SEARCH_RESULTS]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
          },
        },
      },
      [appLocationEnum.UPLOAD_PI]: {
        rules: {
          [assetGroupsEnum.PRODUCT_IMAGES]: {
            preselected: {
              isPreselected: true,
            },
            isMandatory: true,
          },
        },
      },
    },
  },
];

export default FilterConfig;
