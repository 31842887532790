import React from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as PDFIcon } from '../../../assets/icon/placeholders/pdf.svg';

import './SearchMatrixDownloadFile.scss';

interface ISearchMatrixDownloadFile {
  handleClick(): Promise<void>;
}

const SearchMatrixDownloadFile = ({ handleClick }: ISearchMatrixDownloadFile): JSX.Element => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line @typescript-eslint/return-await
    <div className="SearchMatrixDownloadFile space-m-t-4" onClick={async () => await handleClick()}>
      <PDFIcon className="space-m-r-2" height={35} width={30} />
      <span className="SearchMatrixDownloadFile-text">{t('common.where_to_find_what')}?</span>
    </div>
  );
};

export default SearchMatrixDownloadFile;
