import React from 'react';
import { observer } from 'mobx-react-lite';
import { readFileExtension } from 'utils/FileFormatParser';
import { getExtensionsColor } from 'utils/AssetCardPlaceholder';
import CoreDamImage from 'components/coredam/CoreDamImage';
import { showConfidential } from 'utils/AssetDisplayUtils';
import { AssetType, ITransformationArguments, AssetContentType } from '@cdam/shared';
import AssetUtils from 'utils/AssetUtils';
import AssetsDetailsFullScreen from 'containers/assets/details/components/AssetsDetailsFullScreen';
import DialogStore from 'stores/dialog/DialogStore';
import PreviewImage from 'containers/assets/details/components/PreviewImage';
import { AssetData } from '../../stores/assets/data/AssetData';
import AssetTypeSelector from './AssetTypeSelector';

import './AssetCardImage.scss';

interface IAssetCardImage {
  width?: number;
  height?: number;
  transformationWidth?: number;
  transformationHeight?: number;
  item: AssetData;
  className?: string;
  hideBadge?: boolean;
  assetType?: AssetType;
  preview?: boolean;
  onClick?(): void;
  contentType?: AssetContentType;
}

// eslint-disable-next-line complexity
const AssetCardImage: React.FC<IAssetCardImage> = observer(
  // eslint-disable-next-line complexity
  ({
    contentType,
    width,
    height,
    item,
    className,
    hideBadge,
    assetType,
    preview,
    onClick,
    transformationWidth,
    transformationHeight,
  }: IAssetCardImage) => {
    const {
      asset: { id: metadataId },
      activeDigitalContent,
    } = item;

    const showConfidentialWatermark = showConfidential(item);
    const expired = AssetUtils.getExpired(item);

    const imageExtension = (activeDigitalContent?.storageURL && readFileExtension(activeDigitalContent.storageURL)) || 'Unknown';

    const badgeColor = getExtensionsColor(imageExtension);

    const dimensions = {
      width: !preview ? width : assetType === AssetType.Article ? width : '100%',
      height: !preview ? height : assetType === AssetType.Article ? height : '100%',
      overflow: preview ? 'hidden' : '',
    };

    const closeFullscreen = (): void => {
      DialogStore.removeLastDialog();
    };

    const openFullScreen = (url: string): void => {
      DialogStore.addDialog(<AssetsDetailsFullScreen url={url} onClose={() => closeFullscreen()} />);
    };

    const getArguments = (): ITransformationArguments => {
      const args: ITransformationArguments = { format: 'jpg' };

      if (showConfidentialWatermark) {
        args.watermark = true;
        args.watermarkName = 'Confidential';
      } else if (expired === 'Yes') {
        args.watermark = true;
        args.watermarkName = 'Expired';
      }

      if (!preview) {
        args.height = transformationHeight ? transformationHeight : 260;
        args.width = transformationWidth ? transformationWidth : 260;
      }

      return args;
    };

    return (
      <div className={className} style={dimensions} onClick={onClick && onClick}>
        {preview ? (
          <PreviewImage
            metadataId={metadataId}
            width={width}
            height={height}
            transformationArgs={getArguments()}
            fileName={activeDigitalContent?.fileName ?? ''}
            imageExtension={imageExtension}
            openFullPreview={openFullScreen}
            className={`card__image ${preview ? 'PreviewImage-img' : ''}`}
            item={item}
          />
        ) : (
          <React.Fragment>
            <CoreDamImage
              metadataId={metadataId}
              width={width}
              height={height}
              transformationArgs={getArguments()}
              fileName={activeDigitalContent?.fileName ?? ''}
              imageExtension={imageExtension}
              className={`card__image ${preview ? 'CoreDamImage-img' : ''}`}
              etag={item.asset.etag}
              item={item}
            />
            {!hideBadge &&
              imageExtension &&
              contentType &&
              (contentType === AssetContentType.PI ? (
                <span className="badge badge--white card__badge space-p-2 AssetCardImage-square">
                  <AssetTypeSelector type={(assetType && assetType) || AssetType.Asset} border={false} className="AssetCardImage-badge-height" />
                </span>
              ) : (
                <span
                  className="AssetsCard-badge badge badge--white card__badge"
                  style={{
                    backgroundColor: badgeColor,
                  }}
                >
                  {imageExtension.toUpperCase()}
                </span>
              ))}
          </React.Fragment>
        )}
      </div>
    );
  },
);

export default AssetCardImage;
