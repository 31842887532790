import { action, computed, observable, runInAction, makeObservable } from 'mobx';
import { IUploadTemplate, IUploadTemplateInfo } from '@cdam/shared';
import ClientHttpService, { UrlType } from 'services/ClientHttpService';
import TaxonomyStore from '../taxonomy/TaxonomyStore';
import UploadDownloadManagerStore from '../uploadDownloadManager/UploadDownloadManagerStore';
import { UploadItem, UploadType } from '../uploadDownloadManager/data/UploadItem';
import FilterUtils from '../../utils/FilterUtils';
import ViewStore from '../view/ViewStore';
import { AssetFilter } from '../assets/data/AssetFilter';
import { IUploadFile } from './UploadStore';

export default class UploadArchiveAssetsStore {
  @observable public isLoading = false;
  @observable public isUploadLoading = false;
  @observable public error: Error | null = null;
  @observable public templates: Array<IUploadTemplateInfo> | null = null;
  @observable public selectedTemplate: IUploadTemplate | null = null;
  @observable public uploadAssets: Array<IUploadFile> = [];

  @observable public uploadTableFilters: Array<AssetFilter> = [];
  @observable public bulkUpdateFilters: Array<AssetFilter> = [];

  public constructor() {
    makeObservable(this);
  }

  @computed
  public get canUpload(): boolean {
    return !this.uploadAssets.every((item) => {
      let allMandatoryFiltersSelected = true;

      for (const filter of item.presetUploadFilters.filter((f) => f.isMandatory)) {
        const selected =
          (filter.value.length !== 0 || filter.values.length !== 0 || filter.selectedSingleValue) ?? filter.selectedReferenceItems.length > 0;

        if (!selected) {
          allMandatoryFiltersSelected = false;
          break;
        }
      }

      return allMandatoryFiltersSelected;
    });
  }

  @action
  public async load(): Promise<void> {
    this.isLoading = true;
    this.error = null;

    this.selectedTemplate = null;

    try {
      if (!this.templates) {
        const templateList = await ClientHttpService.fetch<Array<IUploadTemplateInfo>>({
          urlType: UrlType.Backend,
          url: `/upload-templates/${ViewStore.selectedAssetDomain}`,
          method: 'GET',
        });

        runInAction(() => {
          this.templates = templateList;
          this.isLoading = false;
        });
      }
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public toggleSelectedFilter(item: AssetFilter, filters: Array<AssetFilter>): boolean {
    return FilterUtils.toggleSelectedFilter(item, filters);
  }

  @action
  public clearAllFilters(): void {
    return this.bulkUpdateFilters.forEach((f) => f.clearAllSelectedValues());
  }

  @action
  public async selectTemplate(templateId: string): Promise<void> {
    this.isLoading = true;
    this.error = null;

    this.selectedTemplate = null;
    this.uploadAssets = [];

    try {
      const template = await ClientHttpService.fetch<IUploadTemplate>({
        urlType: UrlType.Backend,
        url: `/upload-templates/template/${templateId}`,
        method: 'GET',
      });

      if (template) {
        const filters = FilterUtils.findAndPreselectFilters(template.attributes, TaxonomyStore.attributeGroupList);

        runInAction(() => {
          this.selectedTemplate = template;
          this.isLoading = false;
          this.uploadTableFilters = filters;

          this.bulkUpdateFilters = this.uploadTableFilters.reduce<Array<AssetFilter>>((acc, filter) => {
            if (filter.hasNoValues) {
              const newFilter = new AssetFilter(filter.groupName, filter.attribute);

              newFilter.clearAllSelectedValues();
              newFilter.canToggleMandatory = false;
              acc.push(newFilter);
            }

            return acc;
          }, []);
        });
      }
    } catch (e) {
      runInAction(() => {
        this.isLoading = false;
        this.error = e as Error;
      });
    }
  }

  @action
  public async handleFileDrop(fullName: string, file: Blob, mimeType: string, fileSize: number, checksum: string): Promise<void> {
    const splitAt = fullName.lastIndexOf('.');
    const name = fullName;
    const extension = fullName.slice(splitAt + 1);

    const uploadFile: IUploadFile = {
      name,
      extension,
      file,
      size: fileSize,
      mimeType,
      checksum,
      presetUploadFilters: this.selectedTemplate?.attributes
        ? FilterUtils.findAndPreselectFilters(this.selectedTemplate.attributes, TaxonomyStore.attributeGroupList)
        : [],
      displayedUploadFilters: [],
      validFilename: false,
      articleNumberMissing: true,
      productViewMissing: true,
    };

    for (const filter of uploadFile.presetUploadFilters) {
      if (filter.valueOrValues) {
        filter.setIsPreset(true);
      }
    }

    runInAction(() => {
      this.uploadAssets.push(uploadFile);
    });
  }

  @action
  public updateAssets(all: boolean): void {
    const filters = this.bulkUpdateFilters.filter((f) => !f.hasNoValues);

    this.uploadAssets.forEach((asset) => {
      filters.forEach((filter) => {
        const uploadAssetFilter = asset.presetUploadFilters.find((f) => f.attribute.name === filter.attribute.name);

        if (uploadAssetFilter) {
          if (all || uploadAssetFilter.hasNoValues) {
            runInAction(() => {
              uploadAssetFilter.value = filter.value;
              uploadAssetFilter.values = filter.values;

              for (const referenceItem of filter.selectedReferenceItems) {
                // TODO: Check if this is desired behaviour
                uploadAssetFilter.forceAppendReferenceValue(referenceItem.referenceData.id, true);
                // uploadAssetFilter.isPreset = true;
              }

              if (filter.selectedSingleValue) {
                uploadAssetFilter.toggleSelectedReference(filter.selectedSingleValue);
              }
            });
          }
        }
      });
    });
  }

  @action
  public resetAll(): void {
    this.uploadAssets.forEach((asset) => {
      this.bulkUpdateFilters.forEach((filter) => {
        const uploadAssetFilter = asset.presetUploadFilters.find((f) => f.attribute.name === filter.attribute.name);

        if (uploadAssetFilter) {
          runInAction(() => {
            uploadAssetFilter.value = '';
            uploadAssetFilter.selectedSingleValue = null;
            uploadAssetFilter.values = [];
            uploadAssetFilter.selectedReferenceItems = [];
            uploadAssetFilter.isPreset = false;
          });
        }
      });
    });
  }

  @action
  public async beginUploadingFiles(): Promise<void> {
    this.startProcess();
  }

  @action
  public changeFileName(file: IUploadFile, name: string): void {
    file.name = name;
  }

  @action
  public removeFile(index: number): void {
    this.uploadAssets.splice(index, 1);
  }

  @action
  public setUploadLoading(value: boolean): void {
    this.isUploadLoading = value;
  }

  private startProcess(): void {
    UploadDownloadManagerStore.addUploadItem(new UploadItem('', this.uploadAssets.slice(0), UploadType.PI));
  }
}
