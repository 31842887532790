import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AssetsStore from 'stores/assets/AssetsStore';
import { ContextAction } from '@cdam/shared';

interface IContextMenuModal {
  clientX: number;
  clientY: number;
  contextMenu: boolean;
  columnsLenght: number;
  store: AssetsStore;
  onHide(): void;
  onClick(type: ContextAction): void;
}

const ContextMenuModal = ({ clientX, clientY, contextMenu, onHide, onClick, columnsLenght, store }: IContextMenuModal): JSX.Element => {
  const { t } = useTranslation();

  const closeOpenedFilter = useCallback(
    (e: Event): void => {
      const clickedElement = e.target as HTMLElement;

      if (clickedElement?.closest) {
        const closestFilterDropDown = clickedElement.closest('.TableListView-contextMenu');

        if (closestFilterDropDown) {
          return;
        }
      }

      if (contextMenu) {
        onHide();
      }
    },
    [contextMenu, onHide],
  );

  useEffect(() => {
    document.addEventListener('mousedown', closeOpenedFilter);
    document.addEventListener('touchstart', closeOpenedFilter);

    return () => {
      document.removeEventListener('mousedown', closeOpenedFilter);
      document.removeEventListener('touchstart', closeOpenedFilter);
    };
  });

  const menuPosition = {
    top: `${clientY + 2}px`,
    left: `${clientX + 2}px`,
  };

  return (
    <div className="TableListView-contextMenu overflow-menu" style={menuPosition}>
      <ul className="overflow-menu__item-list TableListView-contextMenu-table">
        <li className="overflow-menu__item TableListView-contextMenu-item" onClick={() => onClick(ContextAction.Add)}>
          {t('Insert column')}
        </li>
        {columnsLenght > 1 && store.contextAction?.index !== 0 && (
          <li className="overflow-menu__item TableListView-contextMenu-item" onClick={() => onClick(ContextAction.Remove)}>
            {t('Remove column')}
          </li>
        )}
        {store.contextAction?.index !== 0 && (
          <li className="overflow-menu__item TableListView-contextMenu-item" onClick={() => onClick(ContextAction.Replace)}>
            {t('Replace column')}
          </li>
        )}
        <li className="overflow-menu__divider">
          <span className="text" />
        </li>
        <li
          className="overflow-menu__item TableListView-contextMenu-item"
          onClick={async () => {
            store.clearUserColumns();
            onHide();
          }}
        >
          <span className="text">{t('Reset columns')}</span>
        </li>
      </ul>
    </div>
  );
};

export default ContextMenuModal;
