/* eslint-disable @typescript-eslint/unbound-method */
import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { AppVariantEnum } from '@cdam/shared';
import DetailsStore from '../../../../stores/assets/DetailsStore';
import ViewStore from '../../../../stores/view/ViewStore';
import Button, { ButtonType, ButtonSize } from '../../../../components/input/Button';
import AssetsFilter from '../../../../containers/assets/components/AssetsFilter';
import MatrixSettingsOwners from '../../../../containers/search/dialogs/components/MatrixSettingsOwners';
import AssetsFilterDropDown from '../../../../containers/assets/components/Filters/AssetsFilterDropDown';

import './EditMetadata.scss';

interface IEditMetadata {
  store: DetailsStore;
  onClose(): void;
}

// eslint-disable-next-line complexity
const EditMetadata: React.FC<IEditMetadata> = observer(({ onClose, store }) => {
  const { t } = useTranslation();

  const assetName = store.getAssetName();

  return (
    <div className="EditMetadata space-p-4">
      <section className="EditMetadata-navigation space-m-b-4">
        <div className="EditMetadata-heading">
          <h4 className="space-m-r-3">
            {t('details.edit_title')} {assetName}
          </h4>
        </div>
        <div>
          <Button className="space-m-r-3 EditMetadata-cancel" buttonType={ButtonType.Secondary} size={ButtonSize.Default} onClick={() => onClose()}>
            {t('common.cancel')}
          </Button>
          <Button buttonType={ButtonType.Primary} size={ButtonSize.Default} onClick={async () => store.updateAssetMetadata()}>
            {t('common.save')}
          </Button>
        </div>
      </section>
      <section className="EditMetadata-content space-p-2">
        {ViewStore.appVariant === AppVariantEnum.GateUI && (
          <div className="EditMetadata-edit-owners space-m-t-2 space-m-b-6">
            <h5>{t('details.edit_owners')}</h5>
            <MatrixSettingsOwners
              getAllItems={store.getAllOwners}
              addItem={store.addToEditMetadataOwners}
              removeItem={store.removeFromEditMetadataOwners}
              itemList={store.editMetadataOwners}
            />
          </div>
        )}
        <div className="space-m-b-6">
          <h5>{t('details.edit_filters')}</h5>
          <AssetsFilter
            filterClassName="space-m-r-3 space-m-t-4"
            filters={store.editMetadataFilters}
            selectedFilters={store.editMetadataFilters}
            clearAllFilters={() => store.clearAllFilters()}
            toggleSelectedFilter={(item, filters) => store.toggleSelectedFilter(item, filters)}
            minFilterWidth="15rem"
            validate={true}
            brand={undefined}
            singleChoice={true}
            choicesMandatory={true}
            disableDeleting={true}
            disableAdding={true}
          />
        </div>
        <div className="space-m-b-6">
          <h5>{t('details.other_metadata')}</h5>
          <div className="EditMetadata-non-editable-metadata-container">
            {store.NonEditableMetadata.map((filter, index) => (
              <AssetsFilterDropDown
                className="space-m-r-2 space-m-t-2"
                key={index}
                canBeRemoved={filter.canBeRemoved}
                isOpen={false}
                text={filter.text}
                attributeName={filter.attribute.name}
                group={filter.groupName}
                type={filter.attribute.type ?? filter.attribute.masterDataType}
                selectedItems={filter.selectedReferenceItems}
                selectedValue={filter.value}
                selectedValues={filter.values}
                disabled={true}
                disableDelete={true}
                displayType={filter.attribute.displayType}
                minFilterWidth="15rem"
                maxFilterWidth="15rem"
                displayGrayedOut={true}
                multipleChoices={filter.asList}
                displayValue={filter.displayValue}
                onSelectorClick={() => {
                  /* empty body */
                }}
                onDeleteClick={() => {
                  /* empty body */
                }}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
});

export default EditMetadata;
