/* eslint-disable complexity */
import {
  AssetType,
  IMetadataDigitalContent,
  IAssetData,
  DisplayAssetType,
  IDimensions,
  AssetContentType,
  ITransformationArguments,
  IDisplayCols,
  AppVariantEnum,
  UIAssetDomainsNamesEnums,
} from '@cdam/shared';
import { AssetData } from 'stores/assets/data/AssetData';
import { IDisplayItem } from 'stores/assets/DetailsStore';
import { AssetFilter } from 'stores/assets/data/AssetFilter';
import UtilityStore from 'stores/utility/UtilityStore';
import { IDisplayColumns } from 'stores/assets/AssetsStore';
import TaxonomyStore from 'stores/taxonomy/TaxonomyStore';
import TableListViewConfig from 'configs/TableListViewConfig';
import DetailsConfig from 'configs/DetailsConfig';
import { customFilters } from 'configs/FilterDisplayTypeConfig';
import ViewStore from '../stores/view/ViewStore';
import AssetUtils from './AssetUtils';
import DownloadUtils from './DownloadUtils';
import { readFileExtension, getFileFormatFromExtension } from './FileFormatParser';

export interface IDisplayItems {
  items: Array<AssetData>;
  articles?: Array<IDisplayArticles>;
}

export interface IDisplayArticles {
  articleName?: string;
  items: Array<AssetData>;
}

export interface IDetailsItem {
  item: AssetData;
  articleItems?: Array<AssetData>;
}

const returnDisplayFormat = (name: string, value: string | undefined): IAssetData => ({
  head: name,
  value,
});

export const getCardDisplayValues = (
  displayItem: IDisplayItem,
  contentType: AssetContentType,
  digitalContent: IMetadataDigitalContent | undefined,
): Array<IAssetData> => {
  const returnValues: Array<IAssetData> = [];
  const item = displayItem.item;

  if (contentType === AssetContentType.MCT) {
    returnValues.push(returnDisplayFormat('Asset Category', AssetUtils.getAssetCategory(item)));
    returnValues.push(
      returnDisplayFormat('Original file size', (digitalContent?.fileSize && AssetUtils.getFileSize(digitalContent)) || 'Unknown size'),
    );
    returnValues.push(returnDisplayFormat('Last Modified On', AssetUtils.getLastModifiedOn(item)));
    returnValues.push(returnDisplayFormat('Marketing Season', AssetUtils.getMarketingSeason(item)));
  } else {
    returnValues.push(
      returnDisplayFormat('Original file size', (digitalContent?.fileSize && AssetUtils.getFileSize(digitalContent)) || 'Unknown size'),
    );
    returnValues.push(
      returnDisplayFormat(
        'File format',
        getFileFormatFromExtension(
          (digitalContent?.storageURL && readFileExtension(digitalContent.storageURL)) ||
            readFileExtension(
              item.asset.attributeGroups['Asset Information'] && (item.asset.attributeGroups['Asset Information'].assetName as string),
            ),
        ),
      ),
    );
  }

  return returnValues;
};

export const getAbbreviatedMarketingSeasonName = (item: AssetData): string | undefined =>
  AssetUtils.getMarketingSeason(item)
    ?.replace(/[^A-Z\d]/g, '')
    ?.replace(/\d\d(\d\d)/, '$1');

export const getValues = (displayItem: IDisplayItem, contentType: AssetContentType, assetType: AssetType): Array<string | undefined> => {
  if (!UtilityStore.listViewCols.length) {
    return [];
  }

  const item = displayItem.item;

  const returnValues: Array<string | undefined> = [];
  let columnSelection: Array<AssetFilter> = [];

  if (contentType === AssetContentType.PI) {
    if (assetType === AssetType.Asset) {
      columnSelection = UtilityStore.listViewCols[1].assetColumns;
    } else if (assetType === AssetType.Article) {
      columnSelection = UtilityStore.listViewCols[1].articleColumns;
    }
  } else if (contentType === AssetContentType.MCT) {
    columnSelection = UtilityStore.listViewCols[0].assetColumns;
  } else {
    columnSelection = UtilityStore.listViewCols[2].assetColumns;
  }

  columnSelection.forEach((element) => {
    const customFilters = TableListViewConfig.addMCTCustomColumnList;

    if (customFilters.includes(element.attribute.name)) {
      returnValues.push(AssetUtils.getCustomValue(item, element));
    } else {
      returnValues.push(AssetUtils.getValue(item, element));
    }
  });

  return returnValues;
};

export const getAssetCardMetadata = (
  assetDomain: UIAssetDomainsNamesEnums,
  item: AssetData,
  activeDigitalContent?: IMetadataDigitalContent,
): Array<[string, string]> => {
  switch (assetDomain) {
    case UIAssetDomainsNamesEnums.DABOX_TAXONOMY_NAME:
      // eslint-disable-next-line no-case-declarations
      const brand = item.getAttributeDisplayValue('TAGGING', 'Kalido_MDT_BRAND');

      return [
        [
          `AssetID: ${AssetUtils.getValueByGroupAndAttributeName(item, 'ASSET_INFO', 'assetId')?.join(',') ?? 'none'}`,
          `JobNo: ${AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'jobno')?.join(',') ?? 'none'}`,
        ],
        [`Brand: ${brand ?? 'none'}`, ''],
        [
          `Season: ${AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'season')?.join(',') ?? '  '} ${
            AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'year')?.join(',') ?? 'none'
          }`,
          '',
        ],
      ];
    case UIAssetDomainsNamesEnums.SPLASH_TAXONOMY_NAME:
      return [
        [`BatchName: ${AssetUtils.getValueByGroupAndAttributeName(item, 'ASSET_INFO', 'batchName')?.join(',') ?? 'none'}`, ''],
        [`SubBrand: ${AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'subBrand_pm')?.join(',') ?? 'none'}`, ''],
        [`Category: ${AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'category_pm')?.join(',') ?? 'none'}`, ''],
        [
          `${AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'season_pm')?.join(',') ?? '  '} ${
            AssetUtils.getValueByGroupAndAttributeName(item, 'CAMPAIGN_INFO', 'year_pm')?.join(',') ?? '  '
          }`,
          '',
        ],
      ];
    case UIAssetDomainsNamesEnums.MARVIN_MCT:
    default:
      return [
        [AssetUtils.getFileSize(activeDigitalContent) ?? '', AssetUtils.getLastModifiedBy(item) ?? AssetUtils.getCreatedBy(item) ?? ''],
        [getAbbreviatedMarketingSeasonName(item) ?? '', AssetUtils.getLastModifiedOn(item) ?? ','],
      ];
  }
};

export const getDetailsValues = (displayItem: IDisplayItem, availableFilters: Array<AssetFilter>): Array<AssetFilter> => {
  const item = displayItem.item;

  const displayFilters: Array<AssetFilter> = [];

  availableFilters.forEach((element) => {
    const value = AssetUtils.getValue(item, element, !!element.referenceItems);

    if (value) {
      if (element.referenceItems) {
        const refValues = value.split(',');

        refValues.forEach((e) => {
          element.changeValue(e);

          if (customFilters.includes(element.attribute.name)) {
            element.forceAppendReferenceValue(e);
          }
        });
      } else {
        element.changeValue(value, true);
      }

      displayFilters.push(element);
    } else if (DetailsConfig.customFields.includes(element.attribute.name)) {
      const value = AssetUtils.getCustomValue(item, element);

      if (value) {
        element.changeValue(value, true);
        element.setDisplayValue(value);
        displayFilters.push(element);
      }
    }
  });

  return displayFilters;
};

export const fillUserColumnSelection = (userDefinedColumns?: Array<IDisplayCols>): Array<IDisplayColumns> => {
  let userColumns: Array<IDisplayCols> = [];

  if (userDefinedColumns) {
    userColumns = userDefinedColumns;
  } else {
    userColumns = TableListViewConfig.defaultColumns();
  }

  const displayColumns: Array<IDisplayColumns> = [];
  const filters =
    ViewStore.appVariant === AppVariantEnum.GateUI ? TaxonomyStore.getTaxonomyAttributeFiltersWithRules() : TaxonomyStore.attributeGroupList;

  userColumns.forEach((element) => {
    const colItem: IDisplayColumns = { assetGroup: element.assetGroup, articleColumns: [], assetColumns: [] };

    for (const item of element.assetColumns) {
      let filter = filters.find((f) => f.attribute.name === item);

      if (!filter) {
        const customFilters = TableListViewConfig.addMCTCustomColumnList;

        if (customFilters.includes(item)) {
          filter = new AssetFilter(item, { name: item, list: 'false', type: 'string', indexed: 'false', uiVisible: 'false', description: 'false' });
        }
      }

      if (filter) {
        colItem.assetColumns.push(filter);
      }
    }

    if (element.assetGroup === UIAssetDomainsNamesEnums.MARVIN_PI) {
      for (const item of element.articleColumns) {
        const filter = filters.find((f) => f.attribute.name === item);

        if (filter) {
          colItem.articleColumns.push(filter);
        }
      }
    }

    displayColumns.push(colItem);
  });

  return displayColumns;
};

export const getAssetContentType = (item: AssetData): AssetContentType =>
  (item?.asset?.attributeGroups?.General?.assetGroup &&
    ((item.asset.attributeGroups.General.assetGroup === '7' && AssetContentType.MCT) ||
      (item.asset.attributeGroups.General.assetGroup === '9' && AssetContentType.PI) ||
      AssetContentType.Unknown)) ||
  AssetContentType.Unknown;

export const getAssetContentTypeForAll = (items: Array<AssetData>): AssetContentType => {
  if (!items) {
    return AssetContentType.Unknown;
  }

  if (items.every((e) => e.asset.attributeGroups.General.assetGroup === '7')) {
    return AssetContentType.MCT;
  } else if (items.every((e) => e.asset.attributeGroups.General.assetGroup === '9')) {
    return AssetContentType.PI;
  }

  return AssetContentType.Unknown;
};

export const getDisplayType = (extension: string): DisplayAssetType => {
  if (!extension) {
    return DisplayAssetType.InlineList;
  }

  if (extension === 'pptx' || extension === 'ppt' || extension === 'zip') {
    return DisplayAssetType.ScrollList;
  }

  return DisplayAssetType.InlineList;
};

export const dimensions = (layoutType: string | undefined): IDimensions => {
  if (layoutType === 'Page') {
    return {
      height: 584,
      width: 431,
    };
  } else if (layoutType === 'Slide') {
    return {
      height: 405,
      width: 720,
    };
  }

  return {
    height: 500,
    width: 500,
  };
};

export const showConfidential = (item?: AssetData, items?: Array<AssetData>): boolean => {
  if (!ViewStore.canAccessConfidential) {
    return false;
  }

  if (item) {
    const itemsCopy = [item];

    return itemsCopy.every((item) => item?.asset?.attributeGroups?.Miscellaneous && item.asset.attributeGroups.Miscellaneous.confidential === 'true');
  }

  if (items) {
    return items.every((item) => item?.asset?.attributeGroups?.Miscellaneous && item.asset.attributeGroups.Miscellaneous.confidential === 'true');
  }

  return false;
};

export const getArguments = (showConfidentialWatermark: boolean, expired: string): string => {
  const args: ITransformationArguments = {};

  if (showConfidentialWatermark) {
    args.watermark = true;
    args.watermarkName = 'Confidential';
  } else if (expired === 'Yes') {
    args.watermark = true;
    args.watermarkName = 'Expired';
  }

  return DownloadUtils.transformationArgsToQuery(args);
};
