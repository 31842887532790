/* eslint-disable complexity */
import React, { useState } from 'react';
import cn from 'classnames';
import { getPlaceholderImage } from 'utils/AssetCardPlaceholder';
import { readFileExtension } from 'utils/FileFormatParser';
import { AssetType } from '@cdam/shared';
import Icon from 'components/icons/Icon';
import AssetCardImage from 'components/coredam/AssetCardImage';
import { showConfidential } from 'utils/AssetDisplayUtils';
import AssetUtils from 'utils/AssetUtils';
import { useTranslation } from 'react-i18next';
import DetailsStore, { IDisplayItem } from 'stores/assets/DetailsStore';
import { observer } from 'mobx-react-lite';

import './DetailsItem.scss';

interface IDetailsItem {
  className?: string;
  item: IDisplayItem;
  assetType: AssetType;
  hideDropDown?: boolean;
  detailsStore: DetailsStore;
  secondaryHeader?: boolean;
  onNavigateClick?(): void;
}

// eslint-disable-next-line complexity
const DetailsItem = observer(
  ({ className, item, assetType, hideDropDown, secondaryHeader, onNavigateClick, detailsStore }: IDetailsItem): JSX.Element => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(true);

    const displayItem = item.item;

    const { activeDigitalContent: digitalContent } = displayItem;
    const showConfidentialWatermark = showConfidential(displayItem);
    const expired = AssetUtils.getExpired(displayItem);

    return (
      <div className={cn('DetailsItem space-m-t-4', className)}>
        <div className="DetailsItem-header">
          <strong>{assetType}</strong>
          {!hideDropDown && (
            <div className="DetailsItem-dropdown-icon" onClick={() => setExpanded(!expanded)}>
              <Icon icon={`${expanded ? 'icon-arrow-breadcrumb-up' : 'icon-arrow-breadcrumb-down'}`} color="#737373" height={25} width={25} />
            </div>
          )}
        </div>
        {expanded && (
          <div
            className={`DetailsItem-asset ${onNavigateClick && 'DetailsItem-pointer'}`}
            onClick={assetType === AssetType.Article ? onNavigateClick : undefined}
          >
            <div className="space-m-t-4">
              {secondaryHeader ? (
                <div className="DetailsItem-secondary-header">
                  <AssetCardImage
                    hideBadge={true}
                    className="DetailsItem-image space-m-r-6"
                    assetType={assetType}
                    item={displayItem}
                    width={200}
                    height={200}
                  />
                  <div className="DetailsItem-asset-details-item space-m-b-3 space-m-t-2">
                    <div className="DetailsItem-asset-details-item DetailsItem-asset-details-item-head">
                      {assetType} {t('details.number')}
                    </div>
                    <h5 className="DetailsItem-asset-details-header">{AssetUtils.getArticleNumber(displayItem)}</h5>
                  </div>
                </div>
              ) : (
                <div className={`DetailsItem-primary-header ${expired && 'DetailsItem-primary-header-expired'}`}>
                  <img
                    src={getPlaceholderImage(
                      (assetType && assetType === AssetType.Model && 'Model') ||
                        (assetType === AssetType.Article && 'Article') ||
                        readFileExtension(digitalContent?.storageURL ?? ''),
                    )}
                    style={{ width: 72 /* disable-stylelint */ }}
                    className="space-m-r-4"
                    alt=""
                  />
                  {(expired && <h5>{t('details.expired').toUpperCase()}</h5>) ||
                    (showConfidentialWatermark && <h5>{t('details.confidential').toUpperCase()}</h5>)}
                </div>
              )}
            </div>

            <div className="space-m-t-4 DetailsItem-asset-details">
              {detailsStore.displayItemMetadata(assetType).map((item, index) => (
                <div className="DetailsItem-asset-details-item space-m-b-3" key={index}>
                  <div className="DetailsItem-asset-details-item DetailsItem-asset-details-item-head">{item.text}</div>
                  <div className="">
                    {(item.displayValue ?? item.value) || item.values.join(', ') || item.selectedReferenceItems.map((e) => e.text).join(', ')}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  },
);

export default DetailsItem;
