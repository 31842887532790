import React from 'react';
import cn from 'classnames';
import Icon from 'components/icons/Icon';
import { observer } from 'mobx-react-lite';
import { DownloadItem } from 'stores/uploadDownloadManager/data/DownloadItem';
import DownloadUtils from 'utils/DownloadUtils';
import Formatter from 'utils/Formatter';
import { UrlType } from '../../../services/ClientHttpService';

import './UDMItem.scss';

interface IUDMAvailableDownloadItemProps {
  className?: string;
  item: DownloadItem;
}

const UDMAvailableDownloadItem = observer(
  ({ className, item }: IUDMAvailableDownloadItemProps): JSX.Element => (
    <div className={cn('UDMItem space-m-b-3', className)}>
      <div className="UDMItem-description">
        <Icon
          icon="icon-delete"
          height={22}
          width={22}
          className="UDMItem-btn-icon space-m-r-3"
          onClick={() => {
            item.remove();
          }}
        />
        <Icon icon="icon-Open-in-Desi" height={22} width={22} className="UDMItem-icon space-m-r-3" />
        <div className="UDMItem-description-text">
          <p className="body body--sm UDMItem-main-text">
            {decodeURIComponent(item.title)}
            {'.zip'}
          </p>
          <span className="caption caption--sm">{Formatter.getDateTime(item.createdOn)}</span>
        </div>
      </div>
      <Icon
        icon="icon-download"
        className="UDMItem-btn-icon"
        height={25}
        width={25}
        onClick={() => {
          DownloadUtils.handleItemDownload(null, item.downloadUrl, item.title, UrlType.Raw);
        }}
      />
    </div>
  ),
);

export default UDMAvailableDownloadItem;
