import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { IDownloadType } from '@cdam/shared/src/interfaces/Asset';
import Dropdown from '../../../../components/dropdown/Dropdown';
import DropdownCheckboxItem from '../../../../components/dropdown/DropdownCheckboxItem';
import AdvancedDownloadStore from '../../../../stores/assets/AdvancedDownloadStore';
import { DownloadType } from '../data/DownloadType';

interface IDownloadTypeSelectDropdownProps {
  className?: string;
  advancedDownloadStore: AdvancedDownloadStore;
  label: string;
}

const GlobalDownloadTypeSelectDropdown: React.FC<IDownloadTypeSelectDropdownProps> = observer(({ advancedDownloadStore, className, label }) => {
  const handleDropdownCheckboxItemClick = useCallback(
    (type: IDownloadType) => {
      advancedDownloadStore.setDownloadType(type);
    },
    [advancedDownloadStore],
  );

  return (
    <Dropdown
      className={cn('GlobalDownloadTypeSelectDropdown', className)}
      value={advancedDownloadStore.downloadType ? advancedDownloadStore.downloadType.name : ''}
      label={label && label}
    >
      {DownloadType.map((type) => (
        <DropdownCheckboxItem
          key={type.name}
          labelClassName="space-m-t-1"
          value={type.name}
          checked={advancedDownloadStore.downloadType ? advancedDownloadStore.downloadType.type === type.type : false}
          onClick={() => handleDropdownCheckboxItemClick(type)}
        />
      ))}
    </Dropdown>
  );
});

export default GlobalDownloadTypeSelectDropdown;
