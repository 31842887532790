import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Navbar from '../../components/layout/Navbar';
import AssetsView from '../assets/AssetsView';
import SearchView from '../search/SearchView';
import UploadDownloadManagerStore from '../../stores/uploadDownloadManager/UploadDownloadManagerStore';

const AppWithNavbar = observer(() => {
  UploadDownloadManagerStore.preFillStore();

  return (
    <React.Fragment>
      <Navbar />
      <Switch>
        <Route path="/assets" component={AssetsView} />
        <Route path="/download/:metadataId" component={SearchView} exact />
        <Route path="/:brand/:eventId" component={SearchView} exact />
        <Route path="/:brand" component={SearchView} exact />
        <Route path="/" component={SearchView} exact />
      </Switch>
    </React.Fragment>
  );
});

export default AppWithNavbar;
