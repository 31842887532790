import React from 'react';
import { observer } from 'mobx-react-lite';
import AssetCardImage from 'components/coredam/AssetCardImage';
import { getValues } from 'utils/AssetDisplayUtils';
import { AssetType, AssetContentType } from '@cdam/shared';
import UtilityStore from 'stores/utility/UtilityStore';
import { AssetData } from '../../../../stores/assets/data/AssetData';

import './AssetRowView.scss';

interface IAssetRowView {
  item: AssetData;
  checked: boolean;
  treeNumber?: number;
  highlightAction?: number | null;
  onChecked(): void;
  openAssetDetails?(): void;
  assetType: AssetType;
  assetContetType: AssetContentType | null;
}

// eslint-disable-next-line complexity
const AssetRowView: React.FC<IAssetRowView> = observer(
  // eslint-disable-next-line complexity
  ({ item, checked, treeNumber, onChecked, openAssetDetails, assetType, assetContetType, highlightAction }) => {
    const getTreeClass = (treeNumber: number): string => {
      if (treeNumber === 2) {
        return 'second-square';
      } else if (treeNumber === 3) {
        return 'third-square';
      }

      return 'first-square';
    };

    const displayValues = assetContetType && getValues({ item }, assetContetType, assetType);

    const contentIndex = UtilityStore.listViewCols.findIndex((e) => e.assetGroup === assetContetType);

    let assetNameIndex: number;

    if (contentIndex !== -1) {
      assetNameIndex = UtilityStore.listViewCols[0].assetColumns.findIndex((e) => e.attribute.name === 'assetName');
    }

    return (
      <React.Fragment>
        <tr className="AssetRowView" id={`asset-item-${item.asset.id}`}>
          <td className="AssetRowView-expand">
            {treeNumber && (
              <div className="AssetRowView-expandcell">
                <div className="AssetRowView-horizontal" />
              </div>
            )}
          </td>
          <td className={`AssetRowView-${treeNumber ? getTreeClass(treeNumber) : 'first-square'} AssetRowView-checkbox-cell`}>
            <input type="checkbox" className="AssetRowView-assets-list-view-checkbox" checked={checked} onChange={() => onChecked()} />
          </td>
          <td className="AssetRowView-placeholder" onClick={openAssetDetails && openAssetDetails}>
            <AssetCardImage className={'AssetRowView-display-image'} item={item} width={62} height={62} hideBadge={true} />
          </td>
          {displayValues?.map((item, index) => (
            <td
              key={index}
              className={`AssetRowView-table-item space-p-h-3 ${assetNameIndex && assetNameIndex === index && 'AssetRowView-table-item-priority'} ${
                highlightAction && highlightAction === index && 'AssetRowView-table-item-edit'
              }`}
              onClick={openAssetDetails && openAssetDetails}
            >
              <p className="AssetRowView-table-item-text">{item}</p>
            </td>
          ))}
        </tr>
      </React.Fragment>
    );
  },
);

export default AssetRowView;
