import { IReferenceData, IAssetsFilterItem } from '@cdam/shared';

export class ReferenceDataItem implements IAssetsFilterItem {
  public readonly referenceData: IReferenceData;
  public referenceItems: Array<IAssetsFilterItem> | null = null;

  public get text(): string {
    if (this.referenceData.attributeList) {
      const desc = this.referenceData.attributeList.find((attr) => attr.name === 'Desc');

      if (desc) {
        return desc.value;
      }

      const mdd = this.referenceData.attributeList.find((attr) => attr.name === 'MASTER_DATA_DESC');

      if (mdd) {
        return mdd.value;
      }

      // Dabox reference data
      const name = this.referenceData.attributeList.find((attr) => attr.name === 'name');

      if (name) {
        return name.value;
      }

      if (this.referenceData.attributeList.length) {
        return this.referenceData.attributeList[0].value;
      }
    }

    return '';
  }

  public constructor(referenceData: IReferenceData) {
    this.referenceData = referenceData;
  }
}
