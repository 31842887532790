/* eslint-disable no-console */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Brand, IEventUploadFilters } from '@cdam/shared';
import { useTranslation } from 'react-i18next';
import MinimalLayout from 'components/layout/MinimalLayout';
import FileUploadZone from 'components/fileUpload/FileUploadZone';
import FilesTable from 'components/fileUpload/FilesTable';
import { UploadStore } from '../../stores/upload/UploadStore';
import { ReactComponent as Adidas } from '../../assets/icon/adidas.svg';
import { ReactComponent as Reebok } from '../../assets/icon/reebok.svg';
import { Loader } from '../../components/loader/Loader';
import './UploadAssets.scss';

interface IUploadAssetsProps {
  brand: Brand;
  name: Array<string>;
  filters: IEventUploadFilters | undefined;
  filenameConvention: string;
  owners?: Array<string>;
  onClose(): void;
}

const UploadAssets = observer(
  ({ brand, name, filters, filenameConvention, owners, onClose }: IUploadAssetsProps): JSX.Element => {
    const { t } = useTranslation();
    const [uploadStore] = useState(() => new UploadStore(name.join(' x '), filters, filenameConvention, owners));
    const [xAxisName, yAxisName] = name;

    return (
      <MinimalLayout
        className="UploadAssets"
        header={t('common.upload')}
        headerClassName="space-m-h-6 space-m-t-6 space-m-b-4"
        onCloseButtonClick={() => onClose()}
      >
        <header className="UploadAssets-header space-m-h-6">
          {brand === Brand.Adidas ? <Adidas /> : <Reebok />}
          <div>
            <h5 className="space-m-l-4">
              {xAxisName}
              {yAxisName && (
                <>
                  <br />
                  {yAxisName}
                </>
              )}
            </h5>
          </div>
        </header>
        {filenameConvention !== '' ? (
          <div className="space-m-h-6 space-m-v-5">
            <div className="caption caption--md space-m-b-2 UploadAssets-filename-convention-label">{t('common.name_guideline')}</div>
            <div className="caption caption--lg UploadAssets-filename-convention">{filenameConvention}</div>
          </div>
        ) : (
          <></>
        )}
        <section className="UploadAssets-square-content space-m-h-6 space-m-v-2">
          <div className="col-sm-7">
            <FileUploadZone store={uploadStore} />
            {uploadStore.isLoading && <Loader small />}
            {uploadStore.uploadAssets.length > 0 && <FilesTable store={uploadStore} filenameConvention={filenameConvention} />}
          </div>
        </section>
      </MinimalLayout>
    );
  },
);

export default UploadAssets;
