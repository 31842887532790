import cn from 'classnames';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { AttributeDisplayType } from '@cdam/shared';
import DateTimePicker from '../../../../components/date/DateTimePicker';
import Dropdown from '../../../../components/dropdown/Dropdown';
import Button, { ButtonSize, ButtonType } from '../../../../components/input/Button';
import Icon from '../../../../components/icons/Icon';

import './AssetsFilterSelector.scss';

interface IAssetsFilterSelectorProps {
  canBeRemoved?: boolean;
  label?: string;
  value?: string;
  displayType?: AttributeDisplayType;
  className?: string;
  minFilterWidth?: string;
  maxFilterWidth?: string;
  open?: boolean;
  disabled?: boolean;
  disableDelete?: boolean;
  errorMessage?: string;
  closeOnSelection?: boolean;
  onSelectorClick?(): void;
  onChangeValue?(val: string, openAfter?: boolean): void;
  onDeleteClick?(): void;
  displayGrayedOut?: boolean;
  displayValue?: string;
}

const AssetsFilterSelector = observer(
  ({
    canBeRemoved,
    label,
    value,
    displayType,
    className,
    minFilterWidth,
    maxFilterWidth,
    open,
    disabled,
    disableDelete,
    errorMessage,
    closeOnSelection,
    onSelectorClick,
    onChangeValue,
    onDeleteClick,
    displayGrayedOut,
    displayValue,
  }: IAssetsFilterSelectorProps): JSX.Element => (
    <React.Fragment>
      <div className="selectorContainer">
        {displayType === 'date' || displayType === 'dateTime' ? (
          <DateTimePicker
            label={label}
            value={value}
            className={cn('AssetFilterDatePicker', className)}
            minFilterWidth={minFilterWidth}
            maxFilterWidth={maxFilterWidth}
            open={open}
            disabled={disabled}
            error={errorMessage ? true : undefined}
            closeWhenItemSelect={true}
            displayGrayedOut={displayGrayedOut}
            displayValue={displayValue}
            selectRange={displayType === 'dateTime'}
            onChangeValue={onChangeValue}
          />
        ) : (
          <Dropdown
            label={label}
            value={value?.length ? value : displayValue}
            className={cn('AssetsFilterDropDown', className, displayGrayedOut ? 'AssetsFilterDropDown-grayed-out' : '')}
            minFilterWidth={minFilterWidth}
            maxFilterWidth={maxFilterWidth}
            disabled={disabled}
            error={errorMessage ? true : undefined}
            closeWhenItemSelect={closeOnSelection}
            onClick={onSelectorClick}
          />
        )}
        {!disabled && canBeRemoved && !disableDelete && (
          <Button
            className={`deleteButton space-m-b-3 ${errorMessage ? 'selectorContainer-error' : ''}`}
            buttonType={ButtonType.Secondary}
            size={ButtonSize.Default}
            onClick={onDeleteClick}
          >
            <Icon icon="icon-close-small" height={15} width={15} />
          </Button>
        )}
      </div>
      {errorMessage && <div className="caption caption--md space-m-l-3 space-m-t-2 text-danger">{errorMessage}</div>}
    </React.Fragment>
  ),
);

export default AssetsFilterSelector;
