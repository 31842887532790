import { makeAutoObservable, runInAction } from 'mobx';
import { HandledError, ITransformationArguments } from '@cdam/shared';
import { getFileFormatTypeFromExtension, readFileExtension } from '../../utils/FileFormatParser';
import i18n from '../../i18n';
import { CachedImage } from '../../interfaces';
import DownloadUtils from '../../utils/DownloadUtils';
import ClientHttpService, { UrlType } from '../../services/ClientHttpService';
import GateDB from '../../db/indexdb';
import { getPlaceholderDetailsWidth, getPlaceholderImage, getPlaceholderWidth } from '../../utils/AssetCardPlaceholder';

const UNVALID_FORMATS = ['video', 'audio', 'Unknown'];

export class ImageStore {
  public isLoading = true;
  public error: Error | HandledError | null = null;

  public blobUrl = '';

  private fileFormat: string | undefined;

  private abortController: AbortController | undefined = undefined;

  public constructor(
    private readonly metadataId: string,
    private readonly etag: string,
    private readonly imageExtension?: string | undefined,
    public readonly height?: number | undefined,
    public readonly width?: number | undefined,
  ) {
    makeAutoObservable(this);

    runInAction(() => {
      if (this.imageExtension) {
        this.fileFormat = getFileFormatTypeFromExtension(this.imageExtension);
        this.blobUrl = getPlaceholderImage(readFileExtension(this.imageExtension)) || '';
      }
    });
  }

  public get imageWidth(): number | undefined {
    return this.error
      ? (this.imageExtension && this.width && this.width / getPlaceholderWidth(this.imageExtension)) ||
          (this.imageExtension && getPlaceholderDetailsWidth(this.imageExtension)) ||
          (this.width && this.width / getPlaceholderWidth(''))
      : this.width;
  }

  private get isFormatTypeValid(): boolean {
    return typeof this.fileFormat === 'string' && !UNVALID_FORMATS.includes(this.fileFormat);
  }

  public async load(transformationArgs?: ITransformationArguments): Promise<void> {
    this.isLoading = true;
    this.error = null;

    if (!this.isFormatTypeValid) {
      this.isLoading = false;
      this.error = new HandledError(i18n.t('error.file_format_not_valid'));

      return;
    }

    try {
      let blobUrl = await this.getCachedBlob();

      if (!blobUrl) {
        // eslint-disable-next-line require-atomic-updates
        blobUrl = await this.getBlob(transformationArgs);
      }

      if (blobUrl) {
        runInAction(() => {
          this.blobUrl = blobUrl ?? '';
        });
      }
    } catch (e) {
      runInAction(() => {
        this.error = e as Error;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
  }

  public cancelDownload(): void {
    this.abortController?.abort();
  }

  public setError(): void {
    this.error = new HandledError(i18n.t('error.failed_to_load_image'));
  }

  private async getCachedBlob(): Promise<string | undefined> {
    let cachedImage: CachedImage | undefined;
    let blobUrl: string | undefined;

    try {
      cachedImage = await GateDB.activeDigitalContent.get({ metadataId: this.metadataId });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Could not get cached image');
    }

    if (!cachedImage) {
      return blobUrl;
    }

    if (cachedImage.etag === this.etag) {
      blobUrl = URL.createObjectURL(cachedImage.blob);
    }

    return blobUrl;
  }

  private async getBlob(transformationArgs?: ITransformationArguments): Promise<string> {
    try {
      this.abortController = new AbortController();

      const formatUrlParams = DownloadUtils.transformationArgsToQuery(transformationArgs);
      const url = `/metadata/${this.metadataId}/activeDigitalContent${formatUrlParams}`;
      const urlType = UrlType.Metadata;

      const blob = await ClientHttpService.fetchBlob({ urlType, url, method: 'GET', signal: this.abortController.signal });

      this.saveToDatabase({ metadataId: this.metadataId, versionNumber: null, blob, etag: this.etag, lastAccessedOn: new Date() });

      return URL.createObjectURL(blob);
    } catch (e) {
      throw e as HandledError;
    }
  }

  private async saveToDatabase(imageData: CachedImage): Promise<void> {
    try {
      await GateDB.activeDigitalContent.put(imageData);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('Could not update cache');
    }
  }
}
