export default {
  mctDisplayFilters: ['assetName', 'assetCategory', 'marketingSeason', 'originalFileSize', 'lastModifiedOn', 'checkInUser', 'keyCategory'],
  piDisplayFilters: ['assetName', 'articleNumber', 'assetCategory', 'productView', 'status', 'usage', 'vendor', 'imageStyle'],
  piArticleDisplayFilters: ['articleNumber', 'businessUnit', 'productView', 'productManager'],
  defaultDisplayFilters: ['assetName', 'assetGroup', 'marketingSeason', 'fileSize', 'lastModifiedOn', 'owners', 'createdOn'],
  mctEditFilters: ['assetName', 'sportsCategory', 'quarter'],
  customFields: ['originalFileSize'],
  daboxDisplayFilters: ['assetId', 'assetName', 'status', 'updatedDate', 'archiveDate', 'subBrand', 'category', 'year', 'season', 'jobno', 'generic'],
  splashDisplayFilters: [
    'assetName',
    'assetId',
    'batchName',
    'agency',
    'fileType',
    'lastModifiedOn',
    'archiveDate',
    'status_rd',
    'subBrand_pm',
    'brand_pm',
    'category_pm',
    'year_pm',
    'season_pm',
    'jobn_pm',
    'DABOX_campaign_pm',
    'communicationChannel_pm',
    'mediaType',
    'gender_pm',
    'productTechnology_pm',
    'articleNumber_pm',
    'subtitle',
    'shootLocation',
    'person',
    'club',
    'federation',
    'keywords',
    'team',
    'event',
    'cameraMake',
  ],
  daboxEditFilter: [
    'Kalido_MDT_BRAND_ASSET',
    'Kalido_MDT_BRAND',
    'Kalido_MDT_BRAND_ASSET_TYPE',
    'Kalido_MDT_GENDER',
    'Kalido_MDT_SPORTS_CAT',
    'Kalido_MDT_TECH_CONCEPT',
    'articleNo',
    'boxDynamicKeywords177',
    'boxDynamicKeywords185',
    'boxDynamicKeywords2623',
    'boxDynamicKeywords2625',
    'boxDynamicKeywords2627',
    'boxDynamicKeywords388',
    'boxDynamicKeywords416',
    'boxDynamicKeywords5',
    'boxDynamicKeywords59',
    'boxDynamicKeywords696',
    'boxDynamicKeywords698',
    'boxDynamicKeywords70',
    'boxDynamicKeywords746',
    'visible',
  ],
  splashEditFilter: [
    'DABOX_campaign_pm',
    'agency',
    'articleNumber_pm',
    'batchName',
    'brand_pm',
    'campaignName_pm',
    'category_pm',
    'communicationChannel_pm',
    'gender_pm',
    'jobn_pm',
    'keywords',
    'mediaType',
    'productTechnology_pm',
    'season_pm',
    'status_rd',
    'subBrand_pm',
    'usageEndDate',
    'usageStartDate',
    'viewOnly',
    'year_pm',
  ],
};
