import React from 'react';
import Icon from '../../../../components/icons/Icon';

import './AssetFilterAddNewButton.scss';

interface IAssetFilterAddNewButtonProps {
  label: string;
  onClick(): void;
}

const AssetFilterAddNewButton = ({ label, onClick }: IAssetFilterAddNewButtonProps): JSX.Element => (
  <button className="btn btn-placeholder AssetFilterAddNew-btn" onClick={onClick}>
    <span className="AssetFilterAddNew-text space-m-r-5">{label}</span>
    <Icon icon="icon-plus" color="#737373" height={15} width={15} />
  </button>
);

export default AssetFilterAddNewButton;
