import React from 'react';
import Icon from 'components/icons/Icon';

import './AssetsDetailsFullScreen.scss';

interface IAssetsDetailsFullScreen {
  url: string;
  onClose(): void;
}

const AssetsDetailsFullScreen = ({ url, onClose }: IAssetsDetailsFullScreen): JSX.Element => (
  <div className="AssetsDetailsFullScreen">
    <div className="AssetsDetailsFullScreen-content">
      <div className="AssetsDetailsFullScreen-close-btn" onClick={onClose}>
        <Icon icon="icon-close" className="AssetsDetailsFullScreen-close" color="#808080" height={30} width={30} />
      </div>
      <div className="AssetsDetailsFullScreen-img-content">
        <img className="AssetsDetailsFullScreen-img" src={url} alt="" />
      </div>
    </div>
  </div>
);

export default AssetsDetailsFullScreen;
