import React from 'react';
import { getPlaceholderImage } from 'utils/AssetCardPlaceholder';
import { useTranslation } from 'react-i18next';

interface IPreviewNotAvailable {
  classname?: string;
  height: number;
  width: number;
  backgroundColor?: string;
}

const PreviewNotAvailable = ({ height, width, backgroundColor, classname }: IPreviewNotAvailable): JSX.Element => {
  const { t } = useTranslation();
  const url = getPlaceholderImage('', true);

  return (
    <div className={classname} style={{ height, width, backgroundColor }}>
      <img src={url} alt="" style={{ width: 72 }} />
      <div className="space-m-t-4">{t('common.preview_not_available')}</div>
    </div>
  );
};

export default PreviewNotAvailable;
