import { ILogReport, HandledError, handleErrorToLogData, ILogUserData, ILogHttpData, ILogUrlData, HttpMethodEnum, IResponseData } from '@cdam/shared';
import UploadUtils from '../utils/UploadUtils';
import ClientHttpService, { UrlType, IClientHttpServiceOptions } from './ClientHttpService';

class LoggingService {
  public async log(report: ILogReport, handledError?: HandledError): Promise<void> {
    const userData: ILogUserData = this.getUserData();

    report.user = userData;

    if (handledError) {
      report.severity = handledError.severity;
      report.http = this.getHttpData(handledError.requestData as IClientHttpServiceOptions, handledError.responseData);
      report.url = this.getUrlData(handledError.requestData as IClientHttpServiceOptions);
      report.error = handleErrorToLogData(handledError);
    }

    try {
      await ClientHttpService.fetch({
        method: 'POST',
        url: '/logging',
        urlType: UrlType.Backend,
        body: report,
      });
    } catch (e) {
      // eslint-disable-next-line
      console.error(`Could not log event: ${report.message}`);
    }
  }

  private getHttpData(requestData: IClientHttpServiceOptions, responseData?: IResponseData): ILogHttpData {
    const httpData: ILogHttpData = {
      request: {
        method: requestData.method as HttpMethodEnum,
        body: {
          content: JSON.stringify(requestData.body),
        },
      },
    };

    if (responseData) {
      httpData.response = {
        status_code: responseData.status_code,
      };

      if (responseData.body) {
        httpData.response.body = {
          content: responseData.body ? JSON.stringify(responseData.body) : '',
        };
      }
    }

    return httpData;
  }

  private getUrlData(requestData: IClientHttpServiceOptions): ILogUrlData {
    return {
      full: ClientHttpService.getAbsoluteUrl(requestData.urlType, requestData.url),
    };
  }

  private getUserData(): ILogUserData {
    const { userName: id, email, marvinUsername: name } = UploadUtils.getUniqUserNameAndEmail();

    return {
      id,
      name,
      email,
    };
  }
}

// singletone
export default new LoggingService();
