import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import Button, { ButtonType, ButtonSize } from '../../../components/input/Button';

import './AlertDialog.scss';

interface IAlertProps {
  text: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const AlertDialog = observer(({ text, onClick }: IAlertProps) => {
  const { t } = useTranslation();

  return (
    <div className="alert-dialog modal">
      <div className="alert-dialog-text">{text}</div>
      <Button className="alert-dialog-button" buttonType={ButtonType.Primary} size={ButtonSize.Default} onClick={onClick}>
        {t('common.ok')}
      </Button>
    </div>
  );
});
